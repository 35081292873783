import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { org_agreement_application } from '../../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';

export default function Oagreement() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const navigate = useNavigate();

    const schema = yup.object().shape({
        // date: yup.string().required("Date is required"),
        org_name: yup.string().required("Organization name is required"),
        org_address: yup.string().required("Address is required"),
        first_name: yup.string().required("Name is required"),
        org_position: yup.string().required("Position is required"),
        date1: yup.string().required("Date is required"),
        checkbox: yup.boolean()
            .oneOf([true], 'You must agree this agreement')
            .required('You must agree this agreement'),
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const today = new Date();
  // Format date as DD-MM-YYYY
  const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
  const [StartDate, setStartDate] = useState(formattedDate);

    let onSubmit = async(data) => {
        console.log('data :', data);

        let submit_data = {
            org_id : localStorage.getItem("Tid"),
            date : data.date,
            org_name : data.org_name,
            org_address : data.org_address,
            org_first_name : data.first_name,
            org_position: data.org_position,
            org_date : data.date1,
        }

        org_agreement_application(submit_data).then((response)=>{
            if(response?.code == 1){
                TOAST_SUCCESS(response.message)
                navigate("/organization-step")
                reset();
            }else{
                TOAST_ERROR(response.message)
            }
        })
      
    }

    return (
        <>


            <main>
                <div className="login_sec">
                    <div className="container-fluid">
                        <div className="row my-4 justify-content-center">
                            <div className="col-lg-8">
                                <div className="top">
                                <div className="arrow_back mb-3">
                                        <a onClick={() => navigate(-1)} className="d-flex align-items-center">
                                            <span>
                                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                                                </svg>
                                            </span>
                                            <span onClick={() => navigate(-1)} className="ps-3">Back</span>
                                        </a>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="educational-organizations">
                                                    <h2 className="educational-h2">
                                                        Educational Organizations Terms of Service
                                                    </h2>
                                                    <div className="educational-sub">
                                                        <p className="educational-p text-center">
                                                            THIS ORGANIZATION AGREEMENT (the “Agreement”),
                                                        </p>
                                                        {/* <p className="educational-p text-center">
                                                            is date &nbsp;
                                                            <input type="date" {...register("date")} name="date" min={StartDate} value={StartDate} className="form-control w-25 d-inline-block" />
                                                            &nbsp; entered into by and between STUDENTSCHOLARS.
                                                        </p> */}
                                                        <p className="educational-p text-center ">
                                                            is date &nbsp;
                                                         {StartDate} 
                                                            &nbsp; entered into by and between STUDENTSCHOLARS.
                                                        </p>
                                                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date?.message}</span></p> */}

                                                        <p className="educational-p text-center">
                                                            INC. with the principal business office at 7725 Birchmount Road,
                                                            Unit
                                                            38, Markham,
                                                        </p>
                                                        <p className="educational-p text-center">
                                                            Ontario, L3R 9X3 Canada and
                                                        </p>
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">
                                                                Organization Name :
                                                            </label>
                                                            <input type="text" className="form-control" {...register("org_name")} name="org_name"  />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_name?.message}</span></p>

                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <p className="educational-p">
                                                                with the principal business office at
                                                            </p>
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">
                                                                Full Organization Address:
                                                            </label>
                                                            <textarea {...register("org_address")}  name="org_address" id cols={30} className="form-control" rows={2}  />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_address?.message}</span></p>
                                                        </div>
                                                        <p className="educational-p">
                                                            These Educational Organization Terms of Service (“EO Terms”)
                                                            together
                                                            with the User Terms of Service (“Terms”) and StudentScholars teacher
                                                            terms of service (Teacher Terms) govern your use of the Services,
                                                            including, without limitation, your registration and use of your
                                                            educational organizations account (“EO Account”), creation,
                                                            provision or
                                                            management of any Class or Content (“EO Services”) through the
                                                            Services
                                                            or your use of any learning- or class-management functionality
                                                            provided
                                                            by StudentScholars Inc. and its parent companies, subsidiaries
                                                            and/or
                                                            affiliates (“StudentScholars”, “StudentScholars Academic, Art &amp;
                                                            Music
                                                            Centre Inc.”,“we”, “us” and “our”).
                                                        </p>
                                                        <p className="educational-p">
                                                            By signing up for an EO Account and using and accessing the
                                                            Services,
                                                            you agree to be bound by these EO Terms, including, without
                                                            limitation,
                                                            the Terms, our Payment Policy, our Refund Policy, our Content
                                                            Policy,
                                                            our Safety Policy, the Restrictions (collectively, the “Policies”)
                                                            and
                                                            any additional terms and conditions, policies or schedules
                                                            referenced in
                                                            these EO Terms or the Terms or accepted or acknowledged by you in
                                                            your
                                                            use or access of the Services from time to time (“Platform Terms”).
                                                            Such
                                                            terms, conditions and policies are hereby incorporated by reference.
                                                            If
                                                            you do not agree to these EO Terms, you may not use or access the
                                                            Services using your EO Account.
                                                        </p>
                                                        <p className="educational-p">
                                                            We reserve the right to change or modify these EO Terms at any time.
                                                            If
                                                            we do this, we will notify you through the Services, by email or by
                                                            other reasonable means. You will be deemed to have agreed to the
                                                            amended
                                                            EO Terms by your continued use and access of your EO Account on and
                                                            following the date on which notice of the amended Terms was sent to
                                                            you.
                                                        </p>
                                                        <p className="educational-p">
                                                            Any terms not capitalised herein shall have the same meaning in the
                                                            Terms. In the event of any conflict or inconsistency between the EO
                                                            Terms and the Terms, the EO Terms will prevail to the extent of any
                                                            such
                                                            conflict or inconsistency.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            1. Educational Organizations Obligations
                                                        </h3>
                                                        <p className="educational-p">
                                                            Notwithstanding our Content Policy, our Safety Policy and the
                                                            Restrictions, you represent and warrant that:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                you are validly incorporated or established and legally existing
                                                                in
                                                                your country or place of incorporation or establishment, and
                                                                have
                                                                capacity, full power and authority to enter into these EO Terms
                                                                and
                                                                the Terms, and that these EO Terms and the Terms will
                                                                constitute,
                                                                legal, valid and binding obligations on you in accordance with
                                                                their
                                                                respective terms;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                your use and access of the Services in accordance with the EO
                                                                Terms
                                                                and the Terms will not be prohibited in your country or place of
                                                                incorporation or establishment, and that you will, at your own
                                                                cost
                                                                and expense, comply with all applicable laws and regulations
                                                                (including, without limitation, any applicable tax laws) in your
                                                                country or place of incorporation or establishment;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                comply with all applicable laws and the EO Terms and the Terms
                                                                in
                                                                your provision of Class and/or Content through the Services,
                                                                including the Content standards set out in EO Terms;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <p className="educational-p">
                                                                you (or your employees, teachers or authorised agents (where
                                                                applicable)) own or have or have obtained the necessary
                                                                licenses,
                                                                rights, consents, permissions, and authority to authorize us to
                                                                use
                                                                the Content submitted or uploaded by you (or any of your
                                                                employees,
                                                                teachers or authorised agents) to the Services as specified in
                                                                these
                                                                EO Terms, and that such Content will not infringe any third
                                                                party’s
                                                                rights, including, without limitation, any intellectual
                                                                property,
                                                                privacy or data protection rights or rights of confidentiality;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (e)
                                                            </p>
                                                            <p className="educational-p">
                                                                you have all the necessary licenses, approvals, permissions and
                                                                have
                                                                been duly registered (if and as required under the applicable
                                                                laws)
                                                                as an educational Organization (or the equivalent thereof) in
                                                                your
                                                                country of incorporation or establishment to offer and provide
                                                                the
                                                                EO Services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (f)
                                                            </p>
                                                            <p className="educational-p">
                                                                you have all the necessary licenses, approvals, permissions and
                                                                have
                                                                been duly registered (if and as required under the applicable
                                                                laws)
                                                                to operate an in-person location dedicated for education
                                                                Organization (or the equivalent thereof), if you wish to provide
                                                                in-person EO Services at your organization location in your
                                                                country
                                                                of incorporation or establishment;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (g)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                have
                                                                the required qualifications, credentials and expertise
                                                                (including
                                                                education, training, knowledge, nationality, technology and
                                                                skill
                                                                sets) and, if required under applicable law, all necessary
                                                                licenses,
                                                                certificates and permits to teach and offer the EO Services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (h)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                provide the EO Services with due care and skill and will not
                                                                infringe any third party rights in doing so, including, without
                                                                limitation, any intellectual property, data protection or
                                                                privacy
                                                                right or right of confidentiality;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (i)
                                                            </p>
                                                            <p className="educational-p">
                                                                you will comply with all applicable occupational health and
                                                                safety
                                                                laws in your provision of any Class and/or Content, including by
                                                                maintaining policies and training in accordance with all such
                                                                laws
                                                                and providing proof of same as requested by StudentScholars.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (j)
                                                            </p>
                                                            <p className="educational-p">
                                                                your Class and/or Content are complete, accurate and up-to-date
                                                                and
                                                                appropriate for students based on their age, grade and course of
                                                                study, and that requests for help or support by students
                                                                enrolled in
                                                                your Class are promptly and professionally responded to;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (k)
                                                            </p>
                                                            <p className="educational-p">
                                                                your Class and/or Content are not conducted in an unprofessional
                                                                or
                                                                inappropriate manner or in a manner that negatively impacts
                                                                students’ experience or results in complaints or an excessive
                                                                number
                                                                of poor ratings;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (l)
                                                            </p>
                                                            <p className="educational-p">
                                                                any Class or Content provided by you are suitable for the course
                                                                of
                                                                study set out in your Class’ description and that a conducive
                                                                learning environment is provided to students during Session (s);
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (m)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not cover any subjects or include any Content that may be
                                                                unsuitable
                                                                for students and minors, including, without limitation, any
                                                                subjects
                                                                or Content that might be violent, offensive, indecent,
                                                                inappropriate, racist, hateful, sexist, threatening, abusive,
                                                                insulting, harassing, false, defamatory, libellous, deceptive,
                                                                fraudulent, infringing, tortious, obscene, profane, racially or
                                                                ethnically objectionable and/or in breach of applicable laws;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (n)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not post or transmit any unsolicited or unauthorized
                                                                advertising,
                                                                promotional materials, junk mail, spam, or any other form of
                                                                solicitation (commercial or otherwise) through the Services or
                                                                to
                                                                any user;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (o)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not use the Services for business other than providing the EO
                                                                Services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (p)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not engage in any activity that would breach any third party
                                                                rights
                                                                (including, without limitation, intellectual property, data
                                                                protection and privacy rights or rights of confidentiality) or
                                                                require us to obtain licenses from or pay royalties to any third
                                                                party, including for the public performance of a musical work or
                                                                sound recording;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (q)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not frame or embed the Services (such as to embed a free version
                                                                of
                                                                a course) or otherwise circumvent the Services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (r)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not impersonate another person or gain unauthorized access to
                                                                another person's Account;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (S)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                will
                                                                not interfere with or otherwise prevent other instructors from
                                                                providing EO Services or Teacher Services, or abuse our
                                                                resources,
                                                                including our support services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (t)
                                                            </p>
                                                            <p className="educational-p">
                                                                any information provided by you or any of your employees,
                                                                teachers
                                                                or authorised agents as part of your EO Account (including any
                                                                information about your or your employees’ or your teachers’ or
                                                                your
                                                                authorised agents’ qualifications, credentials and expertise) is
                                                                complete, true, accurate and not misleading, and that you and
                                                                each
                                                                of your employees, teachers and authorised agents will provide
                                                                and
                                                                maintain accurate account information; and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (u)
                                                            </p>
                                                            <p className="educational-p">
                                                                you and each of your employees, teachers and authorised agents
                                                                are
                                                                using and accessing the Services on your own behalf, and not on
                                                                behalf of any other person or entity.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            For more details, you may refer to our Content Policy, which you
                                                            must
                                                            comply with (“Safety and Class Policy”) and restrictions
                                                            (“Restrictions”), which will prevail in the event of any
                                                            inconsistency
                                                            or conflict with this Clause.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            2. Personnel
                                                        </h3>
                                                        <p className="educational-p">
                                                            You shall be solely liable and responsible for
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                the conduct of your teachers on the Platform,
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                compensating its your teacher for any Classes taught by such
                                                                your
                                                                teachers on your behalf,
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                resolving any disputes that may arise between you and your
                                                                teachers,
                                                                and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <div>
                                                                <p className="educational-p">
                                                                    enforcing any rights your organization may have against your
                                                                    teachers, and we shall bear no responsibility whatsoever in
                                                                    connection therewith. Any and all your teachers of your
                                                                    Organization
                                                                    who shall use or otherwise have access to the Platform as
                                                                    Teachers
                                                                    ("Organization Teachers")
                                                                </p>
                                                                <p className="educational-p">
                                                                    (i) shall comply with the terms of this Agreement and the
                                                                    Teacher Terms of Service agreement (including Organization’s
                                                                    obligations thereunder),
                                                                </p>
                                                                <p className="educational-p">
                                                                    (ii) have been advised of and shall respect the requirements
                                                                    related to Teachers outlined in the Teacher Terms of Service
                                                                    (including, but not limited to Safety and Class Policy,
                                                                    Content
                                                                    Policy, Restriction Policy), and
                                                                </p>
                                                                <p className="educational-p">
                                                                    (iii) shall have the necessary experience, qualifications,
                                                                    knowledge, competency and skill set necessary to provide the
                                                                    relevant Classes on Organization’s behalf.
                                                                    Furthermore, each Organization Teacher shall register on the
                                                                    Platform using a dedicated email address associated with the
                                                                    Organization (“Organization Email”), must maintain and have
                                                                    sole
                                                                    access to their specific Organization Email and associated
                                                                    account on the Platform during the term of this Agreement,
                                                                    and
                                                                    may be required to verify such Organization Email upon
                                                                    registration.
                                                                </p>
                                                                <h4 className="educational-h4">
                                                                    2.2. User Complaints
                                                                </h4>
                                                                <p className="educational-p">
                                                                    You shall provide us with all necessary assistance and
                                                                    support
                                                                    in the event of an issue with your classes, your teachers,
                                                                    or
                                                                    use of the Platform. Within ten business days of receipt,
                                                                    you
                                                                    shall provide us with a copy of any written complaint or a
                                                                    report of any verbal complaint received from any User or any
                                                                    third party, including any regulatory authority. All such
                                                                    complaints will be resolved between the you and the
                                                                    concerned
                                                                    User or third party. We do not have a contractual obligation
                                                                    to
                                                                    resolve such complaints, but shall have a right to require
                                                                    you
                                                                    to take commercially reasonable actions to resolve the
                                                                    complaint
                                                                    with the User or third party, as needed to protect the
                                                                    reputation of our company and the Platform.
                                                                </p>
                                                                <h4 className="educational-h4">
                                                                    2.3. Background Checks and Identity Verification.
                                                                </h4>
                                                                <p className="educational-p">
                                                                    You understand and agree that all your teachers must be
                                                                    subject
                                                                    to criminal background checks and identity verification
                                                                    pursuant
                                                                    to our company’s standard procedures, and that your teachers
                                                                    who
                                                                    fail or otherwise refuse to submit to a background check and
                                                                    identity verification shall not be permitted to teach on the
                                                                    Platform.
                                                                </p>
                                                                <p className="educational-p">
                                                                    We shall conduct such background checks pursuant to its
                                                                    standard
                                                                    procedures, provided, that
                                                                </p>
                                                                <div className="d-flex gap-4 align-items-center">
                                                                    <span>(i)</span>
                                                                    <p className="educational-p">
                                                                        You shall provide us with all such cooperation and
                                                                        assistance as we may request, or otherwise may be
                                                                        required,
                                                                        to enable us to conduct such background checks, and
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex gap-4 align-items-center">
                                                                    <span>(ii)</span>
                                                                    <p className="educational-p">
                                                                        You must immediately inform us if it becomes aware that
                                                                        any
                                                                        of your teacher’s has become ineligible to teach or work
                                                                        with students pursuant to any applicable laws, rules, or
                                                                        regulations.
                                                                    </p>
                                                                </div>
                                                                <h4 className="educational-h4">
                                                                    2.4. Organization and Authority.
                                                                </h4>
                                                                <p className="educational-p">
                                                                    You (i) are duly organized, validly existing and in good
                                                                    standing under the laws of its state of organization; (ii)
                                                                    has
                                                                    all necessary power and authority to carry on its business
                                                                    as
                                                                    now being conducted; and (iii) has the necessary power and
                                                                    authority to execute, deliver and perform this Agreement.
                                                                </p>
                                                                <h4 className="educational-h4">
                                                                    2.5. Authorization.
                                                                </h4>
                                                                <p className="educational-p">
                                                                    The execution, delivery and performance of this Agreement by
                                                                    you
                                                                    has been duly and validly authorized by all necessary action
                                                                    on
                                                                    the part of your Organization.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <h3 className="educational-h3">
                                                            3. Account
                                                        </h3>
                                                        <p className="educational-p">
                                                            You must register an EO Account in order to provide EO Services
                                                            using
                                                            the Services.
                                                        </p>
                                                        <p className="educational-p">
                                                            In registering for and maintaining your EO Account, you:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                must provide accurate and complete information (including any
                                                                contact details) regarding yourself and such documents and
                                                                information required by us, if applicable;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                must promptly update your EO Account information with any new
                                                                information (including any contact details) that may affect the
                                                                operation of your EO Account;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                authorise StudentScholars to make any inquiries we consider
                                                                necessary or appropriate to verify the information or documents
                                                                provided to us; and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <p className="educational-p">
                                                                acknowledge and accept any applicable policies, including,
                                                                without
                                                                limitation, pertaining to service quality, confidentiality,
                                                                teacher
                                                                integrity, anti-harassment and conflict of interest, as provided
                                                                to
                                                                you by email and/or posted on the Services
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (e)
                                                            </p>
                                                            <p className="educational-p">
                                                                You are responsible for all activities and transactions under
                                                                your
                                                                EO Account. We will not be responsible in any way if your EO
                                                                Account
                                                                and/or password are misappropriated or used by a third party.
                                                                You
                                                                agree to keep your password secure, and not share your login
                                                                credentials with any other person.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (f)
                                                            </p>
                                                            <p className="educational-p">
                                                                You must notify us immediately of any unauthorised use (or if
                                                                you
                                                                suspect any other breach of security) of your EO Account by
                                                                contacting us using our contact details below.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            Unless expressly permitted by us, you may not:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (i)
                                                            </p>
                                                            <p className="educational-p">
                                                                sub-contract or delegate the provision of the EO Service or
                                                                otherwise permit another person to use or access your EO Account
                                                                or
                                                                provide the EO Services through the EO Account on your behalf,
                                                                save
                                                                for your employees, teachers or other authorised agents;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (ii)
                                                            </p>
                                                            <p className="educational-p">
                                                                transfer, lend or sell your EO Account to any person; or
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (iii)
                                                            </p>
                                                            <p className="educational-p">
                                                                set up multiple EO Accounts, including for the purpose of
                                                                bypassing
                                                                any EO Account restrictions, suspensions or terminations.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            We reserve the right to, at our discretion, restrict, suspend or
                                                            terminate the use of your EO Account for any reason at any time,
                                                            including, without limitation, where we suspect the unauthorised use
                                                            of
                                                            your EO Account, where your use of the Services or your EO Account
                                                            does
                                                            not comply with the Policies or for breach of applicable laws, these
                                                            EO
                                                            Terms or the Terms.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            4. Class
                                                        </h3>
                                                        <p className="educational-p">
                                                            You may, through the Services, provide Class on such subject, for
                                                            such
                                                            duration, on such schedule, with such minimum and/or maximum number
                                                            of
                                                            students, at such price and such minimum number of Sessions to be
                                                            purchased upfront as you may determine, subject to our approval in
                                                            accordance with these EO Terms.
                                                        </p>
                                                        <p className="educational-p">
                                                            You may, to the extent such function is available on the Services,
                                                            cancel or reschedule a Class or Session, subject to our Refund
                                                            Policy,
                                                            these EO Terms and the Terms, including, without limitation, any
                                                            refunds
                                                            a learner may be eligible to pursuant to our Refund Policy.
                                                        </p>
                                                        <p className="educational-p">
                                                            Notwithstanding our Refund Policy, we reserve the right to, at our
                                                            discretion, reschedule or cancel a Class or Session or remove or
                                                            restrict access to any Class, Session (s) or Content at any time,
                                                            including for breach of applicable laws, these EO Terms.
                                                        </p>
                                                        <p className="educational-p">
                                                            You and your teachers shall carry out Class only in accordance with
                                                            our
                                                            Safety Policy and shall not, among other provisions in our Safety
                                                            Policy, use tools other than the Services or as permitted in the
                                                            Safety
                                                            Policy or use substitute teachers (other than those who are teachers
                                                            in
                                                            your Organization).
                                                        </p>
                                                        <p className="educational-p">
                                                            For more details, you may wish to refer to our Refund Policy.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            5. Payments
                                                        </h3>
                                                        <h3 className="educational-h3">
                                                            5.1 Pricing and payments
                                                        </h3>
                                                        <p className="educational-p">
                                                            Subject to our approval, which we may grant or deny at our
                                                            discretion,
                                                            you may determine the price to be charged for Sessions in a Class
                                                            (“Enrollment Fees”) and, if applicable, the minimum number of
                                                            Session
                                                            (s) to be purchased upfront by students for your Class Enrollment
                                                            Fees” ), subject to the Payment Policy. All prices shall, unless
                                                            stated
                                                            otherwise on the Services, be listed as per the organization’s
                                                            country
                                                            currency and charged as per the Student’s country currency.
                                                        </p>
                                                        <p className="educational-p">
                                                            Per the Teacher Terms of Service, you shall set the Enrollment Fees
                                                            for
                                                            its Classes in its discretion, and hereby appoints us as your
                                                            limited
                                                            payment collection agent solely for the purpose of accepting the
                                                            Enrollment Fees from Users. Subject to the terms of the Teacher
                                                            Terms of
                                                            Service , we will transfer the Enrollment Fees to you for each sale
                                                            of a
                                                            Class (or set of Classes, as applicable)
                                                        </p>
                                                        <ul className="educational-ul">
                                                            <li className="educational-li">
                                                                <p className="educational-p">
                                                                    after seven (7) days of the <strong>start</strong> of the
                                                                    purchased Class period
                                                                    for on-going weekly classes, courses and one-day classes
                                                                </p>
                                                            </li>
                                                            <li className="educational-li">
                                                                <p className="educational-p">
                                                                    after seven (7) days of the <strong>completion</strong> of
                                                                    the
                                                                    purchased Class
                                                                    for multi-day classes
                                                                </p>
                                                            </li>
                                                            <li className="educational-li">
                                                                <p className="educational-p">
                                                                    after seven (7) days of the <strong>completion</strong> of
                                                                    each
                                                                    month for monthly
                                                                    or recurring monthly classes.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                        <p className="educational-p">
                                                            The fees will be minus forty percent (40%) for online class, and
                                                            twenty
                                                            percent (20%) for classes held at your educational location, which
                                                            shall
                                                            be retained by us as a service fee. Further, organizations who will
                                                            provide onsite classes, will pay a student introduction fee, which
                                                            is
                                                            the 1st class Enrollment Fees for each student. Enrollment Fees may
                                                            be
                                                            subject to refund in the event of a User dispute, as set forth in
                                                            the
                                                            Teacher Terms of Service.
                                                        </p>
                                                        <p className="educational-p">
                                                            We may also, from time to time, carry out promotional activities
                                                            relating to the Services, including, without limitation, promotions,
                                                            marketing activities and discounts in respect of the Services or
                                                            specific Class or categories of Class (“Promotional Activities”). In
                                                            the
                                                            event you opt in to these Promotional Activities, you agree that any
                                                            promotions or discounts applied to your Class Fees (“Discounts”) may
                                                            be
                                                            deducted from your Gross Fees.
                                                        </p>
                                                        <p className="educational-p">
                                                            We may increase the sale price of your Class at our discretion where
                                                            we
                                                            determine that Transaction Taxes may be due. For purchases through
                                                            mobile applications, applicable Transaction Taxes are collected by
                                                            the
                                                            mobile platform (such as Apple's App Store or Google Play).
                                                        </p>
                                                        <p className="educational-p">
                                                            For more details, please see our Payment Policy.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            5.2 Payment method
                                                        </h3>
                                                        <p className="educational-p">
                                                            Unless specified otherwise in these EO Terms, the Terms or the
                                                            Platform
                                                            Terms, all payments of the Net Fees to you will be made in United
                                                            States
                                                            (US$) dollars or such currency we may indicate from time to time, if
                                                            any, in respect of your country of residence regardless of the
                                                            currency
                                                            with which the sale was made. We are not responsible for any
                                                            currency
                                                            conversion fees, wiring fees, or any other processing fees or duties
                                                            you
                                                            may incur.
                                                        </p>
                                                        <p className="educational-p">
                                                            In order to transfer funds to Organization’s Stripe account,
                                                            Organization shall sign up for a Stripe account. We have the
                                                            discretion
                                                            to act on behalf of the Parent, and to not transfer the Enrollment
                                                            Fees
                                                            to Organization, if Parent reports that the Classes were not
                                                            provided or
                                                            adequately completed. This may include but is not limited to
                                                            circumstances where a Teacher did not arrive for a Class or a Class
                                                            was
                                                            of insufficient quality. We will independently review such cases,
                                                            seeking input from the Parent and/or the Organization, and may
                                                            decide at
                                                            its sole discretion to issue a refund to the Parent. All
                                                            determinations
                                                            of us with respect to a refund shall be final and binding on the
                                                            Parent
                                                            and Teacher.
                                                        </p>
                                                        <p className="educational-p">
                                                            You must, at all times, maintain such online payment account(s) or
                                                            method(s) or bank account(s) as we may require from time to time, if
                                                            any, based on your country of residence in good standing and keep us
                                                            informed as to the correct contact details associated with such
                                                            payment
                                                            method(s) or account(s).
                                                        </p>
                                                        <p className="educational-p">
                                                            The Payment Policy shall prevail to the extent of any conflict or
                                                            inconsistency with these terms. Please regularly review our Payment
                                                            Policy, which we may amend at our discretion from time to time, for
                                                            any
                                                            changes or updates..
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            5.3 Refunds
                                                        </h3>
                                                        <p className="educational-p">
                                                            You acknowledge and agree that students have the right to receive a
                                                            refund, as set out in these Terms and our Refund Policy. You will
                                                            not
                                                            receive any Class Fees from Sessions for which a full refund has
                                                            been
                                                            granted under the Terms.
                                                        </p>
                                                        <p className="educational-p">
                                                            If a Session is completed, we will generally not issue refunds to
                                                            students, but reserve the right to do so at our discretion if a
                                                            refund
                                                            request is due to a breach of applicable laws, these EO Terms or the
                                                            Terms.
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                deducted from the next or subsequent payment(s) sent or to be
                                                                sent
                                                                to you; or
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                where no further payments are due to you under terms (for
                                                                example,
                                                                due to a suspension or termination of your EO Account) or the
                                                                payments under terms are insufficient to cover the refunded
                                                                amounts,
                                                                require you to pay such amount refunded by us.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            For more details, you may refer to our Refund Policy.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            5.4 Taxes
                                                        </h3>
                                                        <p className="educational-p">
                                                            You shall be responsible for all taxes associated with the Services
                                                            other than Canadian taxes based on Company’s net income.
                                                        </p>
                                                        <p className="educational-p">
                                                            You or your teachers agree that you are not an employee of the
                                                            StudentScholars for any purpose. You are solely responsible for
                                                            filing
                                                            and paying any tax obligations, if any, and not entitled to vacation
                                                            pay, holiday pay, incentive plans, or benefit plans. You and your
                                                            teachers shall only be entitled to be paid the monies provided for
                                                            in
                                                            this Agreement.
                                                        </p>
                                                        <p className="educational-p">
                                                            You also acknowledge that upon termination of this Agreement, the
                                                            you
                                                            shall not be entitled to any payment from the us whether in the
                                                            nature
                                                            of a severance payment or otherwise, except as outlined in this
                                                            Agreement. Our liability to you shall be fulfilled by payment to you
                                                            the
                                                            monies to which the you are entitled pursuant to terms of this
                                                            Agreement.\
                                                        </p>
                                                        <p className="educational-p">
                                                            In addition, you agree that we shall have no responsibility for the
                                                            withholding, collection or payment or any taxes or payments of any
                                                            nature on your behalf or for your benefit or on behalf or for the
                                                            benefit of your teachers, including, but not limited to the
                                                            following:
                                                            income taxes, employment insurance, Pension Plan, worker’s
                                                            compensation,
                                                            and employer health tax. You agree to hold us harmless from and
                                                            against
                                                            any order, penalty, interest or tax that may be assessed or levied
                                                            against it as a result of its failure or delay to make such
                                                            payments, to
                                                            file any return or information required by any law or regulation, or
                                                            as
                                                            may be ordered by an applicable government ministry or agency
                                                            arising
                                                            from a determination that you or your teachers have been
                                                            misclassified
                                                            under applicable legislation.
                                                        </p>
                                                        <p className="educational-p">
                                                            You acknowledge that you are not an employee of ours and you shall
                                                            not
                                                            represent itself as such. You further acknowledge that as an
                                                            independent
                                                            contractor, you are not authorized or entitled to enter into any
                                                            contract or agreements on behalf of us or agree to bind us in any
                                                            respect, and covenants not to do so. You hereby fully indemnify and
                                                            save
                                                            harmless StudentScholars and its affiliates, subsidiaries,
                                                            directors,
                                                            officers and employees from any and all losses, liability or damages
                                                            which we may suffer or incur as a result of a breach of this
                                                            covenant by
                                                            you.
                                                        </p>
                                                        <p className="educational-p">
                                                            This Agreement does not prevent the you or your teachers from
                                                            providing
                                                            services to other companies during the term of this Agreement,
                                                            provided
                                                            the you and your teachers otherwise complies with the terms of this
                                                            Agreement.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            6. Limited Exclusivity.
                                                        </h3>
                                                        <p className="educational-p">
                                                            6.1 During the term of this Agreement (including any Renewal Term),
                                                            Organization shall (and shall cause its “Affiliates” (defined as an
                                                            individual, corporation, limited liability company, partnership,
                                                            joint
                                                            venture or association controlling or controlled by or under common
                                                            control with Organization) to) ensure that all Classes offered by
                                                        </p>
                                                        <p className="educational-p">
                                                            Organization on the Platform are
                                                        </p>
                                                        <p className="educational-p">
                                                            (i) actually taught by your teachers using the Platform,
                                                        </p>
                                                        <p className="educational-p">
                                                            (ii) made available exclusively to Users of the Platform, meaning
                                                            that
                                                            students who attend your Classes are limited to enrolled Users on
                                                            the
                                                            Platform, and students who are not enrolled on the Platform will not
                                                            be
                                                            permitted to attend your Classes without first enrolling on the
                                                            Platform, and
                                                        </p>
                                                        <p className="educational-p">
                                                            (iii) are not the same or substantially similar to classes offered
                                                            by
                                                            you for a lower price outside of the Platform.
                                                        </p>
                                                        <p className="educational-p">
                                                            6.2. You agree to not engage in any kind of off-Platform
                                                            solicitation of
                                                            users on the
                                                        </p>
                                                        <p className="educational-p">
                                                            STUDENTSCHOLARS platform, including but not limited to:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                by advertising competing, redundant, or otherwise standalone
                                                                services (“Competitive Services”) that you offer for sale
                                                                outside of
                                                                the Platform, including by referring to your Organizational
                                                                website
                                                                in oral or written communications (such as citing your
                                                                Organization's email address or using Organization's website in
                                                                your
                                                                teacher’s email signatures) or promoting or directing our users
                                                                to
                                                                your Organization's own website if it could expose them to
                                                                Competitive Services offered by you;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                asking users for their personal email addresses or otherwise
                                                                attempting to communicate with users outside of our approved
                                                                channels;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                harvesting or collecting email addresses or other contact
                                                                information of other users from the Platform by electronic or
                                                                other
                                                                means for the purposes of sending unsolicited emails or other
                                                                unsolicited communications. By using our Platform and customer
                                                                contact information to divert business to your Organization, you
                                                                recognize that it would be diverting business to you, and thus
                                                                depriving us of revenue to which it is entitled under this
                                                                Agreement. Upon discovery of any you or your teachers of
                                                                Organization engaging in any of the above described
                                                                solicitation, we
                                                                will fine you USD $800, which is intended to compensate us for
                                                                its
                                                                actual damages/lost revenue in that situation. Should you or
                                                                your
                                                                teachers of Organization engage in a second, subsequent,
                                                                solicitation, we may, in its discretion, terminate this
                                                                Agreement
                                                                and its relationship with your Organization. All determinations
                                                                regarding impermissible solicitations shall be made by us, in
                                                                its
                                                                discretion
                                                            </p>
                                                        </div>
                                                        <h3 className="educational-h3">
                                                            7. Confidentiality
                                                        </h3>
                                                        <p className="educational-p">
                                                            You understand that we have disclosed or may disclose business,
                                                            technical or financial information relating to our business
                                                            (hereinafter
                                                            referred to as “Confidential Information”). Such Confidential
                                                            Information includes, without limitation, any prices, fees, terms,
                                                            Personal Data, customer information, information on other teachers,
                                                            purchase or transaction information or data or analytics regarding
                                                            the
                                                            Services or lesson or teaching plans or materials provided by us (to
                                                            the
                                                            extent accessed and used by you as part of the Services), features,
                                                            functionality and performance of the Platform or Service but
                                                            excludes
                                                            any information that is or becomes generally available to the public
                                                            or
                                                            to you on a non-confidential basis, other than as a result of a
                                                            breach
                                                            of your or any other person’s confidentiality obligations. You
                                                            agree:
                                                        </p>
                                                        <p className="educational-p">
                                                            Confidential Information includes non-public information regarding
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (i)
                                                            </p>
                                                            <p className="educational-p">
                                                                to take reasonable precautions to protect such Confidential
                                                                Information, and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <div>
                                                                <p className="educational-p">
                                                                    not to use (except as otherwise permitted herein) or divulge
                                                                    to
                                                                    any
                                                                    third person any such Confidential Information. The
                                                                    foregoing
                                                                    shall
                                                                    not apply with respect to any Confidential Information after
                                                                    five
                                                                    years following the disclosure thereof or any information
                                                                    that
                                                                    you
                                                                    can document
                                                                </p>
                                                                <p className="educational-p">
                                                                    (a) is or becomes generally available to the public, or \
                                                                </p>
                                                                <p className="educational-p">
                                                                    (b) was in its possession or known by prior to receipt from
                                                                    you,
                                                                    or
                                                                </p>
                                                                <p className="educational-p">
                                                                    (c) was rightfully disclosed to it without restriction by a
                                                                    third party, or
                                                                </p>
                                                                <p className="educational-p">
                                                                    (d) was independently developed without use of any
                                                                    Confidential
                                                                    Information or
                                                                </p>
                                                                <p className="educational-p">
                                                                    (e) is required to be disclosed by law. Upon our request,
                                                                    you
                                                                    shall promptly return all documents and other materials
                                                                    received
                                                                    from us. We shall be entitled to injunctive relief for any
                                                                    violation of this Section.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p className="educational-p">
                                                            Unless expressly permitted by us in writing, you shall:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                keep all Confidential Information confidential and use it solely
                                                                for
                                                                the purposes of your use of the Services and provision of the EO
                                                                Services in accordance with these EO Terms (including the Safety
                                                                Policy), and not for any other purpose;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                not disclose the Confidential Information to any person, save to
                                                                the
                                                                extent such disclosure is required by law, by any governmental
                                                                or
                                                                regulatory authority or by a court of competent jurisdiction,
                                                                provided that, to the extent it is legally permitted to do so,
                                                                you
                                                                provide us as much notice of such disclosure as possible; and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                not make or retain any copies of the Confidential Information
                                                                and
                                                                shall, at StudentScholars’s instructions, destroy or return all
                                                                such
                                                                Confidential Information should you retain copies of the
                                                                Confidential Information for any reason.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <p className="educational-p">
                                                                You shall also ensure that any of your employees, teachers or
                                                                authorised agents adhere and comply with the confidentiality
                                                                requirements.
                                                            </p>
                                                        </div>
                                                        <h3 className="educational-h3">
                                                            8. Personal Data
                                                        </h3>
                                                        <p className="educational-p">
                                                            In the event you obtain or are provided access to any Personal Data
                                                            pursuant to your use or access of the Services (including, without
                                                            limitation, your provision of the EO
                                                        </p>
                                                        <p className="educational-p">
                                                            Services or the purchase of your Class by students), you may, unless
                                                            expressly permitted by us in writing in advance:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                only use and access such Personal Data solely within the
                                                                Services in
                                                                accordance with EO Terms and the Safety Policy, and only for the
                                                                purpose of providing the EO Services to students using the
                                                                Services,
                                                                and not for any other purpose; and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                not transfer or copy the Personal Data or transfer or disclose
                                                                the
                                                                Personal Data to any person.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            You must, in addition to the above, comply with all applicable data
                                                            protection laws and regulations at your own cost and expense,
                                                            including,
                                                            without limitation, by making reasonable security arrangements to
                                                            prevent the unauthorised or accidental access, collection, use,
                                                            disclosure, copying, modification, disposal or destruction of
                                                            Personal
                                                            Data.
                                                        </p>
                                                        <p className="educational-p">
                                                            You must, at your cost and expense, promptly notify us of any
                                                            actual, or
                                                            suspected data breach relating to Personal Data, and provide us all
                                                            reasonable assistance in relation to any investigations relating to
                                                            Personal Data.
                                                        </p>
                                                        <p className="educational-p">
                                                            The provisions in equal force on each of your employees, teachers
                                                            and
                                                            authorised agents, save where any notification to be provided to us,
                                                            for
                                                            such notification to be given by the aforementioned individual to
                                                            yourself and subsequently from you to us.
                                                        </p>
                                                        <p className="educational-p">
                                                            For the purposes of these EO Terms, “Personal Data” means data,
                                                            whether
                                                            true or not, about an individual who can be identified from that
                                                            data or
                                                            from that data and other information to which the organisation has
                                                            or is
                                                            likely to have access.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            9. License
                                                        </h3>
                                                        <p className="educational-p">
                                                            By submitting or uploading Content to the Services, you grant us a
                                                            non-exclusive, worldwide, royalty-free, fully paid, transferrable,
                                                            sub-licensable, perpetual and irrevocable license to reproduce,
                                                            publish,
                                                            perform, distribute, communicate, transmit and adapt (including,
                                                            without
                                                            limitation, by adding captions or otherwise modifying the Content to
                                                            ensure accessibility) the Content for the purposes of:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                making the Content available for purchase, viewing and access on
                                                                the
                                                                Services;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                subject to the payment of the relevant Class Fees, permitting
                                                                users
                                                                of the Services to view and access the Content through the
                                                                Services;
                                                                and
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                offering or marketing the Services (including any EO Services
                                                                accessible therein).
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            In the event that the license of the Content submitted or uploaded
                                                            by
                                                            you (or any of your employees, teachers or authorised agents) to the
                                                            Services is owned by another party other than yourself, you
                                                            undertake to
                                                            have obtained the necessary license, consent or permission from such
                                                            owner (in writing) in order for us to do the same as provided above.
                                                        </p>
                                                        <p className="educational-p">
                                                            In the event you use any Content provided by us or a third party
                                                            content
                                                            provider as part of the Services, you agree to comply with the terms
                                                            of
                                                            any third party notices or licences provided to you in respect of
                                                            such
                                                            Content, where such Content is provided by a third party content
                                                            provider. Where such Content is provided by us or the aforementioned
                                                            notices or licenses are not provided, you shall use and access such
                                                            Content on the basis of a limited, revocable (with or without
                                                            cause),
                                                            non-exclusive, non-transferable, non-sublicensable license solely
                                                            for
                                                            the provision of your Class through the Services, and not at any
                                                            time
                                                            reproduce, redistribute, transmit, broadcast, share, lend, modify,
                                                            adapt, edit, create derivative works of, sublicense or otherwise
                                                            transfer such Content (other than within the Services) or for any
                                                            other
                                                            purpose. You further undertake to ensure compliance of the
                                                            aforementioned requirements in relation to access and use of any
                                                            such
                                                            Content by any of your employees, teachers or authorised agents.
                                                        </p>
                                                        <p className="educational-p">
                                                            Notwithstanding the foregoing, you have the right to remove all or
                                                            any
                                                            portion of your Content from the Services at any time. Removal of
                                                            your
                                                            Content will terminate the foregoing license [sixty (60) days] after
                                                            such removal in respect of new users; provided, however, that any
                                                            rights
                                                            or licenses given to users who have purchased access to your Content
                                                            prior to that time will continue in accordance with the Terms.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            10. Content Standards
                                                        </h3>
                                                        <p className="educational-p">
                                                            You agree to abide by and undertake to ensure that each of your
                                                            employees, teachers and authorised agents will abide by our content
                                                            quality standards or policies prescribed by us on the Services from
                                                            time
                                                            to time. You should check these standards or policies periodically
                                                            to
                                                            ensure that you comply with any updates to them. You understand that
                                                            your use of the Services (including, without limitation, your
                                                            provision
                                                            of EO Services or the publication or display of any Class or Content
                                                            on
                                                            the Services) may be subject to a review period (if applicable) and
                                                            is
                                                            ultimately subject to our Content Policy, the Restrictions and our
                                                            approval, which we may grant or deny at our sole discretion.
                                                        </p>
                                                        <p className="educational-p">
                                                            You are not required to upload Content to be made available to
                                                            students
                                                            outside of Sessions. However, we may, at our Content Policy and the
                                                            Restrictions, require you to upload supporting materials and such
                                                            other
                                                            Content for Class carried out by you for such purpose.
                                                        </p>
                                                        <p className="educational-p">
                                                            We reserve the right to, at our discretion, remove or restrict
                                                            access to
                                                            any Class, Session(s) or Content, suspend payouts, and/or restrict,
                                                            suspend or terminate the use of your EO Account for any reason at
                                                            any
                                                            time without prior notice, including, without limitation, where:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                you or the EO Services provided by you (including that provided
                                                                by
                                                                your employees, teachers and authorised agents individually or
                                                                collectively) or your use of the Services or your EO Account do
                                                                not
                                                                comply with our Policies, applicable laws, these EO Terms or the
                                                                Terms;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                the EO Services provided by you (including that provided by your
                                                                employees, teachers and authorised agents individually or
                                                                collectively) falls below our quality standards or has a
                                                                negative
                                                                impact on users’ experience;
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                you, or any of your employees, teachers or authorised agents,
                                                                engage
                                                                in behavior that might reflect unfavorably on us or bring us
                                                                into
                                                                public disrepute; or
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <p className="educational-p">
                                                                as determined by us in our sole discretion.
                                                            </p>
                                                        </div>
                                                        <p className="educational-p">
                                                            For more details, please refer to Content Policy and the
                                                            Restrictions.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            11. Non-Solicitation
                                                        </h3>
                                                        <p className="educational-p">
                                                            The Organization agrees that during this Agreement and for a period
                                                            of 1
                                                            year (12 months) following the termination of this Agreement or
                                                            ending
                                                            of providing services via StudentScholars platform, the Organization
                                                            shall not solicit any students of the StudentScholars for teaching,
                                                            tutoring or performing any other service for a student who has
                                                            received
                                                            any services from the StudentScholars that are otherwise available
                                                            through the StudentScholars business.
                                                        </p>
                                                        <p className="educational-p">
                                                            If StudentScholars suspects that the organization is privately
                                                            teaching
                                                            StudentScholars students, StudentScholars as its own discretion has
                                                            the
                                                            authority to delete/block the teachers immediately from the
                                                            StudentScholars platform.
                                                        </p>
                                                        <p className="educational-p">
                                                            The Organization agrees that all student relationships at
                                                            StudentScholars are property of the StudentScholars and under no
                                                            circumstance may a Teacher or organization teach a student without
                                                            the
                                                            StudentScholars’s consent.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            12. Termination
                                                        </h3>
                                                        <p className="educational-p">
                                                            These EO Terms are effective unless and until terminated by either
                                                            you
                                                            or us. You may terminate these EO Terms at any time by deactivating
                                                            your
                                                            EO Account, if available through your EO Account settings, or
                                                            notifying
                                                            us that you no longer wish to use our Services.
                                                        </p>
                                                        <p className="educational-p">
                                                            We reserve the right to, at our sole discretion, and without
                                                            liability
                                                            or refund:
                                                        </p>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (a)
                                                            </p>
                                                            <p className="educational-p">
                                                                suspend or terminate your EO Account and/or your access to the
                                                                Services through the EO Account at any time for any reason
                                                                without
                                                                advance notice, including, without limitation, where your use of
                                                                the
                                                                Services or your EO Account does not comply with the Policies or
                                                                for
                                                                any breach of applicable laws, these EO Terms or the Terms; or
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (b)
                                                            </p>
                                                            <p className="educational-p">
                                                                change, modify, suspend or discontinue, whether temporarily or
                                                                permanently, the whole or any part of the Services at any time,
                                                                for
                                                                any reason, and without advance notice.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (c)
                                                            </p>
                                                            <p className="educational-p">
                                                                You acknowledge and agree that if you deactivate your EO Account
                                                                or
                                                                if we suspend or terminate your EO Account, you will lose any
                                                                information associated with your EO Account, including access to
                                                                any
                                                                Class or Content, and any outstanding payments due to you of
                                                                these
                                                                EO Terms will be paid to you in accordance with these EO Terms,
                                                                save
                                                                where any indemnities are due to us under EO Terms. We will not
                                                                be
                                                                liable for your or any other person’s loss of or loss of access
                                                                to
                                                                any Class or Content.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (d)
                                                            </p>
                                                            <p className="educational-p">
                                                                Upon termination, all licenses and rights granted to you or any
                                                                of
                                                                your employees, teachers or authorised agents (where applicable)
                                                                in
                                                                these EO Terms will immediately cease. Any rights or obligations
                                                                incurred by us or you prior to termination shall survive the
                                                                termination of these EO Terms between us and you for all
                                                                purposes.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (e)
                                                            </p>
                                                            <p className="educational-p">
                                                                The term of this Agreement commences on the date set forth above
                                                                and
                                                                shall be in effect for a period of one year, unless terminated
                                                                earlier in accordance with the Sections below. Thereafter, this
                                                                Agreement shall automatically renew for one (1) year terms (each
                                                                a
                                                                “Renewal Term”), unless either party gives written notice of its
                                                                intent to not renew the Agreement at least thirty (30) days
                                                                prior to
                                                                expiration of the initial term or any Renewal Term.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (f)
                                                            </p>
                                                            <p className="educational-p">
                                                                You will have access to your monthly receipts for 3 months,
                                                                please
                                                                copy it during this time as afterwards the receipts will be
                                                                deleted.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (g)
                                                            </p>
                                                            <p className="educational-p">
                                                                This Agreement shall automatically terminate if either party
                                                                hereto:
                                                                (i) is subject to a voluntary or involuntary bankruptcy
                                                                proceeding,
                                                                has a trustee or receiver appointed with respect to or assigns
                                                                for
                                                                the benefit of creditors, all or substantially all of such
                                                                party’s
                                                                assets, or fails to pay its debts and obligations when due; or
                                                                (ii)
                                                                ceases to operate its business or is dissolved or wound up.
                                                            </p>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (h)
                                                            </p>
                                                            <div>
                                                                <p className="educational-p">
                                                                    This Agreement may be terminated by either party upon thirty
                                                                    days
                                                                    (30) advance written notice in the event that the other
                                                                    party
                                                                    materially defaults in the performance of any of its
                                                                    obligations
                                                                    hereunder (“Default”), and cure of such Default is not begun
                                                                    within
                                                                    such period and diligently prosecuted to completion,
                                                                    provided
                                                                    such
                                                                    notice shall specify the nature and extent of the claimed
                                                                    default.
                                                                    Furthermore, this Agreement may be terminated by us
                                                                    immediately
                                                                    upon
                                                                    written notice in the event that Organization Defaults two
                                                                    (2)
                                                                    or
                                                                    more times during the Term, regardless of whether or not
                                                                    such
                                                                    Defaults are cured.
                                                                </p>
                                                                <p className="educational-p">
                                                                    5.4. In addition to the foregoing, either Party may
                                                                    terminate
                                                                    this
                                                                    Agreement for its convenience upon thirty (30) days advance
                                                                    written
                                                                    notice to the other Party.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="educational-category">
                                                            <p className="educational-p">
                                                                (i)
                                                            </p>
                                                            <p className="educational-p">
                                                                All provisions of this Agreement (including the Teacher Terms of
                                                                Service) which, by their nature, should survive termination,
                                                                shall
                                                                survive termination, including, without limitation,
                                                                indemnification
                                                                and limitations of liability.
                                                            </p>
                                                        </div>
                                                        <h3 className="educational-h3">
                                                            13. Promotion.
                                                        </h3>
                                                        <p className="educational-p">
                                                            13.1. By choosing to participate in the Platform, you agree to
                                                            cooperate
                                                            with us to promote the Platform in a commercially reasonable manner
                                                            across its marketing channels and in full compliance with all
                                                            applicable
                                                            laws and regulations. Any written material referring or relating to
                                                            the
                                                            Platform, including the fact you participate in the Platform, shall
                                                            be
                                                            prepared or provided by us or, if prepared by you, shall be subject
                                                            to
                                                            review and approval by the us in advance of being used by you. Any
                                                            such
                                                            review and approval shall be limited to the review and approval of
                                                            Platform-specific representations and statements and shall not be
                                                            construed as a review or approval of any advertising or solicitation
                                                            materials for any other purpose or for compliance with any other
                                                            provisions of any local, state or federal laws not related to the
                                                            Platform.
                                                        </p>
                                                        <p className="educational-p">
                                                            13.2. You hereby consent to us identifying you as a customer and to
                                                            using your name, picture and/or logo, your introduction of yourself
                                                            ,
                                                            class video or your class description material in our website and
                                                            marketing materials.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            14. Miscellaneous
                                                        </h3>
                                                        <p className="educational-p">
                                                            14.1 You acknowledge and agree that nothing contained in these EO
                                                            Terms
                                                            shall constitute a partnership, agency, joint venture, employment or
                                                            franchisor-franchisee relationship between you (or any of your
                                                            employees, teachers or authorised agents) and StudentScholars.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.2 You acknowledge that StudentScholars does not, and does not
                                                            seek
                                                            to, exercise any general rights of control over you and your
                                                            provision
                                                            of the EO Services, including the scheduling, content and conduct of
                                                            any
                                                            Class or Sessions, save to the extent set out in these EO Terms and
                                                            the
                                                            Terms, which you acknowledge is for the purposes of providing the
                                                            Services to you. You retain the discretion to use the Services and
                                                            create, run, manage, accept, decline, or cancel any Class or
                                                            Sessions
                                                            through the Services subject to these EO Terms and the Terms, and
                                                            may
                                                            cease to use the Services at any time.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.3 You will be directly responsible for complying with any laws or
                                                            regulations applicable to your provision (including the provision by
                                                            any
                                                            of your employees, teachers or authorised agents) of Class, Sessions
                                                            or
                                                            Content through the Services, including, without limitation, any
                                                            tax,
                                                            social security or employment contribution or insurance obligations
                                                            incurred by you under your country or place of residence and any
                                                            licenses, permits, consents, approvals or fees required, if any, for
                                                            your performance of the EO Services.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.4 To the extent permitted by law, you will indemnify and hold us
                                                            harmless against any claims, penalties or fines by any authority or
                                                            third party for non- or under-payment or non-compliance with the
                                                            above.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.5 Effect of this Agreement. Except as modified by this Agreement,
                                                            the
                                                            Teacher Terms of Service shall continue in full force and effect as
                                                            originally constituted and is ratified and affirmed by the parties
                                                            hereto.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.6 Entire Agreement. This Agreement and the ToS, including all
                                                            exhibits thereto, constitutes the entire contract between the
                                                            parties
                                                            hereto with regard to the matters described herein. They supersede
                                                            any
                                                            other agreements, representations or understandings (whether oral or
                                                            written and whether express or implied) that relate to the subject
                                                            matter hereof.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.7 Amendment. This Agreement, and each of the terms and provisions
                                                            hereof, may only be amended, modified, waived, or supplemented by an
                                                            agreement in writing signed by each Party.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.8 Counterparts. This Agreement may be executed in two or more
                                                            counterparts, via electronic means or otherwise, each of which shall
                                                            be
                                                            deemed an original, but all of which together shall constitute one
                                                            and
                                                            the same instrument.
                                                        </p>
                                                        <p className="educational-p">
                                                            14.9 Choice of Law. The validity, interpretation, construction and
                                                            performance of this Agreement and the ToS shall be governed by the
                                                            laws
                                                            of the Province of Ontario and the applicable laws of Canada
                                                            therein.
                                                            This Agreement shall be interpreted with all necessary changes in
                                                            gender
                                                            and in number as the context may require.
                                                        </p>
                                                        <h3 className="educational-h3">
                                                            15. Insurance and Indemnity
                                                        </h3>
                                                        <p className="educational-p">
                                                            You shall maintain, at your own expense, liability insurance
                                                            coverage
                                                            against third party injury of any kind arising from your premises
                                                            and
                                                            provision of any Class and/or Content, including teacher abuse
                                                            claims
                                                            coverage. You shall provide evidence of such insurance coverage and
                                                            coverage amounts in accordance with this Agreement as requested by
                                                            StudentScholars from time-to-time.
                                                        </p>
                                                        <p className="educational-p">
                                                            You agree to indemnify StudentScholars and hold it harmless from all
                                                            losses, damages, liabilities and expenses (including reasonable
                                                            legal
                                                            fees) incurred by StudentScholars or by any third party to whom
                                                            StudentScholars becomes liable, as a result of any claims, charges,
                                                            taxes or other penalties and demands which may be made by any
                                                            person,
                                                            agency, authority or entity based on any act or omission by you,
                                                            directly or indirectly in connection with this Agreement, or as a
                                                            result
                                                            of the breach by you of any term of the Agreement or arising from
                                                            your
                                                            provision of any Class and/or Content. This indemnity shall survive
                                                            termination of this Agreement.
                                                        </p>
                                                        <p className="educational-p">
                                                            IN WITNESS WHEREOF, educational organization and StudentScholars
                                                            Inc.
                                                            have executed and delivered this Agreement as of the date first set
                                                            forth above.
                                                        </p>
                                                        <div className="form-group mb-3 d-flex gap-4 align-items-center ">
                                                            <input type="checkbox" id="ideclare"  {...register('checkbox')} name='checkbox' />
                                                            <label htmlFor="ideclare" className="d-block">
                                                                I hereby read, understand and agree to the terms
                                                            </label>
                                                        </div>
                                                       <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox?.message}</span></p>

                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">
                                                                    First and Last name:
                                                                </label>
                                                                <input type="text" className="form-control"{...register("first_name")}  name="first_name" />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.first_name?.message}</span></p>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">
                                                                    Position in the Organization:
                                                                </label>
                                                                <input type="text" className="form-control" {...register("org_position")} name="org_position" />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_position?.message}</span></p>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2 mx-2">
                                                                    Date:
                                                                    {/* <span className="text-danger">*</span> */}
                                                                </label>
                                                                <input type="date" className="form-control w-25 d-inline-block" {...register("date1")} name="date1" />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date1?.message}</span></p>

                                                            </div>
                                                        </div>
                                                        <div className="button mt-5">
                                                            <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                                                                Submit
                                                            </button>
                                                            {/* <a href="dashboard.php" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
