import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TOAST_SUCCESS, TOAST_ERROR } from "../../../utils/common.service";
import { request_class, main_category_list, sub_category_request_class, request_name_details, get_user_budget } from "../../../api/apiHandler";
// import Select from 'react-select/dist/declarations/src/Select'
import Select from "react-select";
import moment from "moment-timezone";

export default function PReqForm() {
  var [NameDetails, set_NameDetails] = useState(null);

  useEffect(() => {
    request_name_Details();
  }, []);


  var [data, setData] = useState([]);
  var [currency, setCurrency] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  var [SelectedCatID, setSelectedCatID] = useState([]);
  var [SelectedCategory, SetSelectedCategory] = useState([]);
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  console.log("🚀 ~ PReqForm ~ SelectedSubCat:", SelectedSubCat);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
  console.log("SelectedSubCatID :", SelectedSubCatID);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);
  console.log("SelectedSubCategory :", SelectedSubCategory);

  const OpenCategoryModel = () => {
    console.log("OpenCategoryModel");

    main_category_list().then((resposnse) => {
      console.log("main_category_list", resposnse);

      if (resposnse?.code == 1) {
        setData(resposnse?.data);
        // setSelectedCatID([resposnse.data[0].category_id])
        // SetSelectedCategory([resposnse.data[0].category_name])
        setIsOpen(true);
      } else {
        console.log("data not found");
        setData([]);
      }
    });
  };

  const getConvertedCurrency = () => {
    get_user_budget().then((resposnse) => {
      console.log("get_user_budget", resposnse);

      if (resposnse?.code == 1) {
        setCurrency(resposnse?.data);

      } else {
        console.log("data not found");
        setCurrency([]);
      }
    });
  }

  useEffect(() => {
    OpenCategoryModel();
    getConvertedCurrency();
  }, []);

  const roundToNearest50 = (num) => {
    return Math.round(num / 5) * 5;
  };

  const roundedCurrencyValues = currency?.map(value => ({
    ...value,
    amount: roundToNearest50(parseFloat(value.amount))
}));

const budgetOptions = [
  { value: 'Under $25', label: `Under ${roundedCurrencyValues[0]?.currency_symbol}${roundedCurrencyValues[0]?.amount}` },
  { value: 'Under $25-$50', label: `Under ${roundedCurrencyValues[0]?.currency_symbol}${roundedCurrencyValues[0]?.amount}-${roundedCurrencyValues[1]?.currency_symbol}${roundedCurrencyValues[1]?.amount}` },
  { value: 'Under $50-75', label: `Under ${roundedCurrencyValues[1]?.currency_symbol}${roundedCurrencyValues[1]?.amount}-${roundedCurrencyValues[2]?.currency_symbol}${roundedCurrencyValues[2]?.amount}` },
  { value: 'Under $75-$100', label: `Under ${roundedCurrencyValues[2]?.currency_symbol}${roundedCurrencyValues[2]?.amount}-${roundedCurrencyValues[3]?.currency_symbol}${roundedCurrencyValues[3]?.amount}` },
  { value: 'Over $100+', label: `Over ${roundedCurrencyValues[3]?.currency_symbol}${roundedCurrencyValues[3]?.amount}` },
  { value: 'Does not matter', label: 'Does not matter' }
];


  const SubCategoryList = () => {
    sub_category_request_class({ category_id: SelectedCatID }).then((resposnse) => {
      console.log("sub_category_request_class", resposnse);

      if (resposnse.code == 1) {
        setSelectedSubCat(resposnse?.data);
        if (SelectedSubCatID.length > 0) {
          setSelectedSubCatErr("abcd");
          // alert(SelectedSubCatErr)
        }
        // setSelectedSubCatID([resposnse.data[0].subject_category_id])
        // SetSelectedSubCategory([resposnse.data[0].subject_name])

        setIsOpen(true);
      } else {
        console.log("data not found");
      }
    });
  };

  useEffect(() => {
    SubCategoryList();
  }, [SelectedCatID]);

  const handleItemClick = (id, cat_name) => {

    clearErrors("category_list")
    if (SelectedCatID != id) {
      setSelectedSubCat([]);
      setSelectedSubCatID([]);
      SetSelectedSubCategory([]);
    }

    setSelectedCatID(id);
    SetSelectedCategory(cat_name);
  };

  const handleItemClick2 = (sub_id, sub_cat_name) => {
    clearErrors("subcategory_list")

    console.log("sub_cat_name :", sub_cat_name);
    console.log("sub_id :", sub_id);
    // setSelectedSubCatID(sub_id)
    // SetSelectedSubCategory(sub_cat_name)

    if (SelectedSubCatID.includes(sub_id)) {
      // selectSubject.includes(subjectName)
      let filteredArray = SelectedSubCatID.filter((item) => item !== sub_id);
      // console.log('filteredArray',filteredArray)
      setSelectedSubCatID(filteredArray);
      let filteredArray1 = SelectedSubCategory.filter((item) => item !== sub_cat_name);
      // console.log('filteredArray',filteredArray)
      SetSelectedSubCategory(filteredArray1);
    } else {
      setSelectedSubCatID((prevSelectSubject) => [...new Set([...prevSelectSubject, sub_id])]);
      SetSelectedSubCategory((prevSelectSubject) => [...new Set([...prevSelectSubject, sub_cat_name])]);
    }
  };



  const request_name_Details = (data) => {
    console.log("getting data favorites_details", data);
    // return;
    request_name_details().then((response) => {
      console.log(response, "request_name_details");

      if (response.code == 1) {
        set_NameDetails(response?.data);
        console.log(response?.data[0]?.full_name, "request_name_details data");
      } else {
        console.log("Data not found");
      }
    });
  };

  const [ClassDurationData, setClassDurationData] = useState("30 minutes");
  console.log("ClassDurationData :", ClassDurationData);

  const ClassDuration = (data) => {
    console.log(data);
    setClassDurationData(data);
  };

  const [showSpecifyInput, setShowSpecifyInput] = useState(false);
  console.log("showSpecifyInput :", showSpecifyInput);

  const handleSelectChange2 = (e) => {
    const selectedValue = e.target.value;
    setShowSpecifyInput(selectedValue === "Specify");
  };

  const [SpecifiedFrequency, setspecifiedFrequency] = useState("30 minutes");
  console.log("SpecifiedFrequency :", SpecifiedFrequency);

  const SpeciFiedFrequency = (data) => {
    console.log(data);
    setspecifiedFrequency(data);
  };

  const [nameMaxLength] = useState(50); // Adjust the character limit as needed
  const [nameCount, setnameCount] = useState(0);

  const handlenameChange = (e) => {
    const commentsValue = e.target.value;
    const newCount = commentsValue.length;

    // Ensure the character count doesn't go into negative values
    setnameCount(Math.max(0, newCount));
  };

  const [commentsMaxLength] = useState(200); // Adjust the character limit as needed
  const [commentsCount, setCommentsCount] = useState(0);

  const handleCommentsChange = (e) => {
    const commentsValue = e.target.value;
    const newCount = commentsValue.length;

    // Ensure the character count doesn't go into negative values
    setCommentsCount(Math.max(0, newCount));
  };

  const [expectationMaxLength] = useState(200); // Adjust the character limit as needed
  const [expectationCount, setExpectationCount] = useState(0);

  const handleExpectationChange = (e) => {
    const expectationValue = e.target.value;
    const newCount = expectationValue.length;

    setExpectationCount(newCount);
  };

  let schema = yup.object().shape({
    name: yup
      .string()
      .required("Name field is required")
      .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters and spaces"),
    budget_price: yup.string().required("Budget price is required"),
    age: yup.string().required("Age is required"),
    class_size: yup.string().required("Class size is required"),
    class_duration: yup.string().required("Class duration is required"),
    hire_someone: yup.string().required("Hire some one is required"),
    your_expectation: yup.string().required("Your expectation is required"),
    day_preference: yup.array().required("Day preference is required"),
    time_preference: yup.array().required("Time preference is required"),
  });

  if (ClassDurationData === "Specify") {
    schema = schema.shape({
      specify_duration: yup.string().required("Specify duration is required"),
    });
  }

  if (SelectedCategory.length == 0) {
    schema = schema.shape({
      category_list: yup.string().required("Category list is required"),
    });
  }

  if (SelectedSubCategory.length == 0) {
    schema = schema.shape({
      subcategory_list: yup.string().required("Subject category list is required"),
    });
  }

  if (showSpecifyInput) {
    console.log(showSpecifyInput, "showSpecifyInput in if con");
    schema = schema.shape({
      specify_lession: yup.string().required("Specify frequency is required"),
    });
  }

  const navigate = useNavigate();
  // validation for review form
  // const validationSchema = yup.object().shape({
  //   name: yup.string().required("Name field is required").matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  //   // timezone: yup.string().required("Time zone is required"),
  //   // category_list: yup.string().required("Category list is required"),
  //   // subcategory_list:yup.string().required("Subject category list is required"),
  //   budget_price: yup.string().required("Budget price is required"),
  //   // class_duration:yup.string().required("Class duration is required"),
  //   age: yup.string().required("Age is required"),
  //   class_size: yup.string().required("Class size is required"),
  //   // day_preference:yup.string().required("Day preference is required"),
  //   // time_preference:yup.string().required("Time preference is required"),
  //   class_duration: yup.string().required("Class duration is required"),
  //   specify_duration: yup.string().required("Specify duration is required"),
  //   // specify_duration: yup.string().when('class_duration', {
  //   //   is: (classDuration) => classDuration === 'specificValue', // Replace 'specificValue' with the actual value that requires specify_duration
  //   //   then: yup.string().required("Specify duration is required"),
  //   //   otherwise: yup.string()
  //   // }),
  //   hire_someone: yup.string().required("Hire some one is required"),
  //   your_expectation: yup.string().required("Your expectation is required"),
  //   want_lessons: yup.string().required("Want lession is required"),
  //   // specify_lession: yup.string().when('Specify', {
  //   //   is: 'Specify',
  //   //   then: yup.string().required("Specify lesson is required"),
  //   // }),

  // });

  const formOptions = { resolver: yupResolver(schema) };
  const { register, control, handleSubmit, watch, setError, reset, setValue, formState, clearErrors } = useForm(formOptions);
  const { errors } = formState;
  // const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
  //   resolver: yupResolver(schema)
  // });
  console.log("errors of request class form :", errors);

  const [selectedTimezone, setSelectedTimezone] = useState("");
  console.log("selectedTimezone :", selectedTimezone);
  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: `${tz} (UTC ${moment.tz(tz).format("Z")})`,
  }));

  useEffect(() => {
    // Detect and set the user's timezone as the default value.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(userTimezone, "121");
    setSelectedTimezone(userTimezone);
  }, []);

  let handleChange = (selectedOption) => {
    setSelectedTimezone(selectedOption.value);
    console.log(setSelectedTimezone, "126");
  };

  let onSubmit = async (data) => {
    console.log("data :", data);

    const days = data.day_preference ? data.day_preference.map((option) => option.value).join(",") : "";
    const time = data.time_preference ? data.time_preference.map((option) => option.value).join(",") : "";

    // const time = data.time_preference.map(option => option.value).join(',');

    console.log(days, "56");
    let specifiedFrequency = null;

    // var selectedValuesTimes = selectedTimes.map(day => day.label).join(',');
    // console.log('selectedValuesTimes :', selectedValuesTimes);
    var datatimezone = selectedTimezone;
    console.log("datatimezone :", datatimezone);
    console.log("data :", data);
    // return

    let submitData = {
      name: data.name,
      timezone: selectedTimezone,
      category_list: SelectedCategory,
      subcategory_list: SelectedSubCategory.join(","),
      budget_price: data.budget_price,
      class_duration: data.class_duration,
      // specify_duration:data.specify_duration,
      age: data.age,
      country: data.country,
      class_size: data.class_size,
      day_preference: days,
      time_preference: time,
      want_lessons: data.want_lessons,
      specify_duration: data.specify_duration ? data.specify_duration : "null",
      specify_lession: data.specify_lession ? data.specify_lession : "null",
      hire_someone: data.hire_someone,
      your_expectation: data.your_expectation,
      comments: data.comments ? data.comments : "null",
    };

    if (ClassDurationData == "Specify") {
      submitData.specify_duration = data.specify_duration != undefined ? data.specify_duration : "";
    }

    if (data.want_lessons === "Specify") {
      submitData.specify_lession = data.specify_lession != undefined ? data.specify_lession : "";
    }
    console.log(submitData, "105");

    // return
    request_class(submitData).then((res) => {
      console.log("submitData request_class", submitData);
      // return
      console.log("res :", res);
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        navigate("/request_class");
      } else {
        TOAST_ERROR(res.message);
      }
    });
    reset();
  };

  const handleSelectChange = (selectedOptions) => {
    // Set the selected values to the form state using setValue
    setValue("day_preference", selectedOptions);
    clearErrors("day_preference");

    // You can also perform any additional logic based on the selected values
    console.log("Selected values:", selectedOptions);
  };

  // const timeOptions = [
  //   { value: 'O1', label: 'No Preference: Flexible', image: './assets/images/dropdown_icon/time.jpg' },
  //   { value: 'O2', label: 'Morning: 9:00 AM- 12:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
  //   { value: 'O3', label: 'Afternoon: 12:00 - 4:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
  //   { value: 'O4', label: 'Evening: 4:00 - 7:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
  //   { value: 'O5', label: 'Night: 7:00 - 10:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
  // ];

  // const initialSelectedValues = [timeOptions[0]]; // Select the first option by default

  const handleSelectChange1 = (selectedOptions) => {
    // Set the selected values to the form state using setValue
    setValue("time_preference", selectedOptions);
    clearErrors("time_preference");

    // You can also perform any additional logic based on the selected values
    console.log("Selected values:", selectedOptions);
  };


  useEffect(() => {
    if (SelectedSubCatID.length > 0) setValue("subCategoriess", "e.value", { shouldValidate: true });
  }, [SelectedSubCatID]);

  if (NameDetails == null)
    return (
      <div className="container">
        <div className="mt-5"></div>
      </div>
    );

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <Helmet>
        <script>
          {`
            $(document).ready(function () {
              $('.js-example-basic-multiple').select2();
            });
          `}
        </script>

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" />

        <style>
          {`
          .select2-container--default .select2-selection--multiple .select2-selection__choice {
            background-color: #782f89;
            color: #fff;
          }
          `}
        </style>
      </Helmet>
      <div>
        <main className="dashboard-app">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            {/*start*/}
            {/* <a href="#" className="mb-4 arrow_left mb-3 d-block" onClick="window.history.back()">
              <span>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <Link to="/request_class"><span className="ms-2">Back</span></Link>
              </span></a> */}
            <div className="mb-3">
              <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}>
                {" "}
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                  </svg>
                </span>{" "}
                Back
              </button>
            </div>
            {/*end*/}
            <section className="requirement_form">
              <div className="text-center mb-4">
                <h2 className=" text-center mb-2 fw-600 poppins text-capitalize text-purple">Request A Class</h2>
                <p className="fs-5">A Teacher will contact you</p>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="card border-purple p-4">
                    <form className="custom_form mt-4" onSubmit={handleSubmit(onSubmit)} action id>
                      <div className="form-group mb-4">
                        <label className="mb-2">Parent’s/Guardian/Learner’s name:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          defaultValue={NameDetails[0].full_name}
                          {...register("name")}
                          readOnly
                        />
                        {/* <p className='mt-2 font-bold col-red'>{errors.name?.message}
                          <span style={{ color: 'red' }} >
                          {nameCount === 0
                            ? ' 50 Characters'
                            : nameCount <= nameMaxLength
                            ? `${nameMaxLength - nameCount} characters`
                            : 'Maximum character limit exceeded'}
                            </span>
                      </p> */}
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.name?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        {/*automatic comeup*/}
                        <label className="mb-2">Time zone</label>
                        <Select
                          options={timezones}
                          value={timezones.find((tz) => tz.value === selectedTimezone)}
                          onChange={handleChange}
                          isSearchable={true}
                          placeholder="Search for a timezone..."
                          isDisabled={true}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "1px solid rgba(102, 102, 102, 0.35)",
                              borderRadius: "12px",
                              padding: "5px 10px", // Adjust padding to make the component smaller
                              height: "46px", // Set the height of the component
                            }),
                          }}
                        />
                        {/* <select className="form-select" aria-label="Default select example" name='timezone' {...register("timezone")} >
                          <option>UTC Universal Coordinated Time GMT</option>
                          <option>ECT European Central Time GMT+1:00</option>
                          <option>EET Eastern European Time GMT+2:00</option>
                          <option>ART (Arabic) Egypt Standard Time GMT+2:00</option>
                          <option>EAT Eastern African Time GMT+3:00</option>
                          <option>MET Middle East Time GMT+3:30</option>
                          <option>NET Near East Time GMT+4:00</option>
                          <option>PLT Pakistan Lahore Time GMT+5:00</option>
                          <option>IST India Standard Time GMT+5:30</option>
                          <option>BST Bangladesh Standard Time GMT+6:00</option>
                          <option>VST Vietnam Standard Time GMT+7:00</option>
                          <option>CTT China Taiwan Time GMT+8:00</option>
                          <option>JST Japan Standard Time GMT+9:00</option>
                          <option>ACT Australia Central Time GMT+9:30</option>
                          <option>AET Australia Eastern Time GMT+10:00</option>
                          <option>SST Solomon Standard Time GMT+11:00</option>
                          <option>NST New Zealand Standard Time GMT+12:00</option>
                          <option>MIT Midway Islands Time GMT-11:00</option>
                          <option>HST Hawaii Standard Time GMT-10:00</option>
                          <option>AST Alaska Standard Time GMT-9:00</option>
                          <option>PST Pacific Standard Time GMT-8:00</option>
                          <option>PNT Phoenix Standard Time GMT-7:00</option>
                          <option>MST Mountain Standard Time GMT-7:00</option>
                          <option>CST Central Standard Time GMT-6:00</option>
                          <option>EST Eastern Standard Time GMT-5:00</option>
                          <option>IET Indiana Eastern Standard Time GMT-5:00</option>
                          <option>PRT Puerto Rico and US Virgin Islands Time GMT-4:00</option>
                          <option>CNT Canada Newfoundland Time GMT-3:30</option>
                          <option>AGT Argentina Standard Time GMT-3:00</option>
                          <option>BET Brazil Eastern Time GMT-3:00</option>
                          <option>CAT Central African TimeGMT-1:00</option>
                        </select> */}
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.timezone?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Select the Category</label>
                        <input
                          className="form-select"
                          name="Categories"
                          onClick={() => OpenCategoryModel()}
                          data-bs-toggle="modal"
                          data-bs-target="#select_learner_like"
                          value={SelectedCategory}
                          aria-label="Default select example"
                        />

                        {/* <select className="form-select" data-bs-toggle="modal" data-bs-target="#select_learner_like" aria-label="Default select example" name='category_list' {...register("category_list")} >
                          <option>Academic</option>
                          <option>Languages</option>
                          <option>Coding &amp; Tech</option>
                          <option>Art</option>
                          <option>Music</option>
                          <option>Health &amp; Wellness</option>
                          <option>Life Skills</option>
                          <option>Professional Training</option>
                          <option>Online</option>
                        </select> */}
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.category_list?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Select the Subcategory</label>
                        <input
                          className="form-select"
                          name="subCategories"
                          onClick={() => SubCategoryList()}
                          data-bs-toggle="modal"
                          data-bs-target="#select_subcategories"
                          value={SelectedSubCategory.join(", ")}
                          aria-label="Default select example"
                        />
                        <input type="hidden" name="subCategoriess" {...register("subCategoriess")} />
                        {/* 
                        <select className="form-select" data-bs-toggle="modal" data-bs-target="#select_subcategories" aria-label="Default select example" name='subcategory_list' {...register("subcategory_list")}>
                          <optgroup label="Subjects">
                            <option>Math</option>
                            <option>English</option>
                            <option>Science</option>
                            <option>Nature &amp; Space</option>
                            <option>Social Sciences</option>
                            <option>Business / Economics</option>
                            <option>Accounting/ Finance</option>
                            <option>Engineering</option>
                            <option>Medicine/Nursing</option>
                            <option>Law</option>
                            <option>Exam Preparation</option>
                            <option>Homework Help</option>
                          </optgroup>
                          Note for developer:- clubs and Competitions no need to show on here
                        </select> */}

                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.subcategory_list?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Age of the Learner</label>
                        <select className="form-select" aria-label="Default select example" name="age" {...register("age")}>
                          {/* <option selected>3 Years</option> */}
                          {/* <option selected>Not Adults</option> */}
                          <option value={3}>3 Years </option>
                          <option value={4}>4 Years </option>
                          <option value={5}>5 Years</option>
                          <option value={6}>6 Years</option>
                          <option value={7}>7 Years</option>
                          <option value={8}>8 Years</option>
                          <option value={9}>9 Years</option>
                          <option value={10}>10 Years </option>
                          <option value={11}>11 Years </option>
                          <option value={12}>12 Years</option>
                          <option value={13}>13 Years</option>
                          <option value={14}>14 Years</option>
                          <option value={15}>15 Years</option>
                          <option value={16}>16 Years</option>
                          <option value={17}>17 Years</option>
                          <option value={18}>18 Years</option>
                          <option>Adult</option>
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.age?.message}</span>
                        </p>
                      </div>
                      {/* <div class="form-group mb-4">
								<label class="mb-2">Type of Class</label>
								<select class="form-select" aria-label="Default select example">
									<option selected="">Online</option> 
									<option>In-person</option> 
								</select>
							</div> */}
                      {/*If it’s in-person, please enter country, city and zip code */}
                      {/* 
							<div class="form-group mb-4">
								<label class="mb-2">Country</label>
								<select class="form-select" aria-label="Default select example">
									<option selected="">India</option>
									<option>United States</option>
									<option>Australia</option>
									<option>Canada</option>
									<option>Bangladesh</option>
									<option>Germany</option>
									<option>China</option>
									<option>Argentina</option>
									<option>Afghanistan</option>
									<option>Belgium</option>
									<option>France</option>
									<option>Brazil</option>
									<option>Austria</option>
									<option>Cambodia</option>
									<option>Angola</option>
									<option>Algeria</option>
								</select>
							</div>

							<div class="form-group mb-4">
								<label class="mb-2">City</label>
								<select class="form-select" aria-label="Default select example">
									<option selected="">India</option>
									<option>Sydney</option>
									<option>Australia</option>
									<option>Melbourne</option>
									<option>Bangladesh</option>
									<option>Brisbane</option>
									<option>Canberra</option>
									<option>Perth</option>
									<option>Adelaide</option>
									<option>Hobart</option>
									<option>Darwin</option>
									<option>Gold Coast</option>
									<option>Cairns</option>
									<option>Newcastle</option>
									<option>Wollongong</option>
									<option>Geelong</option>
								</select>
							</div>

							<div class="form-group mb-4">
						 		<label class="mb-2">zip code</label>
						 		<input type="number" class="form-control" name="" placeholder="" value="">
						 	</div> */}
                      {/* <div className="form-group mb-4">
                        <label className="mb-2">What Is Your Budget?</label>
                        <select className="form-select" aria-label="Default select example" name="budget_price" {...register("budget_price")}>
                          <option value={'Under $25'} selected>Under $25</option>
                          <option value={'Under $25-$50'}>Under $25-$50</option>
                          <option value={'Under $50-75'}>Under $50-75</option>
                          <option value={'Under $75-$100'}>Under $75-$100</option>
                          <option value={'Over $100+'}>Over $100+</option>
                          <option value={'Does not matter'}>Does not matter</option>
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.budget_price?.message}</span>
                        </p>
                      </div> */}

                      <div className="form-group mb-4">
                        <label className="mb-2">What Is Your Budget?</label>
                        <select className="form-select" aria-label="Default select example" name="budget_price" {...register("budget_price")}>
                          {budgetOptions.map((option, index) => (
                            <option key={index} value={option.value} selected={option.value === 'Under $25'}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.budget_price?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Class duration:</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="class_duration"
                          {...register("class_duration")}
                          onChange={(e) => {
                            ClassDuration(e.target.value);
                          }}
                        >
                          <option selected>30 minutes</option>
                          <option>45 minutes</option>
                          <option>1 hour</option>
                          <option>2 hours</option>
                          <option>Does not matter</option>
                          <option value={"Specify"}>Specify</option>
                        </select>
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.class_duration?.message}</span></p> */}
                      </div>
                      {ClassDurationData == "Specify" ? (
                        <>
                          <div className="form-group mb-4">
                            <label className="mb-2">Specify Your Duration</label>
                            <div className="positiocategoryn-relative class_title">
                              <input
                                type="text"
                                className="form-control"
                                {...register("specify_duration")}
                                name="specify_duration"
                                maxLength={50}
                                rows={2}
                                placeholder
                              />
                              <span className="position-absolute word_count">{50 - (watch("specify_duration")?.split("")?.length ?? 0)} Words</span>
                              <p className="mt-2 font-bold col-red">
                                <span style={{ color: "red" }}>{errors.specify_duration?.message}</span>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="form-group mb-4">
                        <label className="mb-2">Class Size</label>
                        <select className="form-select" aria-label="Default select example" name="class_size" {...register("class_size")}>
                          <option value={"Individual/private"}>Individual/Private Lesson</option>
                          <option value={"Group"}>Group Lesson</option>
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.class_size?.message}</span>
                        </p>
                      </div>
                      {/* <div className="form-group mb-4">
                        <label className="mb-2">What is Your Day Preference?</label> */}
                      {/* <select multiple className="form-select" aria-label="Default select example" id="select2-example"  name='day_preference' {...register("day_preference")}>
                          <option selected>Monday</option> 
                          <option>Tuesday</option> 
                          <option>Wednesday</option> 
                          <option>Thursday</option> 
                          <option>Friday</option> 
                          <option>Saturday</option> 
                          <option>Sunday</option>   
                        </select> */}
                      {/* <Select
          isMulti
          options={[
            { value: 'Monday', label: 'Monday' },
            { value: 'Tuesday', label: 'Tuesday' },
            { value: 'Wednesday', label: 'Wednesday' },
            { value: 'Thursday', label: 'Thursday' },
            { value: 'Friday', label: 'Friday' },
            { value: 'Saturday', label: 'Saturday' },
            { value: 'Sunday', label: 'Sunday' },
          ]}
          className="form-select" aria-label="Default select example"
          id="select2-example"category
          name="day_preference"
          {...register('day_preference')}
          onChange={handleSelectChange}
        />
                        
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.day_preference?.message}</span></p>  */}

                      {/* </div> */}
                      <div className="form-group mb-4">
                        <label className="mb-2">What is Your Day Preference?</label>
                        <Select
                          isMulti
                          options={[
                            { value: "Monday", label: "Monday" },
                            { value: "Tuesday", label: "Tuesday" },
                            { value: "Wednesday", label: "Wednesday" },
                            { value: "Thursday", label: "Thursday" },
                            { value: "Friday", label: "Friday" },
                            { value: "Saturday", label: "Saturday" },
                            { value: "Sunday", label: "Sunday" },
                          ]}
                          aria-label="Default select example"
                          id="select2-example"
                          name="day_preference"
                          {...register("day_preference")}
                          onChange={handleSelectChange}
                        // defaultValue={[{ value: 'Monday', label: 'Monday' }]}  // Set default value for Monday
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.day_preference?.message}</span>
                        </p>
                      </div>

                      <div className="form-group mb-4">
                        <label className="mb-2">What Is Your Time Preference?</label>
                        {/* <select multiple className="form-select select_drop" aria-label="Default select example" id="select3-example"  name='time_preference' {...register("time_preference")}>
                          <option value="O1" select3-example="./assets/images/dropdown_icon/time.jpg" selected>No Preference: Flexible</option> 
                          <option value="O2" select3-example="./assets/images/dropdown_icon/time.jpg">Morning: 9:00 AM- 12:00 PM</option> 
                          <option value="O3" select3-example="./assets/images/dropdown_icon/time.jpg">Afternoon: 12:00 - 4:00 PM</option> 
                          <option value="O4" select3-example="./assets/images/dropdown_icon/time.jpg">Evening: 4:00 - 7:00 PM</option> 
                          <option value="O5" select3-example="./assets/images/dropdown_icon/time.jpg">Night: 7:00 - 10:00 PM</option>      
                        </select> */}
                        <Select
                          isMulti
                          options={[
                            { value: "Morning: 7:00 AM - 12:00 PM", label: "Morning: 7:00 AM - 12:00 PM" },
                            { value: "Afternoon: 12:00 PM - 4:00 PM", label: "Afternoon: 12:00 PM - 4:00 PM" },
                            { value: "Evening: 4:00 PM - 7:00 PM", label: "Evening: 4:00 PM - 7:00 PM" },
                            { value: "Night: 7:00 PM - 10:00 PM", label: "Night: 7:00 PM - 10:00 PM" },
                          ]}
                          aria-label="Default select example"
                          id="select2-example"
                          name="time_preference"
                          {...register("time_preference")}
                          onChange={handleSelectChange1}
                        // defaultValue={[{ value: 'Morning: 9:00 AM- 12:00 PM', label: 'Morning: 9:00 AM- 12:00 PM' }]}
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.time_preference?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What Are You Expecting From The Lessons:</label>
                        <div className="position-relative class_title">
                          <textarea
                            type="text"
                            className="form-control"
                            {...register("your_expectation")}
                            name="your_expectation"
                            maxLength={200}
                            rows={3}
                          />
                          <span className="position-absolute word_count">{200 - (watch("your_expectation")?.split("")?.length ?? 0)} Words</span>
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.your_expectation?.message}</span>
                          </p>
                        </div>

                        {/* {expectationCount === 0 && (
                          <p className='mt-2 font-bold col-red'>
                            <span style={{ color: 'red' }}>200 Characters required</span>
                          </p>
                        )}
                        <p className='mt-2 font-bold col-red'>
                          <span style={{ color: 'red' }}>
                            {expectationCount > 0 && expectationCount <= expectationMaxLength
                              ? `${expectationMaxLength - expectationCount} characters remaining`
                              : expectationCount > expectationMaxLength
                                ? ''
                                : ''}
                          </span>
                        </p> */}
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">How often do you want lessons?</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="want_lessons"
                          {...register("want_lessons")}
                          onChange={handleSelectChange2}
                        >
                          <option selected>Daily</option>
                          <option>Once a week</option>
                          <option>Few times a week</option>
                          <option value="Specify">Specify</option>
                        </select>
                        {showSpecifyInput && (
                          <div className="mt-2">
                            <label className="mb-2">Specify Frequency:</label>
                            <div className="position-relative class_title">
                              <input
                                type="text"
                                className="form-control"
                                name="specify_lession"
                                maxLength={30}
                                rows={6}
                                {...register("specify_lession")}
                              />
                              <span className="position-absolute word_count">{30 - (watch("specify_lession")?.split("")?.length ?? 0)} Words</span>
                              <p className="mt-2 font-bold col-red">
                                <span style={{ color: "red" }}>{errors.specify_lession?.message}</span>
                              </p>
                            </div>
                          </div>
                        )}

                        {/* {showSpecifyInput && (
                          <div className="mt-2">
                            <label className="mb-2">Specify lessons:</label>
                            <div className="position-relative class_title">
                            <input
                              type="text"
                              className="form-control"
                              name='specifiedFrequency'
                              maxLength={30}
                              rows={3} 
                            />
                             <span className="position-absolute word_count">{30 - (watch("specifiedFrequency")?.split('')?.length ?? 0)} Words</span>
                        <p className='mt-2 font-bold col-red'>
                          <span style={{ color: 'red' }}>{errors.your_expectation?.message}</span>
                      </p>
                          </div>
                          </div>
                        )} */}
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.want_lessons?.message}</span></p> */}
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Are you ready to hire someone?</label>
                        <select className="form-select" aria-label="Default select example" name="hire_someone" {...register("hire_someone")}>
                          <option selected>Yes, if the professional is right</option>
                          <option>Yes, I need this service ASAP</option>
                          <option>I'm researching and planning</option>
                          <option>Just looking</option>
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.hire_someone?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Comments(optional)</label>
                        <div className="position-relative class_title">
                          <textarea className="form-control" defaultValue={""} name="comments" {...register("comments")} maxLength={200} rows={3} />
                          <span className="position-absolute word_count">{200 - (watch("comments")?.split("")?.length ?? 0)} Words</span>
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.comments?.message}</span>
                          </p>
                        </div>
                        {/* 
                        {commentsCount === 0 && (
                          <p className='mt-2 font-bold col-red'>
                            <span style={{ color: 'red' }}>200 Characters required</span>
                          </p>
                        )}
                        <p className='mt-2 font-bold col-red'>
                          <span style={{ color: 'red' }}>
                            {commentsCount > 0 && commentsCount <= commentsMaxLength
                              ? `${commentsMaxLength - commentsCount} characters remaining`
                              : commentsCount > commentsMaxLength
                                ? ''
                                : ''}
                          </span>
                        </p> */}
                      </div>
                      <div className="button mt-4">
                        {/* <Link to="/dashboard" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Ask a teacher to contact me</Link> */}
                        <button className="btn-theme bg-yellow text-center fw-600 d-block">Request Teachers to Contact Me</button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div class="col-lg-6 order-lg-last order-first">
        			<div class="">
        				<img src="./assets/images/request_a_class.png" alt="request_a_class">
        			</div>
        		</div> */}
              </div>
            </section>
          </div>
        </main>
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_learner_like" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Categories
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  <ul>
                    {data?.map((mainCat, index) => {
                      if (mainCat.category_name !== 'In-person Classes') {
                        return (
                          <li key={index}>
                            {" "}
                            {/* Add onClick handler to <li> */}
                            <a className="d-flex align-items-center" onClick={(e) => handleItemClick(mainCat.category_id, mainCat.category_name)}>
                              {" "}
                              {/* Add onClick handler to <a> */}
                              <div className={`topi_icon ${SelectedCatID == mainCat.category_id ? "active" : ""}`}>
                                <img className="mr-1" src={mainCat.main_category_image} alt="topic9" />
                              </div>
                              <span className="ms-1 mr-1">{mainCat.category_name}</span>
                            </a>
                          </li>
                        );
                      }

                    })}
                  </ul>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" onClick={() => setIsOpen(false)} data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end*/}
        {/*start*/}
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_subcategories" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Your Subcategories
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  {SelectedSubCat[0]?.category_name ? (
                    <>
                      {SelectedSubCat?.map((SubCat, index) => {
                        return (
                          <>
                            <h4 className="fs-6 mb-4">{SubCat.category_name}</h4>
                            <ul>
                              {SubCat.category?.map((category, index1) => {
                                return (
                                  <li key={index1}>
                                    <a
                                      className="d-flex align-items-center"
                                      onClick={(e) => {
                                        handleItemClick2(category.subject_category_id, category.subject_name);
                                      }}
                                    >
                                      <div className={`topi_icon ${SelectedSubCatID.includes(category.subject_category_id) ? "active" : ""}`}>
                                        <img src={category.subject_category_image} alt="maths" />
                                      </div>
                                      <span className="ms-3">{category.subject_name}</span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <ul>
                        {SelectedSubCat?.map((SubCat, index) => {
                          // console.log(SubCat);
                          return (
                            <>
                              <li key={index}>
                                <a
                                  className="d-flex align-items-center"
                                  onClick={(e) => {
                                    handleItemClick2(SubCat.subject_category_id, SubCat.subject_name);
                                  }}
                                >
                                  <div className={`topi_icon ${SelectedSubCatID.includes(SubCat.subject_category_id) ? "active" : ""}`}>
                                    <img src={SubCat.subject_category_image} alt="maths" />
                                  </div>
                                  <span className="ms-3">{SubCat.subject_name}</span>
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </>
  );
}
