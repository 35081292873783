import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { question_prospective } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'

export default function Allquestion() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const navigate = useNavigate();

    var [QuesData, setQuesData] = useState([]);

    const QueStudent = () => {

        question_prospective().then((resposnse) => {
            if (resposnse?.code == 1) {
                setQuesData(resposnse?.data)
            } else {
                setQuesData([])
                console.log('data not found')
            }
        });
    }

    useEffect(() => {
        QueStudent();
    }, []);


    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />


            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={()=>navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className=" ">
                                    {/*start*/}
                                    <div className="row">
                                        <div className="col-xl-8">
                                            {/*start*/}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="maybe_inter">
                                                        <div className="title d-flex justify-content-between mb-3 align-items-center">
                                                            <h3 className=" text-capitalize">Questions By Prospective Students</h3>
                                                            {/* <Link to="/questions-of-students" className="fs-6 text-gray">See All</Link> */}
                                                        </div>
                                                        <div className="qes_by_prospect">
                                                            <div className="inter_class me-0">
                                                                <div className={QuesData?.length > 2 ? 'scrollable-content' : ''}>
                                                                    {QuesData?.length > 0 ?
                                                                        QuesData?.map((Ques_Data, index) => {
                                                                            return (
                                                                                <div className="d-flex inter_top" style={{ "marginRight": "10px" }}>
                                                                                    <img src={Ques_Data.profile_image} alt="interested1" className="rounded-pill" />
                                                                                    <div className="inter_infos ps-3" >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <span className="text-dif-black mb-1 fs-6">{Ques_Data.full_name}</span>
                                                                                            <span className="font-10 text-gray">{Ques_Data.Times_day}</span>
                                                                                        </div>
                                                                                        <span className=" text-gray font-14 mb-2 d-block">{Ques_Data.question}</span>
                                                                                        <a href={Ques_Data.document_file} download={Ques_Data.document_file} target="_blank"
                                                                                            className="btn-theme btn-prple-border py-1 text-center d-inline-block">{Ques_Data.document}</a>
                                                                                    </div>
                                                                                </div>
                                                                            )

                                                                        }) :
                                                                        <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                                                                    }
                                                                </div>

                                                                {/* <div className="d-flex inter_top">
                              <img src="./assets/images/interested_class/interested1.png" alt="interested1" className="rounded-pill" />
                              <div className="inter_infos ps-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="text-dif-black mb-1">Cindy Chen</span>
                                  <span className="font-10 text-gray">09:32</span>
                                </div>
                                <span className=" text-gray font-14 mb-2 d-block">Have you made history assignments?</span>
                                <a href="#" className="btn-theme btn-prple-border py-1 text-center d-inline-block">Algebra.pdf</a>
                              </div>
                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*end*/}
                                        </div>
                                    </div>
                                    {/*end*/}
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>



            <TFooter_Dashboard />
        </>
    )
}
