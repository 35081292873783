import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service';
import { teacher_add_another_question } from '../../../../api/apiHandler';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

const Editor = ({ content, onChange }) => {
    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
};

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];


export default function OAddAnotherQuestion() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const [answerType, setAnswerType] = useState("Single");

    let schema;
    if (answerType == "Single") {
        schema = yup.object().shape({
            question: yup.string().required("Question is required"),
            answer1: yup.string().required("Answer is required"),
            answer2: yup.string().required("Answer is required"),
            answer3: yup.string().required("Answer is required"),
            answer4: yup.string().required("Answer is required"),
            ans_exp: yup.string().required("Answer explanation is required"),
            que_points: yup.string().required("Question Points is required"),
            // profileVideo: yup
            //   .mixed()
            //   .required("Class video is required"),

        });
    } else if (answerType == "Multiple") {
        schema = yup.object().shape({
            question: yup.string().required("Question is required"),
            answer1: yup.string().required("Answer is required"),
            answer2: yup.string().required("Answer is required"),
            answer3: yup.string().required("Answer is required"),
            answer4: yup.string().required("Answer is required"),
            ans_exp: yup.string().required("Answer explanation is required"),
            que_points: yup.string().required("Question Points is required"),
        });
    } else {
        schema = yup.object().shape({
            question: yup.string().required("Question is required"),
            answer1: yup.string().required("Answer is required"),
            answer2: yup.string().required("Answer is required"),
            ans_exp: yup.string().required("Answer explanation is required"),
            que_points: yup.string().required("Question Points is required"),

        });
    }


    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    // console.log('errors :', errors);


    const handleAnswerTypeChange = (e) => {

        setAnswerType(e.target.value);
        // reset({
        //     answer1: '',
        //     answer2: '',
        //     answer3: '',
        //     answer4: ''
        // });
    };

    const [selectedRadio, setSelectedRadio] = useState('1');
    // console.log('selectedRadio :', selectedRadio);

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedRadio(value); // Set the selected radio value to the state
    };

    const answerValues = {
        answer1: selectedRadio === '1' ? '1' : '0',
        answer2: selectedRadio === '2' ? '1' : '0',
        answer3: selectedRadio === '3' ? '1' : '0',
        answer4: selectedRadio === '4' ? '1' : '0',
    };

    // console.log('answerValues :', answerValues);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
    });

    const handleCheckboxChange = (name) => {
        setSelectedCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: !prevCheckboxes[name],
        }));
    };

    const answerValues1 = {
        answer1: selectedCheckboxes.checkbox1 ? '1' : '0',
        answer2: selectedCheckboxes.checkbox2 ? '1' : '0',
        answer3: selectedCheckboxes.checkbox3 ? '1' : '0',
        answer4: selectedCheckboxes.checkbox4 ? '1' : '0',
    };

    // console.log('answerValues1 :', answerValues1);

    const [selectedRadio1, setSelectedRadio1] = useState('1');
    const [ansDescription, setAnsDescription] = useState('');

    const handleRadioChange1 = (e) => {
        const value = e.target.value;
        setSelectedRadio1(value);
    };

    const answerValues2 = {
        answer1: selectedRadio1 === '1' ? '1' : '0',
        answer2: selectedRadio1 === '2' ? '1' : '0',

    };
    // console.log('answerValues2 :', answerValues2);
    const [profileImageState, setProfileImageState] = useState('')
    const [desState, setDesState] = useState('')
    const [tempState, setTempState] = useState('')
    const [tempDesState, setDesTempState] = useState('')

    const handleImageChange = async (image, fileIndex, type) => {
        setTempState(image?.name)

        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        console.log('image :', image);
        setValue("attachments_data", image);
        setValue("attachments_type", type);

        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("attachments_data");
    }
    const handleAttachmentChange = async (image, fileIndex, type) => {
        setDesTempState(image?.name)

        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        setValue("attachments_des_data", image);
        setValue("attachments_des_type", type);
        const bufferImage = await convertToBase64(image);
        setDesState(bufferImage);
        clearErrors("attachments_des_data");
    }

    const handleAnsDescriptionChange = (value) => {
        console.log('handleDescriptionChange :', value);
        // Check if the value contains only whitespace characters
        const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
        // If it's empty, set the description to an empty string
        setAnsDescription(isEmptyDescription ? '' : value);
        setValue('ans_exp', isEmptyDescription ? '' : value);
        clearErrors('ans_exp');
    };

    let onSubmit = async (data) => {

        let image; // Declare image here
        let desImage;
        // var image = await uploadImageOnAWS(data?.attachments_data, "class_image");

        if (data?.attachments_data != undefined && data?.attachments_data != null) {
            image = data?.attachments_data;
            if (image instanceof Blob) {
                image = await uploadImageOnAWS(data?.attachments_data, "class_image");
            }
        } else {
            TOAST_ERROR("Attachment is required");
            return; // Add a return statement to stop execution if there is no attachment
        }

        if (data?.attachments_des_data != undefined && data?.attachments_des_data != null) {
            desImage = data?.attachments_des_data;
            if (desImage instanceof Blob) {
                desImage = await uploadImageOnAWS(data?.attachments_des_data, "class_image");
            }
        } else {
            TOAST_ERROR("Description attachment is required");
            return; // Add a return statement to stop execution if there is no attachment
        }


        let submitData = {
            quiz_id: localStorage.getItem("quiz_id"),
            question: data.question,
            attachment: image,
            attachment_type: data.attachments_type,
            que_type: answerType,
            answer_explanation: data.ans_exp,
            points: data.que_points,
            description_image: desImage,
            description_type: data.attachments_des_type,
        }

        if (answerType == "Single") {
            submitData.options = [
                {
                    "name": data.answer1,
                    "is_answer": answerValues.answer1
                },
                {
                    "name": data.answer2,
                    "is_answer": answerValues.answer2
                },
                {
                    "name": data.answer3,
                    "is_answer": answerValues.answer3
                },
                {
                    "name": data.answer4,
                    "is_answer": answerValues.answer4
                }
            ]
        } else if (answerType == "Multiple") {
            submitData.options = [
                {
                    "name": data.answer1,
                    "is_answer": answerValues1.answer1
                },
                {
                    "name": data.answer2,
                    "is_answer": answerValues1.answer2
                },
                {
                    "name": data.answer3,
                    "is_answer": answerValues1.answer3
                },
                {
                    "name": data.answer4,
                    "is_answer": answerValues1.answer4
                }
            ]
        } else {
            submitData.options = [
                {
                    "name": data.answer1,
                    "is_answer": answerValues2.answer1
                },
                {
                    "name": data.answer2,
                    "is_answer": answerValues2.answer2
                }
            ]
        }

        teacher_add_another_question(submitData).then((response) => {

            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);

                navigate('/organization-questions-list')

            } else {

                TOAST_ERROR(response.message);
            }
            reset();
        });

    };

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <Helmet>
                <style>
                    {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
                </style>

            </Helmet>
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className="row mb-4">
                                    <div className="col-xl-7 d-sm-flex align-items-center justify-content-between">
                                        <h3 className="text-purple mb-sm-0 mb-3">Setup Your Online Quiz</h3>
                                        {/* <span className="text-purple text-end d-block fw-600">Step 2/2</span> */}
                                    </div>
                                </div>
                                <h3 className="mb-4">Add Questions</h3>
                                <div className="row">
                                    <div className="col-xl-7">
                                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Type Your Question </label>
                                                <textarea type="text" className="form-control" name="question" {...register("question")} rows={7} defaultValue={""} />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.question?.message}</span></p>
                                            </div>

                                            <div className="form-group mb-4 attach_box">
                                                <label className="form-label">Attachments</label>
                                                <div className="position-relative">
                                                    <textarea type="text" className="form-control" name rows={7} value={tempState}disabled />
                                                    <div className="att_icon_list position-absolute">
                                                        <ul className="d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <label htmlFor="attach1">
                                                                    <a>
                                                                        <img src="./assets/images/attach1.png" alt="attach1" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach1' onInput={(e) => {
                                                                    const file = e.target.files[0];
                                                                    const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                    handleImageChange(file, 0, fileType);
                                                                }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach2'>
                                                                    <a >
                                                                        <img src="./assets/images/attach2.png" alt="attach2" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach3'>
                                                                    <a>
                                                                        <img src="./assets/images/attach3.png" alt="attach3" />
                                                                    </a>
                                                                    <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach4'>
                                                                    <a>
                                                                        <img src="./assets/images/attach4.png" alt="attach4" />
                                                                    </a>
                                                                    <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>

                                                                <label htmlFor='attach5'>

                                                                    <a >
                                                                        <img src="./assets/images/attach5.png" alt="attach5" />
                                                                    </a>
                                                                    <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach6'>

                                                                    <a >
                                                                        <img src="./assets/images/attach6.png" alt="attach6" />
                                                                    </a>
                                                                    <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2">Answer Type(Single Ans,Multiple ,True And flase)</label>
                                                <select onChange={handleAnswerTypeChange} className="form-select" aria-label="Default select example"  >
                                                    <option value="Single" selected>Single</option>
                                                    <option value="Multiple">Multiple</option>
                                                    <option value="TrueFalse">True/False</option>

                                                </select>
                                            </div>
                                            {answerType === "Single" && (
                                                <>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 1
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radio1' value="1" checked={selectedRadio === "1"}
                                                                    onChange={handleRadioChange} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>

                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 2
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radio2' value="2" checked={selectedRadio === "2"}
                                                                    onChange={handleRadioChange} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>

                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 3
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radio3' value="3" checked={selectedRadio === "3"}
                                                                    onChange={handleRadioChange} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer3")} name="answer3" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer3?.message}</span></p>

                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 4
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radio4' value="4" checked={selectedRadio === "4"}
                                                                    onChange={handleRadioChange} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer4")} name="answer4" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer4?.message}</span></p>

                                                    </div>
                                                </>
                                            )}


                                            {answerType === "Multiple" && (
                                                <>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 1
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input
                                                                    type="checkbox"
                                                                    name="checkbox1"
                                                                    checked={selectedCheckboxes.checkbox1}
                                                                    onChange={() => handleCheckboxChange('checkbox1')}
                                                                />                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 2
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input
                                                                    type="checkbox"
                                                                    name="checkbox2"
                                                                    checked={selectedCheckboxes.checkbox2}
                                                                    onChange={() => handleCheckboxChange('checkbox2')}
                                                                />                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 3
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input
                                                                    type="checkbox"
                                                                    name="checkbox3"
                                                                    checked={selectedCheckboxes.checkbox3}
                                                                    onChange={() => handleCheckboxChange('checkbox3')}
                                                                />                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer3")} name="answer3" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer3?.message}</span></p>

                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 4
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input
                                                                    type="checkbox"
                                                                    name="checkbox4"
                                                                    checked={selectedCheckboxes.checkbox4}
                                                                    onChange={() => handleCheckboxChange('checkbox4')}
                                                                />                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id  {...register("answer4")} name="answer4" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer4?.message}</span></p>
                                                    </div>
                                                </>
                                            )}

                                            {answerType === "TrueFalse" && (
                                                <>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 1
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radiotrue' value="1" checked={selectedRadio1 === "1"}
                                                                    onChange={handleRadioChange} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>

                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Ans 2
                                                            <label className="radio-button-container">Select As Correct answer
                                                                <input type="radio" name="radio" id='radiotrue1' value="2" checked={selectedRadio1 === "2"}
                                                                    onChange={handleRadioChange1} />
                                                                <span className="checkmarks" />
                                                            </label>
                                                        </label>
                                                        <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>
                                                    </div>
                                                </>
                                            )}

                                            <div className="form-group mb-4">
                                                <label className="form-label">Answer explanation </label>
                                                {/* <textarea type="text" className="form-control" {...register("ans_exp")} name="ans_exp" rows={8} defaultValue={""} /> */}
                                                <Editor
                                                    {...register("ans_exp")}
                                                    content={ansDescription}
                                                    onChange={handleAnsDescriptionChange}
                                                />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.ans_exp?.message}</span></p>

                                            </div>
                                            <div className="form-group mb-4 attach_box">
                                                <label className="form-label">Description Attachments</label>
                                                <div className="position-relative">
                                                    <textarea type="text" className="form-control" name rows={7} value={tempDesState}disabled />
                                                    <div className="att_icon_list position-absolute">
                                                        <ul className="d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <label htmlFor="attach_des_1">
                                                                    <a>
                                                                        <img src="./assets/images/attach1.png" alt="attach_des_1" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_1' onInput={(e) => {
                                                                    const file_des = e.target.files[0];
                                                                    const fileType_des = file_des.type.startsWith('image') ? 'image' : 'video';
                                                                    handleAttachmentChange(file_des, 0, fileType_des);
                                                                }} {...register("attachments_des")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_2'>
                                                                    <a >
                                                                        <img src="./assets/images/attach2.png" alt="attach_des_2" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_2' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_3'>
                                                                    <a>
                                                                        <img src="./assets/images/attach3.png" alt="attach_des_3" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_3'  {...register("attachments_des")} accept="image/*" onInput={(e) => handleAttachmentChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_4'>
                                                                    <a>
                                                                        <img src="./assets/images/attach4.png" alt="attach_des_4" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_4' {...register("attachments_des")} accept=".docx" onInput={(e) => handleAttachmentChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>

                                                                <label htmlFor='attach_des_5'>

                                                                    <a >
                                                                        <img src="./assets/images/attach5.png" alt="attach_des_5" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_5' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_6'>

                                                                    <a >
                                                                        <img src="./assets/images/attach6.png" alt="attach_des_6" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_6' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Question Points</label>
                                                <input type="number" className="form-control" placeholder id {...register("que_points")} name="que_points" />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.que_points?.message}</span></p>

                                            </div>
                                            <div className="form-group mb-4 attach_box">
                                                <label className="form-label">Description Attachments</label>
                                                <div className="position-relative">
                                                    <textarea type="text" className="form-control" name rows={7} defaultValue={""} disabled />
                                                    <div className="att_icon_list position-absolute">
                                                        <ul className="d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <label htmlFor="attach_des_1">
                                                                    <a>
                                                                        <img src="./assets/images/attach1.png" alt="attach_des_1" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_1' onInput={(e) => {
                                                                    const file_des = e.target.files[0];
                                                                    const fileType_des = file_des.type.startsWith('image') ? 'image' : 'video';
                                                                    handleAttachmentChange(file_des, 0, fileType_des);
                                                                }} {...register("attachments_des")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_2'>
                                                                    <a >
                                                                        <img src="./assets/images/attach2.png" alt="attach_des_2" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_2' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_3'>
                                                                    <a>
                                                                        <img src="./assets/images/attach3.png" alt="attach_des_3" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_3'  {...register("attachments_des")} accept="image/*" onInput={(e) => handleAttachmentChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_4'>
                                                                    <a>
                                                                        <img src="./assets/images/attach4.png" alt="attach_des_4" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_4' {...register("attachments_des")} accept=".docx" onInput={(e) => handleAttachmentChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>

                                                                <label htmlFor='attach_des_5'>

                                                                    <a >
                                                                        <img src="./assets/images/attach5.png" alt="attach_des_5" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_5' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_6'>

                                                                    <a >
                                                                        <img src="./assets/images/attach6.png" alt="attach_des_6" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_6' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-lg-5">
                                                    {/* <Link to="/question_list" className="btn-theme bg-yellow d-block text-center fw-600">Submit Question</Link> */}
                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Submit Question
                                                    </button>
                                                </div>

                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
