import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { add_learner, reset_password } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import * as yup from "yup";
import Modals from "react-modal";
import Model from "../Models/Model";
import Select from "react-select";
import countryList from "react-select-country-list";
import { yupResolver } from "@hookform/resolvers/yup";
import BackToPrevious from "../../components/backToPrevious";
export default function ResetPassword() {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    new_password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be 8 or more characters")
      .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password should contain at least one uppercase and lowercase character")
      .matches(/\d/, "Password should contain at least one number")
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),
    confirm_password: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("new_password"), null], "Confirm password doesn't match with the New password"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleTogglePassword1 = (e) => {
    e.preventDefault();
    setShowPassword1(!showPassword1);
  };

  const onSubmit = async (data) => {
    console.log("data :", data);

    try {
      let reset_password_data = {
        new_password: data.new_password,
        user_id: localStorage.getItem("PAid"),
      };

      const response = await reset_password(reset_password_data);
      console.log("response :", response);
      // return
      if (response?.code == 1) {
        // console.log('hello')
        navigate("/login");

        TOAST_SUCCESS(response?.message);

        // Redirect to login page or handle successful password reset
      } else {
        // console.log('hello1')
        TOAST_ERROR(response?.message);
      }
    } catch (error) {
      // Handle API error, show toast message, etc.
      TOAST_ERROR("Failed to reset password. Please try again.");
      console.error(error);
    }
  };

  return (
    <>
      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row">
              <div
                className="align-items-center col-lg-6 d-flex justify-content-center left height_100vh"
                style={{ backgroundImage: "url(./assets/images/auth/login_in.png)" }}
              >
                <h1 className="text-white poppins fw_500 position-relative">Be Inspired. Grow Your Skills. Knowledge. Talents.</h1>
              </div>
              <div className="col-lg-6 right">
                <div className="top">
                  <div className="d-md-flex justify-content-between">
                    <BackToPrevious />
                    <div>
                      <p className="fs-6 text-gray">
                        Don’t Have Account?{" "}
                        <a href="#" className="text-purple">
                          Join Now
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btm_div form-section">
                  <div className="select_anyone">
                    <div className="mb-4">
                      <h2 className="poppins fw-600">Reset Password</h2>
                      {/* <p className="text-gray fs-5">Your new password must be different from previous one</p> */}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-5" action id>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between">
                          <label className="mb-2">New password</label>
                          <a href="#" onClick={handleTogglePassword}>
                            <span>
                              {showPassword ? (
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                              ) : (
                                // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                // </svg>

                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                  <path
                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              )}
                            </span>
                            {/* {showPassword ? 'Hide' : 'Show'} */}
                          </a>
                        </div>
                        <input
                          name="new_password"
                          {...register("new_password")}
                          type={showPassword ? "text" : "password"}
                          placeholder=""
                          className="form-control"
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.new_password?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between">
                          <label className="mb-2">Confirm password</label>
                          <a href="#" onClick={handleTogglePassword1}>
                            <span>
                              {showPassword1 ? (
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                              ) : (
                                // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                // </svg>

                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                  <path
                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              )}
                            </span>
                            {/* {showPassword ? 'Hide' : 'Show'} */}
                          </a>
                        </div>
                        <input
                          name="confirm_password"
                          {...register("confirm_password")}
                          type={showPassword1 ? "text" : "password"}
                          placeholder=""
                          className="form-control"
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.confirm_password?.message}</span>
                        </p>
                      </div>
                      <div className="mt-5">
                        {/* <a className="btn-theme bg-yellow fw-600 text-center d-block">Update</a> */}
                        <button type="submit" className="btn-theme bg-yellow text-center fw-600 d-block">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
