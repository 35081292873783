import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { teacher_add_lession } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { Helmet } from 'react-helmet';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';

export default function OAddLesson() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  // const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const location = useLocation();

  var id = location?.state;

  const schema = yup.object().shape({
    lesson_topic: yup.string().required("Lesson topic is required"),

    // lesson_description: yup.string().required("Lesson description is required"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [profileImageState, setProfileImageState] = useState('')
  const [profileImageState1, setProfileImageState1] = useState('')
  const [AttachmentData, setAttachmentData] = useState({})

  const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    var str = "attachment_" + Attachment_Index
    var temp_arr = AttachmentData
    if (temp_arr[str]) {
      var image_name = await uploadImageOnAWS(image, "lesson_attachments");

      temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
    } else {
      var image_name = await uploadImageOnAWS(image, "lesson_attachments");

      temp_arr[str] = ['', '', '', '', '', '']
      temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type }

    }
    setAttachmentData(temp_arr)
    setValue("attachments_data", setAttachmentData);

    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("attachments_data");
  }

  const handleImageChange1 = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    setValue("attachments_data1", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState1(bufferImage);
    clearErrors("attachments_data1");
  }


  let onSubmit = async (data) => {


    let coupon_data = {
      activity_id: id.id,
      activity_type: id.class,
      lession_topic: data.lesson_topic,
      description: data.lesson_description,
      duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
    }
    var description_arr = [];
    lessonDetails.map((lesson, index) => {
      var temp_obj = {}

      var temp_str = "lesson_description_" + index;
      var attach_str = "attachment_" + index
      temp_obj.description = data[temp_str]

      if (AttachmentData[attach_str]) {
        var originalArray = AttachmentData[attach_str];

        // Remove empty strings from the array
        var newArray = originalArray.filter(item => item !== '');
        temp_obj.attachments = newArray
      } else {
        temp_obj.attachments = []
      }
      description_arr.push(temp_obj);

    });
    // coupon_data.description = {}
    coupon_data.lessiondata = description_arr

    teacher_add_lession(coupon_data).then((response) => {

      if (response?.code == 1) {
        TOAST_SUCCESS(response.message);
        navigate('/organization-tech-detail', {
          state: { id: id.id, class: id.class }
        })
      } else {
        TOAST_ERROR(response.message);
      }
    });
    reset();

  }

  const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

  const handleDescriptionChange = (index, value) => {
    const updatedLessonDetails = [...lessonDetails];
    updatedLessonDetails[index].description = value;
    setLessonDetails(updatedLessonDetails);
  };

  const handleAttachmentChange = (index, file) => {
    const updatedLessonDetails = [...lessonDetails];
    updatedLessonDetails[index].attachment = file;
    setLessonDetails(updatedLessonDetails);
  };

  const handleAddDescription = () => {
    setLessonDetails([...lessonDetails, { description: '', attachment: null }]);
  };


  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
        </style>

      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-8">
                      {/*start*/}
                      <div className="mb-4">
                        <h2 className="mb-2 poppins fw-bold">Add Lesson Information</h2>
                      </div>
                      {/*end*/}
                      {/*start*/}
                      <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                        <div className="form-group mb-4">
                          <label className="form-label">Add Lesson Topic</label>
                          <div className="position-relative class_title">

                            <input type="text" {...register("lesson_topic")} maxLength={50} name="lesson_topic" className="form-control" placeholder id />
                            <span className="position-absolute word_count">{50 - (watch("lesson_topic")?.split('')?.length ?? 0)} Words</span>

                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span></p>
                          </div>
                        </div>

                        {lessonDetails.map((lesson, index) => (
                          <div key={index}>
                            <div className="form-group mb-4">
                              <label className="form-label">Add Lesson Description</label>
                              <div className="position-relative class_summary">
                                <textarea
                                  className="form-control"
                                  value={lesson.description}
                                  {...register(`lesson_description_${index}`)}
                                  name={`lesson_description_${index}`}
                                  onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                  rows={7}
                                  placeholder="Add your description"
                                />
                                <span className="position-absolute word_count">
                                  {(watch(`lesson_description_${index}`) ? watch(`lesson_description_${index}`)?.length : 0)} Words
                                </span>
                              </div>
                            </div>
                            <div className="form-group mb-4 attach_box">
                              <label className="form-label">Add Attachments</label>
                              <div className="position-relative">
                                <textarea type="text" className="form-control" name rows={7} defaultValue={""} disabled />
                                <div className="att_icon_list position-absolute">
                                  <ul className="d-flex align-items-center justify-content-end">
                                    <li>
                                      <label htmlFor={`attach1_${index}`}>
                                        <a>
                                          <img src="./assets/images/attach1.png" alt="attach1" />
                                        </a>
                                      </label>
                                      <input type="file" id={`attach1_${index}`} onInput={(e) => {
                                        const file = e.target.files[0];
                                        const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                        handleImageChange(file, index, 0, fileType);
                                      }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                    </li>
                                    <li>
                                      <label htmlFor={`attach2_${index}`}>
                                        <a >
                                          <img src="./assets/images/attach2.png" alt="attach2" />
                                        </a>
                                      </label>
                                      <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                    </li>
                                    <li>
                                      <label htmlFor={`attach3_${index}`}>
                                        <a>
                                          <img src="./assets/images/attach3.png" alt="attach3" />
                                        </a>
                                        <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                      </label>
                                    </li>
                                    <li>
                                      <label htmlFor={`attach4_${index}`}>
                                        <a>
                                          <img src="./assets/images/attach4.png" alt="attach4" />
                                        </a>
                                        <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                      </label>
                                    </li>
                                    <li>

                                      <label htmlFor={`attach5_${index}`}>

                                        <a >
                                          <img src="./assets/images/attach5.png" alt="attach5" />
                                        </a>
                                        <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                      </label>
                                    </li>
                                    <li>
                                      <label htmlFor={`attach6_${index}`}>

                                        <a >
                                          <img src="./assets/images/attach6.png" alt="attach6" />
                                        </a>
                                        <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex justify-content-end mt-3">
                          <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                            + Add another Description
                          </a>
                        </div>


                        <h3 className="mb-3">Lesson duration</h3>
                        <div className="d-md-flex mb-4 align-items-center">
                          <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
                          <div className="d-md-flex ms-3">
                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                              <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                <option >0</option>
                                <option selected>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>

                              </select>
                              <span className="ms-3">Minutes</span>
                            </div>
                            <div className="ms-md-3">
                              <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                <option>0 </option>
                                <option>5 </option>
                                <option>10 </option>
                                <option>15 </option>
                                <option>20 </option>
                                <option>25 </option>
                                <option>30 </option>
                                <option>35 </option>
                                <option>40 </option>
                                <option>45 </option>
                                <option>50 </option>
                                <option selected>55 </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="enviorment_div mb-5 align-items-center d-md-flex">
                          <div className="env_img">
                            <img src="./assets/images/enviorment.png" alt="enviorment" />
                          </div>
                          <div className="env_text">
                            <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                          </div>
                        </div>
                        <div className="button">
                          {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Lesson</Link> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Create Lesson
                          </button>
                        </div>
                      </form>
                      {/*end*/}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
