export function loginRedirectCall() {
  let path = window.location.protocol + "//" + window.location.host + "/";

  window.location.href = path;
}

export function homeRedirectCall(){
  if(localStorage.getItem("type") == "teacher" || localStorage.getItem("type") == "org_teacher"){
    let path= window.location.protocol+"//"+window.location.host+"/teacher_dashboard"
    window.location.href = path;  
  }else if(localStorage.getItem("type") == "organization"){
    let path= window.location.protocol+"//"+window.location.host+"/organization_dashboard"
    window.location.href = path;  
  }
  else if(localStorage.getItem("userType") == "below_18"){
    let path= window.location.protocol+"//"+window.location.host+"/dashboard"
    window.location.href = path;  
  }
  else if(localStorage.getItem("userType") == "above_18"){
    let path= window.location.protocol+"//"+window.location.host+"/dashboard"
    window.location.href = path;  
  }
  else if(localStorage.getItem("userType") == "after_school"){
    let path= window.location.protocol+"//"+window.location.host+"/dashboard"
    window.location.href = path;  
  }
  else if(localStorage.getItem("userType") == "learner"){
    let path= window.location.protocol+"//"+window.location.host+"/dashboard"
    window.location.href = path;  
  }else{
    let path = window.location.protocol + "//" + window.location.host + "/";
    window.location.href = path;
  }
}

export function logOutRedirectCall(){
localStorage.clear();
loginRedirectCall()
}
export function loginRedirectCallWithDataStore(data) {


if(data?.category == "teacher" || data?.category == "org_teacher" || data?.category == "organization" ){
  console.log(data?.category);
  localStorage.setItem("TisLogin",true);
  localStorage.setItem("Tid",data.id);
  localStorage.setItem("Ttoken",data.token);
  localStorage.setItem("Tname",data.name);
  localStorage.setItem("Temail",data.email);
  homeRedirectCall()
}else{
  localStorage.setItem("MAisLogin", true);
  localStorage.setItem("PAid", data.id);
  localStorage.setItem("PAtoken", data.token);
  localStorage.setItem("MAname", data.category == "below_18" || data.category == "above_18" || data.category == "after_school"  ? data.full_name : data.learner_name);
  localStorage.setItem("MAemail", data.email);
  localStorage.setItem("userType", data.category);
  localStorage.setItem("CurrencySymbol", data.currency_symbol);
  localStorage.setItem("CurrencyCode", data.currency_code);
  homeRedirectCall()
}
}