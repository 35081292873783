import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get_user_details, step_comments } from '../../../api/apiHandler';
import { Video_URL } from '../../../Config';

export default function Ostep() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const [data, setData] = useState([])
  console.log('data :', data);

  const steps = async () => {
    try {
      let send_data = {
        userId: localStorage.getItem("Tid")
      };
      console.log('send_data:', send_data);

      const response = await get_user_details(send_data);
      console.log('response:', response);

      if (response?.code == 1) {
        setData(response.data);
      } else {
        console.log('Data not found');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    steps();
  }, [])

  const [commentsData, setCommentsData] = useState({
    comment_step_1: '',
    comment_step_2: '',
    comment_step_3: ''
  });

  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);
  const [showFullText3, setShowFullText3] = useState(false);
  const maxLength = 200;

  const stepComments = async () => {
    try {
      let send_data = {
        user_id: localStorage.getItem("Tid"),
        type: localStorage.getItem("type")
      };

      const response = await step_comments(send_data);
      if (response?.code === "1") {
        setCommentsData(response.data);
      } else {
        console.log('Data not found');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    stepComments();
  }, []);

  const renderComment = (comment, showFullText, setShowFullText) => {
    if (!comment || comment.length <= maxLength) {
      return comment;
    }

    return (
      <>
        {showFullText ? comment : `${comment.slice(0, maxLength)}...`}
        <a
          href="#learn-more"
          className='text-purple fw-600'
          onClick={(e) => {
            e.preventDefault();
            setShowFullText(!showFullText);
          }}
        >
          {showFullText ? ' Show Less' : ' Learn More'}
        </a>
      </>
    );
  };


  return (
    <>

      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 right">
                <div className="top">
                  <div className>
                    <div className="arrow_back mb-3">
                      <a onClick={() => navigate(-1)} className="d-flex align-items-center">
                        <span>
                          <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                          </svg>
                        </span>
                        <span onClick={() => navigate(-1)} className="ps-3">Back</span>
                      </a>
                    </div>
                    <h2 className="mb-4">Welcome To StudentScholars</h2>
                    <p>You are only a few steps away from joining our team of specialized teachers at StudentScholars. Please follow the step-by-step guide to get started.</p>
                    {/*start*/}
                    <div className="video_section dash_video py-5">
                      <div className="video_box text-center">
                        <video id="video" className="border-0 w-auto shadow-none" loop controls="controls" poster="./assets/images/video_bg_dash.png">
                          <source src={`${Video_URL}/videoplayback.mp4`} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    {/*end*/}
                    {/*start*/}
                    <div className="setp_list">
                      <ul>
                        {data[0]?.step_1 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-500">Tell us about yourself. Fill out the applicant to teach with us</p>
                                {data[0]?.step_1 != 2 ?
                                  <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-500">Tell us about yourself. Fill out the applicant to teach with us</p>
                                <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                              </div>
                              {data[0]?.step_1 == 3 ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_1, showFullText1, setShowFullText1)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-500">Tell us about yourself. Fill out the applicant to teach with us</p>
                                <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-500">Tell us about yourself. Fill out the applicant to teach with us</p>
                                <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>) : <></>}
                        {data[0]?.step_2 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-500">Identify Verification : ID Verification
                                  The next step is to confirm your identity with a selfie. It is to show  us that the same person applying for the job will be teaching on the StudentScholars platform.Read more about the Identity  verification process </p>
                                {data[0]?.step_2 != 2 ?
                                  <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-500">Identify Verification : ID Verification
                                  The next step is to confirm your identity with a selfie. It is to show  us that the same person applying for the job will be teaching on the StudentScholars platform.Read more about the Identity  verification process </p>
                                <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                              </div>
                              {data[0]?.step_2 == 3 ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_2, showFullText2, setShowFullText2)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-500">Identify Verification : ID Verification
                                  The next step is to confirm your identity with a selfie. It is to show  us that the same person applying for the job will be teaching on the StudentScholars platform.Read more about the Identity  verification process </p>
                                <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-500">Identify Verification : ID Verification
                                  The next step is to confirm your identity with a selfie. It is to show  us that the same person applying for the job will be teaching on the StudentScholars platform.Read more about the Identity  verification process </p>
                                <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}


                        {data[0]?.step_3 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-500">Review and Fill Out The Organization Agreement</p>
                                {data[0]?.step_3 != 2 ?
                                  <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-500">Review and Fill Out The Organization Agreement</p>
                                <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                              </div>
                              {data[0]?.step_3 == 3 ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_3, showFullText3, setShowFullText3)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-500">Review and Fill Out The Organization Agreement</p>
                                <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-500">Review and Fill Out The Organization Agreement</p>
                                <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}

                        {/* <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                          <div className="d-flex justify-content-between align-items-center row">
                            <div className="col-md-4">
                              <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                              <p className="mb-3 fw-500">Review and Fill Out The Organization Agreement</p>
                              <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                            </div>
                            <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                              <span><img src="./assets/images/completed.png" alt="completed" /></span>
                              <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                            </div>
                          </div>
                        </li> */}
                        {data[0]?.step_4 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-500">Onsite Location Eligibility
                                  This step is optional: Your organization will be eligible to apply, if your organization has a legally authorized location and has liability insurance to conduct lessons for students.</p>
                                <p className="mb-3 fw-500">StudentScholar will list your organization location on the StudentScholars website to find students.
                                  {/* <a  className="text-purple fw-600">Read More</a> */}
                                </p>
                                {data[0]?.step_4 != 2 ?
                                  <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                                  : <></>}
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                {/* <span><img src="./assets/images/cancel.png" alt="cancel" /></span> */}
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-500">Onsite Location Eligibility
                                  This step is optional: Your organization will be eligible to apply, if your organization has a legally authorized location and has liability insurance to conduct lessons for students.</p>
                                <p className="mb-3 fw-500">StudentScholar will list your organization location on the StudentScholars website to find students.<a href="#" className="text-purple fw-600">Read More</a></p>
                                <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                              </div>
                              {data[0]?.step_4 == 3 ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {commentsData?.comment_step_4 != "" && commentsData?.comment_step_4 != null ? commentsData?.comment_step_4 : ""}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-500">Onsite Location Eligibility
                                  This step is optional: Your organization will be eligible to apply, if your organization has a legally authorized location and has liability insurance to conduct lessons for students.</p>
                                <p className="mb-3 fw-500">StudentScholar will list your organization location on the StudentScholars website to find students.<a href="#" className="text-purple fw-600">Read More</a></p>
                                <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional) </span>
                                <p className="mb-3 fw-500">Onsite Location Eligibility
                                  This step is optional: Your organization will be eligible to apply, if your organization has a legally authorized location and has liability insurance to conduct lessons for students.</p>
                                <p className="mb-3 fw-500">StudentScholar will list your organization location on the StudentScholars website to find students.<a href="#" className="text-purple fw-600">Read More</a></p>
                                <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}


                        {data[0]?.step_5 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-500">Setup your profile: Please setup your profile to start teaching. Do not share any personal information including your email, phone number, social media accounts, and website as it is against our policies.  Please refer to our ‘Teacher & Organization Restrictions’ for more information. </p>
                                {data[0]?.step_5 != 2 ?
                                  <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_5 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-500">Setup your profile: Please setup your profile to start teaching. Do not share any personal information including your email, phone number, social media accounts, and website as it is against our policies.  Please refer to our ‘Teacher & Organization Restrictions’ for more information. </p>
                                <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                              </div>
                              {data[0]?.step_5 == 3 ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_3, showFullText3, setShowFullText3)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_5 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-500">Setup your profile: Please setup your profile to start teaching. Do not share any personal information including your email, phone number, social media accounts, and website as it is against our policies.  Please refer to our ‘Teacher & Organization Restrictions’ for more information.</p>
                                <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>

                          </li>
                        ) : data[0]?.step_5 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-500">Setup your profile: Please setup your profile to start teaching. Do not share any personal information including your email, phone number, social media accounts, and website as it is against our policies.  Please refer to our ‘Teacher & Organization Restrictions’ for more information.</p>
                                <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}
                        {data[0]?.step_1 == 2 && data[0]?.step_2 == 2 && data[0]?.step_3 == 2 && data[0]?.step_5 == 2 &&  (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">


                                <Link to={"/login"} className="text-center d-block btn-theme bg-yellow fw-500">Login</Link>
                              </div>

                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                    {/*end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
