import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { contact_us_details, main_category_list, subject_category_list } from '../api/apiHandler';
// import 'react-jquery-plugin'
export default function Header({ sendDataToHome }) {
  const navigate = useNavigate();
  var token = localStorage.getItem('Ttoken') || localStorage.getItem("PAtoken");
  var type = localStorage.getItem('type') || localStorage.getItem("userType")


  const navigateToDashboard = () => {

    if (token && (type == "above_18" || type == "below_18" || type == "learner" || type == "after_school")) {

      navigate("/dashboard");
    }
    else if (token && type == "teacher") {
      // alert('test')

      navigate("/teacher_dashboard");

    }
    else if (token && type == "organization") {

      navigate("/organization_dashboard");

    }
    else {
      navigate("/login")
    }

  }


  const [mainCategory, setMainCategory] = useState([])
  var [SelectedCatID, setSelectedCatID] = useState([]);
  var [SelectedCategory, SetSelectedCategory] = useState([]);
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  var [categoriesData, setCategoriesData] = useState(null);
  const [userData, setUserData] = useState();

  

  var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
  console.log('SelectedSubCatID :', SelectedSubCatID);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);
  console.log('SelectedSubCategory :', SelectedSubCategory);

  const [visibleCatID, setVisibleCatID] = useState(null);

  const handleMouseEnter = (categoryId) => {
    setVisibleCatID(categoryId);
  };

  const handleMouseLeave = () => {
    setVisibleCatID(null);
  };

  const isVisible = visibleCatID === SelectedCatID;


  const MainCategoryData = () => {
    main_category_list().then((resposnse) => {
      if (resposnse?.code === '1') {
        console.log('main_category_list', resposnse.data);
        setMainCategory(resposnse?.data)
        setSelectedCatID([resposnse?.data[0]?.category_id])
        SetSelectedCategory([resposnse?.data[0]?.category_name])
      } else {
        setMainCategory([])

      }
    })
  };

  const getData = () => {
    contact_us_details().then((resposnse) => {
      console.log(resposnse, "resposnse");
      if (resposnse?.code == 1) {

        setUserData(resposnse?.data[0])

      } else {
        // setData([]);
        console.log("data not found");
      }
    });
  };

  useEffect(() => {
    MainCategoryData();
    getData()
    // console.log('main category  ',mainCategory);
  }, [])

  const SubCategoryList = () => {
    subject_category_list({ category_id: SelectedCatID }).then((resposnse) => {
      console.log("subject_category_list", resposnse)

      if (resposnse?.code == 1) {
        setSelectedSubCat(resposnse?.data)
        if (SelectedSubCatID.length > 0) {
          setSelectedSubCatErr('abcd')
          // alert(SelectedSubCatErr)

        }
        // setSelectedSubCatID([resposnse.data[0].subject_category_id])
        // SetSelectedSubCategory([resposnse.data[0].subject_name])



      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    SubCategoryList()
  }, [SelectedCatID]);

  const handleItemClick = (id, cat_name) => {
    // console.log('idaaa :', id);
    // console.log('SelectedCatID111 :', SelectedCatID);
    if (SelectedCatID != id) {
      setSelectedSubCat([])
      setSelectedSubCatID([])
      SetSelectedSubCategory([])
      setSubCategoriesData([])
    }

    setSelectedCatID(id)
    SetSelectedCategory(cat_name)
    setCategoriesData(cat_name)
  }

  const handleSubjectClick = (subCategory) => {
    // SetSelectedSubCategory(subCtegory)
    const data = { SelectedCategory, subCategory };
    console.log(data, 'homeData');
    sendDataToHome(data);
  }

  const reorderCategories = (categories) => {
    const inPersonClassesIndex = categories.findIndex(category => category.category_name === "In-person Classes");
    const professionalTrainingIndex = categories.findIndex(category => category.category_name === "Professional Training");

    if (inPersonClassesIndex !== -1 && professionalTrainingIndex !== -1) {
      const [inPersonClasses] = categories.splice(inPersonClassesIndex, 1);
      categories.splice(professionalTrainingIndex + 1, 0, inPersonClasses);
    }

    return categories;
  };

  const reorderedCategories = reorderCategories([...mainCategory]);


  //   $(document).ready(function () { 
  //     // content

  //   $("#search-icon").click(function() {
  //   $(".menu").toggleClass("search");
  //   $(".menu").toggleClass("no-search");
  //   $(".search-input").toggleClass("search-active");
  // });

  // $('.menu-toggle').click(function(){

  //     console.log($(this).hasClass("is-active"))
  //   if($(this).hasClass("is-active")){
  //     $(".menu").removeClass("mobile-nav");
  //     $(this).removeClass("is-active");
  //   }else{
  //     $(".menu").addClass("mobile-nav");
  //     $(this).addClass("is-active");
  //   }

  // });
  // });

  return (
    <>
    <Helmet>
  <script type="text/javascript">
    {`
      (function() {
        const menuToggleElements = document.querySelectorAll('.menu-toggle');
        menuToggleElements.forEach(function (element) {
          element.addEventListener('click', function () {
            const menu = document.querySelector('.menu');
            menu.classList.toggle('mobile-nav');
            this.classList.toggle('is-active');
          });
        });

        const menuBarElements = document.querySelectorAll('.menu-bar');
        menuBarElements.forEach(function (element) {
          element.addEventListener('click', function () {
            const menuWrappers = document.querySelectorAll('.menu-wrappers');
            menuWrappers.forEach(function (wrapper) {
              wrapper.classList.toggle('side_res_menu');
            });
            this.classList.toggle('side_res_menu');
          });
        });

        document.addEventListener('DOMContentLoaded', function () {
          var rwdMenu = document.querySelector('.rwd-menu');
          var topMenu = document.querySelectorAll('.rwd-menu > li > a');
          var parentLi = document.querySelectorAll('.rwd-menu > li');
          var backBtn = document.querySelector('.back-btn');

          topMenu.forEach(function (menu) {
            menu.addEventListener('click', function (e) {
              var thisTopMenu = this.parentElement;
              rwdMenu.classList.add('rwd-menu-view');
              parentLi.forEach(function (li) {
                li.classList.remove('open-submenu');
              });
              thisTopMenu.classList.add('open-submenu');
            });
          });

          backBtn.addEventListener('click', function () {
            parentLi.forEach(function (li) {
              li.classList.remove('open-submenu');
            });
            rwdMenu.classList.remove('rwd-menu-view');
          });
        });
      })();
    `}
  </script>
</Helmet>

      <div>
        <header>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <div className="logo">
                <a href="#">
                  <img src="/assets/images/logo.png" alt="logo" className="img-fluid" />
                </a>
              </div>
              <div className="d-lg-none d-flex align-items-center">
                <ul className="fist-menu list-unstyled me-3">
                  <li>
                    <a href="#">
                      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.6297 22.0781L17.3484 15.7969C18.7078 14.1328 19.4578 12.0375 19.4578 9.75C19.4578 4.36406 15.0924 0 9.70782 0C4.32329 0 0 4.36547 0 9.75C0 15.1345 4.36501 19.5 9.70782 19.5C11.9949 19.5 14.093 18.7073 15.7547 17.3888L22.0359 23.67C22.2984 23.8922 22.5891 24 22.875 24C23.1609 24 23.4506 23.8901 23.6705 23.6704C24.1078 23.2312 24.1078 22.5187 23.6297 22.0781ZM2.25001 9.75C2.25001 5.61469 5.6147 2.25 9.75001 2.25C13.8853 2.25 17.25 5.61469 17.25 9.75C17.25 13.8853 13.8853 17.25 9.75001 17.25C5.6147 17.25 2.25001 13.8844 2.25001 9.75Z" fill="#131313" />
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="menu-toggle" id="mobile-menu">
                  <span className="bar" />
                  <span className="bar" />
                  <span className="bar" />
                </div>
              </div>
              <div className="menu no-search d-flex align-items-center">
                <ul className="fist-menu d-lg-flex align-items-center list-unstyled">
                  <li>
                    <Link to={token ? "search_class" : "login"}>
                      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.6297 22.0781L17.3484 15.7969C18.7078 14.1328 19.4578 12.0375 19.4578 9.75C19.4578 4.36406 15.0924 0 9.70782 0C4.32329 0 0 4.36547 0 9.75C0 15.1345 4.36501 19.5 9.70782 19.5C11.9949 19.5 14.093 18.7073 15.7547 17.3888L22.0359 23.67C22.2984 23.8922 22.5891 24 22.875 24C23.1609 24 23.4506 23.8901 23.6705 23.6704C24.1078 23.2312 24.1078 22.5187 23.6297 22.0781ZM2.25001 9.75C2.25001 5.61469 5.6147 2.25 9.75001 2.25C13.8853 2.25 17.25 5.61469 17.25 9.75C17.25 13.8853 13.8853 17.25 9.75001 17.25C5.6147 17.25 2.25001 13.8844 2.25001 9.75Z" fill="#570861" />
                      </svg>
                      <span className="ms-2 fw-600">Search</span>
                    </Link>
                  </li>
                  <li className="ms-lg-4">
                    <Link to={token ? "/favorites" : "/login"}>
                      <svg width={27} height={22} viewBox="0 0 27 22" fill="red" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.3089 2.15577C21.4341 -0.718199 16.7575 -0.718199 13.8835 2.15577L13.216 2.82291L12.5489 2.15577C9.67489 -0.718588 4.99789 -0.718588 2.12393 2.15577C-0.691685 4.97138 -0.709969 9.43443 2.08153 12.5375C4.62756 15.3667 12.1365 21.4792 12.4551 21.7379C12.6627 21.9072 12.9224 21.9995 13.1903 21.9993L13.2156 21.9989C13.4832 22.0113 13.7528 21.9195 13.9761 21.7379C14.2947 21.4792 21.8044 15.3667 24.3513 12.5371C27.1424 9.43443 27.1241 4.97138 24.3089 2.15577ZM22.6163 10.9761C20.6312 13.1813 15.1746 17.7296 13.2156 19.3435C11.2566 17.7299 5.80119 13.1821 3.81649 10.9764C1.86913 8.81202 1.85085 5.72955 3.77409 3.80631C4.75632 2.82446 6.04626 2.33315 7.3362 2.33315C8.62614 2.33315 9.91608 2.82407 10.8983 3.80631L12.3656 5.27364C12.5355 5.44254 12.7547 5.55303 12.9915 5.58912C13.1826 5.63052 13.3809 5.62337 13.5685 5.56833C13.7561 5.51328 13.9268 5.4121 14.0652 5.27402L15.5333 3.80631C17.4982 1.84223 20.6942 1.84262 22.6579 3.80631C24.5812 5.72955 24.5629 8.81202 22.6163 10.9761Z" fill="red" />
                      </svg>
                      <span className="ms-2 fw-600">My Favourites</span>
                    </Link>
                  </li>
                  <li className="ms-lg-4">
                    <div onClick={() => navigateToDashboard()} style={{ cursor: 'pointer' }}>
                      <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.4608 8.69902L19.4594 8.69766L11.301 0.53957C10.9532 0.191602 10.4909 0 9.99911 0C9.50732 0 9.04497 0.191484 8.69708 0.539414L0.542902 8.69336C0.540168 8.69609 0.537433 8.69898 0.534699 8.70176C-0.179442 9.41996 -0.178192 10.5853 0.538214 11.3017C0.86548 11.6291 1.29778 11.8188 1.75997 11.8386C1.77872 11.8405 1.79767 11.8414 1.81673 11.8414H2.14189V17.8453C2.14189 19.0334 3.10853 20 4.29689 20H7.48868C7.8122 20 8.07462 19.7377 8.07462 19.4141V14.707C8.07462 14.1649 8.51564 13.7239 9.05779 13.7239H10.9404C11.4826 13.7239 11.9235 14.1649 11.9235 14.707V19.4141C11.9235 19.7377 12.1858 20 12.5095 20H15.7013C16.8897 20 17.8563 19.0334 17.8563 17.8453V11.8414H18.1578C18.6495 11.8414 19.1118 11.6499 19.4599 11.302C20.177 10.5844 20.1773 9.41711 19.4608 8.69902Z" fill="#570861" />
                      </svg>
                      <span className="ms-2 fw-600">Home</span>
                    </div>
                  </li>
                </ul>
                
                <ul className="d-lg-flex list-unstyled button-menu ms-lg-4">
                  {token ? (
                    <li>
                      {/* <Link to="/login" className="log_in d-inline-block text-capitalize">image</Link> */}
                      <div className="avatar-preview">

                        <img src={userData?.profile_image ? userData?.profile_image : "./assets/images/kids-profile.png"} height={'45px'} width={'45px'} style={{ borderRadius: '50%' }} />
                      </div>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link to="/login" className="log_in d-inline-block text-capitalize">Log in</Link>
                      </li>
                      <li className="ms-lg-3 join_now">
                        <Link to="/sign_in_step1" className="bg-yellow d-inline-block text-capitalize">Join now</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </header>
        {/*end-header*/}
        <div className="nav-item-list">
          <div className="container-fluid">
            <ul className="list-unstyled main-menu d-flex align-items-center">
              <li className="menu-bar d-lg-none d-flex">
                <a href="#!" className="badge bg-purple py-2 text-white">Browse
                  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-menu-down" viewBox="0 0 16 16">
                    <path d="M7.646.146a.5.5 0 0 1 .708 0L10.207 2H14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.793L7.646.146zM1 7v3h14V7H1zm14-1V4a1 1 0 0 0-1-1h-3.793a1 1 0 0 1-.707-.293L8 1.207l-1.5 1.5A1 1 0 0 1 5.793 3H2a1 1 0 0 0-1 1v2h14zm0 5H1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zM2 4.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </a>
              </li>
              {reorderedCategories && reorderedCategories.map(category => (
                <li
                  key={category.category_id}
                  onMouseLeave={() => handleMouseLeave(category.category_id)}
                  onMouseEnter={() => handleMouseEnter(category.category_id)}
                >
                  <a
                    onClick={() => handleItemClick(category.category_id, category.category_name)}
                    style={{ padding: "16px", cursor: "pointer" }}
                  >
                    {category.category_name}
                  </a>

                  {SelectedCatID === category.category_id && (
                    <>
                      {SelectedSubCat[0]?.category_name ? (
                        <div className={`dropdown-menus ${isVisible ? 'visible' : ''}`}>
                          {SelectedSubCat.map((SubCat, index) => (
                            <ul className="sub-menu" key={index}>
                              <li className="headings">
                                <span className="text-purple fw-600">{SubCat.category_name}</span>
                              </li>
                              {SubCat.category?.map((SubCat1, index1) => (
                                <li key={index1}>
                                  <a href="javascript:void(0)" onClick={() => handleSubjectClick(SubCat1.subject_name)}>
                                    {SubCat1.subject_name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      ) : (
                        <div className={`dropdown-menus ${isVisible ? 'visible' : ''}`}>
                          <ul className="sub-menu">
                            {SelectedSubCat.map((SubCat, index) => (
                              <li key={index}>
                                <a href="javascript:void(0)" onClick={() => handleSubjectClick(SubCat.subject_name)}>
                                  {SubCat.subject_name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </li>
              ))}


              {/* <li>
                    <a href="#">In-Person Classes</a>
                    <div className="dropdown-menus">
                      <ul className="sub-menu">
                        <li className="headings">
                          <a href="#" className="text-purple fw-600">Activities &amp; Events</a>
                          <ul className="sub-childmenu">
                            <li className="headings">
                              <span className="text-purple">Australia</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">New South Wales</a></li>
                                <li><a href="#" className="text-purple fw-600">Victoria</a></li>
                                <li><a href="#" className="text-purple fw-600">Queensland</a></li>
                                <li><a href="#" className="text-purple fw-600">Western Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">South Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">Tasmania</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Canada</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Alberta</a></li>
                                <li><a href="#" className="text-purple fw-600">British Columbia</a></li>
                                <li><a href="#" className="text-purple fw-600">Manitoba</a></li>
                                <li><a href="#" className="text-purple fw-600">New Brunswick</a></li>
                                <li><a href="#" className="text-purple fw-600">Newfoundland and Labrador</a></li>
                                <li><a href="#" className="text-purple fw-600">Northwest Territories</a></li>
                                <li><a href="#" className="text-purple fw-600">Nova Scotia</a></li>
                                <li><a href="#" className="text-purple fw-600">Nunavut</a></li>
                                <li><a href="#" className="text-purple fw-600">Ontario</a></li>
                                <li><a href="#" className="text-purple fw-600">Prince Edward Island</a></li>
                                <li><a href="#" className="text-purple fw-600">Quebec</a></li>
                                <li><a href="#" className="text-purple fw-600">Saskatchewan</a></li>
                                <li><a href="#" className="text-purple fw-600">Yukon</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">New Zealand</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Auckland</a></li>
                                <li><a href="#" className="text-purple fw-600">New Plymouth</a></li>
                                <li><a href="#" className="text-purple fw-600">Wellington</a></li>
                                <li><a href="#" className="text-purple fw-600">Nelson</a></li>
                                <li><a href="#" className="text-purple fw-600">Canterbury</a></li>
                                <li><a href="#" className="text-purple fw-600">Otago.</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Singapore</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Central Region</a></li>
                                <li><a href="#" className="text-purple fw-600">East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North-East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">West Region</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">USA</span>
                              <ul className="scrollbar" id="style-3">
                                <li><a href="#" className="text-purple fw-600">Alabama</a></li>
                                <li><a href="#" className="text-purple fw-600">Alaska</a></li>
                                <li><a href="#" className="text-purple fw-600">Arizona</a></li>
                                <li><a href="#" className="text-purple fw-600">Arkansas</a></li>
                                <li><a href="#" className="text-purple fw-600">California</a></li>
                                <li><a href="#" className="text-purple fw-600">Colorado</a></li>
                                <li><a href="#" className="text-purple fw-600">Connecticut</a></li>
                                <li><a href="#" className="text-purple fw-600">Delaware</a></li>
                                <li><a href="#" className="text-purple fw-600">Florida</a></li>
                                <li><a href="#" className="text-purple fw-600">Georgia</a></li>
                                <li><a href="#" className="text-purple fw-600">Hawaii</a></li>
                                <li><a href="#" className="text-purple fw-600">Idaho</a></li>
                                <li><a href="#" className="text-purple fw-600">Illinois</a></li>
                                <li><a href="#" className="text-purple fw-600">Indiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Iowa</a></li>
                                <li><a href="#" className="text-purple fw-600">Kansas</a></li>
                                <li><a href="#" className="text-purple fw-600">Kentucky</a></li>
                                <li><a href="#" className="text-purple fw-600">Louisiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Maine</a></li>
                                <li><a href="#" className="text-purple fw-600">Maryland</a></li>
                                <li><a href="#" className="text-purple fw-600">Massachusetts</a></li>
                                <li><a href="#" className="text-purple fw-600">Michigan</a></li>
                                <li><a href="#" className="text-purple fw-600">Minnesota</a></li>
                                <li><a href="#" className="text-purple fw-600">Mississippi</a></li>
                                <li><a href="#" className="text-purple fw-600">Missouri</a></li>
                                <li><a href="#" className="text-purple fw-600">Montana</a></li>
                                <li><a href="#" className="text-purple fw-600">Nebraska</a></li>
                                <li><a href="#" className="text-purple fw-600">Nevada</a></li>
                                <li><a href="#" className="text-purple fw-600">New Hampshire</a></li>
                                <li><a href="#" className="text-purple fw-600">New Jersey</a></li>
                                <li><a href="#" className="text-purple fw-600">New Mexico</a></li>
                                <li><a href="#" className="text-purple fw-600">New York</a></li>
                                <li><a href="#" className="text-purple fw-600">North Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">North Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Ohio</a></li>
                                <li><a href="#" className="text-purple fw-600">Oklahoma</a></li>
                                <li><a href="#" className="text-purple fw-600">Oregon</a></li>
                                <li><a href="#" className="text-purple fw-600">Pennsylvania</a></li>
                                <li><a href="#" className="text-purple fw-600">Rhode Island</a></li>
                                <li><a href="#" className="text-purple fw-600">South Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">South Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Tennessee</a></li>
                                <li><a href="#" className="text-purple fw-600">Texas</a></li>
                                <li><a href="#" className="text-purple fw-600">Utah</a></li>
                                <li><a href="#" className="text-purple fw-600">Vermont</a></li>
                                <li><a href="#" className="text-purple fw-600">Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Washington</a></li>
                                <li><a href="#" className="text-purple fw-600">West Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Wisconsin</a></li>
                                <li><a href="#" className="text-purple fw-600">Wyoming</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">UK</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">London</a></li>
                                <li><a href="#" className="text-purple fw-600">North East</a></li>
                                <li><a href="#" className="text-purple fw-600">North West</a></li>
                                <li><a href="#" className="text-purple fw-600">Yorkshire</a></li>
                                <li><a href="#" className="text-purple fw-600">East Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">West Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">South East</a></li>
                                <li><a href="#" className="text-purple fw-600">East of England </a></li>
                                <li><a href="#" className="text-purple fw-600">South West</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="headings">
                          <span className="text-purple">Afterschool Programs</span>
                          <ul className="sub-childmenu">
                            <li className="headings">
                              <span className="text-purple">Australia</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">New South Wales</a></li>
                                <li><a href="#" className="text-purple fw-600">Victoria</a></li>
                                <li><a href="#" className="text-purple fw-600">Queensland</a></li>
                                <li><a href="#" className="text-purple fw-600">Western Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">South Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">Tasmania</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Canada</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Alberta</a></li>
                                <li><a href="#" className="text-purple fw-600">British Columbia</a></li>
                                <li><a href="#" className="text-purple fw-600">Manitoba</a></li>
                                <li><a href="#" className="text-purple fw-600">New Brunswick</a></li>
                                <li><a href="#" className="text-purple fw-600">Newfoundland and Labrador</a></li>
                                <li><a href="#" className="text-purple fw-600">Northwest Territories</a></li>
                                <li><a href="#" className="text-purple fw-600">Nova Scotia</a></li>
                                <li><a href="#" className="text-purple fw-600">Nunavut</a></li>
                                <li><a href="#" className="text-purple fw-600">Ontario</a></li>
                                <li><a href="#" className="text-purple fw-600">Prince Edward Island</a></li>
                                <li><a href="#" className="text-purple fw-600">Quebec</a></li>
                                <li><a href="#" className="text-purple fw-600">Saskatchewan</a></li>
                                <li><a href="#" className="text-purple fw-600">Yukon</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">New Zealand</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Auckland</a></li>
                                <li><a href="#" className="text-purple fw-600">New Plymouth</a></li>
                                <li><a href="#" className="text-purple fw-600">Wellington</a></li>
                                <li><a href="#" className="text-purple fw-600">Nelson</a></li>
                                <li><a href="#" className="text-purple fw-600">Canterbury</a></li>
                                <li><a href="#" className="text-purple fw-600">Otago.</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Singapore</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Central Region</a></li>
                                <li><a href="#" className="text-purple fw-600">East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North-East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">West Region</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">USA</span>
                              <ul className="scrollbar" id="style-3">
                                <li><a href="#" className="text-purple fw-600">Alabama</a></li>
                                <li><a href="#" className="text-purple fw-600">Alaska</a></li>
                                <li><a href="#" className="text-purple fw-600">Arizona</a></li>
                                <li><a href="#" className="text-purple fw-600">Arkansas</a></li>
                                <li><a href="#" className="text-purple fw-600">California</a></li>
                                <li><a href="#" className="text-purple fw-600">Colorado</a></li>
                                <li><a href="#" className="text-purple fw-600">Connecticut</a></li>
                                <li><a href="#" className="text-purple fw-600">Delaware</a></li>
                                <li><a href="#" className="text-purple fw-600">Florida</a></li>
                                <li><a href="#" className="text-purple fw-600">Georgia</a></li>
                                <li><a href="#" className="text-purple fw-600">Hawaii</a></li>
                                <li><a href="#" className="text-purple fw-600">Idaho</a></li>
                                <li><a href="#" className="text-purple fw-600">Illinois</a></li>
                                <li><a href="#" className="text-purple fw-600">Indiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Iowa</a></li>
                                <li><a href="#" className="text-purple fw-600">Kansas</a></li>
                                <li><a href="#" className="text-purple fw-600">Kentucky</a></li>
                                <li><a href="#" className="text-purple fw-600">Louisiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Maine</a></li>
                                <li><a href="#" className="text-purple fw-600">Maryland</a></li>
                                <li><a href="#" className="text-purple fw-600">Massachusetts</a></li>
                                <li><a href="#" className="text-purple fw-600">Michigan</a></li>
                                <li><a href="#" className="text-purple fw-600">Minnesota</a></li>
                                <li><a href="#" className="text-purple fw-600">Mississippi</a></li>
                                <li><a href="#" className="text-purple fw-600">Missouri</a></li>
                                <li><a href="#" className="text-purple fw-600">Montana</a></li>
                                <li><a href="#" className="text-purple fw-600">Nebraska</a></li>
                                <li><a href="#" className="text-purple fw-600">Nevada</a></li>
                                <li><a href="#" className="text-purple fw-600">New Hampshire</a></li>
                                <li><a href="#" className="text-purple fw-600">New Jersey</a></li>
                                <li><a href="#" className="text-purple fw-600">New Mexico</a></li>
                                <li><a href="#" className="text-purple fw-600">New York</a></li>
                                <li><a href="#" className="text-purple fw-600">North Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">North Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Ohio</a></li>
                                <li><a href="#" className="text-purple fw-600">Oklahoma</a></li>
                                <li><a href="#" className="text-purple fw-600">Oregon</a></li>
                                <li><a href="#" className="text-purple fw-600">Pennsylvania</a></li>
                                <li><a href="#" className="text-purple fw-600">Rhode Island</a></li>
                                <li><a href="#" className="text-purple fw-600">South Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">South Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Tennessee</a></li>
                                <li><a href="#" className="text-purple fw-600">Texas</a></li>
                                <li><a href="#" className="text-purple fw-600">Utah</a></li>
                                <li><a href="#" className="text-purple fw-600">Vermont</a></li>
                                <li><a href="#" className="text-purple fw-600">Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Washington</a></li>
                                <li><a href="#" className="text-purple fw-600">West Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Wisconsin</a></li>
                                <li><a href="#" className="text-purple fw-600">Wyoming</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">UK</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">London</a></li>
                                <li><a href="#" className="text-purple fw-600">North East</a></li>
                                <li><a href="#" className="text-purple fw-600">North West</a></li>
                                <li><a href="#" className="text-purple fw-600">Yorkshire</a></li>
                                <li><a href="#" className="text-purple fw-600">East Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">West Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">South East</a></li>
                                <li><a href="#" className="text-purple fw-600">East of England </a></li>
                                <li><a href="#" className="text-purple fw-600">South West</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="headings">
                          <span className="text-purple">Baby &amp; Preschool</span>
                          <ul className="sub-childmenu">
                            <li className="headings">
                              <span className="text-purple">Australia</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">New South Wales</a></li>
                                <li><a href="#" className="text-purple fw-600">Victoria</a></li>
                                <li><a href="#" className="text-purple fw-600">Queensland</a></li>
                                <li><a href="#" className="text-purple fw-600">Western Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">South Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">Tasmania</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Canada</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Alberta</a></li>
                                <li><a href="#" className="text-purple fw-600">British Columbia</a></li>
                                <li><a href="#" className="text-purple fw-600">Manitoba</a></li>
                                <li><a href="#" className="text-purple fw-600">New Brunswick</a></li>
                                <li><a href="#" className="text-purple fw-600">Newfoundland and Labrador</a></li>
                                <li><a href="#" className="text-purple fw-600">Northwest Territories</a></li>
                                <li><a href="#" className="text-purple fw-600">Nova Scotia</a></li>
                                <li><a href="#" className="text-purple fw-600">Nunavut</a></li>
                                <li><a href="#" className="text-purple fw-600">Ontario</a></li>
                                <li><a href="#" className="text-purple fw-600">Prince Edward Island</a></li>
                                <li><a href="#" className="text-purple fw-600">Quebec</a></li>
                                <li><a href="#" className="text-purple fw-600">Saskatchewan</a></li>
                                <li><a href="#" className="text-purple fw-600">Yukon</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">New Zealand</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Auckland</a></li>
                                <li><a href="#" className="text-purple fw-600">New Plymouth</a></li>
                                <li><a href="#" className="text-purple fw-600">Wellington</a></li>
                                <li><a href="#" className="text-purple fw-600">Nelson</a></li>
                                <li><a href="#" className="text-purple fw-600">Canterbury</a></li>
                                <li><a href="#" className="text-purple fw-600">Otago.</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Singapore</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Central Region</a></li>
                                <li><a href="#" className="text-purple fw-600">East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North-East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">West Region</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">USA</span>
                              <ul className="scrollbar" id="style-3">
                                <li><a href="#" className="text-purple fw-600">Alabama</a></li>
                                <li><a href="#" className="text-purple fw-600">Alaska</a></li>
                                <li><a href="#" className="text-purple fw-600">Arizona</a></li>
                                <li><a href="#" className="text-purple fw-600">Arkansas</a></li>
                                <li><a href="#" className="text-purple fw-600">California</a></li>
                                <li><a href="#" className="text-purple fw-600">Colorado</a></li>
                                <li><a href="#" className="text-purple fw-600">Connecticut</a></li>
                                <li><a href="#" className="text-purple fw-600">Delaware</a></li>
                                <li><a href="#" className="text-purple fw-600">Florida</a></li>
                                <li><a href="#" className="text-purple fw-600">Georgia</a></li>
                                <li><a href="#" className="text-purple fw-600">Hawaii</a></li>
                                <li><a href="#" className="text-purple fw-600">Idaho</a></li>
                                <li><a href="#" className="text-purple fw-600">Illinois</a></li>
                                <li><a href="#" className="text-purple fw-600">Indiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Iowa</a></li>
                                <li><a href="#" className="text-purple fw-600">Kansas</a></li>
                                <li><a href="#" className="text-purple fw-600">Kentucky</a></li>
                                <li><a href="#" className="text-purple fw-600">Louisiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Maine</a></li>
                                <li><a href="#" className="text-purple fw-600">Maryland</a></li>
                                <li><a href="#" className="text-purple fw-600">Massachusetts</a></li>
                                <li><a href="#" className="text-purple fw-600">Michigan</a></li>
                                <li><a href="#" className="text-purple fw-600">Minnesota</a></li>
                                <li><a href="#" className="text-purple fw-600">Mississippi</a></li>
                                <li><a href="#" className="text-purple fw-600">Missouri</a></li>
                                <li><a href="#" className="text-purple fw-600">Montana</a></li>
                                <li><a href="#" className="text-purple fw-600">Nebraska</a></li>
                                <li><a href="#" className="text-purple fw-600">Nevada</a></li>
                                <li><a href="#" className="text-purple fw-600">New Hampshire</a></li>
                                <li><a href="#" className="text-purple fw-600">New Jersey</a></li>
                                <li><a href="#" className="text-purple fw-600">New Mexico</a></li>
                                <li><a href="#" className="text-purple fw-600">New York</a></li>
                                <li><a href="#" className="text-purple fw-600">North Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">North Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Ohio</a></li>
                                <li><a href="#" className="text-purple fw-600">Oklahoma</a></li>
                                <li><a href="#" className="text-purple fw-600">Oregon</a></li>
                                <li><a href="#" className="text-purple fw-600">Pennsylvania</a></li>
                                <li><a href="#" className="text-purple fw-600">Rhode Island</a></li>
                                <li><a href="#" className="text-purple fw-600">South Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">South Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Tennessee</a></li>
                                <li><a href="#" className="text-purple fw-600">Texas</a></li>
                                <li><a href="#" className="text-purple fw-600">Utah</a></li>
                                <li><a href="#" className="text-purple fw-600">Vermont</a></li>
                                <li><a href="#" className="text-purple fw-600">Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Washington</a></li>
                                <li><a href="#" className="text-purple fw-600">West Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Wisconsin</a></li>
                                <li><a href="#" className="text-purple fw-600">Wyoming</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">UK</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">London</a></li>
                                <li><a href="#" className="text-purple fw-600">North East</a></li>
                                <li><a href="#" className="text-purple fw-600">North West</a></li>
                                <li><a href="#" className="text-purple fw-600">Yorkshire</a></li>
                                <li><a href="#" className="text-purple fw-600">East Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">West Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">South East</a></li>
                                <li><a href="#" className="text-purple fw-600">East of England </a></li>
                                <li><a href="#" className="text-purple fw-600">South West</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="headings">
                          <span className="text-purple">Camps &amp; Clubs </span>
                          <ul className="sub-childmenu">
                            <li className="headings">
                              <span className="text-purple">Australia</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">New South Wales</a></li>
                                <li><a href="#" className="text-purple fw-600">Victoria</a></li>
                                <li><a href="#" className="text-purple fw-600">Queensland</a></li>
                                <li><a href="#" className="text-purple fw-600">Western Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">South Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">Tasmania</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Canada</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Alberta</a></li>
                                <li><a href="#" className="text-purple fw-600">British Columbia</a></li>
                                <li><a href="#" className="text-purple fw-600">Manitoba</a></li>
                                <li><a href="#" className="text-purple fw-600">New Brunswick</a></li>
                                <li><a href="#" className="text-purple fw-600">Newfoundland and Labrador</a></li>
                                <li><a href="#" className="text-purple fw-600">Northwest Territories</a></li>
                                <li><a href="#" className="text-purple fw-600">Nova Scotia</a></li>
                                <li><a href="#" className="text-purple fw-600">Nunavut</a></li>
                                <li><a href="#" className="text-purple fw-600">Ontario</a></li>
                                <li><a href="#" className="text-purple fw-600">Prince Edward Island</a></li>
                                <li><a href="#" className="text-purple fw-600">Quebec</a></li>
                                <li><a href="#" className="text-purple fw-600">Saskatchewan</a></li>
                                <li><a href="#" className="text-purple fw-600">Yukon</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">New Zealand</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Auckland</a></li>
                                <li><a href="#" className="text-purple fw-600">New Plymouth</a></li>
                                <li><a href="#" className="text-purple fw-600">Wellington</a></li>
                                <li><a href="#" className="text-purple fw-600">Nelson</a></li>
                                <li><a href="#" className="text-purple fw-600">Canterbury</a></li>
                                <li><a href="#" className="text-purple fw-600">Otago.</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Singapore</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Central Region</a></li>
                                <li><a href="#" className="text-purple fw-600">East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North-East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">West Region</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">USA</span>
                              <ul className="scrollbar" id="style-3">
                                <li><a href="#" className="text-purple fw-600">Alabama</a></li>
                                <li><a href="#" className="text-purple fw-600">Alaska</a></li>
                                <li><a href="#" className="text-purple fw-600">Arizona</a></li>
                                <li><a href="#" className="text-purple fw-600">Arkansas</a></li>
                                <li><a href="#" className="text-purple fw-600">California</a></li>
                                <li><a href="#" className="text-purple fw-600">Colorado</a></li>
                                <li><a href="#" className="text-purple fw-600">Connecticut</a></li>
                                <li><a href="#" className="text-purple fw-600">Delaware</a></li>
                                <li><a href="#" className="text-purple fw-600">Florida</a></li>
                                <li><a href="#" className="text-purple fw-600">Georgia</a></li>
                                <li><a href="#" className="text-purple fw-600">Hawaii</a></li>
                                <li><a href="#" className="text-purple fw-600">Idaho</a></li>
                                <li><a href="#" className="text-purple fw-600">Illinois</a></li>
                                <li><a href="#" className="text-purple fw-600">Indiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Iowa</a></li>
                                <li><a href="#" className="text-purple fw-600">Kansas</a></li>
                                <li><a href="#" className="text-purple fw-600">Kentucky</a></li>
                                <li><a href="#" className="text-purple fw-600">Louisiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Maine</a></li>
                                <li><a href="#" className="text-purple fw-600">Maryland</a></li>
                                <li><a href="#" className="text-purple fw-600">Massachusetts</a></li>
                                <li><a href="#" className="text-purple fw-600">Michigan</a></li>
                                <li><a href="#" className="text-purple fw-600">Minnesota</a></li>
                                <li><a href="#" className="text-purple fw-600">Mississippi</a></li>
                                <li><a href="#" className="text-purple fw-600">Missouri</a></li>
                                <li><a href="#" className="text-purple fw-600">Montana</a></li>
                                <li><a href="#" className="text-purple fw-600">Nebraska</a></li>
                                <li><a href="#" className="text-purple fw-600">Nevada</a></li>
                                <li><a href="#" className="text-purple fw-600">New Hampshire</a></li>
                                <li><a href="#" className="text-purple fw-600">New Jersey</a></li>
                                <li><a href="#" className="text-purple fw-600">New Mexico</a></li>
                                <li><a href="#" className="text-purple fw-600">New York</a></li>
                                <li><a href="#" className="text-purple fw-600">North Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">North Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Ohio</a></li>
                                <li><a href="#" className="text-purple fw-600">Oklahoma</a></li>
                                <li><a href="#" className="text-purple fw-600">Oregon</a></li>
                                <li><a href="#" className="text-purple fw-600">Pennsylvania</a></li>
                                <li><a href="#" className="text-purple fw-600">Rhode Island</a></li>
                                <li><a href="#" className="text-purple fw-600">South Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">South Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Tennessee</a></li>
                                <li><a href="#" className="text-purple fw-600">Texas</a></li>
                                <li><a href="#" className="text-purple fw-600">Utah</a></li>
                                <li><a href="#" className="text-purple fw-600">Vermont</a></li>
                                <li><a href="#" className="text-purple fw-600">Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Washington</a></li>
                                <li><a href="#" className="text-purple fw-600">West Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Wisconsin</a></li>
                                <li><a href="#" className="text-purple fw-600">Wyoming</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">UK</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">London</a></li>
                                <li><a href="#" className="text-purple fw-600">North East</a></li>
                                <li><a href="#" className="text-purple fw-600">North West</a></li>
                                <li><a href="#" className="text-purple fw-600">Yorkshire</a></li>
                                <li><a href="#" className="text-purple fw-600">East Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">West Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">South East</a></li>
                                <li><a href="#" className="text-purple fw-600">East of England </a></li>
                                <li><a href="#" className="text-purple fw-600">South West</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="headings">
                          <span className="text-purple">Classes &amp; Programs </span>
                          <ul className="sub-childmenu">
                            <li className="headings">
                              <span className="text-purple">Australia</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">New South Wales</a></li>
                                <li><a href="#" className="text-purple fw-600">Victoria</a></li>
                                <li><a href="#" className="text-purple fw-600">Queensland</a></li>
                                <li><a href="#" className="text-purple fw-600">Western Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">South Australia</a></li>
                                <li><a href="#" className="text-purple fw-600">Tasmania</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Canada</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Alberta</a></li>
                                <li><a href="#" className="text-purple fw-600">British Columbia</a></li>
                                <li><a href="#" className="text-purple fw-600">Manitoba</a></li>
                                <li><a href="#" className="text-purple fw-600">New Brunswick</a></li>
                                <li><a href="#" className="text-purple fw-600">Newfoundland and Labrador</a></li>
                                <li><a href="#" className="text-purple fw-600">Northwest Territories</a></li>
                                <li><a href="#" className="text-purple fw-600">Nova Scotia</a></li>
                                <li><a href="#" className="text-purple fw-600">Nunavut</a></li>
                                <li><a href="#" className="text-purple fw-600">Ontario</a></li>
                                <li><a href="#" className="text-purple fw-600">Prince Edward Island</a></li>
                                <li><a href="#" className="text-purple fw-600">Quebec</a></li>
                                <li><a href="#" className="text-purple fw-600">Saskatchewan</a></li>
                                <li><a href="#" className="text-purple fw-600">Yukon</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">New Zealand</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Auckland</a></li>
                                <li><a href="#" className="text-purple fw-600">New Plymouth</a></li>
                                <li><a href="#" className="text-purple fw-600">Wellington</a></li>
                                <li><a href="#" className="text-purple fw-600">Nelson</a></li>
                                <li><a href="#" className="text-purple fw-600">Canterbury</a></li>
                                <li><a href="#" className="text-purple fw-600">Otago.</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">Singapore</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">Central Region</a></li>
                                <li><a href="#" className="text-purple fw-600">East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North Region</a></li>
                                <li><a href="#" className="text-purple fw-600">North-East Region</a></li>
                                <li><a href="#" className="text-purple fw-600">West Region</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">USA</span>
                              <ul className="scrollbar" id="style-3">
                                <li><a href="#" className="text-purple fw-600">Alabama</a></li>
                                <li><a href="#" className="text-purple fw-600">Alaska</a></li>
                                <li><a href="#" className="text-purple fw-600">Arizona</a></li>
                                <li><a href="#" className="text-purple fw-600">Arkansas</a></li>
                                <li><a href="#" className="text-purple fw-600">California</a></li>
                                <li><a href="#" className="text-purple fw-600">Colorado</a></li>
                                <li><a href="#" className="text-purple fw-600">Connecticut</a></li>
                                <li><a href="#" className="text-purple fw-600">Delaware</a></li>
                                <li><a href="#" className="text-purple fw-600">Florida</a></li>
                                <li><a href="#" className="text-purple fw-600">Georgia</a></li>
                                <li><a href="#" className="text-purple fw-600">Hawaii</a></li>
                                <li><a href="#" className="text-purple fw-600">Idaho</a></li>
                                <li><a href="#" className="text-purple fw-600">Illinois</a></li>
                                <li><a href="#" className="text-purple fw-600">Indiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Iowa</a></li>
                                <li><a href="#" className="text-purple fw-600">Kansas</a></li>
                                <li><a href="#" className="text-purple fw-600">Kentucky</a></li>
                                <li><a href="#" className="text-purple fw-600">Louisiana</a></li>
                                <li><a href="#" className="text-purple fw-600">Maine</a></li>
                                <li><a href="#" className="text-purple fw-600">Maryland</a></li>
                                <li><a href="#" className="text-purple fw-600">Massachusetts</a></li>
                                <li><a href="#" className="text-purple fw-600">Michigan</a></li>
                                <li><a href="#" className="text-purple fw-600">Minnesota</a></li>
                                <li><a href="#" className="text-purple fw-600">Mississippi</a></li>
                                <li><a href="#" className="text-purple fw-600">Missouri</a></li>
                                <li><a href="#" className="text-purple fw-600">Montana</a></li>
                                <li><a href="#" className="text-purple fw-600">Nebraska</a></li>
                                <li><a href="#" className="text-purple fw-600">Nevada</a></li>
                                <li><a href="#" className="text-purple fw-600">New Hampshire</a></li>
                                <li><a href="#" className="text-purple fw-600">New Jersey</a></li>
                                <li><a href="#" className="text-purple fw-600">New Mexico</a></li>
                                <li><a href="#" className="text-purple fw-600">New York</a></li>
                                <li><a href="#" className="text-purple fw-600">North Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">North Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Ohio</a></li>
                                <li><a href="#" className="text-purple fw-600">Oklahoma</a></li>
                                <li><a href="#" className="text-purple fw-600">Oregon</a></li>
                                <li><a href="#" className="text-purple fw-600">Pennsylvania</a></li>
                                <li><a href="#" className="text-purple fw-600">Rhode Island</a></li>
                                <li><a href="#" className="text-purple fw-600">South Carolina</a></li>
                                <li><a href="#" className="text-purple fw-600">South Dakota</a></li>
                                <li><a href="#" className="text-purple fw-600">Tennessee</a></li>
                                <li><a href="#" className="text-purple fw-600">Texas</a></li>
                                <li><a href="#" className="text-purple fw-600">Utah</a></li>
                                <li><a href="#" className="text-purple fw-600">Vermont</a></li>
                                <li><a href="#" className="text-purple fw-600">Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Washington</a></li>
                                <li><a href="#" className="text-purple fw-600">West Virginia</a></li>
                                <li><a href="#" className="text-purple fw-600">Wisconsin</a></li>
                                <li><a href="#" className="text-purple fw-600">Wyoming</a></li>
                              </ul>
                            </li>
                            <li className="headings">
                              <span className="text-purple">UK</span>
                              <ul>
                                <li><a href="#" className="text-purple fw-600">London</a></li>
                                <li><a href="#" className="text-purple fw-600">North East</a></li>
                                <li><a href="#" className="text-purple fw-600">North West</a></li>
                                <li><a href="#" className="text-purple fw-600">Yorkshire</a></li>
                                <li><a href="#" className="text-purple fw-600">East Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">West Midlands</a></li>
                                <li><a href="#" className="text-purple fw-600">South East</a></li>
                                <li><a href="#" className="text-purple fw-600">East of England </a></li>
                                <li><a href="#" className="text-purple fw-600">South West</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li> */}
              <li>
                <a href="#">Private School</a>
                <div className="dropdown-menus right-0">
                  <ul className="sub-menu">
                    <li className="headings"><Link to="/privat_school_about_us" className="text-purple fw-600"> About Us</Link></li>
                    <li className="headings"><span className="text-purple">Elementary School (Grades 1-8)</span></li>
                    <li className="headings"><span className="text-purple">High school (Grades 9-12)</span></li>
                  </ul>
                </div>
              </li>
            </ul>
            {/*this one for mobile*/}
            <div className="menu-wrappers  d-none">
              <ul className="rwd-menu">
                <li><a className="main-cat-menu">Menu</a></li>
                <li>
                  <a href="#">Academic</a>
                  <ul className="rwd-submenu">
                    <li>
                      <a href="#" className="back-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                        </svg>
                        Back
                      </a>
                    </li>
                    <li className>
                      <a href>Subjects</a>
                    </li>
                    <ul className="rwd-submenu">
                      <li>
                        <a href="#" className="back-btn">
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                          </svg>
                          Back
                        </a>
                      </li>
                      <li><a href="#">Math</a></li>
                      <li><a href="#">English</a></li>
                      <li><a href="#">Science</a></li>
                      <li><a href="#">Nature &amp; Space</a></li>
                      <li><a href="#">Social Sciences</a></li>
                      <li><a href="#">Business / Economics</a></li>
                      <li><a href="#">Accounting/ Finance</a></li>
                      <li><a href="#">Engineering</a></li>
                      <li><a href="#">Medicine/Nursing</a></li>
                      <li><a href="#">Law</a></li>
                      <li><a href="#">Exam Preparation</a></li>
                      <li><a href="#">Homework Help</a></li>
                      <li><a href="#">Montessori
                      </a></li><li><a href="#">Pre-K &amp; Kindergarten</a></li>
                      <li><a href="#">Elementary School</a></li>
                      <li><a href="#">Middle School</a></li>
                      <li><a href="#">High Sch</a></li>
                      <li><a href="#">University</a></li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a href="#">Languages</a>
                  <ul className="rwd-submenu">
                    <li>
                      <a href="#" className="back-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                        </svg>
                        Back
                      </a>
                    </li>
                    <li><a href="#">Juranger</a></li>
                    <li><a href="#">Juranger</a></li>
                    <li><a href="#">Juranger</a></li>
                    <li><a href="#">Juranger</a></li>
                    <li><a href="#">Juranger</a></li>
                  </ul>
                </li>
                <li><a href="#">Coding &amp; Tech</a></li>
                <li><a href="#">Special Ed</a></li>
                <li><a href="#">Arts</a></li>
                <li><a href="#">Music</a></li>
                <li><a href="#">Health and Wellness</a></li>
                <li><a href="#">Life Skills</a></li>
                <li><a href="#">Professional Training</a></li>
                <li><a href="#">In-Person Classes</a></li>
                <li>
                  <a href="#">Private School</a>
                  <ul className="rwd-submenu">
                    <li>
                      <a href="#" className="back-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                        </svg>
                        Back
                      </a>
                    </li>
                    <li><a href="#">Curriculum</a></li>
                    <li><a href="#">Montessori</a></li>
                    <li><a href="#">Pre-K &amp; Kindergarten</a></li>
                    <li><a href="#">Elementary School</a></li>
                    <li><a href="#">Middle School</a></li>
                    <li><a href="#">High School</a></li>
                    <li><a href="#">Test Preparation</a></li>
                    <li><a href="#">Homework Help</a></li>
                    <li><a href="#">Clubs</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
