import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { purchase_coupon_detail } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'

export default function OMyPurchaseDetail() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  const navigate = useNavigate();
  const location = useLocation();

  var step = location?.state;

  var [data, setData] = useState([]);
  console.log('data :', data);


  const purchase_details = () => {
    let send_data = {}

    if (step == 1) {
      send_data.type = "class"
    } else if (step == 2) {
      send_data.type = "club"
    } else if (step == 3) {
      send_data.type = "quiz"
    } else {
      send_data.type = "competition"
    }
    purchase_coupon_detail(send_data).then((resposnse) => {
      console.log("purchase_coupon_detail", resposnse)

      if (resposnse?.code == 1) {
        setData(resposnse?.data)
      } else {
        console.log('data not found')
      }
    });
  }
  useEffect(() => {
    purchase_details();
  }, [])


  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)}  className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-12">
                      {/*start*/}
                      <div className="mb-5">
                        <h2 className="poppins fw-bold">Coupon Purchase Details</h2>
                      </div>
                      {/*end*/}
                      {/*start*/}
                      <div className="table-responsive-xl">
                        <table className="table table-bordered table_custom_bg">
                          <thead>
                            <tr>
                              <th scope="col">Code</th>
                              <th scope="col">Description</th>
                              <th scope="col">Discount</th>
                              <th scope="col">Applies to</th>
                              <th scope="col">Purchased</th>
                              <th scope="col" className="text-center">Start Date</th>
                              <th scope="col" className="text-center">Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((coupon_data,index) => {
                              return (
                                <tr>
                                  <td>ACADESQH1R5</td>
                                  <td>{coupon_data.discription}</td>
                                  <td>${coupon_data.amount} USD</td>
                                  <td><span className="bg-pink d-inline-block px-2 rounded-3">Any Classes</span></td>
                                  <td><span className="bg-yellow rounded-3 px-2 py-1 d-inline-block">{coupon_data.Purchased}</span></td>
                                  <td className="text-center fw-600">21-07-2021</td>
                                  <td className="text-center fw-600">21-07-2021</td>
                                </tr>
                              )
                            })}

                          </tbody>
                        </table>
                      </div>
                      {/*end*/}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
