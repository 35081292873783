import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { attendance_details, classroom_delete_lesson, classroom_list, notification_listing } from '../../../api/apiHandler';
import { htmlToText } from 'html-to-text';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

const blinkAnimation = `
  @keyframes blink {
    0% { visibility: visible; }
    50% { visibility: hidden; }
    100% { visibility: visible; }
  }
`;

const blinkingTextStyle = {
  color: '#570861',
  textDecoration: 'underline',
  animation: 'blink 1s steps(2, start) infinite',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  textAlign: 'center'
};

const TruncateText = ({ text, length }) => {
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  // Convert HTML to plain text
  const plainText = htmlToText(text || '', {
    wordwrap: false,
    ignoreImage: true,
    ignoreHref: true,
  });

  const truncatedText = truncateString(plainText, length);

  return (
    <p
      dangerouslySetInnerHTML={{ __html: truncatedText }}
      className="fs-6"
      style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
    />
  );
};


export default function Recording({id}) {

  const location = useLocation();

  // var id = location?.state;
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');

    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

    id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  } else if (location?.state) {
    var id = location?.state;
    console.log('Assessmentid :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    var type = location?.search?.split("=")[1];
    console.log('type :', type);
    var id = after
    id.class = type
    console.log('idtype :', id);
  }

  const [currentDate, setCurrentDate] = useState(dayjs());
  const [notificationData, setNotificationData] = useState('');
  const [dataLesson, setDataLesson] = useState([]);
  const [dataLearner, setDataLearner] = useState([]);
  console.log('dataLearner :', dataLearner);
  // const [currentDate, setCurrentDate] = useState(dayjs());
  const [nextMonth, setNextMonth] = useState(currentDate.add(1, 'month'));
  const [previousMonth, setPreviousMonth] = useState(currentDate.subtract(1, 'month'));
  const [selectedMonth, setSelectedMonth] = useState(currentDate);
  const [UpdatedData, setUpdatedData] = useState(currentDate.format("MMM YYYY"));
  
  const NextMonthYear = (e) => {
    e.preventDefault();

    setCurrentDate((prevCurrentDate) => prevCurrentDate.add(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.add(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.add(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.add(1, 'month'));
  };

  const PreviousMonthYear = (e) => {
    e.preventDefault();
    setCurrentDate((prevCurrentDate) => prevCurrentDate.subtract(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.subtract(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.subtract(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.subtract(1, 'month'));
  }

  const PMonth = (e) => {
    setUpdatedData(e)
    console.log("XYZ", e)
  }

  const Classroom_listing = async () => {
    try {

      let sendData = {
        class_id: id?.id,
        activity_type: id?.class,
        month_wise: UpdatedData.toLowerCase(),

      }
      console.log('sendData :', sendData);
      const response = await classroom_list(sendData);
      console.log('sendDataresponse :', response);

      if (response?.code == 1) {
        setDataLesson(response?.data);
      } else {
        setDataLesson([]);
        console.log('data not found');
      }
    } catch (error) {
      console.error("Error fetching classroom list:", error);
    }
  };

  useEffect(() => {
    Classroom_listing();
  }, [UpdatedData]);

  const delete_class = (index, id) => {

    // alert(id)

    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        classroom_delete_lesson({ classroom_id: id }).then((response) => {
          if (response?.code == 1) {
            setDataLesson(dataLesson.filter(o => o.id !== id));
            TOAST_SUCCESS(response?.message);

          } else {
            TOAST_ERROR(response?.message);
          }
        })
      }
    })

  }

  const teacherClassListing = () => {
    let sendData = {
      activity_id: id?.id,
      activity_type: id?.class
    }
    console.log('teacherClassListing :', sendData);
    attendance_details(sendData).then((response) => {

      if (response?.code == 1) {
        setDataLearner(response?.data?.[0])
      } else {
        setDataLearner([])
      }
    });
  }

  useEffect(() => {
    teacherClassListing();
  }, []);

  const NotificationList = async () => {
    try {
      let send_data = {
        action_type: id.class,
        teach_type: "quiz"
      }
      console.log('send_data :', send_data);

      const response = await notification_listing(send_data);
      console.log('response123 :', response);
      if (response?.code == 1) {
        setNotificationData(response.data)
      } else {
        setNotificationData([])
      }

    } catch (error) {
      console.log('error :', error);

    }
  }
  useEffect(() => {
    NotificationList();
  }, [])

  return (
    <>
      <div className="row mt-2">
        <style>
          {blinkAnimation}
        </style>
        <p style={blinkingTextStyle}>Details will appear here after the meeting is created.</p>
        {/* <div className="col-xl-9 mb-xl-0 mb-4">
          <div className="review_dack">
            <div className="card review_box mb-4">
              <div className="review_top">
                <div className="review_text row mb-4">
                  <div className="col-lg-8">
                    <ul>
                      <li className="mb-3">
                        <a href="#!" className="text-decoration-underline text-purple fw-600 ">Mon, Mar 20, 7:29 – 8:26 PM Eastern</a>
                      </li>
                      <li className="mb-3">
                        <a href="#!" className="fw-600 ">Teacher Joined : <span>Celina Mark</span></a>
                      </li>
                      <li className="mb-3">
                        <a href="#!" className="fw-600 ">Learner(s) Joined : <span>Julie Mark</span></a>
                      </li>
                      <li className="mb-3">
                        <a href="#!" className="fw-600 ">Learner(s) Absent : <span>Julie Mark</span></a>
                      </li>
                    </ul>
                    <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                  </div>
                </div>
                <div className="button safety_btn d-sm-flex align-items-center">
                  <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Watch Recording</a>
                  <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3" data-bs-toggle="modal" data-bs-target="#safety_tips_only_one">
                    <span className="me-3">
                      <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z" fill="#570861" />
                        <path d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z" fill="#570861" />
                      </svg>
                    </span>
                    Safety Tips
                  </a>
                </div>
              </div>
            </div>
            <div className="card review_box mb-4">
              <div className="review_top">
                <div className="review_text row mb-4">
                  <div className="col-lg-8">
                    <ul>
                      <li className="mb-3">
                        <a href="#!" className="text-decoration-underline text-purple fw-600 ">Mon, Mar 20, 7:29 – 8:26 PM Eastern</a>
                      </li>
                      <li className="mb-3">
                        <a href="#!" className="fw-600 ">Teacher Joined : <span>Celina Mark</span></a>
                      </li>
                      <li className="mb-3">
                        <a href="#!" className="fw-600 ">Learner(s) Joined : <span>Julie Mark</span></a>
                      </li>
                    </ul>
                    <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                  </div>
                </div>
                <div className="button">
                  <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Watch Recording</a>
                  <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3" data-bs-toggle="modal" data-bs-target="#safety_tips_only_one">
                    <span className="me-3">
                      <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z" fill="#570861" />
                        <path d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z" fill="#570861" />
                      </svg>
                    </span>
                    Safety Tips
                  </a>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
            <ul className="pagination justify-content-evenly align-items-center border-radius-10">
              <li className="page-item">
                <a className="page-link text-dark" href="#" aria-label="Previous">
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
              <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
              <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
              <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li>
              <li className="page-item">
                <a className="page-link border-start-0 " href="#" aria-label="Next">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-xl-3">
          <div className="mb-4">
            <div className="attend_recor_bx d-flex mb-4 align-items-center justify-content-between">
              <span className="fs-5 atten_tit_recor fw-600 d-block text-dif-black">Attendance</span>
              <div className="d-flex justify-content-end">
                <select className="border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">
                  <option selected>Learner’s Name</option>
                  <option>Relevance</option>
                  <option>New Classes</option>
                  <option>Starting soon</option>
                </select>
              </div>
            </div>
            <div className="wrappers">
              <div className="container-calendar">
                <div className="cal_header d-flex justify-content-between">
                  <h3 className="poppins fw_500" id="monthAndYear" />
                  <div className="button-container-calendar">
                    <button id="previous" onclick="previous()">‹</button>
                    <button className=" ms-3" id="next" onclick="next()">›</button>
                  </div>
                </div>
                <table className="table-calendar" id="calendar" data-lang="en">
                  <thead id="thead-month" />
                  <tbody id="calendar-body" />
                </table>
                <div className="footer-container-calendar d-none">
                  <label htmlFor="month">Jump To: </label>
                  <select id="month" onchange="jump()">
                    <option value={0}>Jan</option>
                    <option value={1}>Feb</option>
                    <option value={2}>Mar</option>
                    <option value={3}>Apr</option>
                    <option value={4}>May</option>
                    <option value={5}>Jun</option>
                    <option value={6}>Jul</option>
                    <option value={7}>Aug</option>
                    <option value={8}>Sep</option>
                    <option value={9}>Oct</option>
                    <option value={10}>Nov</option>
                    <option value={11}>Dec</option>
                  </select>
                  <select id="year" onchange="jump()" />
                </div>
              </div>
            </div>
            <div className="attendence_count g-3 row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap d-flex align-items-center">
                  <canvas id="myChart" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total Hours attended for Month</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap d-flex align-items-center">
                  <canvas id="myChart1" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total Hours attended To Date</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                  <canvas id="myChart2" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total  Days Absent for Month</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                  <canvas id="myChart3" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total  Days Absent To Date</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                  <canvas id="myChart4" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total  Days joined for Month</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                  <canvas id="myChart5" width={33} height={33} />
                  <div className="ms-3">
                    <span>150</span>
                    <p>Total Days joined To Date</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className>
            <div className="accordion border-radius-10 " id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header poppins" id="headingOne">
                  <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="list_notification">
                      {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                        <div key={index}>
                          {dayjs(dateItem.date).isSame(dayjs(), 'day') && (
                            <span className="mb-4 d-block">Today</span>
                          )}
                          {dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                            <span className="mb-4 d-block">Yesterday</span>
                          )}
                          {!dayjs(dateItem.date).isSame(dayjs(), 'day') &&
                            !dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                              <span className="mb-4 d-block">{dateItem.date}</span>
                            )}

                          <ul  >
                            {dateItem.notification_data.map((notification, index) => (
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                    <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">{notification.message}</p>
                                </div>
                                <span className="noti_timer">
                                  {dayjs(notification.insert_datetime).format('hh:mm A')}
                                </span>
                              </li>
                            ))}
                         
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {id?.type_class == "Offline Course" ?
          <>
            <div className="row mt-4">
              <div className="col-xl-9 mb-xl-0 mb-4">
                <div className="review_dack">
                  {dataLesson?.length > 0 ?
                    dataLesson?.map((classroom_data, index) => {
                      // console.log('classroom_data :', classroom_data);
                      return (
                        <div key={index} className="card review_box mb-4">
                          <div className="review_top">
                            <div className="list_btw_dots mb-4">
                              <ul className="d-sm-flex align-items-center">
                                <span className="pe-4 fw-600 position-relative">Lesson <span className="green_dots position-absolute" /></span>
                                <li>
                                  <span className="text-purple fw-500">Duration Of Lesson : {classroom_data?.duration}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                              <div className="d-flex align-items-center">
                                <div className="fw-600">
                                  <span className="d-block">{classroom_data?.lession_topic}</span>
                                </div>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>{classroom_data?.full_name}</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <TruncateText text={classroom_data?.description} length={25} className="fs-6" />
                              </div>
                            </div>
                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                              <div className="button d-flex align-items-center justify-content-between">
                                <Link to="/view-details-classroom" state={{ id: classroom_data?.id, type: "class" }} className="btn-theme px-3 d-inline-block text-center">View Details</Link>
                                <ul className="edit_delete d-flex ms-4 align-items-center justify-content-end">
                                  <li>
                                    <a onClick={() => (delete_class(index, classroom_data.id))} className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/edit-lesson" state={classroom_data?.id} className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="list_btw_dots mb-sm-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {classroom_data?.Posted_on}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }) :
                    <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                  }

                </div>
                <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                  <ul className="pagination justify-content-evenly align-items-center border-radius-10">

                    <li className="page-item">
                      <button className="page-link text-dark border-start-0" style={{ "border": "0 !important" }} aria-label="Previous" onClick={(e) => PreviousMonthYear(e)}>
                        <span className>
                          <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                        </span>
                      </button>
                    </li>
                    <li className={`page-item ${UpdatedData == previousMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(previousMonth.format("MMM YYYY"))}>{previousMonth.format("MMM YYYY")}</a></li>
                    <li className={`page-item ${UpdatedData == selectedMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(selectedMonth.format("MMM YYYY"))}>{selectedMonth.format("MMM YYYY")}</a></li>
                    <li className={`page-item ${UpdatedData == nextMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(nextMonth.format("MMM YYYY"))}>{nextMonth.format("MMM YYYY")}</a></li>
                    <li className="page-item">
                      <button className="page-link border-start-0 " style={{ "border": "0 !important" }} aria-label="Next" onClick={(e) => NextMonthYear(e)}>
                        <span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                          </span>
                        </span>
                      </button>
                    </li>

                  </ul>
                </nav>
              </div>
              <div className="col-xl-3">
                <div className="mb-4">
                  <span className="fs-5 fw-600 mb-4 d-block text-dif-black">Attendance</span>
                  {/* <div className="wrappers">
                    <Calendar
                      value={selectedDay}
                      onChange={(day) => {
                        handleCalendarSelect(day);
                      }}
                      shouldHighlightWeekends
                      customDaysClassName={scheduledDateList}
                    />
                  </div> */}
                  {/*start*/}
                  <div className="attendence_count g-3 row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div className="attendence_box flex-wrap d-flex align-items-center">
                        <canvas id="myChart" width={33} height={33} />
                        <div className="ms-3">
                          <span>{dataLearner?.learners}</span>
                          <p>Subscribed Learners</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap d-flex align-items-center">
                              <canvas id="myChart1" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total Hours attended To Date</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                              <canvas id="myChart2" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days Absent for Month</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                              <canvas id="myChart3" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days Absent To Date</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                              <canvas id="myChart4" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days joined for Month</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                              <canvas id="myChart5" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total Days joined To Date</p>
                              </div>
                            </div> 
                          </div> */}
                  </div>
                  {/*end*/}
                </div>
                <div className>
                  <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="list_notification">
                            <ul className="position-static">
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className="noti_img bg-pink position-relative">
                                    <span className="text-uppercase text-danger">A</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">Assignments Due</p>
                                </div>
                                <span className="noti_timer">10.12 PM</span>
                              </li>
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                    <span className="text-uppercase text-yellow">q</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">New assignments.</p>
                                </div>
                                <span className="noti_timer">10.12 PM</span>
                              </li>
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className="noti_img bg-sky-blue-op-1 position-relative">
                                    <span className="text-uppercase text-blue">e</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">Any upcoming events</p>
                                </div>
                                <span className="noti_timer">10.12 PM</span>
                              </li>
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className="noti_img bg-sky-blue-op-1 position-relative">
                                    <span className="text-uppercase text-blue">e</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">Any upcoming events</p>
                                </div>
                                <span className="noti_timer">10.12 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          : <></>}
      </div>
    </>
  )
}
