import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { main_category_list, subject_category_list, teacher_club_detail, teacher_edit_club_detail, teacher_select_class_list } from '../../../../api/apiHandler';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
    content: {
        // backgroundColor: 'rgb(0 0 0 / 56%)',
        background: 'none',
        border: '0px',
    },
}

var model_css = {
    content: {
        display: 'flex',
        border: '1px solid rgb(224, 224, 224)',
        borderRadius: '4px',
        position: 'absolute',
        right: '5%',
        flexDirection: 'column',
        padding: '1em',
        width: '215px',
    }
};

export default function EditClub() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const location = useLocation();

    const { class_edit_id } = useParams();

    // var id = location?.state;
    let id = null;
    if (location?.state) {
        id = location?.state
    } else {
        id = class_edit_id
    }

    const navigate = useNavigate();

    const schema = yup.object().shape({
        club_title: yup.string().required("Club title is required"),
        club_summary: yup.string().required("Club summary is required"),
        learner_summary: yup.string().required("Learner summary is required"),

        profileVideo: yup
            .mixed()
            .required("Club video is required"),

        // subject_category: yup.string().required("Subject category is required"),
        // subject_sub_category: yup.string().required("Subject Subcategory is required"),
        class_description: yup.string()
            .required("Club description is required")
            .min(200, 'Must be at least 200 characters')
            .max(500, 'Cannot exceed 500 characters'),
        // p_guide: yup.string().required("guide description is required"),

    });


    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [selectedRadio, setSelectedRadio] = useState("One-Time");
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.nextSibling.textContent)
    };

    const [profileImageState, setProfileImageState] = useState('')

    const handleImageChange = async (image) => {
        setValue("profileImage", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("profileImage");
    }

    const [profileVideoState, setProfileVideoState] = useState(null)
    const handleVideoChange = async (Video) => {
        setValue("profileVideo", Video);
        const bufferVideo = await convertToBase64(Video);

        setProfileVideoState(bufferVideo);
        clearErrors("profileVideo");
    }
    const [startageRanges, setstartAgeRanges] = useState(3)
    const [ageRanges, setAgeRanges] = useState(6)
    const [startLearner, setStartLearner] = useState(1)
    const [endLearner, setEndLearner] = useState(6)

    const startageRange = (data) => {

        if (data < 16) {
            setAgeRanges(parseInt(data) + 3);

            setstartAgeRanges(data);

        } else if (data == 'adults') {
            setAgeRanges('adults');

            setstartAgeRanges(data);
        } else {
            setAgeRanges(18);
            setstartAgeRanges(data);

        }
        setStartLearner(1)
        setstartAgeRanges(data);

    }

    const [classSize, setClassSize] = useState('group');
    const handleClassChange = (data) => {
        setClassSize(data)
    }

    var [classHoursData, setClassHoursData] = useState('0')
    var [classMinutesData, setClassMinutesData] = useState('55')
    var [classSessionData, setClassSessionData] = useState('1')
    var [classWeekData, setClassWeekData] = useState('1')
    var [totalHours, setTotalHours] = useState(0);

    useEffect(() => {
        var total = (((parseInt(classHoursData) + parseInt(classMinutesData) / 60) * classSessionData * classWeekData).toFixed(1));
        setTotalHours(total);
    }, [classHoursData, classMinutesData, classSessionData, classWeekData]);


    const classHours = (data) => {
        setClassHoursData(data)
    }

    const classsMinutesData = (data) => {
        setClassMinutesData(data)
    }

    const classSessionDetails = (selectedValue) => {
        setClassSessionData(selectedValue)
    };
    const classWeek = (data) => {
        setClassWeekData(data)
    }


    const ageRange = (data) => {
        if (data <= 6) {

            setEndLearner(6)
        }
        if (data > 6 && data < 12) {
            setEndLearner(9)
        }
        if (data >= 12) {
            setEndLearner(12)
        }

        setAgeRanges(data);
    }

    const StartLearner = (data) => {
        setStartLearner(data);
    }

    const EndLearner = (data) => {
        setEndLearner(data);
    }

    const [price, setPrice] = useState('');
    const [Estimatedprice, setEstimatedprice] = useState(0);
    const [endEstimatedprice, setendEstimatedprice] = useState(0);
    const [pricingLevel, setPricingLevel] = useState('Very low');

    const handlePriceChange = (e) => {

        const inputPrice = e.target.value;
        setPrice(inputPrice);
        var start_est_ear = parseInt((startLearner * inputPrice) * 0.6);
        var end_est_ear = parseInt((endLearner * inputPrice) * 0.6);
        setEstimatedprice(start_est_ear)
        setendEstimatedprice(end_est_ear)
        if (inputPrice !== "" && !isNaN(inputPrice)) {
            const newPrice = parseFloat(inputPrice);
            if (newPrice > 50) {
                setPricingLevel('High');
            } else if (newPrice >= 20) {
                setPricingLevel('Medium');
            } else {
                setPricingLevel('Very low');
            }
        } else {
            setPricingLevel('Very low');
        }
    };

    const progressBarWidth = `${Math.max(0, Math.min((price - 20) / 50 * 100, 100))}%`;


    var [data, setData] = useState(null);
    var [classTitleLength, setclassTitleLength] = useState(0);
    var [classSummaryLength, setclassSummaryLength] = useState(0);
    var [printEstimation, setPrintEstimation] = useState("")
    const SetPriceData = () => {

        if (ageRanges <= 6) {
            if (classSize == 'private') {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $25 to $55')
                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $40')
                        }
                    }
                }
            } else {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

                        }
                    }
                }
            }
        } else if (ageRanges <= 12) {

            if (classSize == 'private') {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $70')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $20 to $50')

                        }
                    }
                }
            } else {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

                        }
                    }
                }
            }
        } else if (ageRanges <= 18) {

            if (classSize == 'private') {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $50 to $80')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $70')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')

                        }
                    }
                }
            } else {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

                        }
                    }
                }
            }
        } else {

            if (classSize == 'private') {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $50 to $100')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $90')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $70')

                        }
                    }
                }
            } else {
                if (classHoursData >= 1) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')
                } else {
                    if (classMinutesData >= 45) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $20 to $50')

                    } else {
                        if (classMinutesData >= 30) {
                            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $40')

                        }
                    }
                }
            }
        }
    }


    useEffect(() => {
        if (classHoursData !== null && classMinutesData !== null) {
            SetPriceData();
        }
    }, [startageRanges, classSize]);


    const [subjectCategory, setSubjectCategory] = useState('');
    const [subjectCategoryId, setSubjectCategoryId] = useState('');
    var [MainCatdata, setMainCatdata] = useState([]);
    var [SelectedSubCategory, SetSelectedSubCategory] = useState('');
    var [SelectedSubCat, setSelectedSubCat] = useState([]);
    var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

    const handleSubjectCategoryChange = (e) => {
        setSubjectCategory((e.target.value)?.split(",")[1]);
        setSubjectCategoryId((e.target.value)?.split(",")[0])
    };

    const handleSubjectSubCategoryChange = (e) => {
        SetSelectedSubCategory(e.target.value)
    };

    const MainCategory = () => {
        main_category_list().then((response) => {

            if (response?.code == 1) {

                setMainCatdata(response?.data)

                var category_id = response?.data.find(item => item.category_name === subjectCategory);
                setSubjectCategoryId(category_id.category_id)

            } else {
                console.log('data not found')
                setMainCatdata([])

            }
        });
    }


    useEffect(() => {
        if (subjectCategory) {
            MainCategory();
        }
    }, [subjectCategory]);

    const SubCategoryList = () => {
        subject_category_list({ category_id: subjectCategoryId }).then((response) => {

            if (response?.code == 1) {
                setSelectedSubCat(response?.data)
            } else {
                console.log('data not found')
            }
        });
    }

    useEffect(() => {
        if (subjectCategoryId) {
            SubCategoryList()
        }
    }, [subjectCategoryId]);

    var [selectClass, setselectClass] = useState([]);
    var [preClassId, setPreClassId] = useState([]);
    var [nextClassId, setNextPreClassId] = useState([]);

    const selectClassData = () => {

        teacher_select_class_list().then((response) => {

            if (response?.code == 1) {

                setselectClass(response?.data)

            } else {
                console.log('data not found')
            }
        });
    }

      useEffect(() => {
        selectClassData();
      }, []);


    useEffect(() => {

        teacher_club_detail({ "club_id": id }).then((response) => {
            console.log("response", response)

            if (response?.code == 1) {
                const inputPrice = response.data[0].price;
                setPrice(inputPrice);
                var start_est_ear = parseInt((startLearner * inputPrice) * 0.6);
                var end_est_ear = parseInt((endLearner * inputPrice) * 0.6);
                setEstimatedprice(start_est_ear)
                setendEstimatedprice(end_est_ear)
                if (inputPrice !== "" && !isNaN(inputPrice)) {
                    const newPrice = parseFloat(inputPrice);
                    if (newPrice > 50) {
                        setPricingLevel('High');
                    } else if (newPrice >= 20) {
                        setPricingLevel('Medium');
                    } else {
                        setPricingLevel('Very low');
                    }
                } else {
                    setPricingLevel('Very low');
                }
                setProfileImageState(response?.data?.[0]?.image != "" ? response?.data?.[0]?.image_file : null);
                setValue("profileImage", response?.data?.[0]?.image);
                setProfileVideoState(response?.data?.[0]?.video_file);
                setValue("profileVideo", response?.data?.[0]?.video);
                setstartAgeRanges(response.data[0]?.min_age)
                setAgeRanges(response.data[0]?.max_age)
                setStartLearner(response.data[0]?.min_learner)
                setEndLearner(response.data[0]?.max_learner)
                setSubjectCategory(response.data[0]?.main_category)
                SetSelectedSubCategory(response?.data[0]?.subject_category)
                setPreClassId(response?.data[0]?.pre_requisites)
                setNextPreClassId(response?.data[0]?.next_class)

                if (response?.data[0]?.session_per_week != "") {

                    setClassSessionData(response?.data[0]?.session_per_week)
                }
                if (response.data[0]?.number_of_week != "") {

                    setClassWeekData(response.data[0]?.number_of_week)
                }
                setclassTitleLength(response.data[0]?.title.length)
                setClassHoursData(response.data[0]?.duration.split(' ')[0])
                setClassMinutesData(response.data[0]?.duration.split(' ')[2])
                setData(response.data[0])
            } else {
                console.log('data not found')
            }
        });
    }, []);

    let classTitleData = (e) => {

        setclassTitleLength(0)
    }
    let classSummaryData = (e) => {
        setclassSummaryLength(0)
    }


    let onSubmit = async (data) => {

        let image = data?.profileImage


        if (image instanceof Blob) image = await uploadImageOnAWS(image, "class_image");

        let video = data?.profileVideo
        if (video instanceof Blob) video = await uploadImageOnAWS(video, "class_image");

        data.start_age = startageRanges
        data.end_age = ageRanges
        let submitData = {
            club_id: id,
            length: selectedRadio,
            title: data.club_title,
            description: data.class_description,
            summary: data.club_summary,
            learner_summary: data.learner_summary,
            parental_guide: data.p_guide,
            main_category: subjectCategory,
            subject_category: SelectedSubCategory,
            min_age: data.start_age,
            max_age: data.end_age,
            language: data.lang,
            duration: data.class_hours + " Hour" + (data.class_hours != 1 ? "s " : " ") + data.class_min + " Minutes",
            size: classSize,
            min_learner: data.start_learner_age,
            max_learner: endLearner,
            pre_requisites: data.pre_req,
            next_class: data.next_class,
            homeworks: data.homework,
            learning_goals: data.learning_goals,
            assessment: data.assesment,
            sources: data.sources,
            supply_list: data.supply_list,
            price: price,
            video: video,
        }
        if (selectedRadio != "offline course" && selectedRadio != "One-Time") {
            submitData.session_per_week = (classSessionData != undefined ? classSessionData : '')
            submitData.number_of_week = (classWeekData != undefined ? classWeekData : '')
        }
        if (classSize == "group") {
            submitData.min_learner = data.start_learner_age
            submitData.max_learner = data.end_learner_age
        }
        if (data.profileImage != null) {
            submitData.image = image
        }

        teacher_edit_club_detail(submitData).then((response) => {
            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                navigate('/organization-history');
                reset();
            } else {
                TOAST_ERROR(response.message);
            }
        });

    }

    if (data == null) return <></>

    return (
        <>
            <JqueryLoad />

            <OHeader_Dashboard />

            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <h3 className="text-purple mb-3">Setup Your Clubs</h3>
                                {/* <div className='mb-4'>
                                    <h3 className="mb-2">Select the Club Type</h3>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio6" type="radio" defaultChecked={data.type == "Online" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio6" className="d-block text-center">Online</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio7" type="radio" defaultChecked={data.type == "In-Person" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio7" className="d-block text-center">In-Person</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio8" type="radio" defaultChecked={data.type == "Offline Course" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio8" className="d-block text-center">Offline Course</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <h3 className="mb-2 text-capitalize">Select the Club Length</h3>
                                <p className="fs-6 mb-4 text-capitalize">You cannot change this setting once the course is listed.</p>
                                <div className="row">
                                    <div className="col-xl-7">
                                        <form>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio1" type="radio" defaultChecked={data.length == "One-Time" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio1" className="d-block text-center">One-Time</label>
                                                    </div>
                                                    <p className="fs-6">These clubs meet LIVE over video a single time. Discussions may occur by messaging on the classroom page before or after class. Learners pay one time.</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio2" type="radio" defaultChecked={data.length == "Multi-Day" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio2" className="d-block text-center">Multi-Day</label>
                                                    </div>
                                                    <p className="fs-6">These clubs  meet LIVE over video over multiple days. Discussions occur by messaging on the classroom page between meetings. It is sold as a package. Learners pay one time.</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio3" type="radio" defaultChecked={data.length == "Ongoing Weekly" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio3" className="d-block text-center">Ongoing Weekly</label>
                                                    </div>
                                                    <p className="fs-6">Learners subscribe to these clubs, which meet LIVE over video on a weekly schedule. Learners can join or leave at any time. Learners pay weekly.</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio4" type="radio" defaultChecked={data.length == "Ongoing Monthly" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio4" className="d-block text-center">Ongoing Monthly</label>
                                                    </div>
                                                    <p className="fs-6">Learners subscribe to these clubs, which meet LIVE over video on a weekly schedule. Learners can join at any time and leave at the end of the month. Learners pay monthly.</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio5" type="radio" defaultChecked={data.length == "offline course" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio5" className="d-block text-center">offline course</label>
                                                    </div>
                                                    <p className="fs-6">Learners subscribe to these clubs, will not meet LIVE over video. Learners will get access to material. Learners pay one time.</p>
                                                </div>
                                            </div>
                                        </form>
                                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                            <div className="form-group mb-4">
                                                <h3 className="mb-1 text-capitalize">Club Title</h3>
                                                <p className="fs-6 mb-1 text-capitalize">Choose A Catchy And Descriptive Title. </p>
                                                <p className="fs-6 mb-2 text-capitalize">E.G., "Reading Club for Young Teens", No More Than 1 Exclamation Mark, And No Emojis Or Coupon Codes.</p>
                                                <div className="position-relative club_title">
                                                    <textarea type="text" className="form-control" maxLength={50} {...register("club_title")} name="club_title" rows={2} defaultValue={data.title} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 50 - (classTitleLength + ((watch("club_title")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.club_title?.message}</span></p>

                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-1">Club summary</h3>

                                                <p className="fs-6 mb-4 text-capitalize">Add A Single Sentence Or Phrase To Describe Your Club. The Summary Should Be Clear And Descriptive Rather Than A Teaser, And Include Keywords. Use No More Than One Exclamation Mark, And No Emojis Or Coupon Codes</p>
                                                <div className="position-relative class_title">

                                                    <input type="text" className="form-control" maxLength={200} id {...register("club_summary")} name="club_summary" defaultValue={data.summary} onKeyUp={(e) => classSummaryData(e.target.value)} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 200 - (((watch("club_summary")?.split('')?.length ?? 0)))))} Words
                                                    </span>

                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.club_summary?.message}</span></p>
                                                </div>


                                            </div>
                                            {/* <div className="form-group mb-4">
                                                <h3 className="mb-1 text-capitalize">Learner Summary(optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Add a single sentence or phrase to describe your club to learners in your target age group. Remember to write in an age-appropriate tone and style.</p>
                                                <label className="form-label">Club Summary</label>
                                                <input type="text" className="form-control" placeholder id {...register("learner_summary")} name="learner_summary" defaultValue={data.learner_summary} />
                                            </div> */}
                                            {/* <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1">Class Image</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                                                <label className="form-label">Program Image</label>
                                                <Controller name="profileImage" control={control} defaultValue={null} render={({ field }) => (
                                                    <>

                                                        <div className="imageWrapper position-relative">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img className="image" src={profileImageState ? profileImageState : process.env.PUBLIC_URL + "/assets/images/image_upload.png"} alt="image_upload" style={{ "width": profileImageState ? "100%" : "" }} />
                                                            </div>

                                                            <label htmlFor='profileImage'>
                                                                <button className="file-upload">
                                                                    <input type="file" className="file-input" id="profileImage" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} />Choose File
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </>
                                                )} />
                                            </div> */}
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1">Class Video</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Describe to parents and families what to expect from club. Recommended length: max 30 seconds limit</p>

                                                <Controller name="profileVideo" control={control} defaultValue={null} rules={{ required: "Profile video is required" }} render={({ field }) => (
                                                    <>
                                                        <div className="imageWrapper position-relative">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                {profileVideoState ?
                                                                    <video src={profileVideoState} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>
                                                                    :
                                                                    // <img className="video" src="./assets/images/video_upload.png" style={{ "width": profileVideoState ? "100%" : "" }} alt="video_upload" />}
                                                                    <video src={data.video_file} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>}
                                                            </div>

                                                            <label htmlFor='profileVideo'>
                                                                <button className="file-upload">
                                                                    <input type="file" className="file-input" id="profileVideo" accept="video/*" onChange={(e) => handleVideoChange(e.target.files[0])} />Choose File
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </>
                                                )} />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileVideo?.message}</span></p>

                                            </div>

                                            <div className="form-group mb-4">
                                                <h3 className="mb-2">Subject Category</h3>
                                                <label className="mb-2" style={{ textTransform: 'none' }}>Select the Subject Category</label>
                                                <select className="form-select" aria-label="Default select example" name='subject_category' {...register("subject_category")} onChange={handleSubjectCategoryChange}>
                                                    {MainCatdata?.map((mainCategory, index) => {
                                                        console.log('mainCategory :', mainCategory);
                                                        return (
                                                            <option
                                                                key={index}
                                                                selected={subjectCategoryId == mainCategory.category_id ? 'selected' : ''}
                                                                value={`${mainCategory.category_id},${mainCategory.category_name}`}
                                                            >
                                                                {mainCategory.category_name}
                                                            </option>

                                                        );
                                                    })}
                                                </select>

                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2" style={{ textTransform: 'none' }}>Select the Subject Sub-Category</label>
                                                <select className="form-select fw-500" aria-label="Default select example" name='subject_sub_category' {...register("subject_sub_category")} onChange={handleSubjectSubCategoryChange}>
                                                    {SelectedSubCat[0]?.category_name ? (<>
                                                        {SelectedSubCat?.map((SubCat, index) => {
                                                            return (
                                                                <>
                                                                    <optgroup label={SubCat.category_name}>
                                                                        {SubCat.category?.map((SubCat1, index1) => {
                                                                            return (

                                                                                <option selected={SelectedSubCategory == SubCat1.subject_name ? 'selected' : ''} value={SubCat1.subject_name}>{SubCat1.subject_name}</option>
                                                                            )

                                                                        })}
                                                                    </optgroup>
                                                                </>)
                                                        })}
                                                    </>) : (<>
                                                        {SelectedSubCat?.map((SubCat, index) => {
                                                            return (
                                                                <>
                                                                    <option selected={SelectedSubCategory == SubCat.subject_name ? 'selected' : ''} value={SubCat.subject_name}>{SubCat.subject_name}</option>
                                                                </>)
                                                        })}
                                                    </>)}


                                                </select>
                                            </div>
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1">Learner age range</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Age ranges cannot exceed 3 years for group classes, For Learners Under 18 Years Old. Learners tend to thrive in classes with others who match their developmental stage.</p>
                                                <div className="d-md-flex">
                                                    <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                        <select className="form-select mb-md-0 mb-3 fw-500 w-auto pe-5 rounded-pill" {...register("start_age")} name="start_age" onChange={(e) => startageRange(e.target.value)} defaultValue={data.min_age} aria-label="Default select example">

                                                            <option selected={startageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                                                            <option selected={startageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                                                            <option selected={startageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                                                            <option selected={startageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                                                            <option selected={startageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                                                            <option selected={startageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                                                            <option selected={startageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                                                            <option selected={startageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                                                            <option selected={startageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                                                            <option selected={startageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                                                            <option selected={startageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                                                            <option selected={startageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                                                            <option selected={startageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                                                            <option selected={startageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                                                            <option selected={startageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                                                            <option selected={startageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                                                            <option selected={startageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                                                        </select>
                                                        <span className="ms-md-3">To</span>
                                                    </div>
                                                    <div className="d-flex ms-md-3 align-items-center">
                                                        <select className="form-select w-auto pe-5 fw-500 rounded-pill" aria-label="Default select example" {...register("end_age")} name='end_age' defaultValue={data.max_age} onChange={(e) => ageRange(e.target.value)}>

                                                            <option selected={ageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                                                            <option selected={ageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                                                            <option selected={ageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                                                            <option selected={ageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                                                            <option selected={ageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                                                            <option selected={ageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                                                            <option selected={ageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                                                            <option selected={ageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                                                            <option selected={ageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                                                            <option selected={ageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                                                            <option selected={ageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                                                            <option selected={ageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                                                            <option selected={ageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                                                            <option selected={ageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                                                            <option selected={ageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                                                            <option selected={ageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                                                            <option selected={ageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                                                        </select>
                                                        <span className="ms-3 text-capitalize">years old</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2">Select Club Size</h3>
                                                <select
                                                    className="form-select mb-4 fw-500"
                                                    onClick={(e) => handleClassChange(e.target.value)}

                                                    {...register("class_size")} // Use the register function to bind the select value to the form data
                                                    name='class_size' >
                                                    <option value="group">Group Clubs</option>
                                                    {/* <option value="private">1-on-1 Private class</option> */}
                                                </select>


                                                {classSize == "group" ?
                                                    <>
                                                        <p className="fs-6 mb-2 text-capitalize">Group Class Sizes :</p>
                                                        <p className="fs-6 mb-2 text-capitalize">-For Ages 6 And Below : Up To 6 Learners</p>
                                                        <p className="fs-6 mb-2 text-capitalize">-For Ages 6-12 : Up To 9 Learners</p>
                                                        <p className="fs-6 mb-2 text-capitalize">-For Ages 12-18 : Up To 12 Learners</p>
                                                        <p className="fs-6 mb-2 text-capitalize">-For Adults : Up To 20 Learners</p>
                                                        <div className="d-md-flex">
                                                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"  {...register("start_learner_age")} name='start_learner_age' aria-label="Default select example" onChange={(e) => StartLearner(e.target.value)}>
                                                                    {ageRanges <= 6 ? (

                                                                        <>
                                                                            <option value="1" selected>1 Learner</option>
                                                                            <option value="2">2 Learners</option>
                                                                            <option value="3">3 Learners</option>
                                                                            <option value="4">4 Learners</option>
                                                                            <option value="5">5 Learners</option>
                                                                            <option value="6">6 Learners</option>
                                                                        </>
                                                                    ) : ageRanges <= 12 ? (
                                                                        <>
                                                                            <option value="1" selected>1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3" >3 Learners</option>
                                                                            <option value="4" >4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" >9 Learners</option>
                                                                        </>
                                                                    ) : ageRanges <= 18 ? (
                                                                        <>
                                                                            <option value="1" selected>1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3">3 Learners</option>
                                                                            <option value="4">4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" >9 Learners</option>
                                                                            <option value="10" >10 Learners</option>
                                                                            <option value="11" >11 Learners</option>
                                                                            <option value="12" >12 Learners</option>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <option value="1" selected>1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3">3 Learners</option>
                                                                            <option value="4">4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" >9 Learners</option>
                                                                            <option value="10" >10 Learners</option>
                                                                            <option value="11" >11 Learners</option>
                                                                            <option value="12" >12 Learners</option>
                                                                            <option value="13">13 Learners</option>
                                                                            <option value="14">14 Learners</option>
                                                                            <option value="15">15 Learners</option>
                                                                            <option value="16">16 Learners</option>
                                                                            <option value="17">17 Learners</option>
                                                                            <option value="18">18 Learners</option>
                                                                            <option value="19">19 Learners</option>
                                                                            <option value="20">20 Learners</option>
                                                                        </>

                                                                    )}
                                                                </select>
                                                                <span className="ms-3">To</span>
                                                            </div>
                                                            <div className="ms-md-3">
                                                                <select className="form-select w-auto fw-500 pe-5 rounded-pill"
                                                                    {...register("end_learner_age")} name='end_learner_age' aria-label="Default select example" onChange={(e) => EndLearner(e.target.value)}>

                                                                    {ageRanges <= 6 ? (

                                                                        <>
                                                                            <option value="1" >1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3" >3 Learners</option>
                                                                            <option value="4" >4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" selected>6 Learners</option>
                                                                        </>
                                                                    ) : ageRanges <= 12 ? (
                                                                        <>
                                                                            <option value="1"  >1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3" >3 Learners</option>
                                                                            <option value="4" >4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" selected>9 Learners</option>
                                                                        </>
                                                                    ) : ageRanges <= 18 ? (
                                                                        <>
                                                                            <option value="1" >1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3">3 Learners</option>
                                                                            <option value="4">4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" >9 Learners</option>
                                                                            <option value="10" >10 Learners</option>
                                                                            <option value="11" >11 Learners</option>
                                                                            <option value="12" selected>12 Learners</option>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <option value="1" >1 Learner</option>
                                                                            <option value="2" >2 Learners</option>
                                                                            <option value="3">3 Learners</option>
                                                                            <option value="4">4 Learners</option>
                                                                            <option value="5" >5 Learners</option>
                                                                            <option value="6" >6 Learners</option>
                                                                            <option value="7" >7 Learners</option>
                                                                            <option value="8" >8 Learners</option>
                                                                            <option value="9" >9 Learners</option>
                                                                            <option value="10" >10 Learners</option>
                                                                            <option value="11" >11 Learners</option>
                                                                            <option value="12" >12 Learners</option>
                                                                            <option value="13">13 Learners</option>
                                                                            <option value="14">14 Learners</option>
                                                                            <option value="15">15 Learners</option>
                                                                            <option value="16">16 Learners</option>
                                                                            <option value="17">17 Learners</option>
                                                                            <option value="18">18 Learners</option>
                                                                            <option value="19">19 Learners</option>
                                                                            <option value="20" selected>20 Learners</option>
                                                                        </>

                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </>
                                                    : <></>}

                                            </div>
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1 text-capitalize">Class duration</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p>
                                                <div className="d-md-flex align-items-center">
                                                    <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p>
                                                    <div className="d-md-flex ms-md-3">
                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                                                {...register("class_hours")} onChange={(e) => classHours(e.target.value)} name='class_hours' aria-label="Default select example">
                                                                {/* <option selected>0 Hours</option> */}
                                                                <option selected={classHoursData === '0' ? 'selected' : ''} value={0}>0</option>
                                                                <option selected={classHoursData === '1' ? 'selected' : ''} value={1}>1</option>
                                                                <option selected={classHoursData === '2' ? 'selected' : ''} value={2}>2</option>
                                                                <option selected={classHoursData === '3' ? 'selected' : ''} value={3}>3</option>
                                                                <option selected={classHoursData === '4' ? 'selected' : ''} value={4}>4</option>
                                                                <option selected={classHoursData === '5' ? 'selected' : ''} value={5}>5</option>
                                                                <option selected={classHoursData === '6' ? 'selected' : ''} value={6}>6</option>
                                                                <option selected={classHoursData === '7' ? 'selected' : ''} value={7}>7</option>
                                                                <option selected={classHoursData === '8' ? 'selected' : ''} value={8}>8</option>
                                                                <option selected={classHoursData === '9' ? 'selected' : ''} value={9}>9</option>

                                                            </select>
                                                            <span className="ms-3 text-capitalize">and minutes</span>
                                                        </div>
                                                        <div className="ms-md-3">
                                                            <select className="form-select w-auto pe-5 rounded-pill fw-500"
                                                                {...register("class_min")} onChange={(e) => classsMinutesData(e.target.value)} name='class_min' aria-label="Default select example">
                                                                <option selected={classMinutesData === '0' ? 'selected' : ''} value={0}>0</option>
                                                                <option selected={classMinutesData === '5' ? 'selected' : ''} value={5}>5</option>
                                                                <option selected={classMinutesData === '10' ? 'selected' : ''} value={10}>10</option>
                                                                <option selected={classMinutesData === '15' ? 'selected' : ''} value={15}>15</option>
                                                                <option selected={classMinutesData === '20' ? 'selected' : ''} value={20}>20</option>
                                                                <option selected={classMinutesData === '25' ? 'selected' : ''} value={25}>25</option>
                                                                <option selected={classMinutesData === '30' ? 'selected' : ''} value={30}>30</option>
                                                                <option selected={classMinutesData === '35' ? 'selected' : ''} value={35}>35</option>
                                                                <option selected={classMinutesData === '40' ? 'selected' : ''} value={40}>40</option>
                                                                <option selected={classMinutesData === '45' ? 'selected' : ''} value={45}>45</option>
                                                                <option selected={classMinutesData === '50' ? 'selected' : ''} value={50}>50</option>
                                                                <option selected={classMinutesData === '55' ? 'selected' : ''} value={55}>55</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    {selectedRadio === "Multi-Day" ?
                                                        <>
                                                            <div class="MuiBox-root outschool-vuszil" style={model_css}>
                                                                <h6 class="MuiTypography-root MuiTypography-subtitle1 outschool-y2285n">Summary</h6>
                                                                <div role="presentation" class="outschool-l2z0vi"></div>
                                                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classWeekData} weeks</h6>
                                                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classSessionData * classWeekData} sessions</h6>
                                                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{totalHours} total hours</h6>
                                                            </div>
                                                        </> :
                                                        (selectedRadio === "Ongoing Weekly" || selectedRadio === "Ongoing Monthly") ?
                                                            <>
                                                                <div class="MuiBox-root outschool-vuszil" style={model_css}>
                                                                    <h6 class="MuiTypography-root MuiTypography-subtitle1 outschool-y2285n">Summary</h6>
                                                                    <div role="presentation" class="outschool-l2z0vi"></div>
                                                                    <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv"></h6>
                                                                    <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classSessionData * classWeekData} sessions</h6>
                                                                    <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{totalHours} total hours</h6>
                                                                </div>
                                                            </> :
                                                            <></>
                                                    }

                                                </div>
                                            </div>

                                            {selectedRadio != "offline course" && selectedRadio != "One-Time" ?
                                                <>

                                                    <div className="form-group mb-4 class_image">
                                                        <h3 className="mb-1 text-capitalize">Sessions per Week</h3>
                                                        {/* <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p> */}
                                                        <div className="d-md-flex align-items-center">
                                                            {/* <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p> */}
                                                            <div className="d-md-flex ms-md-3">
                                                                <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                                    <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                                                        {...register("class_session_count", { onChange: (e) => classSessionDetails(e.target.value) })}
                                                                        name='class_session_count' aria-label="Default select example">

                                                                        <option value="1" selected>1 </option>
                                                                        <option value="2" >2 </option>
                                                                        <option value="3">3 </option>
                                                                        <option value="4">4 </option>
                                                                        <option value="5" >5 </option>
                                                                        <option value="6" >6 </option>
                                                                        <option value="7" >7 </option>
                                                                        <option value="8" >8 </option>
                                                                        <option value="9" >9 </option>
                                                                        <option value="10" >10 </option>
                                                                        <option value="11" >11 </option>
                                                                        <option value="12" >12 </option>
                                                                        <option value="13">13 </option>
                                                                        <option value="14">14 </option>
                                                                        <option value="15">15 </option>
                                                                        <option value="16">16 </option>
                                                                        <option value="17">17 </option>
                                                                        <option value="18">18 </option>
                                                                        <option value="19">19 </option>
                                                                        <option value="20" >20 </option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {selectedRadio == "Multi-Day" ?
                                                        <>
                                                            <div className="form-group mb-4 class_image">
                                                                <h3 className="mb-1 text-capitalize">Number of Weeks</h3>
                                                                {/* <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p> */}
                                                                <div className="d-md-flex align-items-center">
                                                                    {/* <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p> */}
                                                                    <div className="d-md-flex ms-md-3">
                                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                                                                {...register("session_week", { onChange: (e) => classWeek(e.target.value) })} name='session_week' aria-label="Default select example">

                                                                                <option value="1" selected>1 </option>
                                                                                <option value="2" >2 </option>
                                                                                <option value="3">3 </option>
                                                                                <option value="4">4 </option>
                                                                                <option value="5" >5 </option>
                                                                                <option value="6" >6 </option>
                                                                                <option value="7" >7 </option>
                                                                                <option value="8" >8 </option>
                                                                                <option value="9" >9 </option>
                                                                                <option value="10" >10 </option>
                                                                                <option value="11" >11 </option>
                                                                                <option value="12" >12 </option>
                                                                                <option value="13">13 </option>
                                                                                <option value="14">14 </option>
                                                                                <option value="15">15 </option>
                                                                                <option value="16">16 </option>
                                                                                <option value="17">17 </option>
                                                                                <option value="18">18 </option>
                                                                                <option value="19">19 </option>
                                                                                <option value="20" >20 </option>

                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : <></>}
                                                </> : <></>}
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Pre-requisites(Optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Please Select Any Pre-Requisites Classes</p>
                                                <select className="form-select" {...register("pre_req")} name="pre_req" aria-label="Default select example"  >
                                                    <option value='' selected disabled>Select the Class</option>
                                                    {selectClass?.map((coupon, index) => {
                                                        return (
                                                            <option key={index} selected={preClassId == coupon?.class_id} value={coupon?.class_id}>
                                                                {coupon?.title}
                                                            </option>
                                                        );
                                                    })}

                                                </select>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Language of instruction</h3>
                                                <p className="fs-6 mb-4 text-capitalize">What is the main language this class will be taught in?</p>

                                                <select className="form-select fw-500 mb-4" defaultValue={data.class_language} {...register("lang")} name='lang' aria-label="Default select example">
                                                    <option selected>English</option>
                                                    <option>French</option>
                                                    <option>Mandarin</option>
                                                    <option>Cantonese</option>
                                                    <option>Spanish</option>
                                                    <option>Hindi</option>
                                                    <option>Japanese</option>
                                                    <option>Arabic</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Club Description</h3>
                                                <p className="fs-6 mb-2 text-capitalize">Explain additional club details. The longer the club, the more details you should provide. For a half hour class, a few sentences is sufficient. For a semester-long course, please include a detailed curriculum.</p>
                                                <p className="fs-6 mb-2 text-capitalize">Multi-week classes should specify which topics are covered each week. Classes running week after week should do the same for at least the first 4 weeks, unless the topics are learner-directed or responsive to current events.</p>
                                                <p className="fs-6 mb-2 text-capitalize"> A class that meets 6 or more times should have a description of at least 200 words.</p>
                                                <p className="fs-6 mb-2 text-capitalize">Topics to include:</p>
                                                <ol style={{ listStyleType: 'circle !important' }}>
                                                    <li>- What will be taught?</li>
                                                    <li>- What topics will you cover?</li>
                                                    <li>- How is your class structured?</li>
                                                    <li>- How will you teach?</li>
                                                    <li>- What's your teaching style?</li>
                                                    <li>- How much will learners get to interact with you and each other (mention specifics like: lecture, games, slides, video clips, discussion)?</li>
                                                </ol>

                                                <div className="position-relative class_title">

                                                    <textarea className="form-control" name='class_description'  {...register("class_description")} rows={6} defaultValue={data.description} maxLength={500} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 500 - (((watch("class_description")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.class_description?.message}</span></p>
                                                </div>
                                            </div>
                                            {/* <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Learning Goals (Optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">What will students learn? Be as specific as possible. Max 200 characters</p>
                                                <div className="position-relative class_title">

                                                     
                                                    <textarea className="form-control" maxLength={200} defaultValue={data.learning_goals} {...register("learning_goals")} name='learning_goals' rows={6} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 200 - (((watch("learning_goals")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Homework (optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">What kind of homework is assigned? Will learners be completing projects individually or as a group? How long will homework take each week? For a class requiring reading, approximately how many pages will learners be expected to read independently?Max 200 characters</p>
                                                <div className="position-relative class_title">

                                                    {/*   */}
                                                    <textarea className="form-control" {...register("homework")} maxLength={200} name='homework' rows={6} defaultValue={data.homeworks} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 200 - (((watch("homework")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Assessment (optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">How is learner progress assessed (eg., a letter grade, written report, informal assessment, etc.)?</p>
                                                {/*   */}
                                                <textarea className="form-control" {...register("assesment")} name='assesment' rows={6} defaultValue={data.assessment} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Parental Guidance (Optional) - For Learners Under 18 Years Old</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Identify Any Class Content That May Be Upsetting Or Scary To Some Learners, Or That Requires Learners To Take Extra Safety Precautions. Include The Official Rating Of Any Media Content You're Using. If You Are Using An Approved Third-Party Tool, Inform Families About How The Tool Will Be Used And Whether Or Not An Account Is Required. If Your Class Doesn't Require Any Content Or Safety Warnings, Leave This Section Blank.</p>
                                                {/*   */}

                                                {ageRanges == "adults" ? <textarea className="form-control" rows={6} defaultValue={data.parental_guide} /> : <> <textarea className="form-control" {...register("p_guide")} rows={6} defaultValue={data.parental_guide} />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.p_guide?.message}</span></p></>
                                                }                      </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Sources (required for certain topics)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Books, Articles, Websites, Or Other Sources That Are Used In The Class, Or Provide Source Material For The Curriculum. Reference </p>

                                                <textarea className="form-control"  {...register("sources")} name='sources' rows={6} defaultValue={data.sources} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Learner Supply List(optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">List any materials learners will need to complete the class. This may include ingredients for a recipe, supplies for a project, or books/textbooks. If the supplies are not commonly found around the home, include the price. If you will supply curriculum or materials, note that here.</p>

                                                <textarea className="form-control" {...register("supply_list")} name='supply_list' rows={6} defaultValue={data.supply_list} />
                                            </div>
                                            {/* <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Student Welcome Post</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Welcome Post This message will be displayed as the first post on the classroom page, and is included in the welcome email each enrolled family receives before class. Please include anything needed for the first class in this message. Changes will only affect newly-created sections, not existing ones.</p>
                                                 
                                                <textarea className="form-control" {...register("welcome_post")} name='welcome_post' rows={6} defaultValue={data.welcome_post} />
                                            </div> */}
                                            {/* <div className="button mb-4">
                                                <a href="#" className="btn-theme bg-yellow d-inline-block fw-600">Add A Video</a>
                                            </div> */}
                                             <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Next Class(Optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Please Select Next Class</p>
                                                <label className="mb-2">Select the Class</label>
                                                <select className="form-select" {...register("next_class")} name="next_class" aria-label="Default select example"  >
                                                    <option value='' selected disabled>Select the Class</option>
                                                    {selectClass?.map((coupon, index) => {
                                                        return (
                                                            <option key={index} selected={nextClassId == coupon?.class_id} value={coupon?.class_id} >{coupon?.title}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Sources (required for certain topics)</h3>
                                                <p className="fs-6 fw-600 mb-4 text-capitalize">{printEstimation}</p>
                                                <p className="fs-6 mb-4 text-capitalize">How much will you charge for your Class?</p>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-lg-0 mb-4">
                                                        <div className="usd_learner flex-wrap mb-4 d-flex align-items-center">
                                                            <span>$</span>
                                                            <input
                                                                type="number"
                                                                className="form-control mx-3 w-auto"
                                                                name="price"
                                                                defaultValue={data.price}
                                                                // value={price}
                                                                onChange={handlePriceChange}
                                                                min={10}
                                                                max={70}
                                                                autoComplete="off"

                                                            />
                                                            <span className="text-capitalize">USD per learner</span>
                                                        </div>
                                                        <div className="pricing_guide">
                                                            <span className="mb-2 d-block text-capitalize">{pricingLevel}</span>

                                                            <div className="progress rounded-pill">

                                                                <div className="progress-bar bg-warning rounded-pill" role="progressbar" style={{ width: progressBarWidth }} aria-valuenow={price} aria-valuemin={20} aria-valuemax={70} />

                                                            </div>
                                                            <div className="mt-1 mb-2 d-flex justify-content-between align-items-center ">
                                                                <span>(20 $)</span>
                                                                <span>(50 $)</span>
                                                                <span>(70 $)</span>
                                                            </div>
                                                            <p className="fs-6 text-capitalize">Pricing Guide: Please refer to similar class prices set by other teachers as per the subject category and student ages.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="pricing_guide">
                                                            <span className="fs-6 fw-600 mb-2 text-capitalize">Estimated Earnings</span>
                                                            <h3 className="font-18 mb-2 text-capitalize">${Estimatedprice} - {endEstimatedprice} USD per week</h3>
                                                            <p className="fs-6 text-capitalize">How this is calculated: Price per learner, class size, course duration, and Studentscholar’s 40% service fee.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Refund & Subscription Policy</h3>
                                                <p className="fs-6 mb-4 text-capitalize">All One-Time classes have a standard refund policy: "Full refund until a week in advance, or before 24 hours of purchase (until class starts)." <a className="fw-600 text-purple" data-bs-toggle="modal" data-bs-target="#safety_tips1" onClick={(e) => setIsOpen(true)}>Learn More</a></p>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-lg-3">
                                                    {/* <a href="#" className="btn-theme bg-yellow d-block text-center fw-600">Create Club</a> */}
                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Club
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>

            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

                {/* <div className="modal fade yello_left_border" id="safety_tips1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                                <span className="me-2">
                                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                                        <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                                    </svg>
                                </span>
                                Refunds &amp; Subscription Policy:
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpen(false)} />
                        </div>
                        <div className="modal-body">
                            <div className="safety_list">
                                <p >We care about your experience with StudentScholars. Therefore, we have introduced a variety of Refund and Subscription Policies to assist our learners.</p>
                                <h5 className="modal-title poppins fw_500 mt-2">Refund Policy</h5>
                                <p className='mt-2'>To request a refund, please contact the teacher WITHIN FIVE DAYS (5) days after the day of the class.</p>
                                <h5 className="modal-title poppins fw_500 mt-2">Full Refunds</h5>
                                <p className='mt-2'>In the following situations, you are entitled to request for and to receive a full refund for a class or a session.</p>
                                <ol className='my-2'>
                                    <li>
                                        <p>You may receive a full refund for a class, club or competition if you withdraw from the class: within 24 hours of purchase or before 24 hours of the class start time for an on-going weekly, or monthly lesson or a one-time lesson,  or at least 7 days before the start of the first session for a multi-day class.</p>
                                    </li>
                                    <li>
                                        <p>In the event that a teacher misses a one-time class, you may request a full refund for the class, or communicate with the teacher to reschedule the class.</p>
                                    </li>
                                    <li>
                                        <p>In the event that a teacher reschedules a session of the class before the first session starts, you may request a full refund for the class.</p>
                                    </li>

                                </ol>
                                <h5 className="modal-title poppins fw_500 mt-2">Prorated Refunds For Multi-day Classes</h5>
                                <p className='mt-2'>In the following situations, you will be entitled to request for and receive a prorated refund based on the number of sessions remaining in your class：</p>
                                <ol className='my-2'>
                                    <li>
                                        <p>If the sessions purchased by you are rescheduled, you will receive, upon request, a prorated refund based on the number of sessions rescheduled that you (i) did not attend and (ii) did not consent to such rescheduling. However, if you did not attend the rescheduled session where you have consented to such rescheduling, you will not be eligible for a refund for such session.</p>
                                    </li>
                                    <li>
                                        <p>If the teacher misses some of the sessions listed in the class, you will receive a prorated refund based on the number of sessions which the teacher missed upon request.</p>
                                    </li>
                                    <li>
                                        <p>If the teacher cancels a class in which you have any uncompleted sessions, you are eligible to receive a prorated refund for the class according to the proportion of sessions that were canceled out of all sessions you have paid for in the class.</p>
                                    </li>
                                </ol>
                                <h5 className="modal-title poppins fw_500 mt-2">No Refunds</h5>
                                <ol className='my-2'>
                                    <li>
                                        <p>We do not offer a refund for missed classes, unless the learners/parents inform the teacher 24 hours before the class start time. If you miss 3 consecutive class meetings without communicating with the teacher, you forfeit the class fee and the teacher may cancel the class or withdraw the learner from the class.
                                        </p>
                                    </li>
                                    <li>
                                        <p>If your learner has not joined the meeting 10 minutes after the class’s start time, the meeting may be cancelled, at the teacher’s discretion. This is considered a missed meeting and you are not entitled to a refund.</p>
                                    </li>

                                </ol>
                                <h5 className="modal-title poppins fw_500 mt-2">Subscription Policy</h5>
                                <h5 className="modal-title poppins fw_500 mt-2">Weekly & Monthly Subscription Policy</h5>
                                <ol className='my-2'>
                                    <li>
                                        <p>A complete single subscription cycle is from 00:00:00（Canadian Eastern Time) every Monday to 23:59:59 the following Sunday (Subscription Cycle);
                                        </p>
                                    </li>
                                    <li>
                                        <p>A subscription shall become effective once you make payment for the subscription fees of the first Subscription Cycle. Your payment for the first Subscription Cycle might be adjusted as the price reflected on the subscription page depending on the remaining session(s) in the current week.</p>
                                    </li>
                                    <li>
                                        <p> Deductions will be made from your designated payment method on a regular basis starts from every Sunday at 23:50:00（Canadian Eastern Time）(Fixed Deduction Time) for the next Subscription Cycle, unless you cancel the subscription in accordance with provisions below before the Fixed Deduction Time. Should any deduction fail to pass through on the Fixed Deduction Time, the subscription will be automatically cancelled.</p>
                                    </li>
                                    <li>
                                        <p>Subscriptions made by a subscriber are non-transferrable (to the next person).</p>
                                    </li>
                                </ol>

                                <h5 className="modal-title poppins fw_500 mt-2">Cancelling Subscriptions
                                </h5>
                                <ol className='my-2'>
                                    <li>
                                        <p> Subscribers may cancel their subscriptions at any time, subject to the payment of all outstanding fees for any Subscriptions Cycles that have already been redeemed by the subscriber. Please refer to our refund policy.
                                        </p>
                                    </li>
                                    <li>
                                        <p>We maintain the right to suspend or cancel any existing subscriptions in the event that:</p>
                                        <ol type='a'>
                                            <li>There is insufficient enrollment for a class, or the class is cancelled for whatever reason, we may automatically cancel your subscription for the class, and we will refund, prior to the commencement of the cancelled class, the full amount (including the used coupons) to the original form of payment used for your subscription;</li>
                                            <li>A class is cancelled by the conducting teacher (including any available classes in a study plan that is conducted by the teacher which you have subscribed for), we will refund the subscription fees already paid for the class that has not commenced;</li>
                                            <li>The subscription is automatically cancelled after the end of the last Subscription Cycle (including cancellation due to non-payment);</li>
                                            <li>The subscription payment order is unsuccessful (including payments to be made on the Fixed Deduction Time), the subscription will be automatically cancelled.</li>
                                        </ol>
                                    </li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </Modals>
            <OFooter_Dashboard />

        </>

    )
}
