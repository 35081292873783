import React, { useEffect, useState } from 'react'
import { Link, useLocation,useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';

import Class_Map from './Class_Map'
import Club_Map from './Club_Map'
import Competitation_Map from './Competitation_Map'
import Quiz_Map from './Quiz_Map'
import Search_Filter from './Search_Filter'
import Search_Header from './Search_Header'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';

export default function OSearch_All() {
    window.scrollTo(0, 0)
    const handleStepChange = (newStep) => setStep(newStep);

    const location = useLocation();
    const navigate = useNavigate();

    // var id = location?.state;
    // console.log('id :', id);
    // var edit_class_id = id.id;
    const [classSize,setclassSize] = useState('')
    const [classType,setclassType] = useState('')
    const [classPrice,setclassPrice] = useState('')
    const [classAge,setclassAge] = useState('')
    const [classLength,setclassLength] = useState('')
    const [classLanguage,setclassLanguage] = useState('')
    // const [selectedRadio, setRadioButtonCheck] = useState('Today');
    const [DateApply,setDateApply] = useState('')
    const [DateApplydata,setDateApplydata] = useState('')
    const [SortData,setSortData] = useState('')
    const [selectedDaysSend, setselectedDaysSend] = useState([]);

    const [StartTimeSend, setStartTimeSend] = useState('');
    console.log('StartTime :', StartTimeSend);
    const [EndTimeSend, setEndTimeSend] = useState('');
    console.log('EndTime :', EndTimeSend);
    const [SearchTab, setSearchTab] = useState('');
    const [CategoriesData,setCategoriesData] = useState("Academic")
    console.log('CategoriesData2222 :', CategoriesData);
    const [subCategoriesData,setSubCategoriesData] = useState([])  
    console.log('subCategoriesData2222 :', subCategoriesData);
    const [CountryName, setCountryName] = useState('');

    var [stateList, setstateList] = useState('');

    const currentDate = dayjs();

    // Set the default value to the current year, month, and day
    const defaultValue = {
        year: currentDate.year(),
        month: currentDate.month() + 1,  // Month in Day.js starts from 0
        day: currentDate.date(),
    };
    const [selectedDay, setSelectedDay] = useState(defaultValue);

    const [step, setStep] = useState(parseInt(localStorage.getItem("Search_Step", 1)) ? parseInt(localStorage.getItem("Search_Step", 1)) : 1);
    console.log('step :', step);

    useEffect(() => {
        localStorage.setItem("Search_Step", step)
    }, [step, parseInt(localStorage.getItem("Search_Step", 1))]);

    // console.log('parseInt(localStorage.getItem("Search_Step",1)) :', localStorage.getItem("Search_Step", 1));
    const renderStep = () => {
        console.log('stepIndex :', step);
        switch (step) {
            case 1:
                return (
                    <Class_Map step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList}/>
                );
            case 2:
                return (
                    <Club_Map step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList}/>
                );
            case 3:
                return (
                    <Quiz_Map step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList}/>
                );
            case 4:
                return (
                    <Competitation_Map step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList}/>
                );

            default:
                return null;
        }
    };
    return (
        <>
        <JqueryLoad/>
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            <Search_Header step={step} onStepChange={handleStepChange}/>
                            <Search_Filter step={step} SearchTab={SearchTab} setSearchTab={setSearchTab} classSize={classSize} setclassSize={setclassSize} classType={classType} setclassType={setclassType} classPrice={classPrice} setclassPrice={setclassPrice} classAge={classAge} setclassAge={setclassAge} classLength={classLength} setclassLength={setclassLength} classLanguage={classLanguage} setclassLanguage={setclassLanguage} selectedDay={selectedDay} setSelectedDay={setSelectedDay} DateApply={DateApply} setDateApply={setDateApply} DateApplydata={DateApplydata} setDateApplydata={setDateApplydata} SortData={SortData} setSortData={setSortData} selectedDaysSend={selectedDaysSend} setselectedDaysSend={setselectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} setStartTimeSend={setStartTimeSend} setEndTimeSend={setEndTimeSend} CategoriesData={CategoriesData} setCategoriesData={setCategoriesData} subCategoriesData={subCategoriesData} setSubCategoriesData={setSubCategoriesData} CountryName={CountryName} setCountryName={setCountryName} stateList={stateList} setstateList={setstateList}/>
                            {renderStep()}

                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />

        </>
    )
}
