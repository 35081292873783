import React from "react";
import Slider from "react-slick";
import PFooter_Dashboard from "../../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../../Pinc/PHeader_Dashboard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import JqueryLoad from "../../../../JqueryLoad/JqueryLoad";

export default function PMyCourseInPerson() {
  let location_slider_pic = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>

        <script>
          {`
  var ctx = document.getElementById("myChart").getContext('2d');

	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});




var ctx = document.getElementById("myChart1").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



var ctx = document.getElementById("myChart2").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



	var ctx = document.getElementById("myChart3").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart4").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart5").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});
  `}
        </script>
      </Helmet>
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content  bg-dark-gray">
          {/*start*/}
          <a href="#" className="mb-4 arrow_left mb-3 d-block" onClick="window.history.back()">
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
              </svg>
              <span className="ms-2">Back</span>
            </span>
          </a>
          {/*end*/}
          {/*start*/}
          <section>
            <div className="class_dtls_sec pb-0">
              <div className="row align-items-center">
                <div className="class_image_box h-auto col-xl-8 mb-xl-0 mb-4">
                  <div className="class_img position-relative">
                    <div className="pb-0 position-static video-container">
                      {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                      <video
                        id="video"
                        className="position-static rounded-3"
                        loop
                        width="100%"
                        controls="hide"
                        poster="./assets/images/video_image.png"
                      >
                        <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div>
                    <div className="align-items-center bottom-info d-flex justify-content-between position-static rounded-3">
                      <a href="teachers_detail.php" className="d-flex align-items-center">
                        <img src="./assets/images/user1.png" alt="user1" />
                        <span className="tech_nm ms-2">Celina Mark</span>
                      </a>
                      <div className="star_rat">
                        <span>
                          <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                              fill="#FDC840"
                            />
                          </svg>
                        </span>
                        <span className="fw-600">
                          4.87<span className="text-gray fw-500">(8426)</span>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between follow-btn-sec position-static py-2 w-100">
                      <div>
                        <a href="#" className="follow_btn">
                          Follow Me
                        </a>
                      </div>
                      <span className="bg-yellow px-3 py-1 rounded-3 border-radius-10">Music</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 ps-xl-5 ps-3">
                  <div className="justify-content-between">
                    <div>
                      <h2 className="mb-4 poppins fw-600">Welcome To</h2>
                      <p className="fw-500 text-capitalize">
                        Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.
                      </p>
                      <div className="button safety_btn mt-4">
                        <a
                          href="#!"
                          data-bs-toggle="modal"
                          data-bs-target="#safety_tips_in_person"
                          className="btn-theme mb-3 bg-yellow d-inline-block"
                        >
                          <span className="me-3">
                            <svg width={26} height={31} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M13.1731 9.78467C10.0212 9.78467 7.45703 12.3488 7.45703 15.5005C7.45703 18.6525 10.0212 21.2166 13.1731 21.2166C16.3249 21.2166 18.889 18.6525 18.889 15.5005C18.889 12.3488 16.3249 9.78467 13.1731 9.78467ZM16.5668 14.4241L12.4718 18.519C12.3902 18.6009 12.2931 18.6658 12.1863 18.7101C12.0795 18.7544 11.9649 18.7771 11.8493 18.777C11.7337 18.7771 11.6192 18.7544 11.5124 18.7102C11.4056 18.6659 11.3086 18.6009 11.227 18.519L9.66209 16.9541C9.31832 16.6106 9.31832 16.0533 9.66209 15.7095C10.0056 15.3657 10.563 15.3657 10.9067 15.7095L11.8493 16.652L15.3221 13.1795C15.6657 12.8356 16.223 12.8356 16.5668 13.1795C16.9105 13.523 16.9105 14.0804 16.5668 14.4241Z"
                                fill="#570861"
                              />
                              <path
                                d="M25.9172 8.63975L25.9163 8.61643C25.9027 8.30584 25.8937 7.99507 25.8893 7.68421C25.8648 6.03005 24.5499 4.66177 22.8955 4.56937C19.4463 4.37683 16.778 3.25213 14.4981 1.02991L14.4787 1.01139C13.735 0.329536 12.6133 0.329536 11.8694 1.01139L11.8499 1.02991C9.57005 3.25213 6.90175 4.37683 3.45252 4.56955C1.79842 4.66177 0.48323 6.03011 0.458738 7.68439C0.454439 7.99519 0.445454 8.3059 0.431785 8.61643L0.430379 8.67063C0.363289 12.1879 0.279969 16.5652 1.74434 20.5384C2.54959 22.7233 3.76904 24.6225 5.36871 26.1837C7.19062 27.9617 9.5769 29.3732 12.4613 30.3789C12.5562 30.4119 12.6533 30.4384 12.7519 30.4581C12.892 30.486 13.033 30.5 13.174 30.5C13.315 30.5 13.4562 30.486 13.5961 30.4581C13.6951 30.4383 13.7926 30.4116 13.8879 30.3784C16.7688 29.3709 19.1526 27.9587 20.9729 26.181C22.5719 24.6194 23.7914 22.7196 24.5973 20.5343C26.0671 16.5492 25.9841 12.1636 25.9172 8.63975ZM13.1736 22.9769C9.05091 22.9769 5.69713 19.6231 5.69713 15.5005C5.69713 11.3778 9.05097 8.02405 13.1736 8.02405C17.296 8.02405 20.65 11.3778 20.65 15.5005C20.65 19.6231 17.296 22.9769 13.1736 22.9769Z"
                                fill="#570861"
                              />
                            </svg>
                          </span>
                          Safety Tips
                        </a>
                        <a href="#!" data-bs-toggle="modal" data-bs-target="#class_option" className="btn-theme bg-yellow d-inline-block">
                          <span className="me-3">
                            <svg width={30} height={27} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M27.2385 0.5H14.0584C12.5331 0.5 11.2969 1.73645 11.2969 3.26147V9.75365C11.9936 10.0999 12.7465 10.4744 13.4491 10.8237L14.5242 8.67272C15.1751 7.37193 16.7619 6.84342 18.0622 7.49322C19.3652 8.14537 19.8933 9.72781 19.241 11.0311L17.3245 14.8649C17.3759 15.3737 17.3292 15.8876 17.1872 16.3789H18.4525L15.1154 25.5553C14.9173 26.0995 15.3001 26.7346 15.9416 26.7346C16.3003 26.7346 16.6377 26.5133 16.7672 26.1558L20.3227 16.3789H20.9386L24.5302 26.1588C24.6608 26.5145 24.9973 26.7346 25.3557 26.7346C25.9552 26.7346 26.3946 26.1359 26.1802 25.5529L22.8111 16.3789H27.2386C28.7636 16.3789 30 15.1425 30 13.6174V3.26152C30 1.73645 28.7636 0.5 27.2385 0.5ZM25.3553 12.6136H21.4119C20.9266 12.6136 20.533 12.2199 20.533 11.7346C20.533 11.249 20.9266 10.8557 21.4119 10.8557H25.3553C25.841 10.8557 26.2342 11.249 26.2342 11.7346C26.2342 12.2199 25.8411 12.6136 25.3553 12.6136ZM25.3553 9.31836H21.4119C20.9266 9.31836 20.533 8.9252 20.533 8.43945C20.533 7.95424 20.9266 7.56055 21.4119 7.56055H25.3553C25.841 7.56055 26.2342 7.95424 26.2342 8.43945C26.2342 8.9252 25.8411 9.31836 25.3553 9.31836ZM25.3553 6.02363H15.9416C15.4559 6.02363 15.0627 5.62988 15.0627 5.14473C15.0627 4.65957 15.4559 4.26582 15.9416 4.26582H25.3553C25.841 4.26582 26.2342 4.65945 26.2342 5.14473C26.2342 5.63 25.841 6.02363 25.3553 6.02363Z"
                                fill="#570861"
                              />
                              <path
                                d="M15.4642 16.0019C15.623 15.5366 15.6219 15.0505 15.4877 14.6069L17.669 10.2448C17.886 9.81066 17.7102 9.28268 17.276 9.06564C16.8421 8.84844 16.314 9.02445 16.0968 9.45869L14.2365 13.1789C13.1245 12.6252 11.4352 11.7853 10.0743 11.1085C8.80805 10.4788 7.90441 9.91414 6.3627 9.91414H5.58609C6.78656 9.91414 7.85795 9.3616 8.55926 8.49687C9.0944 7.83887 9.41438 7.00021 9.41438 6.08633C9.41438 3.97168 7.70051 2.25781 5.58563 2.25781C3.47168 2.25781 1.75781 3.97168 1.75781 6.08633C1.75781 7.10187 2.15332 8.02473 2.79855 8.70957C3.49617 9.45137 4.48699 9.91414 5.58539 9.91437C4.11275 9.91437 2.67311 10.511 1.6374 11.5495C0.581543 12.6026 0 14.0056 0 15.5002V18.3245C0 19.5403 0.789434 20.5747 1.88279 20.9428V25.8555C1.88279 26.341 2.27625 26.7344 2.7617 26.7344H8.41002C8.89547 26.7344 9.28893 26.341 9.28893 25.8555V15.9805C10.1223 16.3971 11.2171 16.9443 12.1028 17.3874C12.7238 17.6978 13.4493 17.7257 14.0964 17.4625C14.7416 17.197 15.2389 16.6685 15.4607 16.0123L15.4642 16.0019Z"
                                fill="#570861"
                              />
                            </svg>
                          </span>
                          Class Options
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end*/}
        </div>
        <div className="class-content pt-lg-5">
          {/*start-video-section*/}
          <div className="video_boxs_sec position-relative">
            <div>
              <div className="video_boxs">
                <video id="video" className="w-100" loop height={557} controls="controls" poster="./assets/images/video_bg_dash.png">
                  <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="cancel_btn position-absolute">
              <a href="#" className="colse_div_video">
                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
                  <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg>
              </a>
            </div>
          </div>
          {/*end-video-section*/}
          {/*start-tabing*/}
          <ul className="nav custom_nav_bar custom_yellow_line nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-classes-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-classes"
                type="button"
                role="tab"
                aria-controls="pills-classes"
                aria-selected="true"
              >
                Classroom
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Homework-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Homework"
                type="button"
                role="tab"
                aria-controls="pills-Homework"
                aria-selected="false"
              >
                Homework
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Assignments-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Assignments"
                type="button"
                role="tab"
                aria-controls="pills-Assignments"
                aria-selected="false"
              >
                Assesments
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-quizzes-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-quizzes"
                type="button"
                role="tab"
                aria-controls="pills-quizzes"
                aria-selected="false"
              >
                Quizzes
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Discussion-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Discussion"
                type="button"
                role="tab"
                aria-controls="pills-Discussion"
                aria-selected="false"
              >
                Discussion Board
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Recordings-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Recordings"
                type="button"
                role="tab"
                aria-controls="pills-Recordings"
                aria-selected="false"
              >
                Recordings
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Messages"
                type="button"
                role="tab"
                aria-controls="pills-Messages"
                aria-selected="false"
              >
                Messages
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Progress-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Progress"
                type="button"
                role="tab"
                aria-controls="pills-Progress"
                aria-selected="false"
              >
                Progress Report
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-Feedback-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Feedback"
                type="button"
                role="tab"
                aria-controls="pills-Feedback"
                aria-selected="false"
              >
                Feedback
              </button>
            </li>
          </ul>
          {/*end-tabing*/}
          {/*start-content*/}
          <div className="tab-content mt-5" id="pills-tabContent">
            {/*----------------------------------Tab1-------------------------------------------------*/}
            <div className="tab-pane fade active show" id="pills-classes" role="tabpanel" aria-labelledby="pills-classes-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  {/*start-location-card*/}
                  <div className="row location_card mb-4">
                    <div className="col-xl-5 mb-xl-0 mb-5 left">
                      <div className="text-center">
                        <img src="./assets/images/location_card.png" alt="location_card" />
                      </div>
                    </div>
                    <div className="col-xl-7 right">
                      <div className="location_photo">
                        <h3 className="mb-5">Location Photos:</h3>
                        <div className="location_slider_pic slick_silder_button slick_slider_white">
                          <Slider {...location_slider_pic}>
                            <div className="loction_box">
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                            </div>
                            <div className="loction_box">
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                            </div>
                            <div className="loction_box">
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                              <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                            </div>
                          </Slider>
                        </div>
                        <div className="locat_add mt-5">
                          <h3>Class Address:</h3>
                          <p>
                            4517 Washington Ave. Manchester,
                            <br /> Kentucky 39495.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end-location-card*/}
                  <div className="card mb-4 bg-gray review_box text-center">
                    <h3 className="mb-4 poppins">Next Meeting Starts Apr 24th At 6 PM EST</h3>
                    <div className="button">
                      <a
                        href="#!"
                        className="btn-theme fw-600 text-dark bg-yellow mb-xl-0 mb-3 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ask_the_teacher"
                      >
                        Start Live Meeting
                      </a>
                    </div>
                  </div>
                  <div className="enviorment_div mb-5 align-items-center d-md-flex">
                    <div className="env_img">
                      <img src="./assets/images/enviorment.png" alt="enviorment" />
                    </div>
                    <div className="env_text">
                      <p className=" fw-500 fs-6">
                        Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available
                        to you.
                      </p>
                    </div>
                  </div>
                  <div className="review_dack">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="list_btw_dots mb-4">
                          <ul className="d-sm-flex align-items-center">
                            <span className="pe-4 position-relative">
                              Lesson <span className="green_dots position-absolute" />
                            </span>
                            <li>
                              <span className="text-purple fw-500"> Duration Of Lesson : 3 Hours</span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                          <div className="d-flex align-items-center">
                            <div className="fw-600">
                              <span className="d-block">Collecting Moodboard from Art.</span>
                            </div>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                          <div className="button">
                            <a href="#" className="btn-theme px-3 d-inline-block text-center">
                              View Details
                            </a>
                          </div>
                          <div className="list_btw_dots mb-sm-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="list_btw_dots mb-4">
                          <ul className="d-sm-flex align-items-center">
                            <span className="pe-4 position-relative">
                              Lesson <span className="green_dots position-absolute" />
                            </span>
                            <li>
                              <span className="text-purple fw-500"> Duration Of Lesson : 3 Hours</span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                          <div className="d-flex align-items-center">
                            <div className="fw-600">
                              <span className="d-block">Collecting Moodboard from Art.</span>
                            </div>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                          <div className="button">
                            <a href="#" className="btn-theme px-3 d-inline-block text-center">
                              View Details
                            </a>
                          </div>
                          <div className="list_btw_dots mb-sm-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab2-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Homework" role="tabpanel" aria-labelledby="pills-Homework-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">
                              Upload An Assignment
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">
                              Upload An Assignment
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab2-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Assignments" role="tabpanel" aria-labelledby="pills-Assignments-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">
                              Upload An Assignment
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">
                              Upload An Assignment
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">1 Day Left</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mb-4 flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3 px-4">
                              Submited
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-purple fw-500">12:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="remark mb-3 bg-dark-gray border-radius-10 d-flex align-items-center justify-content-between">
                          <p className="font-14">Your Assignment Is not Done Properly So you Have to Reupload it in 6 Following Days.</p>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center">
                              <li>
                                <span className="text-danger">Remark</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3 px-4">
                              Reupload
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">30 Minutes</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab3-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-quizzes" role="tabpanel" aria-labelledby="pills-quizzes-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                1. Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <Link to="/quiz-review" className="btn-theme text-center mb-sm-0 mb-3 bg-yellow d-inline-block">
                              Completed Quiz
                            </Link>
                            <Link to="/certificates_list" className="btn-theme px-3 ms-sm-3 d-inline-block text-center">
                              Show Certificate
                            </Link>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                2. Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <Link to="/quizlist" className="btn-theme px-3 d-inline-block text-center">
                              Start Quiz
                            </Link>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab4-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Discussion" role="tabpanel" aria-labelledby="pills-Discussion-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack discussion_divs">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                1. Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">
                              Start Discussion
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-danger fw-500">Closed</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="pe-sm-4 fw-600 position-relative">
                                2. Collecting Moodboard from Art. <span className="green_dots position-absolute" />
                              </span>
                              <li>
                                <span className="text-purple fw-500"> Score : 25/30</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">
                              Start Discussion
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-success fw-500">Opened</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500"> Posted On: 25 March 2022 5:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">Deadline: 25 March 2022 5:00 PM</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-discussion-panel*/}
                  <div className="discussion_panel_board" style={{ display: "none" }}>
                    <div className="modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="msg-head">
                          <div>
                            <span>Live Discussion Board</span>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="msg-body">
                            {/*start-loop-to-here*/}
                            <div className="d-sm-flex align-items-center mb-4 justify-content-between">
                              <div className="discussion_sec mb-sm-0 mb-3">
                                <div className=" d-flex align-items-center">
                                  <div className="discss_img">
                                    <img src="./assets/images/user/user4.png" alt="user3" />
                                  </div>
                                  <div className="discss_ctn ms-3">
                                    <span className="text-gray">
                                      Celina <span className="text-dif-black">Helllo how are!</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span className="text-gray">25 March</span>
                                <span className="d-block text-dif-black">10:10 PM </span>
                              </div>
                            </div>
                            {/*end-loop-to-here*/}
                          </div>
                        </div>
                        <div className="send-box">
                          <form action className="position-relative">
                            <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                            <a href="#" className="send_icons">
                              <i className="fa fa-paper-plane" aria-hidden="true" />
                            </a>
                            <div className="send-btns">
                              <div className="attach">
                                <div className="button-wrapper position-relative">
                                  <span className="label">
                                    <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                                        fill="#131313"
                                      />
                                    </svg>
                                  </span>
                                  <input
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    className="upload-box"
                                    placeholder="Upload File"
                                    aria-label="Upload File"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end-discuss-panel*/}
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab5-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Recordings" role="tabpanel" aria-labelledby="pills-Recordings-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <ul>
                              <li className="mb-3">
                                <a href="#!" className="text-decoration-underline text-purple fw-600 ">
                                  Mon, Mar 20, 7:29 – 8:26 PM Eastern
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Teacher Joined : <span>Celina Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Joined : <span>Julie Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Absent : <span>Julie Mark</span>
                                </a>
                              </li>
                            </ul>
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="button safety_btn d-sm-flex align-items-center">
                          <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                            Watch Recording
                          </a>
                          <a
                            href="#"
                            className="btn-theme bg-yellow d-inline-block ms-sm-3"
                            data-bs-toggle="modal"
                            data-bs-target="#safety_tips_only_one"
                          >
                            <span className="me-3">
                              <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z"
                                  fill="#570861"
                                />
                                <path
                                  d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z"
                                  fill="#570861"
                                />
                              </svg>
                            </span>
                            Safety Tips
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <ul>
                              <li className="mb-3">
                                <a href="#!" className="text-decoration-underline text-purple fw-600 ">
                                  Mon, Mar 20, 7:29 – 8:26 PM Eastern
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Teacher Joined : <span>Celina Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Joined : <span>Julie Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Absent : <span>Julie Mark</span>
                                </a>
                              </li>
                            </ul>
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                            Watch Recording
                          </a>
                          <a
                            href="#"
                            className="btn-theme bg-yellow d-inline-block ms-sm-3"
                            data-bs-toggle="modal"
                            data-bs-target="#safety_tips_only_one"
                          >
                            <span className="me-3">
                              <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z"
                                  fill="#570861"
                                />
                                <path
                                  d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z"
                                  fill="#570861"
                                />
                              </svg>
                            </span>
                            Safety Tips
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                    <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                      <li className="page-item">
                        <a className="page-link text-dark" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          May 2023
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Apr 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Mar 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 border-end-0" href="#">
                          Feb 2023
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                              <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-3">
                  <div className="mb-4">
                    <span className="fs-5 fw-600 mb-4 d-block text-dif-black">Attendance</span>
                    <div className="wrappers">
                      <div className="container-calendar">
                        <div className="cal_header d-flex justify-content-between">
                          <h3 className="poppins fw_500" id="monthAndYear" />
                          <div className="button-container-calendar">
                            <button id="previous" onClick="previous()">
                              ‹
                            </button>
                            <button className=" ms-3" id="next" onClick="next()">
                              ›
                            </button>
                          </div>
                        </div>
                        <table className="table-calendar" id="calendar" data-lang="en">
                          <thead id="thead-month" />
                          <tbody id="calendar-body" />
                        </table>
                        <div className="footer-container-calendar d-none">
                          <label htmlFor="month">Jump To: </label>
                          <select id="month" onchange="jump()">
                            <option value={0}>Jan</option>
                            <option value={1}>Feb</option>
                            <option value={2}>Mar</option>
                            <option value={3}>Apr</option>
                            <option value={4}>May</option>
                            <option value={5}>Jun</option>
                            <option value={6}>Jul</option>
                            <option value={7}>Aug</option>
                            <option value={8}>Sep</option>
                            <option value={9}>Oct</option>
                            <option value={10}>Nov</option>
                            <option value={11}>Dec</option>
                          </select>
                          <select id="year" onchange="jump()" />
                        </div>
                      </div>
                    </div>
                    {/*start*/}
                    <div className="attendence_count g-3 row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap d-flex align-items-center">
                          <canvas id="myChart" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Hours attended for Month</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap d-flex align-items-center">
                          <canvas id="myChart1" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Hours attended To Date</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                          <canvas id="myChart2" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Days Absent for Month</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                          <canvas id="myChart3" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Days Absent To Date</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                          <canvas id="myChart4" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Days joined for Month</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                          <canvas id="myChart5" width={33} height={33} />
                          <div className="ms-3">
                            <span>150</span>
                            <p>Total Days joined To Date</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end*/}
                  </div>
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul className="position-static">
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab6-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Messages" role="tabpanel" aria-labelledby="pills-Messages-tab">
              {/*start-chatbox*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row">
                          <div className="col-8">
                            <div className="d-flex align-items-center">
                              <span className="chat-icon">
                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
                                    fill="#131313"
                                  />
                                </svg>
                              </span>
                              <div className="flex-shrink-0 position-relative">
                                <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
                                <span className="active" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Mehedi Hasan</h3>
                                <p>Active Now</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <ul className="moreoption">
                              <li className="navbar nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                </a>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Action
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Another action
                                    </a>
                                  </li>
                                  <li>
                                    <hr className="dropdown-divider" />
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Something else here
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black">
                                    {" "}
                                    Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday
                                    you have gave a pen This very nice{" "}
                                  </p>
                                  <span className="time text-end">10:06 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:16 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">
                                    yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice
                                  </p>
                                  <span className="time text-start">10:20 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:26 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:32 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">How are you?</p>
                                  <span className="time text-start">10:35 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li>
                              <div className="divider">
                                <h6>Today</h6>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black"> yes, tell me</p>
                                  <span className="time text-start">10:36 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">yes... on it</p>
                                  <span className="time text-start">junt now</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="send-box">
                        <form action className="position-relative">
                          <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                          <a href="#" className="send_icons">
                            <i className="fa fa-paper-plane" aria-hidden="true" />
                          </a>
                          <div className="send-btns">
                            <div className="attach">
                              <div className="button-wrapper position-relative">
                                <span className="label">
                                  <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                                      fill="#131313"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="file"
                                  name="upload"
                                  id="upload"
                                  className="upload-box"
                                  placeholder="Upload File"
                                  aria-label="Upload File"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end-chatbox*/}
            </div>
            {/*----------------------------------Tab7-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Progress" role="tabpanel" aria-labelledby="pills-Progress-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="button my-5 text-center">
                    <a href="#!" data-bs-toggle="modal" data-bs-target="#progrss-report" className="btn-theme bg-yellow d-inline-block">
                      Go To Your Progress Report
                    </a>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div>
                    <div className="accordion border-radius-10 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header poppins" id="headingOne">
                          <button
                            className="accordion-button fs-5 text-dif-black bg-transparent"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            New Notifications
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="list_notification">
                              <ul>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-pink position-relative">
                                      <span className="text-uppercase text-danger">A</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Assignments Due</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                      <span className="text-uppercase text-yellow">q</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">New assignments.</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                  <div className="notif_left d-flex align-items-center">
                                    <div className="noti_img bg-sky-blue-op-1 position-relative">
                                      <span className="text-uppercase text-blue">e</span>
                                      <span className="notifi_logs">
                                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                            fill="#3F3F44"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="ms-3 noti_text">Any upcoming events</p>
                                  </div>
                                  <span className="noti_timer">10.12 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab10-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Feedback" role="tabpanel" aria-labelledby="pills-Feedback-tab">
              {/*start*/}
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <form className="custom_form mt-4" action id>
                    <div className="form-group mb-4">
                      <label className="mb-2">Teacher’s Name</label>
                      <input type="text" className="form-control" name placeholder defaultValue />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Name of Class</label>
                      <input type="text" className="form-control" name placeholder defaultValue />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">URL link to the class</label>
                      <input type="text" className="form-control" name placeholder defaultValue />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Day and Time of Class</label>
                      <select className="form-select" aria-label="Default select example">
                        <option selected>Lorem ipsum dolor sit amet</option>
                        <option value={1}>Lorem ipsum dolor sit amet.</option>
                        <option value={2}>sed do eiusmod ut labore et dolore magna aliqua</option>
                        <option value={3}>sed do eiusmod ut labore et dolore.</option>
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Date</label>
                      <select className="form-select" aria-label="Default select example">
                        <option selected>Lorem ipsum dolor sit amet</option>
                        <option value={1}>Lorem ipsum dolor sit amet.</option>
                        <option value={2}>sed do eiusmod ut labore et dolore magna aliqua</option>
                        <option value={3}>sed do eiusmod ut labore et dolore.</option>
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Type</label>
                      <select className="form-select pe-5" aria-label="Default select example">
                        <optgroup label="Attendance">
                          <option>Teacher cancellation within 24 hours of class</option>
                          <option>Teacher Cancelling remaining session(s) after class begins</option>
                          <option>Multiple teacher/stduent cancellations 24 hours before a class begins</option>
                          <option>Cancelling class based on parent reviews or comments on other classes</option>
                          <option>Missing or failing to attend Session(s)</option>
                          <option>Repeatedly arriving late or leaving early</option>
                          <option>Failing to follow up with StudentScholars and affected learners when a Session is missed or Cancelled</option>
                        </optgroup>
                        <optgroup label="Quality of Classes">
                          <option>Failing to have the teaching environment or equipment required under our policies</option>
                          <option>Consistently providing poor-quality class experiences</option>
                          <option> Repeatedly failing to achieve pre-set teaching objectives for a class</option>
                          <option>
                            {" "}
                            Failing to wait when a learner is late or leaves a session for 10 minutes or less before leaving or cancelling the class
                          </option>
                          <option> Using inappropriate language or gestures</option>
                        </optgroup>
                        <optgroup label="Quality of Services">
                          <option>Not providing regular feedback or answering questions</option>
                          <option> Not responding to messages from learners who are enrolled in a class</option>
                          <option>Failing to refund</option>
                          <option>StudentScholars receiving complaints about a teacher’s behavior Prohibited</option>
                          <option> Failing to maintain a good working relationship with StudentScholars</option>
                          <option>Unprofessional behaviors</option>
                        </optgroup>
                        <optgroup label="Strictly Prohibited">
                          <option>Disregarding learner or parent safety or privacy</option>
                          <option> Using an account or unapproved third party platform to teach live sessions, rather than using the services</option>
                          <option>Requesting payment (other than those collected through StudentScholars)</option>
                          <option>Requesting student’s/teacher’s phone number and email to contact them privately.</option>
                          <option>Sharing your phone number and email with the student/teacher for them to contact you privately.</option>
                          <option>Transferring, lending or selling your teacher accoun</option>
                          <option>
                            Failing to provide the required qualification certificate or routine background investigation information as required by
                            StudentScholars
                          </option>
                          <option>Deceiving, or trying to deceive, learners or StudentScholars</option>
                          <option>Using the content provided by StudentScholars other than as permitted in the Terms and Teacher Terms</option>
                        </optgroup>
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Please describe the feedback in detail</label>
                      <textarea className="form-control" rows={6} defaultValue={""} />
                    </div>
                    <div className="mb-4">
                      <label className="mb-2">Attachments</label>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <div className="file_update position-relative">
                              <input type="file" className="form-control" name />
                              <span className="file_icons position-absolute">
                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                    fill="#131313"
                                    fillOpacity="0.4"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <div className="file_update position-relative">
                              <input type="file" className="form-control" name />
                              <span className="file_icons position-absolute">
                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                    fill="#131313"
                                    fillOpacity="0.4"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <div className="file_update position-relative">
                              <input type="file" className="form-control" name />
                              <span className="file_icons position-absolute">
                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                    fill="#131313"
                                    fillOpacity="0.4"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-12">
                  <div>
                    <p className="text-gray fw-600">StudentScholars Support team will get back to you.</p>
                  </div>
                  <div className="button mt-4">
                    <a href="#" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
          {/*end-content*/}
        </div>
      </main>
      <Helmet>{/* <script src="./assets/js/custom_calendar.js"></script> */}</Helmet>
      <PFooter_Dashboard />
    </>
  );
}
