import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { verify_otp, resendOtpApi } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
export default function SendOtpModel({ message, type, setIsOpen, setIsOpenReset }) {
  const navigate = useNavigate();
  const publicUrl = process.env.PUBLIC_URL;

  const handleResendOTP = () => {
    let submitData = {
      user_id: localStorage.getItem("PAid"),
    };

    // if (type === 'forgot') {
    //     submitData.otp_type = 'Forgot';
    // } else {
    //     submitData.otp_type = 'Signup';
    // }
    console.log("submitData :", submitData);

    resendOtpApi(submitData).then((res) => {
      console.log("res :", res);

      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
      } else {
        TOAST_ERROR(res.message);
      }
    });
    reset();
  };

  const location = useLocation();

  console.log(location.pathname);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    try {
      const enteredOtp = Object.values(value).join("");
      if (location.pathname === "/forgot-password") {
        var submitData = {
          // "otp_type": "forgot",
          userr_id: localStorage.getItem("PAid") ? localStorage.getItem("PAid") : localStorage.getItem("Tid"),
          otp: enteredOtp,
        };
      } else {
        var submitData = {
          userr_id: localStorage.getItem("PAid") ? localStorage.getItem("PAid") : localStorage.getItem("Tid"),

          otp: enteredOtp,
        };
      }
      console.log(submitData, "submitdata");
      // return
      verify_otp(submitData).then((res) => {
        console.log("otp_res :", res);

        if (res.code == 1) {
          if (location.pathname === "/forgot-password") {
            navigate("/reset-password");
          } else {
            localStorage.removeItem("Otp");
            console.log(type);
            if (localStorage.getItem("userType") == "below_18") {
              // localStorage.setItem("PASignupStep", res.data.data?.signup_step);
              localStorage.setItem("PAtoken", res.data.token);
              navigate("/sign_in_step3");
            } else if (localStorage.getItem("userType") == "above_18") {
              localStorage.setItem("PAtoken", res.data.token);
              navigate("/dashboard");
            } else if (localStorage.getItem("userType") == "after_school") {
              localStorage.setItem("PAtoken", res.data.token);
              navigate("/sign_in_after_school_step3");
            } else if (localStorage.getItem("type") == "teacher") {
              localStorage.setItem("Ttoken", res.data.token);
              navigate("/signup-teacher");
            } else if (localStorage.getItem("type") == "organization") {
              localStorage.setItem("Ttoken", res.data.token);
              navigate("/organization-step");
            } else if (localStorage.getItem("type") == "org_teacher") {
              localStorage.setItem("Ttoken", res.data.token);
              navigate("/signup-teacher");
            } else {
              setIsOpenReset(true);
            }
            TOAST_SUCCESS(res.message);
          }
        } else {
          TOAST_ERROR(res.message);
        }
      });
      reset();
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  const handleChange = (e, index) => {
    if (e.target.value.length === 1 && index < 6) {
      document.getElementById(`digit${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && index > 1 && e.target.value === "") {
      document.getElementById(`digit${index - 1}`)?.focus();
    }
  };

  return (
    <>
      {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="{-1}" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0 border-bottom-0">
            <h5 className="modal-title" id="staticBackdropLabel">
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close"></button>
            </h5>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h4 className="modal-title" id="staticBackdropLabel">
                Verify OTP
              </h4>
              <p className="fs-6 text-gray">
                We’ve sent a One Time Password (OTP) to the your number. Please enter it to complete verification {message}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="custom_form verf_otp mt-4" action id>
              <div className="form-group d-flex justify-content-center mb-4">
                {/* <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(1)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(2)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(3)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(4)" maxLength="{1}" /> */}

                {/* <div className="verify-code"> */}
                {[1, 2, 3, 4].map((index) => (
                  <input
                    key={index}
                    {...register(`digit${index}`, { required: "4 digit OTP required.", pattern: { value: /^[0-9]$/, message: "Invalid OTP" } })}
                    aria-invalid={errors[`digit${index}`] ? "true" : "false"}
                    className="otp form-control"
                    type="text"
                    id={`digit${index}`}
                    maxLength="1"
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
              {Object.keys(errors).length > 0 && (
                <label className="errorc pass form-text-fild mt-1" style={{ color: "red" }}>
                  {errors[Object.keys(errors)[0]]?.message}
                </label>
              )}
              {/* </div>/ */}
              <div className="modal-footer border-top-0 justify-content-center pt-0">
                {/* <a href="reset_password.php" className="btn-theme bg-yellow fw-600">Verify</a> */}
                <button type="submit" className="btn-theme bg-yellow fw-600">
                  {/* <Link to="/sign_in_step3">Next</Link> */}Verify
                </button>
              </div>
            </form>
          </div>

          <div className="modal-footer justify-content-center">
            <p className="fs-6">
              Didn’t received?{" "}
              <Link onClick={handleResendOTP} className="text-purple text-uppercase">
                Resend
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
