import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import BackToPrevious from '../../../components/backToPrevious';
import { faq_list } from '../../../api/apiHandler';
import FilterBySearch from '../../Parents/SearchClass/filterBySearch';

export default function LandingPageFaq() {
  var token = localStorage.getItem('Ttoken') || localStorage.getItem("PAtoken");


  var [FaqData, setFaqData] = useState([]);
  console.log("FaqData :", FaqData);

  const handleSearch = (val) => setSearch(val);

  const [search, setSearch] = useState("");

  const FaqDetails = () => {
    faq_list({ search: search }).then((resposnse) => {
      console.log("Faq_details", resposnse);

      if (resposnse?.code == 1) {
        const filteredData = resposnse?.data.filter((item) =>
        item?.panels && Array.isArray(item?.panels) && item?.panels.some(panel => panel?.panel_name === "landing_page")
      );
        setFaqData(filteredData);
      } else {
        setFaqData([]);
        console.log("data not found");
      }
    });
  };

  useEffect(() => {
    FaqDetails();
  }, [search]);
  return (
    <>

      <Header />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <BackToPrevious />
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600 text-capitalize">FAQ - training Videos</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row">
                    <div className="col-lg-8">
                      {/*start-Search*/}
                      <div className="search_box mb-4 position-relative">
                        <FilterBySearch search={search} handleSearch={handleSearch} />

                      </div>
                      {/*end-Search*/}
                      <ul className="traning_list ps-lg-3">
                        {FaqData && FaqData.length > 0 ? (
                          FaqData.map((item) => (
                            <li key={item.id} className="mb-4 ">
                              <Link to={token ? "/landing_page_faq_video" : "/login"} state={item.id} className="d-flex align-items-center justify-content-between">
                                <div>
                                  <h3 className="fs-5 poppins fw_500">{item.title}</h3>
                                </div>
                                <div>
                                  <svg width={20} height={15} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M23.6164 9.06753L14.9491 0.400093C14.7017 0.15268 14.372 0.0168762 14.0204 0.0168762C13.6684 0.0168762 13.3388 0.152875 13.0914 0.400093L12.3045 1.18721C12.0573 1.43423 11.9211 1.76418 11.9211 2.11598C11.9211 2.46759 12.0573 2.80866 12.3045 3.05568L17.3608 8.12315H1.29657C0.572288 8.12315 0 8.69017 0 9.41465V10.5274C0 11.2519 0.572288 11.8761 1.29657 11.8761H17.4182L12.3047 16.9719C12.0575 17.2193 11.9213 17.5402 11.9213 17.892C11.9213 18.2435 12.0575 18.5691 12.3047 18.8163L13.0916 19.6009C13.339 19.8483 13.6686 19.9832 14.0206 19.9832C14.3722 19.9832 14.7019 19.8466 14.9493 19.5992L23.6166 10.9319C23.8646 10.6837 24.001 10.3524 24 10.0002C24.0008 9.64685 23.8646 9.31534 23.6164 9.06753Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </Link>
                            </li>
                          ))
                        ) : (
                          <li>No Data Found</li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
