import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { coupon_list } from '../../../../api/apiHandler';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader_Dashboard from '../../Include/THeader_Dashboard'

export default function MyCoupon() {

  const location = useLocation();
  const navigate = useNavigate();

  var step = location?.state;
  console.log('step123 :', step);

  var [data, setData] = useState([]);
  console.log('coupon_listing :', data);
  // console.log('datagdffhgfhgfjhgjkjhl :', data);

  const [showMoreMap, setShowMoreMap] = useState({}); // State to track showMore state for each card
  const maxLength = 15; // Maximum number of words to show initially

  // Function to toggle showMore state for a specific card
  const toggleShowMore = (index) => {
    setShowMoreMap(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  // Function to trim description text
  const trimDescription = (description, index) => {
    const words = description.split(' ');
    const showMore = showMoreMap[index] || false;
    return showMore ? words.join(' ') : words.slice(0, maxLength).join(' ');
  };
  const coupon_listing = () => {

    let send_data = {}

    if (step == 1) {
      send_data.type = "class"
    } else if (step == 2) {
      send_data.type = "club"
    } else if (step == 3) {
      send_data.type = "quiz"
    } else {
      send_data.type = "competition"
    }
    console.log('send_datacoupon_listing :', send_data);

    coupon_list(send_data).then((resposnse) => {
      console.log("coupon_listing", resposnse)
      if (resposnse?.code == 1) {
        setData(resposnse.data)
      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    coupon_listing();
  }, [])



  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-8">
                      {/*start*/}
                      <div className="mb-4 d-md-flex align-items-center justify-content-between">
                        <h2 className="mb-md-0 mb-4 poppins fw-bold">my Coupon </h2>
                        <div className="d-sm-flex flex-wrap justify-content-end">
                          <Link to="/my_purchase_detail" state={step} className="text-decoration-underline text-purple">Coupon Purchase Details</Link>
                          <Link to="/add_coupon" state={step} className="text-decoration-underline text-purple ms-3">Add New Coupon</Link>
                        </div>
                      </div>
                      {/*end*/}
                      {/*start*/}

                      {data?.map((myData, index) => (
                        <div key={index} className="coupon_card card">
                          <div className="d-md-flex align-items-center justify-content-between">
                            <div className="coup_box d-md-flex">
                              <div className="coupon_left">
                                <span>{myData.amount_percentages}% off</span>
                              </div>
                              <div className="coupon_right">
                                <span>{myData.amount_percentages}% Off Coupon | {myData?.title}</span>
                                <p>
                                  {trimDescription(myData.discription, index)}
                                  {myData.discription.length > maxLength && (
                                    <a className="text-decoration-underline" onClick={() => toggleShowMore(index)}>
                                      {showMoreMap[index] ? ' Less' : ' More'}
                                    </a>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="button">
                              <a  className="btn-theme bg-yellow d-inline-block fw-600">{myData.amount_percentages}$ Off</a>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* <div className="coupon_card card">
                        <div className="d-md-flex align-items-center justify-content-between">
                          <div className="coup_box d-md-flex">
                            <div className="coupon_left">
                              <span>20% off</span>
                            </div>
                            <div className="coupon_right">
                              <span>20% Off Coupon | Rewards Program</span>
                              <p>Denny's is offering 20% off your next visit when you join their Rewards Program! You will get this coupon within 24-48 hours after signing up...<a href="#" className="text-decoration-underline">More</a></p>
                            </div>
                          </div>
                          <div className="button">
                            <a href="#" className="btn-theme bg-yellow d-inline-block fw-600">15 $ Off</a>
                          </div>
                        </div>
                      </div>
                      <div className="coupon_card card">
                        <div className="d-md-flex align-items-center justify-content-between">
                          <div className="coup_box d-md-flex">
                            <div className="coupon_left">
                              <span>20% off</span>
                            </div>
                            <div className="coupon_right">
                              <span>20% Off Coupon | Rewards Program</span>
                              <p>Denny's is offering 20% off your next visit when you join their Rewards Program! You will get this coupon within 24-48 hours after signing up...<a href="#" className="text-decoration-underline">More</a></p>
                            </div>
                          </div>
                          <div className="button">
                            <a href="#" className="btn-theme bg-yellow d-inline-block fw-600">15 $ Off</a>
                          </div>
                        </div>
                      </div>
                      <div className="coupon_card card">
                        <div className="d-md-flex align-items-center justify-content-between">
                          <div className="coup_box d-md-flex">
                            <div className="coupon_left">
                              <span>20% off</span>
                            </div>
                            <div className="coupon_right">
                              <span>20% Off Coupon | Rewards Program</span>
                              <p>Denny's is offering 20% off your next visit when you join their Rewards Program! You will get this coupon within 24-48 hours after signing up...<a href="#" className="text-decoration-underline">More</a></p>
                            </div>
                          </div>
                          <div className="button">
                            <a href="#" className="btn-theme bg-yellow d-inline-block fw-600">15 $ Off</a>
                          </div>
                        </div>
                      </div> */}
                      {/*end*/}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
