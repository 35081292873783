import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { logOutRedirectCall } from "../../Common/RedirectPathMange";
import { ask_the_teacher, ask_the_teacher_details, request_another_time, logout, start_pause_subscriptions, contact_us_details, transfer_schedule_list, transfer_schedule_request, class_option_payment_list, refund_request_parent_students, cancelled_meetings_inserted } from "../../../api/apiHandler";
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR, formatDate } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import SubscribeModal1 from "./SubscribeModals/SubscribeModal1";
import SubscribeModal2 from "./SubscribeModals/SubscribeModal2";
import SubscribeModal3 from "./SubscribeModals/SubscribeModal3";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import Select from "react-select";
import dayjs from "dayjs";
import { getUserDetailsApiCall } from "../../../Store/slices/MasterSlice";
import { useDispatch } from "react-redux";


export default function PFooter_Dashboard({ classes_id }) {
  const dispatch = useDispatch()
  console.log(classes_id, 'test1');
  // console.log(activity_type, 'test2');

  // const logoutRef = useRef();
  var [ask_the_Teacher_Details, set_ask_the_Teacher_Details] = useState(null);

  const [userData, setUserData] = useState();
  const [schedule, setSchedule] = useState([]);
  // var [LogoutDetails, set_LogoutDetails] = useState(null);
  const userType = localStorage.getItem('userType')
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id, 'id');
  // var id = location?.state;
  // var teacherId = location?.state?.teacher_id;
  // var classes_id = lo

  // var [request_Another_Time,set_request_Another_Time] = useState([]);

  // let schema;

  // schema = yup.object().shape({
  //   question: yup.string().required("question is required"),
  //   document: required("document is required"),

  // });



  let schema;

  schema = yup.object().shape({
    timezone: yup.string().required("Timezone is required"),
    typeofclass: yup.string().required("Typeofclass is required"),
    day_preference: yup.string().required("Day preference category is required"),
    time_preference: yup.string().required("Time preference is required"),
    want_lession: yup.string().required("Want lession is required"),
    comments: yup.string().required("Comments is required"),
    country: yup.string().required("Country is required"),

  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let schema1 = yup.object().shape({
    question: yup.string().required("Question is required"),
    document: yup.string().required("document is required"),
  });

  // const { register1, handleSubmited, setValue1, clearErrors1, reset1, watch1, control1, getValues1, formState: { errors1 } } = useForm({
  //   resolver: yupResolver(schema1)
  // });
  // console.log('errors :', errors);
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    clearErrors: clearErrors1,
    reset: reset1,
    watch: watch1,
    control: control1,
    getValues: getValues1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
  });

  var classData = ask_the_Teacher_Details;

  const getData = () => {
    console.log("test");
    contact_us_details().then((resposnse) => {
      console.log("details", resposnse?.data[0]);
      console.log('country', resposnse?.data[0]?.user_country);

      if (resposnse?.code == 1) {
        setValue("country", resposnse?.data[0]?.user_country)
        setValue("timezone", resposnse?.data[0]?.time_zone)
        setUserData(resposnse?.data[0])
        reset1();
      } else {
        // setData([]);
        console.log("data not found");
      }
    });
  };


  
  const handleGetUserDetailsApiCall = () => {
    dispatch(getUserDetailsApiCall({ userId: localStorage.getItem("PAid"),type:localStorage.getItem('userType') }));
  }

  useEffect(() => {
    if (localStorage.getItem("PAid")) {
      handleGetUserDetailsApiCall();
    }
  }, []);

  useEffect(() => {
    ask_the_teacher_Details();
    getData();

    // logout_Details();
  }, []);

  // const logout_Details = (e) => {
  //   e.preventDefault();
  //   logout({})
  //     .then((resposnse) => {
  //       if (resposnse.code == 1) {
  //         logOutRedirectCall();
  //         logoutRef.current.click();
  //         navigate("/login");
  //       } else {
  //         console.log("data not found");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "58");
  //     });
  // };

  const [profileImageState, setProfileImageState] = useState("");

  const handleImageChange = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    setValue1("document", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors1("document");
  };

  const ask_the_teacher_Details = (data) => {
    ask_the_teacher_details({ class_id: "48", type: "class" }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_ask_the_Teacher_Details(resposnse.data);
      } else {
      }
    });
  };

  let onSubmit = async (data) => {
    //   if (data.imageUpload != null) {
    //     var image = await uploadImageOnAWS(data?.attachments_data, "class_image");
    // }

    var image = await uploadImageOnAWS(data?.document, "class_image");

    let submitData = {
      classes_id: "48",
      classes_type: classes_id.type,
      question: data.question,
      document: image,
    };

    ask_the_teacher(submitData).then((res) => {
      // return
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        // navigate('/teachers_my_class');
      } else {
        TOAST_ERROR(res.message);
      }
    });
    reset();
  };

  let onSubmit1 = async (data) => {
    let submitData = {
      activity_id: id,
      activity_type: classes_id.type,
      timezone: data.timezone,
      typeofclass: data.typeofclass,
      day_preference: data.day_preference,
      time_preference: data.time_preference,
      want_lession: data.want_lession,
      comments: data.comments,
    };

    console.log("submitData", submitData);
    request_another_time(submitData).then((res) => {
      // return
      console.log("res :", res);
      // if (res.code == 1) {
      //   TOAST_SUCCESS(res.message);
      //   // navigate('/teachers_my_class');
      // } else {
      //   TOAST_ERROR(res.message);
      // }
    });
    reset();
  };


  const [value, setCountry] = useState("");
  // console.log("hello",value)


  const options = useMemo(() => countryList().getData(), []);



  /////////////////////// Class Option ///////////////////////////

  // fro request a refund

  const [selectedReason, setSelectedReason] = useState("");

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setSelectedReason(event.target.value);
  };

  let schema2 = yup.object().shape({
    refund_reason: yup.string().required("Please select reason"),

  });

  if (selectedReason === "Other Reason") {
    schema2 = schema2.shape({
      other_reason: yup.string().required("Please add reason"),
    });
  }

  const [refundList, setrefundList] = useState([])
  const [totalAmount, setTotalAmount] = useState(0);
  const [Refund, setRefund] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [meetingId, setMeetingId] = useState(null);

  const refund_list = () => {
    class_option_payment_list({ activity_id: `${classes_id?.class_id}`, activity_type: classes_id?.type, learnerId: classes_id?.learner_data?.id }).then((resposnse) => {
      console.log(resposnse, 'refund_list');
      if (resposnse?.code === "1") {
        setrefundList(resposnse?.data);
        setCurrencySymbol(resposnse?.data[0]?.currency_symbol)
        console.log('resposnse?.data?.currency_symbol :', resposnse?.data[0]?.currency_symbol);
        setCurrencyCode(resposnse?.data[0]?.currency_code)
        const total = Array.isArray(resposnse?.data?.meeting_data)
          ? resposnse.data.meeting_data.reduce((acc, curr) => acc + parseFloat(curr.paymentPerMeeting), 0)
          : 0;

        console.log("Total amount:", total.toFixed(2));
        setTotalAmount(total.toFixed(2));
      } else {
        setrefundList([]);
        setTotalAmount(0);
      }
    });
  };

  const currentDate = new Date().toISOString().split('T')[0];
  const futureSchedules = refundList?.meeting_data?.filter(schedule => schedule.date > currentDate);

  const handleCancel = (enrolledId, meetingId, activity_length) => {
    console.log('meetingId :', meetingId);
    console.log('enrolledId :', enrolledId);
    var obj = {
      enrolled_id: enrolledId, meeting_id: meetingId, activity_id: `${classes_id?.class_id}`, activity_type: classes_id?.type, learnerId: classes_id?.learner_data?.id
    }
    if (activity_length) {
      obj.category = activity_length
    }

    cancelled_meetings_inserted(obj).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        refund_list();
        schedule_list();
      } else {
        TOAST_ERROR(resposnse.message)
      }
    });

  }

  // const setRefund = (meetingid) =>{
  // console.log('meetingId :', meetingid);

  // }




  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    reset: reset2,
    watch: watch2,
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });
  let onSubmit2 = (data) => {

    refund_request_parent_students({ activity_id: `${classes_id.class_id}`, activity_type: classes_id.type, is_refund: 1 ,meeting_id:Refund}).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        reset2();
        setRefund(null)
      } else {
        TOAST_ERROR(resposnse.message)
      }
    });
    console.log(data);
  }


  ///////// for stop subscription 

  const [selectedReason1, setSelectedReason1] = useState("");

  const handleSelectChange1 = (event) => {
    console.log(event.target.value);
    setSelectedReason1(event.target.value);
  };

  let schema3 = yup.object().shape({
    stop_class_reason: yup.string().required("Please select reason"),

  });

  if (selectedReason1 === "Other Reason") {
    schema3 = schema3.shape({
      other_reason: yup.string().required("Please add reason"),
    });
  }

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    clearErrors: clearErrors3,
    reset: reset3,
    watch: watch3,
    control: control3,
    getValues: getValues3,
    formState: { errors: errors3 },
  } = useForm({
    resolver: yupResolver(schema3),
  });
  let onSubmit3 = (data) => {
    console.log(data);
    start_pause_subscriptions({ action: 'stop', reason_id: data.stop_class_reason, activity_id: `${classes_id.class_id}`, activity_type: classes_id.type, teacher_id: classes_id.teacher_id, learnerId: classes_id.learner_data.id }).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        reset3();
      } else {
        TOAST_ERROR(resposnse.message)
      }
    });
  }

  /////////////// For pause class

  const [minEndDate, setMinEndDate] = useState('');

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setMinEndDate(selectedDate);
  };

  let schema4 = yup.object().shape({
    start_date: yup.string().required("Please select start date"),
    end_date: yup.string().required("Please select end date"),

  });


  const {
    register: register4,
    handleSubmit: handleSubmit4,
    setValue: setValue4,
    clearErrors: clearErrors4,
    reset: reset4,
    watch: watch4,
    control: control4,
    getValues: getValues4,
    formState: { errors: errors4 },
  } = useForm({
    resolver: yupResolver(schema4),
  });
  let onSubmit4 = (data) => {
    // console.log(data);


    start_pause_subscriptions({ action: 'pause', start_date: data.start_date, end_date: data.end_date, activity_id: `${classes_id.class_id}`, activity_type: classes_id.type, teacher_id: classes_id.teacher_id, learnerId: classes_id.learner_data.id }).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        reset4();
      } else {
        TOAST_ERROR(resposnse.message)
      }
    });
  }

  /////////////// For Transfer Class

  const [classdata, setClassdata] = useState([])


  const schedule_list = () => {
    transfer_schedule_list({ activity_id: `${classes_id?.class_id}`, activity_type: classes_id?.type }).then((resposnse) => {
      console.log(resposnse, 'schedule');
      if (resposnse?.code == 1) {
        setClassdata(resposnse.data);
      } else {
        setClassdata([]);
      }
    });
  };

  let schema5 = yup.object().shape({
    class: yup.string().required("Please select class schedule"),
    meeting_id: yup.string().required("Please select class schedule"),

  });

  const {
    register: register5,
    handleSubmit: handleSubmit5,
    setValue: setValue5,
    clearErrors: clearErrors5,
    reset: reset5,
    watch: watch5,
    control: control5,
    getValues: getValues5,
    formState: { errors: errors5 },
  } = useForm({
    resolver: yupResolver(schema5),
  });
  let onSubmit5 = (data) => {

    transfer_schedule_request({ activity_id: `${classes_id?.class_id}`, activity_type: classes_id?.type, request_meeting_id: data?.class ,meeting_id:data?.meeting_id}).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        reset5();
      } else {
        TOAST_ERROR(resposnse.message)
      }
    });
    // console.log(data,'transfer');
  }


  useEffect(() => {
    schedule_list();
    refund_list();
  }, [classes_id]);


  if (classData == null)
    return (
      <div className="container">
        <div className="mt-5"></div>
      </div>
    );

  return (
    <>
      <div>

        {/* Modal */}
        {/* <div className="modal fade" id="share_link_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header pb-0 border-bottom-0">
                <h1 className="modal-title fs-6" id="exampleModalLabel">
                  Share Link
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <ul className="soical_media_link">
                  <li>
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        {/*start-modal*/}
        <div className="modal fade" id="progrss-report" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Progress Report
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="mb-2 fw-600">
                  <span>Student’s Name:</span>
                  <span>Name1</span>
                </div>
                <div className="fw-600 mb-4">
                  <span>Program Name:</span>
                  <span> Program1</span>
                </div>
                <div className="table-responsive-xl">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date (DD/MM/YY)</th>
                        <th scope="col">Lesson Topic</th>
                        <th scope="col">Homework</th>
                        <th scope="col">Assessment</th>
                        <th scope="col">Quiz</th>
                        <th scope="col">Discussion</th>
                        <th scope="col">Teacher Feedback</th>
                        <th scope="col">Teacher’s Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1/1/2023</th>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <th scope="row">2/1/2023</th>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <th scope="row">3/1/2023</th>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-theme" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="button" className="btn-theme bg-yellow">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        {/* <div className="modal fade" id="logout" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header pb-0 border-bottom-0">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button type="button" ref={logoutRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <h2 className="fs-5 text-center poppins fw-600 text-purple">Are you sure you want to log out ?</h2>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pt-0">
                <a href="#!" className="btn-theme bg-yellow fw-500" data-bs-dismiss="modal">
                  No
                </a>
                <button type="button" onClick={logout_Details} className="btn-theme fw-500">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div> */}
        {/*ask_the_teacher*/}

        {/*end-ask_the_teacher*/}
        {/* <SubscribeModal1 />
        <SubscribeModal2 />
        <SubscribeModal3 /> */}

        {/*start*/}
        <div className="modal fade yello_left_border" id="safety_tips_only_one" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z"
                        fill="#570861"
                      />
                      <path
                        d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  Safety Tips &amp; Guidelines for Learners
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="safety_list">
                  <ul>
                    <li>
                      <p>
                        <span className="fw-600">Class Recordings:</span> Video recordings are available to registered learners for educational
                        purposes only. Please note, you are not allowed to share or copy these videos.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        {/*start*/}
        <div className="modal fade yello_left_border" id="safety_tips" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z"
                        fill="#570861"
                      />
                      <path
                        d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  Online Class Policies &amp; Safety Tips:
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="safety_list">
                  <ol>
                    <li>
                      <p>
                        <span className="fw-600">Familiarize Using the Platform:</span> StudentScholars website is user friendly and easy to navigate.
                        However, we urge learners to watch the training videos in the FAQ section to get a better understanding of the website
                        features available to you. Please remember <b>not to</b> copy the zoom link and join from outside the platform classroom. If
                        you do so, you may be unsubscribed from the class.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Learner Behaviour:</span> We want to create a pleasant environment for all users. Please be
                        respectful towards the teacher and other learners. Bullying is not tolerated and may result in the learner being blocked from
                        the website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Posting Reviews:</span> It is helpful if you could leave a review regarding the class. Our key
                        priority and concern at StudentScholars is to continue to be a unique and safe place for all learners.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Environment:</span> Learners should try their best to be in a quiet environment with no distractions
                        while in class.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Parent/Guardian Supervision:</span> If the learners are young, we ask parents/guardians to please
                        supervise while they attend class, until you’re confident they can participate safely and respectfully on their own with
                        absolutely no interference to any ongoing class. We encourage parents/guardians to stay off-screen as much as possible, so if
                        your learner needs hands-on assistance or supervision, please reach out to the teacher before class to let them know. Parents
                        may listen into a class at a reasonable distance from their learner or watch the recording later, but it will be against
                        StudentScholars policies for a parent/guardian to listen in or watch a class from a separate device.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Confirming Identity:</span> Learners should join the class each time with their video enabled to
                        allow the teacher to confirm the identity. This can be a quick check-in at the beginning of each class meeting and is a
                        requirement to promote classroom safety.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Sharing Personal Information:</span> For user safety, <b>do not</b> share or ask for personal
                        information from teachers or other learners. Personal information can include but not limited to, a full name, birth date,
                        personal email address, address, or phone number, social media information.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Class Recordings:</span> All classes are recorded for educational purposes only. Learners have an
                        option to review the class material covered after the class. It is against our policy for anyone to copy, record or share the
                        video recordings.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Feedback:</span> StudentScholars has a feedback form easily accessible from the user account. You can
                        give us feedback and a customer support team member will get back to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">No Directly Hiring:</span> You should not privately hire teachers or organizations. If we suspect
                        such an incident, StudentScholars at its discretion has the right to terminate the user from the website.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        {/*start*/}
        <div className="modal fade yello_left_border" id="safety_offline" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z"
                        fill="#570861"
                      />
                      <path
                        d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  Offline Course Policies &amp; Safety Tips:
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="safety_list">
                  <ol>
                    <li>
                      <p>
                        <span className="fw-600">Familiarize Using the Platform:</span> StudentScholars website is user friendly and easy to navigate.
                        However, we urge learners to watch the training videos in the FAQ section to get a better understanding of the website
                        features available to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Learner Behaviour:</span> We want to create a pleasant environment for all users. Please be
                        respectful towards the teacher and other learners. Bullying is not tolerated and may result in the learner being blocked from
                        the website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Posting Reviews:</span> It is helpful if you could leave a review regarding the class. Our key
                        priority and concern at StudentScholars is to continue to be a unique and safe place for all learners.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Sharing Personal Information:</span> For user safety, <b>do not</b> share or ask for personal
                        information from teachers or other learners. Personal information can include but not limited to, a full name, birth date,
                        personal email address, address, or phone number, social media information.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Feedback</span> StudentScholars has a feedback form easily accessible from the user account. You can
                        give us feedback and a customer support team member will get back to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">No Directly Hiring:</span> You should not privately hire teachers or organizations. If we suspect
                        such an incident, StudentScholars at its discretion has the right to terminate the user from the website.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        {/*start*/}
        <div className="modal fade yello_left_border" id="secure_payment" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z"
                        fill="#570861"
                      />
                      <path
                        d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  Secure &amp; Safe Payment:
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="safety_list">
                  <ol>
                    <li>
                      <p>
                        <span className="fw-600"> Encrypted Transactions:</span> Your payment details are shielded with state-of-the-art encryption. This means that whenever you make a purchase, your information is transformed into a secret code that only our system and Stripe can decipher.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Trusted Security Standards:</span> Stripe meets the highest level of security standards set by the payment card industry. This certification ensures that your payment data is handled with the utmost care and meets stringent security requirements.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Easy and Secure Account Access:</span> We've added extra layers of protection to your account. With two-factor authentication, you'll need to confirm your identity using a unique code sent to your phone whenever you log in, adding an extra shield against unauthorized access.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Smart Fraud Protection:</span> Our systems are continuously scanning for any suspicious activity. If anything seems off, we'll be alerted immediately, allowing us to act swiftly to protect your account and prevent any potential fraud.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Your Data, Your Control:</span> Your payment information is yours alone. With tokenization, we don't store your card details directly. Instead, a secure token is used for transactions, keeping your sensitive data safe from prying eyes.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Regular Safety Checks:</span> We're always on guard to keep your information secure. Stripe undergoes regular security audits by independent experts to ensure we're meeting the highest security standards and keeping your data safe.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        {/*start*/}
        <div className="modal fade yello_left_border" id="safety_tips_in_person" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z"
                        fill="#570861"
                      />
                      <path
                        d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  In-person Class Policies &amp; Safety Tips
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="safety_list">
                  <ol>
                    <li>
                      <p>
                        {" "}
                        <span className="fw-600">Class Location:</span> This is an independent location decided by the organization. StudentScholars
                        is not responsible for the selection of the location site. Before you book a class, you will see the full address and pictures
                        of the location, introduction to the organization, and the teaching experience of the teacher conducting the class. These
                        details have been uploaded by the organization. It is up to the discretion of the learner, parent, or guardian to decide to
                        attend the class at the organization location.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Learner Behaviour:</span> We want to create a pleasant environment for all users. Please be
                        respectful towards the teacher and other learners. Bullying is not tolerated and may result in the learner being blocked from
                        the website. Organizations and teachers have the right to cancel the class of the learner if they wish to do so.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Arriving on Time:</span> Please arrive for the class on time, as the teacher will charge for the
                        allocated scheduled time duration.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Canceling Classes:</span> If you want to cancel a class, you need to inform the teacher 24 hours in
                        advance. If you do not, you will be charged for the lesson.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Posting Reviews:</span> It is helpful if you could leave a review regarding the class. Our key
                        priority and concern at StudentScholars is to continue to be a unique and safe place for all learners.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Unexpected Incident:</span> Any issues that may occur at the location, must be taken up directly with
                        the in-person teacher and/or the organization. Learners, Parents and/or Guardians agree to indemnify, defend and hold harmless
                        StudentScholars, its officers, directors, sub Teachers, agents, and their respective successors, heirs and assigns (the
                        “Indemnitees”),against any claim, liability, cost, damage, deficiency, loss, expense or obligation of any kind, including
                        lawyers’ fees and other costs of litigation, incurred in connection with any claims, suits, actions, demands, or judgments
                        arising by offering this service including, but not limited to, actions in the form of tort, warranty, negligence, or strict
                        liability.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">No Direct Hiring:</span> You should not privately hire teachers or organizations. If we suspect such
                        an incident, StudentScholars at its discretion has the right to terminate the user from the website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Feedback:</span> StudentScholars has a feedback form easily accessible from the user account. You can
                        give us feedback and a customer support team member will get back to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Other Policies by the Organization:</span> Please follow any policies or procedures noted by the
                        organization when booking the class.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        {/*start*/}
        <div className="modal fade" id="safety_tips_in_person_reject" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <form className="custom_form">
                  <div className="form-group">
                    <label>reason for rejection</label>
                    <input type="text" className="form-control" />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-theme bg-yellow" data-bs-dismiss="modal">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal fade" id="quiz_submit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <span className="mb-3 d-block">
                    <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width={100} height="99.2307" rx="49.6154" fill="#FFC924" />
                      <path
                        d="M62.1688 44.3298C62.1688 41.6143 60.9292 39.0099 58.7226 37.0898C56.5161 35.1696 53.5233 34.0908 50.4028 34.0908C47.2822 34.0908 44.2895 35.1696 42.0829 37.0898C39.8764 39.0099 38.6367 41.6143 38.6367 44.3298C38.6367 44.8729 38.8846 45.3938 39.326 45.7778C39.7673 46.1619 40.3658 46.3776 40.9899 46.3776C41.614 46.3776 42.2126 46.1619 42.6539 45.7778C43.0952 45.3938 43.3431 44.8729 43.3431 44.3298C43.3431 43.1148 43.7572 41.927 44.5329 40.9167C45.3086 39.9064 46.4112 39.119 47.7012 38.6541C48.9911 38.1891 50.4106 38.0674 51.78 38.3045C53.1495 38.5415 54.4074 39.1266 55.3947 39.9858C56.382 40.8449 57.0544 41.9396 57.3268 43.1313C57.5992 44.323 57.4593 45.5582 56.925 46.6808C56.3907 47.8033 55.4858 48.7628 54.3249 49.4379C53.1639 50.1129 51.799 50.4732 50.4028 50.4732C49.7787 50.4732 49.1801 50.689 48.7388 51.073C48.2975 51.457 48.0496 51.9779 48.0496 52.521V56.6166C48.0496 57.1597 48.2975 57.6806 48.7388 58.0646C49.1801 58.4487 49.7787 58.6644 50.4028 58.6644C51.0269 58.6644 51.6254 58.4487 52.0667 58.0646C52.5081 57.6806 52.756 57.1597 52.756 56.6166V54.364C55.4136 53.8919 57.8022 52.6353 59.5175 50.8068C61.2328 48.9783 62.1694 46.6902 62.1688 44.3298Z"
                        fill="#570861"
                      />
                      <path
                        d="M50.402 66.8554C51.7017 66.8554 52.7552 65.9385 52.7552 64.8076C52.7552 63.6766 51.7017 62.7598 50.402 62.7598C49.1024 62.7598 48.0488 63.6766 48.0488 64.8076C48.0488 65.9385 49.1024 66.8554 50.402 66.8554Z"
                        fill="#570861"
                      />
                    </svg>
                  </span>
                  <p className="fw-600">Are You Sure Want To Submit Your Quiz?</p>
                </div>
              </div>
              <div className="modal-footer border-top-0 pt-0 justify-content-center">
                <button type="button" className="btn-theme bg-yellow" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#quiz_congrat">
                  Yes
                </button>
                <button type="button" className="btn-theme d-block text-center" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="quiz_congrat" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <span className="mb-3 d-block">
                    <img src="./assets/images/congrat.png" alt="congrat" />
                  </span>
                  <p className="fw-600">Congratulations you have passed</p>
                  <span className="my-3 d-block">You scored 80%</span>
                  <a href="kids_quiz_review.php" className="text-purple d-block fs-6 fw-bold">
                    Review Quiz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*end*/}
        {/*start-class-potion*/}
        <div className="modal fade" id="class_option" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header pb-0 border-bottom-0">
                <div className="class_op_user d-flex align-items-center">
                  {userType === "above_18" ? <>
                    <img src={userData?.profile_image ? userData?.profile_image : "./assets/images/kids-profile.png"} alt="kids-profile" />
                    <div className="ms-3">
                      <span className="text-dif-black fw-500">{userData?.user_name}</span>
                      {/* <span className="font-14 ms-2 fw-500 text-dif-black">( {classes_id?.learner_data?.learner_age} Years)</span> */}
                    </div>
                  </> : <>
                    <img src={classes_id?.learner_data?.learner_profile_img ? classes_id?.learner_data?.learner_profile_img : "./assets/images/kids-profile.png"} alt="kids-profile" />
                    <div className="ms-3">
                      <span className="text-dif-black fw-500">{classes_id?.learner_data?.learner_name}</span>
                      <span className="font-14 ms-2 fw-500 text-dif-black">( {classes_id?.learner_data?.learner_age} Years)</span>
                    </div>
                  </>}




                </div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body p-0">
                {/*start*/}
                <div className="px-3 py-3 border-bottom">
                  <div>
                    <form className="custom_form mt-4" onSubmit={handleSubmit5(onSubmit5)}>

                      <div className="form-group mb-4">
                        <p className="text-purple fw-500 fs-5 mb-3">Transfer</p>
                        <label className="mb-2">Transfer class from </label>
                        <select className="form-select" aria-label="Default select example" {...register5("meeting_id")}>
                          <option value={''}>Select Schedule</option>
                          {futureSchedules?.map(schedule => (
                            <option key={schedule?.meeting_id} value={schedule?.meeting_id}>
                              {`Class date: ${schedule?.formatted_date} ${schedule?.formatted_time_range}`}
                            </option>
                          ))}
                        </select>
                        {/* {errors5.class && <p>{errors5.class.message}</p>} */}
                        {errors5.class && <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors5.class.message}</span>
                        </p>}

                      </div>

                      <div className="form-group mb-4">

                        <label className="mb-2">Transfer class to </label>

                        <select className="form-select" aria-label="Default select example" {...register5("class")}>
                          <option value={''}>Select Schedule</option>
                          {classdata && classdata.map(schedule => (
                            <option key={schedule?.id} value={schedule?.id}>
                              {`Upcoming class on ${schedule?.formatted_date} ${schedule?.formatted_time_range}`}
                              {/* {`Upcoming class on ${schedule?.formatted_datetime}`} */}

                            </option>
                          ))}
                        </select>
                        {/* {errors5.class && <p>{errors5.class.message}</p>} */}
                        {errors5.class && <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors5.class.message}</span>
                        </p>}
                        <span className="fw-500 text-dif-black mt-2 d-block">Please confirm this change with the buyer before you transfer.</span>
                      </div>
                      <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Request Class Schedule Change</button>
                    </form>

                  </div>
                </div>
                {/*end*/}
                {/*start*/}
                <div className="px-3 py-3 border-bottom">
                  <p className="text-purple fw-500 fs-5 mb-3">Payment History</p>
                  <div className="class_op_price">
                    <ul>
                      {refundList?.meeting_data?.length > 0 ?

                        refundList?.meeting_data?.map((pData, index) => {
                          return (
                            <li >Class date:  {pData?.meeting_date}  {dayjs(pData?.date + ' ' + pData?.start_time, 'hh:mm:ss').format('h:mm')}-{dayjs(pData?.date + ' ' + pData?.end_time, 'hh:mm:ss').format('h:mm A')} - Payment made of {pData?.currency_symbol}{pData.paymentPerMeeting} {pData?.currency_code} by {pData.full_name} on {pData.payment_date}
                              &nbsp;{pData?.not_joined == 0 && <span className="text-purple text-decoration-underline mb-4 me-2" onClick={() => setRefund(pData?.meeting_id)}>Request Refund</span>}</li>
                          )
                        }) : <> <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": '18px' }}>No data Found
                        </h4></>}
                    </ul>
                    {/* <a href="#" className="text-purple text-decoration-underline mb-4 me-2">
                      Request A Refund
                    </a> */}
                  </div>
                  {/* <form className="custom_form mt-4">
                    <div className="form-group mb-4">
                      <label className="mb-2">Request a Refund for this reason</label>
                      <select className="form-select" aria-label="Default select example">
                        <option selected>Teacher missed the class</option>
                        <option>learner missed the class</option>
                        <option>Technical issues</option>
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label>Other Reason</label>
                      <input type="text" className="form-control" />
                    </div>
                  </form> */}
                  {Refund &&
                    <form className="custom_form mt-4" onSubmit={handleSubmit2(onSubmit2)}>
                      <div className="form-group mb-4">
                        <label className="mb-2">Request a Refund for this reason</label>
                        <select className="form-select" aria-label="Default select example" {...register2("refund_reason")} onChange={handleSelectChange}>
                          <option value={""}>Select Reason</option>
                          <option value={"Teacher missed the class"}>Teacher missed the class</option>
                          <option value={"Learner missed the class"}>Learner missed the class</option>
                          <option value={"Technical issues"}>Technical issues</option>
                          <option value={"Other Reason"}>Other Reason</option>
                        </select>
                        {/* {errors2.refund_reason && <p className="error">{errors2.refund_reason.message}</p>} */}
                        {errors2.refund_reason && <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors2.refund_reason.message}</span>
                        </p>}
                      </div>
                      {selectedReason === "Other Reason" && (
                        <div className="form-group mb-4">
                          <label>Other Reason</label>
                          <input type="text" className="form-control" {...register2("other_reason")} />
                        </div>
                      )}
                      {errors2.other_reason && selectedReason === "Other Reason" && (
                        <p className=" font-bold col-red">
                          <span style={{ color: "red" }}>{errors2.other_reason.message}</span>
                        </p>
                      )}

                      <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Request A Refund</button>
                    </form>
                  }
                  {/* <div className="mt-4">
                    <a href="#!" className="btn-theme bg-yellow text-center d-block">
                      Request A Refund
                    </a>
                  </div> */}
                  {/* <div className="mt-4">
						<a href="#" className="btn-theme bg-purple text-center d-block">Request A Credit</a>
					</div> */}
                </div>
                <div className="px-3 py-3 border-bottom">
                  <p className="text-purple fw-500 fs-5 mb-3">Upcoming Classes</p>
                  <div className="class_op_price">
                    <ul>
                      {refundList?.cancel_meeting?.length > 0 ? (
                        (() => {
                          const sortedMeetings = [...refundList.cancel_meeting].sort((a, b) => new Date(a.date) - new Date(b.date));
                          const firstMeeting = sortedMeetings[0];
                          const lastMeeting = sortedMeetings[sortedMeetings.length - 1];

                          if (firstMeeting.activity_length === "Multi-Day") {
                            const startDateTime = dayjs(firstMeeting.date + ' ' + firstMeeting.start_time, 'YYYY-MM-DD hh:mm A');
                            console.log('startDateTime :', startDateTime);
                            const endDateTime = dayjs(lastMeeting.date + ' ' + lastMeeting.end_time, 'YYYY-MM-DD hh:mm A');
                            console.log('endDateTime :', endDateTime);
                            const meetingIds = sortedMeetings.map(meeting => meeting.meeting_id);
                            return (
                              <li>
                                Class date: {startDateTime.format('dddd, MMMM D')} {startDateTime.format('h:mm A')} To {endDateTime.format('dddd, MMMM D')} {endDateTime.format('h:mm A')}
                                <button
                                  onClick={() => handleCancel(firstMeeting?.enrolled_id, meetingIds, firstMeeting.activity_length)}
                                  style={styles.cancelButton}
                                >
                                  X
                                </button>
                              </li>
                            );
                          } else {
                            return sortedMeetings.map((pData, index) => (
                              <li key={index}>
                                Class date: {pData.meeting_date} {dayjs(pData.date + ' ' + pData.start_time, 'YYYY-MM-DD hh:mm A').format('h:mm')}-{dayjs(pData.date + ' ' + pData.end_time, 'YYYY-MM-DD hh:mm A').format('h:mm A')}
                                <button
                                  onClick={() => handleCancel(pData.enrolled_id, [pData.meeting_id])}
                                  style={styles.cancelButton}
                                >
                                  X
                                </button>
                              </li>
                            ));
                          }
                        })()
                      ) : (
                        <h4 className="d-flex justify-content-center" style={{ color: "#570861", fontSize: '18px' }}>
                          No data Found
                        </h4>

                        
                      )}
                    </ul>
                    {/* <a href="#" className="text-purple text-decoration-underline mb-4 me-2">
                      Request A Refund
                    </a> */}
                  </div>


                </div>
                {/*end*/}
                {/*start*/}
                <div className="px-3 py-3 border-bottom">
                  <div>
                    {/* <form className="custom_form">
                      <p className="text-purple fw-500 fs-5 mb-2">Pause Class</p>
                      <div className="d-flex justify-content-between">

                        <div className="form-group mb-4 col-4">
                          <label className="mb-2">From Date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Start"
                            name
                          />
                        </div>
                        <div className="form-group mb-4 col-4">
                          <label className="mb-2">To Date</label>
                          <input
                            type="date"
                            className="form-control"
                            // min={startDate}
                            placeholder="Start"
                            name
                          />
                        </div>
                      </div>
                    <p>You will not be charged during this period. The subscription will be stopped till this day.</p>
                    <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Pause Class</button>

                    </form> */}
                    <form className="custom_form" onSubmit={handleSubmit4(onSubmit4)}>
                      <p className="text-purple fw-500 fs-5 mb-2">Pause Class</p>
                      <div className="d-flex justify-content-between">
                        <div className="form-group mb-4 col-5">
                          <label className="mb-2">From Date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Start"
                            min={new Date().toISOString().split("T")[0]}
                            {...register4("start_date")}
                            onChange={handleStartDateChange}
                          />
                          {/* {errors4.start_date && <p>{errors4.start_date.message}</p>} */}
                          {errors4.start_date && <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors4.start_date.message}</span>
                          </p>}
                        </div>
                        <div className="form-group mb-4 col-5">
                          <label className="mb-2">To Date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="End"
                            {...register4("end_date")}
                            min={minEndDate}
                          />
                          {/* {errors4.end_date && <p>{errors4.end_date.message}</p>}  */}
                          {errors4.end_date && <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors4.end_date.message}</span>
                          </p>}
                        </div>
                      </div>
                      <p>You will not be charged during this period. The subscription will be stopped till this day.</p>
                      <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Pause Class</button>
                    </form>
                  </div>
                </div>
                {/*end*/}
                {/*start*/}
                <div className="px-3 py-3 border-bottom">
                  <p className="text-purple fw-500 fs-5">Stop Subscription</p>
                  <form className="custom_form mt-4" onSubmit={handleSubmit3(onSubmit3)}>
                    <div className="form-group mb-4">
                      <label className="mb-2">Reasons why a learner stopped the class</label>
                      <select className="form-select" aria-label="Default select example" {...register3('stop_class_reason')} onChange={handleSelectChange1}>
                        <option value="">Select Reason</option>
                        <option value="Teacher is not a good fit">Teacher is not a good fit</option>
                        <option value="Teacher was not prepared for the lesson">Teacher was not prepared for the lesson</option>
                        <option value="Learner is not interested anymore">Learner is not interested anymore</option>
                        <option value="Leaner is too busy">Leaner is too busy</option>
                        <option value="Conflicts with other classes">Conflicts with other classes</option>
                        <option value="Other Reason">Other Reason</option>
                      </select>
                      {/* {errors3.stop_class_reason && <p>{errors3.stop_class_reason.message}</p>} */}
                      {errors3.stop_class_reason && <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors3.stop_class_reason.message}</span>
                      </p>}
                    </div>
                    {selectedReason1 === "Other Reason" && (
                      <div className="form-group mb-4">
                        <label>Other Reason</label>
                        <input type="text" className="form-control" {...register3('other_reason')} />
                        {/* {errors3.other_reason && <p>{errors3.other_reason.message}</p>} */}

                      </div>
                    )}
                    {errors3.other_reason && <p className="font-bold col-red">
                      <span style={{ color: "red" }}>{errors3.other_reason.message}</span>
                    </p>}
                    <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Stop Subscription</button>

                  </form>
                  {/* <div>
                    <p className="text-purple fw-500 fs-5">Stop subscription for Mark Joseph to this class</p>
                  </div> */}
                </div>
                {/*end*/}
                {/*start*/}
                <div className="px-3 py-3">
                  <p>
                    Total payment made: <span>{currencySymbol}{totalAmount} {currencyCode}</span>
                  </p>
                </div>
                {/*end*/}
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
      </div>
    </>
  );
}


const styles = {
  cancelButton: {
    marginLeft: "10px",
    color: "#570861",
    // backgroundColor: "#FFC924", 

    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
  },
};