import React, { useEffect, useState } from "react";
import PFooter_Dashboard from "../../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../../Pinc/PHeader_Dashboard";
import { Helmet } from "react-helmet";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../../JqueryLoad/JqueryLoad";
import { Controller, useForm } from "react-hook-form";
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from "../../../../utils/common.service";
import {
  user_classroom_list,
  user_homework_list,
  user_assetment_list,
  user_quiz_list,
  user_discussion_list,
  parents_review_form,
  parents_feedback_form,
  parents_class_feedback_details,
  teacher_view_profile,
  update_read_status_enrollment,
} from "../../../../api/apiHandler";
import { uploadImageOnAWS } from "../../../aws/Service";
import Pagination from "./pagination";
import CommonTabs from "./commonTabs";
import dayjs from "dayjs";
import CommonSection from "./commonSection";
import CommonIntroVideo from "./commonIntroVideo";
import CommonNotificationList from "./commonNotificationList";
import CommonAttendanceCalender from "./commonAttendanceCalender";
import CommonMessageTab from "./commonMessageTab";
import CommonChatBox from "../../Message/commonChatBox";

export default function PMyCourseDetailsQuize() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMonth, setActiveMonth] = useState("");

  var classes_id = location?.state;
  console.log(classes_id, 'classes_id');

  var [FeedbackDetails, set_FeedbackDetails] = useState(null);

  var [HomeworkList, set_HomeworkList] = useState([]);

  var [AssetmentList, set_AssetmentList] = useState([]);

  var [QuizeList, set_QuizeList] = useState([]);

  // var [ClassroomList, set_ClassroomList] = useState(null);

  var [DiscussionList, set_DiscussionList] = useState([]);
  const [discussionId, setDiscussionId] = useState(null);
  console.log("🚀 ~ PMyCourseDetailsQuize ~ DiscussionList:", DiscussionList);

  const [profileImageState1, setProfileImageState1] = useState("");
  console.log("profileImageState1", profileImageState1);
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);

  const [teacherData, setTeacherData] = useState(null);
  const { teacher_id } = location?.state;
  const [parentState, setParentState] = useState('');


  const teacher_view_profile_details = () => {
    teacher_view_profile({ teacher_id })
      .then((resposnse) => {
        if (resposnse?.code == 1) {
          setTeacherData(resposnse?.data?.[0]);
        }
      })
      .catch((err) => {
        console.log(err, "58");
      });
  };

  useEffect(() => {
    teacher_view_profile_details();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleActiveMonth = (val) => {
    const currentDate = dayjs();
    const currentMonth = currentDate.format("MMM YYYY");
    setActiveMonth(val && typeof val === "string" ? val : currentMonth);
    setDiscussionId(null);
  };

  const handleImage1Change = async (image) => {
    if (image !== null) {
      setValue("attach1", image);
      const bufferImage = await convertToBase64(image);
      setImagePreview(bufferImage);
      clearErrors("attach1");
    }
  };

  // const handleImage1Change = async (image) => {
  //   if (image != null) {
  //     TOAST_SUCCESS("Your attachment has been uploaded!");
  //   }
  //   // console.log('image :', image);
  //   setValue("attach1", image);
  //   const bufferImage = await convertToBase64(image);
  //   setProfileImageState1(bufferImage);
  //   clearErrors("attach1");
  // }
  // console.log("hello", watch("attachments_data"))

  const handleImage2Change = async (image) => {
    if (image != null) {
      setValue("attach2", image);
      const bufferImage = await convertToBase64(image);
      setImagePreview2(bufferImage);
      clearErrors("attach2");
    }
  };

  const handleImage3Change = async (image) => {
    if (image != null) {
      setValue("attach3", image);
      const bufferImage = await convertToBase64(image);
      setImagePreview3(bufferImage);
      clearErrors("attach3");
    }
  };

  useEffect(() => {
    parents_class_feedback_Details();
  }, []);

  useEffect(() => {
    if (activeMonth) {
      user_quiz_list_details();
      user_assetment_list_details();
      user_homework_list_details();
      user_discussion_list_details();
    }
  }, [activeMonth]);

  const parents_class_feedback_Details = () => {
    parents_class_feedback_details({ class_id: classes_id.class_id, class_type: "quiz" }).then((resposnse) => {
      console.log(resposnse, "parents_class_feedback_details");
      if (resposnse?.code == 1) {
        set_FeedbackDetails(resposnse?.data);
        console.log(resposnse?.data);
      } else {
        set_FeedbackDetails([]);

        console.log("data not found");
      }
    });
  };

  const user_homework_list_details = () => {
    user_homework_list({ class_id: classes_id.class_id, class_type: "quiz", month_wise: activeMonth?.toLowerCase() }).then((resposnse) => {
      // console.log("classes_id-----",classes_id.class_id)

      // console.log(resposnse, "user_homework_list_details")

      if (resposnse?.code == 1) {
        set_HomeworkList(resposnse?.data);
      } else {
        set_HomeworkList([]);
        console.log("data not found");
      }
    });
  };

  const user_assetment_list_details = () => {
    user_assetment_list({ class_id: classes_id.class_id, class_type: "quiz", month_wise: activeMonth?.toLowerCase() }).then((resposnse) => {
      // console.log("classes_id-----",classes_id.class_id)

      // console.log(resposnse, "user_assetment_list_details")

      if (resposnse?.code == 1) {
        set_AssetmentList(resposnse?.data);
      } else {
        set_AssetmentList([]);
        console.log("data not found");
      }
    });
  };

  const user_quiz_list_details = () => {
    user_quiz_list({ class_id: classes_id.class_id, month_wise: activeMonth?.toLowerCase() }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_QuizeList(resposnse?.data);
      } else {
        set_QuizeList([]);
        console.log("data not found");
      }
    });
  };

  const user_discussion_list_details = () => {
    user_discussion_list({ class_id: classes_id.class_id, class_type: "quiz", month_wise: activeMonth?.toLowerCase() }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_DiscussionList(resposnse?.data);
      } else {
        set_DiscussionList([]);
        console.log("data not found");
      }
    });
  };

  // validation for review form
  const validationSchema = yup.object().shape({
    program_name: yup.string().required("Program name is required"),
    review: yup.string().required("Review is required"),
    rating: yup.string().required("Rating is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  // const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
  //   resolver: yupResolver(schema)
  // });
  console.log("errors of review form :", errors);

  let onSubmit = async (data) => {
    console.log("data :", data);

    let submitData = {
      class_id: classes_id.class_id,
      class_type: "club",
      program_name: data.program_name,
      review: data.review,
      rating: "4",
      comments_student_scholars: data.comments_student_scholars || "",
      comments_orgainization: data.comments_orgainization || "",
    };

    parents_review_form(submitData).then((res) => {
      console.log("submitData", submitData);
      // return
      console.log("res :", res);
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        // navigate('/teachers_my_class');
      } else {
        TOAST_ERROR(res.message);
      }
    });
    reset();
  };

  const [selectedRating, setSelectedRating] = useState(0);

  const handleRatingChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedRating(selectedValue);
  };

  const getRatingDescription = (rating) => {
    switch (rating) {
      case 5:
        return "Excellent";
      case 4:
        return "Good";
      case 3:
        return "Average";
      case 2:
        return "Satisfactory";
      case 1:
        return "Needs Improvement";
      default:
        return "";
    }
  };

  // validation for feedback form
  const validationSchema2 = yup.object().shape({
    class_link: yup.string().required("Please enter class link").url("Please enter a valid URL for the class link"),
    time_of_class: yup.string().required("Please select the time of class"),
    date: yup.string().required("Please select the date"),
    feedback_type: yup.string().required("Please select the feedback type"),
    feedback_details: yup.string().required("Please enter the feedback details"),
  });

  const formOptions2 = { resolver: yupResolver(validationSchema2) };
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    control,
    setValue,
    clearErrors,
    formState: { errors: errors2 },
  } = useForm(formOptions2);
  console.log("errors of feedback form :", errors2);

  let onSubmit2 = async (data) => {
    let attachment1 = data?.attach1;

    attachment1 = await uploadImageOnAWS(attachment1, "Feedback_Image");

    let attachment2 = data?.attach2;

    attachment2 = await uploadImageOnAWS(attachment2, "Feedback_Image");

    let attachment3 = data?.attach3;

    attachment3 = await uploadImageOnAWS(attachment3, "Feedback_Image");

    // console.log(coupon_data).
    let submitData1 = {
      class_id: "48",
      class_link: data.class_link,
      time_of_class: data.time_of_class,
      date: data.date,
      feedback_type: data.feedback_type,
      feedback_details: data.feedback_details,
    };

    parents_feedback_form(submitData1).then((res) => {
      console.log("submitData1", submitData1);
      // return
      console.log("res :", res);
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        // navigate('/teachers_my_class');
      } else {
        TOAST_ERROR(res.message);
      }
    });
    reset2();
  };

  const handleUpdateReadStatus = async (activity_id, activity_type, is_read) => {
    try {
      if (!is_read)
        await update_read_status_enrollment({
          activity_id,
          activity_type,
        });
    } catch (error) {
      TOAST_ERROR(error.message);
    }
  };

  const updateParentState = (newValue) => {
    setParentState(newValue);
  };


  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>

        <script>
          {`
  var ctx = document.getElementById("myChart").getContext('2d');

	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});




var ctx = document.getElementById("myChart1").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



var ctx = document.getElementById("myChart2").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



	var ctx = document.getElementById("myChart3").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart4").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart5").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});
  `}
        </script>
      </Helmet>
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <CommonSection teacherData={teacherData} />
        <div className="class-content pt-lg-5">
          <CommonIntroVideo />
          <CommonTabs handleActiveMonth={handleActiveMonth} updateParentState={updateParentState}/>
          {/*start-content*/}
          <div className="tab-content" id="pills-tabContent">
            {/*----------------------------------Tab1-------------------------------------------------*/}

            {/*----------------------------------Tab2-------------------------------------------------*/}
            <div className="tab-pane fade active show" id="pills-Homework" role="tabpanel" aria-labelledby="pills-Homework-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    {HomeworkList.length > 0 ? (
                      HomeworkList.map((data, index) => (
                        <div key={index} className="card review_box mb-4">
                          <div className="review_top">
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">
                                    Topic:{data.topic}
                                    {data?.is_read == 0 && <span className="green_dots position-absolute" />}
                                  </span>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: {data.Total_duration}</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>{data.full_name}</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Descriptions:{data.description}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <Link
                                  to={`/my_courses_details_quizzes/${data.id}?type=homework`}
                                  className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center"
                                  onClick={() => handleUpdateReadStatus(data.id, "homework", data?.is_read)}
                                >
                                  View Details
                                </Link>
                                <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">
                                  Upload An Assignment
                                </a>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500"> Day Left : {data.Days_Left}</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">
                                      {" "}
                                      <span className="text-dif-black">Posted On:</span> {data.Posted_On}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">
                                      <span className="text-dif-black">Deadline:</span> {data.Deadline}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ color: "purple" }}>
                        <strong>Data not found</strong>
                      </p>
                    )}
                  </div>
                  {/*start-pagination*/}
                  <Pagination handleActiveMonth={handleActiveMonth} activeMonth={activeMonth} />
                </div>
                <CommonNotificationList />
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab3-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Assignments" role="tabpanel" aria-labelledby="pills-Assignments-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack">
                    {AssetmentList.length > 0 ? (
                      AssetmentList.map((data, index) => (
                        <div className="card review_box mb-4" key={index}>
                          <div className="review_top">
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">
                                    Topic:{data.topic} {data?.is_read == 0 && <span className="green_dots position-absolute" />}
                                  </span>
                                  <li>
                                    <span className="text-purple fw-500"> Score : {data.total_score}/100</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: {data.Total_duration}</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>{data.full_name}</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Description:{data.description}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <Link
                                  to={`/my_courses_details_quizzes/${data.id}?type=assessment`}
                                  className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center"
                                  onClick={() => handleUpdateReadStatus(data.id, "assetment", data?.is_read)}
                                >
                                  View Details
                                </Link>
                                <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3 text-center">
                                  Upload An Assignment
                                </a>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">Day Left : {data.Days_Left}</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">
                                      {" "}
                                      <span className="text-dif-black">Posted On:</span> {data.Posted_On}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">
                                      <span className="text-dif-black">Deadline:</span> {data.Deadline}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ color: "purple" }}>
                        <strong>Data not found</strong>
                      </p>
                    )}
                    {/* <br></br>
                    <br></br>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          <div className="list_btw_dots mb-3">
                            <ul className="d-xl-flex align-items-center">
                              <span className="fw-600 position-relative">Collecting Moodboard from Art.</span>
                            </ul>
                          </div>
                          <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                            <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          </div>
                        </div>
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mb-4 flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3 px-4">
                              Submited
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                              <li>
                                <span className="text-purple fw-500">12:00 PM</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">
                                  <span className="text-dif-black">Posted On:</span> 25 March 2022 5:00 PM
                                </span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">
                                  <span className="text-dif-black">Deadline:</span> 25 March 2022 5:00 PM
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="remark mb-3 bg-dark-gray border-radius-10 d-flex align-items-center justify-content-between">
                          <p className="font-14">Your Assignment Is not Done Properly So you Have to Reupload it in 6 Following Days.</p>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center">
                              <li>
                                <span className="text-danger fw-500">Remark</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          <div className="button d-sm-flex align-items-center">
                            <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                              View Details
                            </a>
                            <a href="#" className="btn-theme bg-yellow d-inline-block ms-sm-3 px-4">
                              Reupload
                            </a>
                          </div>
                          <div className="list_btw_dots mb-xl-0 mb-4">
                            <ul className="d-sm-flex align-items-center">
                              <li>
                                <span className="text-danger fw-500">30 MInutes</span>
                              </li>
                              <li>
                                <span className="text-purple fw-500">
                                  <span className="text-dif-black">Posted On:</span> 25 March 2022 5:00 PM
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/*start-pagination*/}
                  <Pagination handleActiveMonth={handleActiveMonth} activeMonth={activeMonth} />
                </div>
                <CommonNotificationList />
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab4-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-quizzes" role="tabpanel" aria-labelledby="pills-quizzes-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack mt-5">
                    {QuizeList.length > 0 ? (
                      QuizeList.map((data, index) => (
                        <div className="card review_box mb-4" key={index}>
                          <div className="review_top">
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">
                                    Title {data.title} {data?.is_read == 0 && <span className="green_dots position-absolute" />}
                                  </span>
                                  <li>
                                    <span className="text-purple fw-500">
                                      Score : {data?.earn_score}/ {data?.total_points}{" "}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: {data.duration}</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>{data.full_name}</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">{data.summary}.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                {data.is_submit == 0 ? (
                                  <Link
                                    to="/quiz-info"
                                    onClick={() => handleUpdateReadStatus(data.id, "quiz", data?.is_read)}
                                    state={{ id: data?.id }}
                                    className="btn-theme px-3 d-inline-block text-center"
                                  >
                                    Start Quiz
                                  </Link>
                                ) : (
                                  <>
                                    <Link
                                      to="/quiz-review"
                                      state={{ quiz_id: data?.id, totalQuestions: data?.total_questions }}
                                      className="btn-theme mb-sm-0 text-center mb-3 bg-yellow d-inline-block"
                                    >
                                      Completed Quiz
                                    </Link>
                                    <Link to="/certificates_list" className="btn-theme px-3 ms-sm-3 d-inline-block text-center">
                                      Show Certificate
                                    </Link>
                                  </>
                                )}
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-purple fw-500">
                                      {" "}
                                      <span className="text-dif-black">Posted On:</span>
                                      {data.Posted_On}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">
                                      <span className="text-dif-black">Deadline:</span>
                                      {data.Deadline ? data.Deadline : "No deadline set"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ color: "purple" }}>
                        <strong>Data not found</strong>
                      </p>
                    )}
                  </div>
                  {/*start-pagination*/}
                  <Pagination handleActiveMonth={handleActiveMonth} activeMonth={activeMonth} />
                </div>
                <CommonNotificationList />
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab5-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Discussion" role="tabpanel" aria-labelledby="pills-Discussion-tab">
              {/*start*/}

              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  {!discussionId ? (
                    <div className="review_dack discussion_divs mt-5">
                      {DiscussionList.length > 0 ? (
                        DiscussionList.map((discussion, index) => (
                          <div className="card review_box mb-4" key={index}>
                            <div className="review_top">
                              <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                <div className="list_btw_dots mb-3">
                                  <ul className="d-xl-flex align-items-center">
                                    <span className="pe-sm-4 fw-600 position-relative">
                                      1. Collecting Moodboard from Art.{" "}
                                      {discussion?.is_read == 0 && <span className="green_dots position-absolute" />}
                                    </span>
                                    <li>
                                      <span className="text-purple fw-500"> Score : {discussion.score}/30</span>
                                    </li>
                                    <li>
                                      <span className="text-purple fw-500">Total Duration: {discussion.duration}</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                  <span>Created By</span>:&nbsp;<span>{discussion.created_by}</span>
                                </div>
                              </div>
                              <div className="review_text row mb-4">
                                <div className="col-lg-8">
                                  <p className="fs-6">Description:{discussion.description}.</p>
                                </div>
                              </div>
                              <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                <div className="button d-sm-flex align-items-center">
                                  <button
                                    onClick={() => setDiscussionId(discussion.id)}
                                    className="discussion_btn btn-theme px-3 d-inline-block text-center"
                                  >
                                    Start Discussion
                                  </button>
                                </div>
                                <div className="list_btw_dots mb-xl-0 mb-4">
                                  <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                    <li>
                                      <span className="text-danger fw-500">Closed</span>
                                    </li>
                                    <li>
                                      <span className="text-purple fw-500">
                                        <span className="text-dif-black">Posted On:</span> {discussion.Posted_On}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="text-purple fw-500">
                                        <span className="text-dif-black">Deadline:</span> {discussion.Deadline}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          //    <div className="card review_box mb-4">
                          //    <div className="review_top">
                          //      <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                          //        <div className="list_btw_dots mb-3">
                          //          <ul className="d-xl-flex align-items-center">
                          //            <span className="fw-600">2. Collecting Moodboard from Art.</span>
                          //          </ul>
                          //        </div>
                          //        <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                          //          <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                          //        </div>
                          //      </div>
                          //      <div className="review_text row mb-4">
                          //        <div className="col-lg-8">
                          //          <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                          //        </div>
                          //      </div>
                          //      <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                          //        <div className="button d-sm-flex align-items-center">
                          //          <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">Start Discussion</a>
                          //        </div>
                          //        <div className="list_btw_dots mb-xl-0 mb-4">
                          //          <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                          //            <li>
                          //              <span className="text-success fw-500">Opened</span>
                          //            </li>
                          //            <li>
                          //              <span className="text-purple fw-500"><span className="text-dif-black">Posted On:</span> 25 March 2022  5:00 PM</span>
                          //            </li>
                          //            <li>
                          //              <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                          //            </li>
                          //          </ul>
                          //        </div>
                          //      </div>
                          //    </div>
                          //  </div>
                        ))
                      ) : (
                        <p style={{ color: "purple" }}>
                          <strong>Data not found</strong>
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="discussion_panel_board">
                      <CommonChatBox teacherData={teacherData} discussionId={discussionId} />
                      {/* <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="msg-head">
                            <div>
                              <span>Live Discussion Board</span>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="msg-body">
                              <div className="d-sm-flex align-items-center mb-4 justify-content-between">
                                <div className="discussion_sec mb-sm-0 mb-3">
                                  <div className=" d-flex align-items-center">
                                    <div className="discss_img">
                                      <img src="./assets/images/user/user4.png" alt="user3" />
                                    </div>
                                    <div className="discss_ctn ms-3">
                                      <span className="text-gray">
                                        Celina <span className="text-dif-black">Helllo how are!</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span className="text-gray">25 March</span>
                                  <span className="d-block text-dif-black">10:10 PM </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="send-box">
                            <form action className="position-relative">
                              <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                              <a href="#" className="send_icons">
                                <i className="fa fa-paper-plane" aria-hidden="true" />
                              </a>
                              <div className="send-btns">
                                <div className="attach">
                                  <div className="button-wrapper position-relative">
                                    <span className="label">
                                      <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                                          fill="#131313"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="file"
                                      name="upload"
                                      id="upload"
                                      className="upload-box"
                                      placeholder="Upload File"
                                      aria-label="Upload File"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )}
                  {/*start-pagination*/}
                  <Pagination handleActiveMonth={handleActiveMonth} activeMonth={activeMonth} />
                </div>
                <CommonNotificationList />
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab6-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Recordings" role="tabpanel" aria-labelledby="pills-Recordings-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="review_dack mt-5">
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <ul>
                              <li className="mb-3">
                                <a href="#!" className="text-decoration-underline text-purple fw-600 ">
                                  Mon, Mar 20, 7:29 – 8:26 PM Eastern
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Teacher Joined : <span>Celina Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Joined : <span>Julie Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Absent : <span>Julie Mark</span>
                                </a>
                              </li>
                            </ul>
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="button safety_btn d-sm-flex align-items-center">
                          <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                            Watch Recording
                          </a>
                          <a
                            href="#"
                            className="btn-theme bg-yellow d-inline-block ms-sm-3"
                            data-bs-toggle="modal"
                            data-bs-target="#safety_tips_only_one"
                          >
                            <span className="me-3">
                              <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z"
                                  fill="#570861"
                                />
                                <path
                                  d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z"
                                  fill="#570861"
                                />
                              </svg>
                            </span>
                            Safety Tips
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card review_box mb-4">
                      <div className="review_top">
                        <div className="review_text row mb-4">
                          <div className="col-lg-8">
                            <ul>
                              <li className="mb-3">
                                <a href="#!" className="text-decoration-underline text-purple fw-600 ">
                                  Mon, Mar 20, 7:29 – 8:26 PM Eastern
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Teacher Joined : <span>Celina Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Joined : <span>Julie Mark</span>
                                </a>
                              </li>
                              <li className="mb-3">
                                <a href="#!" className="fw-600 ">
                                  Learner(s) Absent : <span>Julie Mark</span>
                                </a>
                              </li>
                            </ul>
                            <p className="fs-6">
                              Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of
                              problems.
                            </p>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">
                            Watch Recording
                          </a>
                          <a
                            href="#"
                            className="btn-theme bg-yellow d-inline-block ms-sm-3"
                            data-bs-toggle="modal"
                            data-bs-target="#safety_tips_only_one"
                          >
                            <span className="me-3">
                              <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.11543 6.6897C7.01414 6.6897 5.30469 8.39915 5.30469 10.5002C5.30469 12.6016 7.01414 14.311 9.11543 14.311C11.2166 14.311 12.926 12.6016 12.926 10.5003C12.926 8.39911 11.2166 6.6897 9.11543 6.6897ZM11.3779 9.78267L8.64789 12.5126C8.59345 12.5672 8.52875 12.6105 8.45753 12.64C8.3863 12.6695 8.30995 12.6847 8.23285 12.6846C8.15578 12.6847 8.07945 12.6695 8.00825 12.64C7.93705 12.6105 7.8724 12.5672 7.81801 12.5126L6.77473 11.4693C6.54555 11.2403 6.54555 10.8688 6.77473 10.6396C7.00375 10.4104 7.37531 10.4104 7.60449 10.6396L8.23285 11.2679L10.5481 8.9529C10.7771 8.72368 11.1487 8.72368 11.3779 8.9529C11.607 9.18192 11.607 9.55349 11.3779 9.78267Z"
                                  fill="#570861"
                                />
                                <path
                                  d="M17.6128 5.9265L17.6122 5.91096C17.6031 5.7039 17.5971 5.49671 17.5942 5.28947C17.5778 4.1867 16.7012 3.27452 15.5983 3.21291C13.2988 3.08456 11.52 2.33475 10.0001 0.853271L9.98708 0.840927C9.4913 0.386358 8.74349 0.386358 8.24755 0.840927L8.23459 0.853271C6.71467 2.33475 4.9358 3.08456 2.63632 3.21303C1.53358 3.27452 0.656789 4.18674 0.640461 5.28959C0.637595 5.49679 0.631605 5.70394 0.622492 5.91096L0.621555 5.94709C0.576828 8.29193 0.521281 11.2102 1.49753 13.8589C2.03436 15.3155 2.84733 16.5817 3.91377 17.6225C5.12838 18.8078 6.71924 19.7488 8.64216 20.4193C8.70546 20.4413 8.7702 20.4589 8.83591 20.4721C8.92931 20.4907 9.02333 20.5 9.11732 20.5C9.2113 20.5 9.30544 20.4907 9.39868 20.4721C9.46467 20.4588 9.52967 20.4411 9.59321 20.4189C11.5138 19.7473 13.1031 18.8058 14.3166 17.6207C15.3826 16.5796 16.1955 15.3131 16.7328 13.8562C17.7127 11.1995 17.6573 8.27572 17.6128 5.9265ZM9.11701 15.4846C6.36857 15.4846 4.13272 13.2487 4.13272 10.5003C4.13272 7.75189 6.36861 5.51604 9.11701 5.51604C11.8653 5.51604 14.1013 7.75189 14.1013 10.5003C14.1013 13.2488 11.8653 15.4846 9.11701 15.4846Z"
                                  fill="#570861"
                                />
                              </svg>
                            </span>
                            Safety Tips
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*start-pagination*/}
                  <Pagination handleActiveMonth={handleActiveMonth} activeMonth={activeMonth} />
                </div>
                <CommonAttendanceCalender>
                  <CommonNotificationList isRecordingTab={true} />
                </CommonAttendanceCalender>
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab7-------------------------------------------------*/}
            <CommonMessageTab teacherData={teacherData} />
            {/* <div className="tab-pane fade" id="pills-Messages" role="tabpanel" aria-labelledby="pills-Messages-tab">
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row">
                          <div className="col-8">
                            <div className="d-flex align-items-center">
                              <span className="chat-icon">
                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
                                    fill="#131313"
                                  />
                                </svg>
                              </span>
                              <div className="flex-shrink-0 position-relative">
                                <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
                                <span className="active" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Mehedi Hasan</h3>
                                <p>Active Now</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <ul className="moreoption">
                              <li className="navbar nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                </a>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Action
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Another action
                                    </a>
                                  </li>
                                  <li>
                                    <hr className="dropdown-divider" />
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Something else here
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black">
                                    {" "}
                                    Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday
                                    you have gave a pen This very nice{" "}
                                  </p>
                                  <span className="time text-end">10:06 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:16 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">
                                    yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice
                                  </p>
                                  <span className="time text-start">10:20 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:26 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="sender">
                              <div className="d-flex align-items-start">
                                <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                <div>
                                  <p className="text-dif-black"> Hey, Are you there? </p>
                                  <span className="time text-end">10:32 am</span>
                                </div>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">How are you?</p>
                                  <span className="time text-start">10:35 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li>
                              <div className="divider">
                                <h6>Today</h6>
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black"> yes, tell me</p>
                                  <span className="time text-start">10:36 am</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                            <li className="repaly">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <p className="text-dif-black">yes... on it</p>
                                  <span className="time text-start">junt now</span>
                                </div>
                                <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="send-box">
                        <form action className="position-relative">
                          <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                          <a href="#" className="send_icons">
                            <i className="fa fa-paper-plane" aria-hidden="true" />
                          </a>
                          <div className="send-btns">
                            <div className="attach">
                              <div className="button-wrapper position-relative">
                                <span className="label">
                                  <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                                      fill="#131313"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="file"
                                  name="upload"
                                  id="upload"
                                  className="upload-box"
                                  placeholder="Upload File"
                                  aria-label="Upload File"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <CommonNotificationList />
              </div>
            </div> */}
            {/*----------------------------------Tab8-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="mb-3">
                    <h2>Review form</h2>
                    <p>
                      Hi There. Let us know what you think of the class and teacher.
                      <br />
                      If you are taking in-person classes, let us know your thoughts on the location as well.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <form className="custom_form mt-4" onSubmit={handleSubmit(onSubmit)} action id>
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Program Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input type="text" className="form-control" name="program_name" {...register("program_name")} placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.program_name?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Comments to send to Student Scholars-Private(optional)</label>
                          <textarea
                            className="form-control"
                            name="comments_student_scholars"
                            {...register("comments_student_scholars")}
                            rows={6}
                            defaultValue={""}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Comments to send to the Organization/Teacher-Private(optional)</label>
                          <textarea
                            className="form-control"
                            name="comments_orgainization"
                            {...register("comments_orgainization")}
                            rows={6}
                            defaultValue={""}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Comments Posted to the Public<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea className="form-control" name="review" {...register("review")} rows={6} defaultValue={""} />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.review?.message}</span>
                          </p>
                        </div>
                        <div>
                          <span>Select Number of stars</span>
                          <div id="full-stars-example-two">
                            <div className="rating-group">
                              {[1, 2, 3, 4, 5].map((value) => (
                                <React.Fragment key={value}>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id={`rating3-${value}`}
                                    value={value}
                                    {...register("rating")}
                                    type="radio"
                                  />
                                  <label aria-label={`${value} star`} className={`rating__label`} htmlFor={`rating3-${value}`}>
                                    <i className={`rating__icon ${value <= watch("rating") ? "rating__filled" : "rating__empty"} fa fa-star`} />
                                  </label>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                          <div className="bg-gray border-radius-10 p-3">
                            <ul>
                              <li className="text-gray">
                                <span>5 Stars - Excellent</span>
                              </li>
                              <li className="text-gray">
                                <span>4 Stars - Good</span>
                              </li>
                              <li className="text-gray">
                                <span>3 Stars - Average</span>
                              </li>
                              <li className="text-gray">
                                <span>2 Stars - Satisfactory</span>
                              </li>
                              <li className="text-gray">
                                <span>1 Star - Needs Improvement</span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* <div className="your-rating-component">
                        <span>Select Number of stars<span style={{ color: 'red' }}>*</span></span>
                        <div id="full-stars-example-two">
                          <div className="rating-group">
                            {[1, 2, 3, 4, 5].map((value) => (
                              <React.Fragment key={value}>
                                <input
                                  className="rating__input"
                                  name="rating"
                                  id={`rating-${value}`}
                                  value={value}
                                  type="radio"
                                  {...register("rating")}
                                  onChange={handleRatingChange}
                                  checked={selectedRating === value}
                                />
                              
                                <label aria-label={`${value} star`} className="rating__label" htmlFor={`rating-${value}`}>
                                  <i className="rating__icon rating__icon--star fa fa-star" />
                                </label>
                          
                              </React.Fragment>
                            ))}

                          </div>
                            <p className='mt-2 font-bold col-red'>
                            <span style={{ color: 'red' }}>{errors.rating?.message}</span>
                          </p>
                          </div>
                        <div className="bg-gray border-radius-10 p-3">
                          <ul>
                            <li className="text-gray"><span>{selectedRating} Stars - {getRatingDescription(selectedRating)}</span></li>
                          </ul>
                        </div>
                      </div> */}
                        <div className="col-lg-12">
                          <div className="button mt-4">
                            {/* <a href="kids_dashboard.php" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</a> */}
                            <button className="btn-theme bg-yellow text-center fw-600 d-block">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <CommonNotificationList />
              </div>
              {/*end*/}
            </div>
            {/*----------------------------------Tab9-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Progress" role="tabpanel" aria-labelledby="pills-Progress-tab">
              {/*start*/}
              <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                  <div className="button my-5 text-center">
                    <a href="#!" data-bs-toggle="modal" data-bs-target="#progrss-report" className="btn-theme bg-yellow d-inline-block">
                      Go To Your Progress Report
                    </a>
                  </div>
                </div>
                <CommonNotificationList />
              </div>
            </div>
            {/*----------------------------------Tab10-------------------------------------------------*/}
            <div className="tab-pane fade" id="pills-Feedback" role="tabpanel" aria-labelledby="pills-Feedback-tab">
              {/*start*/}
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <form className="custom_form mt-4" onSubmit={handleSubmit2(onSubmit2)} action id>
                    <div className="form-group mb-4">
                      <label className="mb-2">Teacher’s Name</label>
                      <input type="text" defaultValue={FeedbackDetails?.[0]?.full_name} className="form-control" name readOnly />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Name of Class</label>
                      <input
                        type="text"
                        className="form-control"
                        name="classname"
                        defaultValue={FeedbackDetails?.[0]?.title || ""} // Display the value of FeedbackDetails.title
                        // Make the input read-only if you only want to display the value
                        readOnly
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">URL link to the class</label>
                      <input type="text" className="form-control" name="class_link" {...register2("class_link")} />
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors2.class_link?.message}</span>
                      </p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Day and Time of Class</label>
                      <select className="form-select" aria-label="Default select example" name="time_of_class" {...register2("time_of_class")}>
                        <option selected>Lorem ipsum dolor sit amet</option>
                        <option value={1}>Lorem ipsum dolor sit amet.</option>
                        <option value={2}>sed do eiusmod ut labore et dolore magna aliqua</option>
                        <option value={3}>sed do eiusmod ut labore et dolore.</option>
                      </select>
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors2.time_of_class?.message}</span>
                      </p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Date</label>
                      <select className="form-select" aria-label="Default select example" name="date" {...register2("date")}>
                        <option selected>Lorem ipsum dolor sit amet</option>
                        <option value={1}>Lorem ipsum dolor sit amet.</option>
                        <option value={2}>sed do eiusmod ut labore et dolore magna aliqua</option>
                        <option value={3}>sed do eiusmod ut labore et dolore.</option>
                      </select>
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors2.date?.message}</span>
                      </p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Type</label>
                      <select className="form-select pe-5" aria-label="Default select example" name="feedback_type" {...register2("feedback_type")}>
                        <optgroup label="Attendance">
                          <option>Teacher Cancelling remaining session(s) after class begins</option>
                          <option>Multiple teacher cancellations 24 hours before a class begins</option>
                          <option>Missing or failing to attend Session(s)</option>
                          <option>Repeatedly arriving late or leaving early</option>
                        </optgroup>
                        <optgroup label="Quality of Classes">
                          <option>Consistently providing poor-quality class experiences</option>
                          <option> Repeatedly failing to achieve pre-set teaching objectives for a class</option>
                          <option> Using inappropriate language or gestures Quality of Services</option>
                          <option>Not providing regular feedback or answering questions</option>
                          <option>Not responding to messages from learners who are enrolled in a class</option>
                          <option>Failing to refund</option>
                        </optgroup>
                        <optgroup label="Strictly Prohibited">
                          <option>Disregarding learner or parent safety or privacy and requesting personal information</option>
                          <option>Using an account or unapproved third party platform to teach live sessions, rather than using the services</option>
                          <option>Requesting payment (other than those collected through StudentScholars)</option>
                          <option>Requesting student’s phone number and email to contact them privately.</option>
                          <option>Sharing your phone number and email with the student/teacher for them to contact you privately.</option>
                        </optgroup>
                      </select>
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors2.feedback_type?.message}</span>
                      </p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Please describe the feedback in detail</label>
                      <textarea className="form-control" rows={6} defaultValue={""} name="feedback_details" {...register2("feedback_details")} />
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors2.feedback_details?.message}</span>
                      </p>
                    </div>
                    <div>
                      <label className="mb-2">Attachments</label>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4 file_upload">
                            <Controller
                              name="attach1"
                              control={control}
                              defaultValue={null}
                              rules={{ required: "Profile image is required" }}
                              render={({ field }) => (
                                <>
                                  <div className="file_update position-relative">
                                    <input
                                      type="file"
                                      id="attach1"
                                      name="attach1"
                                      onChange={(e) => handleImage1Change(e.target.files[0])}
                                      className="form-control"
                                    />
                                    <span className="file_icons position-absolute">
                                      {!imagePreview ? (
                                        <svg width={22} height={24} viewBox="0 0 22 24" id="svgg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                            fill="#131313"
                                            fillOpacity="0.4"
                                          />
                                        </svg>
                                      ) : (
                                        <img src={imagePreview} width={100} />
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <Controller
                              name="attach2"
                              control={control}
                              defaultValue={null}
                              rules={{ required: "Profile image is required" }}
                              render={({ field }) => (
                                <>
                                  <div className="file_update position-relative">
                                    <input type="file" onInput={(e) => handleImage2Change(e.target.files[0])} className="form-control" />
                                    <span className="file_icons position-absolute">
                                      {!imagePreview2 ? (
                                        <svg width={22} height={24} viewBox="0 0 22 24" id="svg2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                            fill="#131313"
                                            fillOpacity="0.4"
                                          />
                                        </svg>
                                      ) : (
                                        <img src={imagePreview2} width={100} />
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <Controller
                              name="attach3"
                              control={control}
                              defaultValue={null}
                              rules={{ required: "Profile image is required" }}
                              render={({ field }) => (
                                <>
                                  <div className="file_update position-relative">
                                    <input type="file" onInput={(e) => handleImage3Change(e.target.files[0])} className="form-control" />
                                    <span className="file_icons position-absolute">
                                      {!imagePreview3 ? (
                                        <svg width={22} height={24} viewBox="0 0 22 24" id="svg3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                            fill="#131313"
                                            fillOpacity="0.4"
                                          />
                                        </svg>
                                      ) : (
                                        <img src={imagePreview3} width={100} />
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div>
                        <p className="text-gray fw-600">StudentScholars Support team will get back to you.</p>
                      </div>
                      <div className="button mt-4">
                        <button className="btn-theme bg-yellow text-center fw-600 d-block">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
          {/*end-content*/}
        </div>
      </main>
      {/* <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>
      </Helmet> */}
      <PFooter_Dashboard />
    </>
  );
}
