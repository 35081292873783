import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { teacher_quiz_question_delete, teacher_quiz_question_list } from '../../../../api/apiHandler'
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import Swal from 'sweetalert2';
import { TOAST_ERROR,TOAST_SUCCESS } from '../../../../utils/common.service';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
export default function OQueList() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  const location = useLocation();

  //  console.log('location.state',location.state)
  //  var quiz_id = location.state;

  const navigate = useNavigate();
  var [data, setData] = useState([]);

  console.log("data", data)

  const [storedLocation, setStoredLocation] = useState(null);

  // Effect to retrieve the stored location from local storage
  useEffect(() => {
    const storedLocationString = localStorage.getItem('location');
    if (storedLocationString) {
      setStoredLocation(JSON.parse(storedLocationString));
      // Remove stored location from local storage after retrieving it
      // localStorage.removeItem('location');
    }
  }, []);

  function handleLinkClick() {
    // Remove stored location from local storage after clicking the link
    localStorage.removeItem('location');
  }

  useEffect(() => {

    teacherQuizQuestionListing();

  }, []);

  const teacherQuizQuestionListing = () => {
    teacher_quiz_question_list({ quiz_id: localStorage.getItem("quiz_id") }).then((response) => {
      console.log(response)

      if (response?.code == 1) {
        setData(response.data)
      } else {
        console.log('data not found')
      }
    });
  }

  const delete_quiz_question = (index, id) => {

    // alert(id)

    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        teacher_quiz_question_delete({ question_id: id }).then((response) => {
          if (response?.code == 1) {
            TOAST_SUCCESS(response.message);
            teacherQuizQuestionListing();

          } else {
            TOAST_ERROR(response.message);
          }
        })
      }
    })

  }


  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block"  >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className="row mb-4">
                  <div className="col-xl-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="text-purple text-end d-block fw-600">Step 2/2</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-xl-8">
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <h3 className="mb-4 text-capitalize">List Of Questions</h3>
                    <Link to="/organization-add-another-question" className="text-decoration-underline text-purple fw-600">+ Add another Question</Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-8">
                  {/*start*/}
                  {data?.map((question, index) => {
                    return (
                      <div key={index} className="list_of_quiz">
                        <ul className>
                          <li className="card_gray d-md-flex justify-content-between align-items-center">
                            <div className="d-md-flex align-items-center quiz_ques_box">
                              <h4>{index+1}</h4>
                              <span className="ms-md-1 mb-md-0 mb-3 d-inline-block">{question.question}</span>
                            </div>
                            <ul className="edit_delete d-flex align-items-center justify-content-end">
                              <li>
                                <a onClick={() => (delete_quiz_question(index, question.id))} className>
                                  <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <Link to={`/organization-edit-question/${question.id}`} className>
                                  <svg width={20} height={20} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.55055 24.2832C3.83895 24.2832 3.89663 24.2543 4.15619 24.1966L9.34739 23.1584C9.89535 23.0142 10.4433 22.7546 10.8759 22.322L23.4501 9.7478C25.3824 7.81553 25.3824 4.49892 23.4501 2.56665L22.3831 1.44189C20.4508 -0.490394 17.1053 -0.490394 15.1731 1.44189L2.59883 14.045C2.19507 14.4487 1.90667 15.0255 1.76248 15.5735L0.666556 20.8224C0.522356 21.8029 0.810755 22.7546 1.50292 23.4468C2.05087 23.9948 2.85839 24.2832 3.55055 24.2832ZM4.53111 16.1214L17.1053 3.51837C17.9417 2.68201 19.4702 2.68201 20.2777 3.51837L21.3737 4.61428C22.3542 5.59484 22.3542 6.97916 21.3737 7.93088L8.82827 20.534L3.49287 21.428L4.53111 16.1214Z" fill="#570861" />
                                  </svg>
                                </Link>
                              </li>
                            </ul>
                          </li>

                        </ul>

                      </div>
                    )
                  })}
                  {/*end*/}
                  {/*start-pagination*/}
                  {/* <div className="custom_pagenation">
                    <ul className="d-flex align-items-center justify-content-center">
                      <li className="circle active">
                        <a href="#" className="text-purple fw-600">1</a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple fw-600">2</a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple fw-600">3</a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple fw-600">4</a>
                      </li>
                      <li className="mx-2">
                        <span>...</span>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple fw-600">13</a>
                      </li>
                    </ul>
                  </div> */}

                  {/*end-pagination*/}
                </div>

              </div>
              <div className="row mt-5">
              {storedLocation ? 
                <div className="col-lg-3">
                  <Link to="/organization-tech-detail" state={storedLocation?.state || {}}  onClick={handleLinkClick} className="btn-theme bg-yellow d-block text-center fw-600">Submit Quiz</Link>
                </div> :

                <div className="col-lg-3">
                  <Link to="/organization-all-quiz" className="btn-theme bg-yellow d-block text-center fw-600">Submit Quiz</Link>
                </div>
              }
              </div>
            </div>
            {/*end*/}
          </div>
        </div>
      </main>

      <OFooter_Dashboard />
    </>
  )
}
