// config.js
export const baseUrls = {
  kids: "/kids",
  parents: "/parents",
  organization: "/organization",
  teacher: "/teacher",
};
export const SOCKET_URL = "https://api.studentscholars.com:4225/";
// export const SOCKET_URL = "http://13.234.122.246:4225/";

export const SHARE_DETAIL_URL = "http://13.234.122.246/studentscholar"
export const Video_URL = "https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/video"
export const AI_URL = "sk-ovn9oF23x21bjQ2onmGSfdEGs9ERdg4rcnQ27PSyd-T3BlbkFJMAgAlwTKjw-9-ermnL_luEQx8M7YkvW9qiv7qm1TQA"
export const ENC_KEY = process.env.REACT_APP_KEY
export const ENC_IV = process.env.REACT_APP_IV

export const REACT_APP_BASE_URL = "http://99.79.109.5/studentscholar";
export const BASE_NAME = "/studentscholar";

// cd /opt/FileZilla3/bin/
