import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import { homework_list, homework_upload, learner_name_list, notification_listing } from '../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { uploadImageOnAWS } from '../../aws/Service';
import dayjs from 'dayjs';
import { htmlToText } from 'html-to-text';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

const TruncateText = ({ text, length }) => {
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  // Convert HTML to plain text
  const plainText = htmlToText(text || '', {
    wordwrap: false,
    ignoreImage: true,
    ignoreHref: true,
  });

  const truncatedText = truncateString(plainText, length);

  return (
    <p
      dangerouslySetInnerHTML={{ __html: truncatedText }}
      className="fs-6"
      style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
    />
  );
};

export default function Homework() {

  const location = useLocation();

  // var id = location?.state;
  // console.log('idHomework :', id);
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');

    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

    id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  } else if (location?.state) {
    var id = location?.state;
    console.log('Homework :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    // var type = location?.search?.split("=")[1];
    // console.log('type :', type);
    // var id = after
    // id.class = type
    // console.log('idtype :', id);

    // const queryParams = queryString.parse(location.search);
    // const type = queryParams.type;
    // const typeClass = queryParams?.type_class;
    // var id = after
    // id.class = type
    // id.type_class = typeClass

    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    var descryptedJson = decryptedData.split('?')
    const queryParams = queryString.parse(descryptedJson[1]);
    console.log('queryParams :', queryParams);
    const type = queryParams?.type;
    const typeClass = queryParams?.type_class;
    var id = {}
    var edit_class_id = descryptedJson[0]
    id.id = descryptedJson[0]
    id.class = type
    id.type_class = typeClass
  }

  const [data, setData] = useState([]);
  const [learnerData, setLearnerData] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [nextMonth, setNextMonth] = useState(currentDate.add(1, 'month'));
  const [previousMonth, setPreviousMonth] = useState(currentDate.subtract(1, 'month'));
  const [selectedMonth, setSelectedMonth] = useState(currentDate);
  const [UpdatedData, setUpdatedData] = useState(currentDate.format("MMM YYYY"));

  const NextMonthYear = (e) => {
    e.preventDefault();

    setCurrentDate((prevCurrentDate) => prevCurrentDate.add(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.add(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.add(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.add(1, 'month'));
  };

  const PreviousMonthYear = (e) => {
    e.preventDefault();
    setCurrentDate((prevCurrentDate) => prevCurrentDate.subtract(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.subtract(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.subtract(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.subtract(1, 'month'));
  }

  const PMonth = (e) => {
    setUpdatedData(e)
    console.log("XYZ", e)
  }

  const learner_listing = () => {
    // isLoader(true)

    learner_name_list({ activity_id: id.id, activity_type: id.class }).then((response) => {
      console.log("learner_name_list", response)
      // isLoader(false)

      if (response?.code == 1) {
        setLearnerData(response.data)
      } else {
        console.log('data not found')
      }
    });
  }

  const handleSelectedLearner = (data) => {
    console.log('data123 :', data);
    var main = JSON.parse(data);
    console.log('mainHome :', main);
    if (main != 0) {
      Homework_listing(main)
    } else {
      Homework_listing(0)
    }
  }

  const Homework_listing = (main) => {
    console.log('mainHome :', main);

    // isLoader(true)
    let Homework_data = {
      activity_id: id?.id,
      activity_type: id?.class,
      month_wise: UpdatedData.toLowerCase()
    }
    if (main != 0) {
      if (main.type == 'user') {
        Homework_data.userId = main.id
      } else {
        Homework_data.learnerId = main.id
      }
    }
    console.log('Homework_data :', Homework_data);

    homework_list(Homework_data).then((response) => {
      console.log('response :', response);
      // isLoader(false)
      if (response?.code == 1) {
        setData(response.data)
      } else {
        setData([])
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    learner_listing();
    Homework_listing(0);
  }, [UpdatedData]);

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({

  });
  const [profileImageState, setProfileImageState] = useState('')
  // console.log("profileImageState", profileImageState)

  const handleImageChange = async (image, homework_id) => {
    console.log('homework_id :', image);

    if (image != null) {
      setValue("attachments_data", image);

      clearErrors("attachments_data");

      var HomeworkDocuments = await uploadImageOnAWS(image, "Homework");
      console.log('HomeworkDocuments :', HomeworkDocuments);

      var HomeworkData = {
        homework_id: homework_id,
        upload_homework: HomeworkDocuments
      }
      console.log('HomeworkData', HomeworkData);
      homework_upload(HomeworkData).then((response) => {
        console.log("homework", response)
        // isLoader(false)

        if (response?.code == 1) {
          TOAST_SUCCESS("Your homework has been uploaded!");
        } else {
          TOAST_ERROR(response.message);
        }
      });

    } else {
      // TOAST_ERROR("Please select homework file");
    }
    // console.log('image :', image);

  }
  console.log("hello", watch("attachments_data"))

  const [notificationData, setNotificationData] = useState('');
  console.log('notificationData :', notificationData);

  const NotificationList = async () => {
    try {
      let send_data = {
        action_id: id?.id,
        action_type: id.class,
        teach_type: "homework"
      }
      const response = await notification_listing(send_data);
      console.log('response123 :', response);
      if (response?.code == 1) {
        setNotificationData(response.data)
      } else {
        setNotificationData([])
      }

    } catch (error) {
      console.log('error :', error);

    }
  }

  useEffect(() => {
    NotificationList();
  }, [])

  return (
    <>
      {/* <div className="tab-pane fade" id="pills-Homework" role="tabpanel" aria-labelledby="pills-Homework-tab">  */}
      {/*start*/}
      <div className="row">
        <div className="col-xl-9 mb-xl-0 mb-4">
          <div className="d-flex mb-4 justify-content-between align-items-center">
            <Link to="/add-homework" state={id} className="btn-theme fw-500 text-dif-black border-dark">+ Add Homework</Link>
            <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">

              <option value='0' selected >Learner’s Name</option>

              {learnerData?.map((student, index) => {
                const type = student?.learner_id ? 'learner' : 'user';
                const id = student?.learner_id || student?.user_id;
                const valueObj = JSON.stringify({ type, id });
                return (
                  <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                )
              })}
            </select>
          </div>
          <div className="review_dack">
            {data?.length > 0 ?
              data?.map((homework_data, index) => {
                // console.log('homework_data :', homework_data);
                return (

                  <div className="card review_box mb-4">
                    <div className="review_top">
                      <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                        <div className="list_btw_dots mb-3">
                          <ul className="d-xl-flex align-items-center">
                            <span className="pe-sm-4 fw-600 position-relative">{homework_data.topic} <span className="green_dots position-absolute" /></span>
                            <li>
                              <span className="text-purple fw-500">Total Duration: {homework_data.Total_duration} </span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                          <span>Created By</span>:&nbsp;<span>{homework_data.full_name}</span>
                        </div>
                      </div>
                      {/* <div className="review_text row mb-4">
                        <div className="col-lg-8">
                          <p className="fs-6">{homework_data.description}</p>
                        </div>
                      </div>
                      <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                        <Link to="/assesment-response" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Assesment</Link>
                      </div> */}

                      <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                        <div className="list_btw_dots mb-3">
                          <ul className="d-xl-flex align-items-center">
                            {/* <p className="fs-6">{homework_data.description}</p> */}
                            {/* <p dangerouslySetInnerHTML={{ __html: homework_data?.description }} className="fs-6" /> */}
                            <TruncateText text={homework_data?.description} length={25} className="fs-6" />


                          </ul>
                        </div>
                        <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                          <Link to="/homework-response" state={{ homework_id: homework_data?.id, id: id }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Homework</Link>
                        </div>
                      </div>
                      <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                        <div className="button d-sm-flex align-items-center">
                          <Link to="/view-details-page" state={{ id: homework_data?.id, type: "homework" }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</Link>
                          {/* <label htmlFor={`upload_homework_${homework_data.id}`}
                            className="btn-theme text-center bg-yellow d-inline-block ms-sm-3" >
                            Upload Homework
                            <input type="file" id={`upload_homework_${homework_data.id}`} onInput={(e) => handleImageChange(e.target.files[0], homework_data.id
                            )} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                          </label> */}
                        </div>
                        <div className="list_btw_dots mb-xl-0 mb-4">
                          <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                            <li>
                              <span className="text-danger fw-500">{homework_data.Days_Left}</span>
                            </li>
                            <li>
                              <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {homework_data.Posted_On}</span>
                            </li>
                            <li>
                              <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> {homework_data.Deadline}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
            }
          </div>
          {/*start-pagination*/}
          <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
            <ul className="pagination justify-content-evenly align-items-center border-radius-10">

              <li className="page-item">
                <button className="page-link text-dark border-start-0" style={{ "border": "0 !important" }} aria-label="Previous" onClick={(e) => PreviousMonthYear(e)}>
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                  </span>
                </button>
              </li>
              <li className={`page-item ${UpdatedData == previousMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(previousMonth.format("MMM YYYY"))}>{previousMonth.format("MMM YYYY")}</a></li>
              <li className={`page-item ${UpdatedData == selectedMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(selectedMonth.format("MMM YYYY"))}>{selectedMonth.format("MMM YYYY")}</a></li>
              <li className={`page-item ${UpdatedData == nextMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(nextMonth.format("MMM YYYY"))}>{nextMonth.format("MMM YYYY")}</a></li>
              <li className="page-item">
                <button className="page-link border-start-0 " style={{ "border": "0 !important" }} aria-label="Next" onClick={(e) => NextMonthYear(e)}>
                  <span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                    </span>
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-xl-3">
          <div className>
            <div className="accordion border-radius-10 " id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header poppins" id="headingOne">
                  <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="list_notification">
                      {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                        <div key={index}>
                          {/* <span className="mb-4 d-block">{dateItem.date}</span> */}
                          {dayjs(dateItem.date).isSame(dayjs(), 'day') && (
                            <span className="mb-4 d-block">Today</span>
                          )}
                          {/* Check if the date is yesterday */}
                          {dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                            <span className="mb-4 d-block">Yesterday</span>
                          )}
                          {/* Render the date */}
                          {!dayjs(dateItem.date).isSame(dayjs(), 'day') &&
                            !dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                              <span className="mb-4 d-block">{dateItem.date}</span>
                            )}

                          <ul  >
                            {dateItem.notification_data.map((notification, index) => (
                              <li className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                    <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">{notification.message}</p>
                                </div>
                                <span className="noti_timer">
                                  {dayjs(notification.insert_datetime).format('hh:mm A')}
                                </span>
                              </li>
                            ))}
                            {/* <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-diff-yellow-op-1 position-relative">
                              <span className="text-uppercase text-yellow">q</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">New assignments.</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li>
                        <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-sky-blue-op-1 position-relative">
                              <span className="text-uppercase text-blue">e</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">Any upcoming events</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li>
                        <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-sky-blue-op-1 position-relative">
                              <span className="text-uppercase text-blue">e</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">Any upcoming events</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li> */}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end*/}
      {/* </div> */}
    </>
  )
}
