import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import { reply_on_review, review_list } from '../../../api/apiHandler';
import { formatDate, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CryptoJS from 'crypto-js';
const dayjs = require('dayjs');
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

export default function Review() {

  const location = useLocation();

  // var id = location?.state;
  // console.log('id :', id);
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');

    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

    id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  } else if (location?.state) {
    var id = location?.state;
    console.log('Review :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    var type = location?.search?.split("=")[1];
    console.log('type :', type);
    var id = after
    id.class = type
    console.log('idtype :', id);
  }

  const [data, setData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reviewId, setReviewId] = useState(false);
  
  const schema = yup.object().shape({
    reply: yup.string().required("This is required"),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const review_listing = (learner_id) => {
    // isLoader(true)

    review_list({ class_id: id.id, type: id?.class }).then((response) => {
      console.log("review_list", response)
      // isLoader(false)

      if (response?.code == 1) {
        setData(response.data)
      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    review_listing();
  }, []);

  let onSubmit = async (data) => {
    console.log('dataonSubmit :', data);

    try {
      let send_data = {
        id : reviewId,
        type_id: id?.id,
        type: id?.class,
        reply: data.reply
      }

      console.log('send_data123 :', send_data);
      const response = await reply_on_review(send_data);
      console.log('reply_on_review :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message)
        reset();
        setIsOpen(false)
      } else {
        TOAST_ERROR(response.message)
      }
    } catch (error) {
      console.log('error :', error);
    }
  }


  return (
    <>

      <div className>
        {data?.length > 0 ?
          data?.map((review_data, index) => {
            return (

              <div className="card review_box mb-4">
                <div className="review_top">
                  <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                    <div className="review_user d-flex align-items-center">
                      <img src="./assets/images/user/user1.png" alt="user1" />
                      <div className="ms-3">
                        <span className="d-block fs-5">{review_data.full_name}</span>

                      </div>
                    </div>
                    <ul className="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">

                      <i data-star={review_data.rating}></i>

                    </ul>
                  </div>
                  <div className="review_text row mb-4">
                    <div className="col-lg-6">
                      <p className="fw-500">{review_data.review}</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="text-gray fs-6">{dayjs(review_data.created_at).format('MMMM D, YYYY')}</span>
                    <a onClick={(e) => {setIsOpen(true);setReviewId(review_data.review_id)}} data-bs-toggle="modal">Reply</a>
                  </div>
                </div>
              </div>
            )
          }) :
          <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
        }
      </div>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Give Reply</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {setIsOpen(false); reset();}} aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className>
                <form onSubmit={handleSubmit(onSubmit)} className="custom_form" action id>

                  <div className="form-group ">
                    <label className="mb-2">Give Reply</label>
                    <textarea type="text" className="form-control" name='reply' {...register("reply")} />
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.reply?.message}</span></p>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>
                    <button type="button" onClick={() => {setIsOpen(false); reset();}} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modals>

    </>
  )
}
