import React, { useEffect, useState } from 'react'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import { get_user_details,get_payment_setting_data,teacherViewProfile } from '../../../api/apiHandler';
import { useNavigate } from 'react-router-dom';

export default function OrgTeacherEarning() {

  const navigate = useNavigate();
    
  const [data, setData] = useState();
  const [tableData, setTableData] = useState();
  const [typeData, setTypeData] = useState('online');
  console.log('TeacherEarning :', data);
  const ClassType = (data) => {
    setTypeData(data)
  }

  const TeacherEarning = async () => {
    try {
      const response = await teacherViewProfile();
      console.log('response123 :', response);
      if (response?.code == 1) {
        setData(response?.data)
      } else { 
        setData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    TeacherEarning();
  }, [])

  const TeacherTable = async () => {

    try {
        let send_data = {
            type : typeData
        }
      const response = await get_payment_setting_data(send_data);
      console.log('get_payment_setting_data :', response);
      if (response?.code == 1) {
        setTableData(response?.data)
      } else { 
        setTableData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    TeacherTable();
  }, [typeData])
  return (
    <>
      <JqueryLoad/>
      <THeader_Dashboard/>
 

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div> 
            </div> 
          </header>  
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)}className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}  
              <div className="row pay_pice_set "> 
                <div className="col-xl-8">
                  <div className="card  mb-3">	
                    <div className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src={data?.teacher_profile_image} alt="user4" style={{ borderRadius: "50%" }}/>
                          <div className="price_flag position-absolute">
                            <img src={data?.country_image} alt="country_flag" />
                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">{data?.full_name}</h3>
                      </div>
                      <div className="pay_set_right d-flex justify-content-end">
                      <select className="border-purple fs-6 fw-500 text-dif-black rounded-cricle rounded-pill ms-3 px-2 py-2" onChange={(e) => ClassType(e.target.value)}>
                          <option value={"online"}>Online Classes</option>
                          <option value={"inperson"}>In-Person</option>
                        </select>
                      </div>
                    </div>
                  </div> 
                
                  <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          
                          <th scope="col" className="fw-600">No of students</th>
                          <th scope="col" className="fw-600 w-25">Payment in USD</th>
                        </tr>
                      </thead>
                      <tbody>
                      {tableData?.sort((a, b) => a.no_students - b.no_students)?.map((tableDetails, index) => {
                          // console.log('tableDetails :', tableDetails);
                          return (
                            <>
                        <tr>
                         
                          <td>{tableDetails?.no_students}</td>
                          <td>USD {tableDetails?.payment}</td>
                         
                        </tr>
                        </>
                          )

                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
              {/*end*/} 
            </div>
          </div>
        </div>
      </main>

      <TFooter_Dashboard/>
    </>
  )
}
