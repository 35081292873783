import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader_Dashboard from '../../Include/THeader_Dashboard'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { add_coupon, calculate_discount, coupon_class } from '../../../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
const generateCouponNumber = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let couponCode = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    couponCode += characters[randomIndex];
  }
  return couponCode;
};


export default function AddCoupon() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  const location = useLocation();

  var step = location?.state;

  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState('');

  const schema = yup.object().shape({
    // coupon_number: yup.string().required("Coupon number is required"),
    coupon_name: yup.string().required("Coupon name is required"),
    coupon_description: yup.string().required("Coupon description is required"),
    amount: yup.string().required("Amount is required"),
    start_date: yup.string().required("Start Date is required"),
    end_date: yup.string().required("End Date is required"),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  var [couponData, setCouponData] = useState([]);
  console.log('couponData :', couponData);
  const [selClass, setSelectedClass] = useState("")
  console.log('selClass :', selClass);
  const [selType, setSelectedType] = useState(
    step === 1 ? "class" :
      step === 2 ? "club" :
        step === 3 ? "quiz" :
          step === 4 ? "competition" :
            ""
  );
  const [selPer, setSelectedPer] = useState("5")
  const [getData, setGetData] = useState("")
  const [getAmountData, setGetAmountData] = useState("")
  console.log('getAmountData :', getAmountData);
  console.log('getData :', getData);
  const [OptionValue, setOptionValue] = useState('1')
  console.log('OptionValue :', OptionValue);

  const [countLearnerData, setCountLearnerData] = useState('2')
  console.log('countLearnerData :', countLearnerData);
  const [percentageLearnerData, setPercentageLearnerData] = useState('10')
  console.log('percentageLearnerData :', percentageLearnerData);
  const [discountLearnerData, setDiscountLearnerData] = useState('3')
  const [countLearnerDataOpt, setcountLearnerDataOpt] = useState('2')
  const [freeLearnerData, setFreeLearnerData] = useState('1')
  const [getPrie, setGetPrie] = useState('')
  console.log('getPrie :', getPrie);

  // option = 2

  const countLearner = async (value) => {
    setCountLearnerData(value)
  }

  const percentageLearner = async (value) => {
    setPercentageLearnerData(value)

  }

  const DisLearner = async (value) => {
    setDiscountLearnerData(value)

  }

  // option = 3

  const countLearnerOpt3 = async (value) => {
    setcountLearnerDataOpt(value)

  }

  const freeLearner = async (value) => {
    setFreeLearnerData(value)

  }

  useEffect(() => {
    if (OptionValue == 1) {
      setGetAmountData((getData * 0.6).toFixed(2)); //  for Option 1
    } else if (OptionValue == 2) {

      let count = (countLearnerData * getPrie) + (getPrie  - (getPrie * (percentageLearnerData/100) )); //  for Option 2
      console.log('count :', count);
      setGetAmountData(count*0.6)

    } else if (OptionValue == 3) {
      let count1 = (countLearnerDataOpt* getPrie) //  for Option 3
      setGetAmountData(count1*0.6)

    }
  }, [getData, OptionValue,countLearnerData,getPrie,percentageLearnerData,countLearnerDataOpt]);

  const coupon_class_list = () => {
    let send_data = {}
    if (step == 1) {
      send_data.type = "class"
    } else if (step == 2) {
      send_data.type = "club"
    } else if (step == 3) {
      send_data.type = "quiz"
    } else {
      send_data.type = "competition"
    }

    coupon_class(send_data).then((resposnse) => {
      console.log("coupon_class", resposnse)

      if (resposnse?.code == 1) {
        setCouponData(resposnse.data)
        setSelectedClass(resposnse?.data[0]?.id)
        setGetPrie(resposnse?.data[0]?.price)
        // setValue("select_class",resposnse?.data[0]?.title)
      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    coupon_class_list();
  }, [])

  let onSubmit = async (data) => {

    let coupon_data = {
      // type: "class",
      type_id: selClass,
      // coupan_number: data.coupon_number,
      coupan_number: couponNumber,
      coupan_name: data.coupon_name,
      discription: data.coupon_description,
      amount: (OptionValue == 1 ? data.amount : ""),
      start_date: data.start_date,
      end_date: data.end_date,
      after_discounte_price: (OptionValue == 1 ? getData : ""),
      option_id : OptionValue,
      subscribe: (OptionValue == 2 ? countLearnerData : OptionValue == 3 ? countLearnerDataOpt : OptionValue == 3 ? "" : ""),
      get_percentages : (OptionValue == 2 ? percentageLearnerData : ""),
      option2_learner : (OptionValue == 2 ? discountLearnerData : ""),
      option3_learner : (OptionValue == 3 ?freeLearnerData : "")

    }
    if (step == 1) {
      coupon_data.type = "class"
    } else if (step == 2) {
      coupon_data.type = "club"
    } else if (step == 3) {
      coupon_data.type = "quiz"
    } else {
      coupon_data.type = "competition"
    }
    console.log('coupon_data :', coupon_data);

    add_coupon(coupon_data).then((res) => {

      if (res?.code == 1) {
        TOAST_SUCCESS(res?.message);
        reset();
        navigate('/my_coupon', {
          state: step
        });
      } else {
        TOAST_ERROR(res?.message);
      }
    });
  }




  // State to store the generated coupon number
  const [couponNumber, setCouponNumber] = useState('');
  console.log('couponNumber :', couponNumber);

  // Function to handle generating a new coupon number
  const handleGenerateCouponNumber = () => {
    const newCouponNumber = generateCouponNumber(8);
    setCouponNumber(newCouponNumber);
    setValue("coupon_number", newCouponNumber)

  };

  useEffect(() => {
    handleGenerateCouponNumber();
  }, []);


  const selectedClass = (a) => {
    let SEND = JSON.parse(a)
    setSelectedClass(SEND?.id)
    setSelectedType(SEND?.type)
    setGetPrie(SEND?.price)
  }
  const SelectedPercentage = (b) => {
    setSelectedPer(b)
  }

  const SelectedOption = (value) => {
    setOptionValue(value)
  }

  const CalDisCount = () => {
    let submit_data = {
      id: selClass,
      discount_percentage: selPer,
      type: selType
    }
    console.log('submit_data :', submit_data);

    calculate_discount(submit_data).then((resposnse) => {
      console.log("calculate_discount", resposnse)

      if (resposnse.code == 1) {
        setGetData(resposnse.data.total_amount)
      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {
    CalDisCount();
  }, [selClass, selPer])

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1, { state: { step } })} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-8">
                      {/*start*/}
                      <div className="mb-4">
                        <h2 className="mb-2 poppins fw-bold">Add Coupon Information</h2>
                      </div>
                      {/*end*/}
                      {/*start*/}
                      <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                        <div className="form-group mb-4">
                          <label className="mb-2">Select Class</label>

                          <select className="form-select" {...register("select_class")} value={JSON.stringify({ id: selClass, type: step === 1 ? 'class' : step === 2 ? 'club' : step === 3 ? 'quiz' : 'competition' ,price: getPrie})} name="select_class" onChange={(e) => (selectedClass(e.target.value))} aria-label="Default select example">
                            <option value='' selected disabled>select {step == 1 ? "class" : step == 2 ? "club" : step == 3 ? "quiz" : step == 4 ? "competition" : ""}</option>

                            {couponData?.map((coupon, index) => {
                              const couponValue = JSON.stringify({ id: coupon?.id, type: coupon?.type, price: coupon?.price });
                              return (

                                <option key={index} value={couponValue} >{coupon?.title}</option>
                              )
                            })}


                          </select>
                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">Coupon Number</label>
                          <input type="text" {...register("coupon_number")} name="coupon_number" className="form-control" disabled />
                          {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.coupon_number?.message}</span></p> */}

                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">Add Coupon Name</label>
                          <div className="position-relative class_title">

                            <input type="text" {...register("coupon_name")} name="coupon_name" maxLength={50} minLength={5} placeholder id className="form-control" />
                            <span className="position-absolute word_count">{50 - (watch("coupon_name")?.split('')?.length ?? 0)} Words</span>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.coupon_name?.message}</span></p>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label" >Add Coupon Description</label>
                          <div className="position-relative class_title">
                            <textarea className="form-control" {...register("coupon_description")} name="coupon_description" maxLength={200} rows={7} placeholder id />
                            <span className="position-absolute word_count">{200 - (watch("coupon_description")?.split('')?.length ?? 0)} Words</span>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.coupon_description?.message}</span></p>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">coupon options</label>
                          <select className="form-select" onChange={(e) => SelectedOption(e.target.value)}>
                            <option value="1" selected>Give a new learner discount</option>
                            <option value="2">Give a percentage discount for multiple learners</option>
                            <option value="3">Offer a class free class for multiple learners</option>
                          </select>
                          {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.amount?.message}</span></p> */}
                        </div>
                        <div className="form-group mb-4 mt-2">
                          <label className="form-label">Enter Start Date</label>
                          <input type="date" {...register("start_date")} onChange={(e) => setStartDate(e.target.value)} min={currentDate} name="start_date" className="form-control" placeholder id />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_date?.message}</span></p>

                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">Enter End Date</label>
                          <input type="date" {...register("end_date")} name="end_date" min={startDate || currentDate} className="form-control" placeholder id />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.end_date?.message}</span></p>

                        </div>
                        {OptionValue == 1 ? (<>

                          <div className="form-group mb-4">
                            <label className="form-label">Select Discount Percentage</label>
                            <select className="form-select" {...register("amount")} name="amount" onChange={(e) => SelectedPercentage(e.target.value)}>
                              <option value="5" selected>5% OFF</option>
                              <option value="10">10% OFF</option>
                              <option value="15">15% OFF</option>
                              <option value="20">20% OFF</option>
                              <option value="25">25% OFF</option>
                              <option value="30">30% OFF</option>
                              <option value="50">50% OFF</option>
                            </select>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.amount?.message}</span></p>
                          </div>
                          {getData &&
                            <div className="form-group mb-4">
                              <label className="form-label">After Discounte Price</label>
                              <input type="text" name="d_price" {...register("d_price")} value={getData} className="form-control" disabled />

                            </div>}
                        </>
                        ) : OptionValue == 2 ? (<>

                          {/* <div className="form-group mb-4">
                            <p> Subscribe <input type='number' style={{ width: "70px" }} /> or more learners and get <input type='number' style={{ width: "70px" }} />  % off the entire class price</p>
                          </div> */}
                          <div className="d-md-flex align-items-center">
                            <p className="fs-6 mb-md-0 mb-3 text-capitalize">Subscribe </p>
                            <div className="d-md-flex ms-md-3">
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill "
                                  {...register("count_learner")} name='count_learner' onChange={(e) => countLearner(e.target.value)} aria-label="Default select example">
                                  {/* <option selected>0 Hours</option> */}
                                  <option value="2" selected>2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5 or more</option>
                                </select>
                                <span className="ms-2 text-capitalize">learners to the class and get  </span>
                              </div>
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">

                                <select className="form-select w-auto pe-5 rounded-pill fw-500 ms-2"
                                  {...register("per_learner")} name='per_learner' onChange={(e) => percentageLearner(e.target.value)} aria-label="Default select example">
                                  <option value="10" selected>10 % </option>
                                  <option value="20">20 % </option>
                                  <option value="30">30 % </option>
                                  <option value="40">40 % </option>
                                  <option value="50">50 % </option>

                                </select>
                                <span className="ms-2 text-capitalize">off the </span>

                              </div>
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                <select className="form-select w-auto pe-5 rounded-pill fw-500 ms-2"
                                  {...register("dis_learner")} name='dis_learner'  onChange={(e) => DisLearner(e.target.value)} aria-label="Default select example">
                                  <option value="3" selected={countLearnerData != 4} disabled={countLearnerData == 4}>3rd </option>
                                  <option value="4">4th </option>
                                  <option value="5">5th or more </option>

                                </select>
                                <span className="ms-2 text-capitalize">learner/s.</span>

                              </div>

                            </div>

                          </div>
                        </>) : OptionValue == 3 ? (<>

                          {/* <div className="form-group mb-4">
                            <p> Subscribe <input type='number' style={{ width: "70px" }} />  learners to the class and get the <input type='number' style={{ width: "70px" }} />  learner free</p>
                          </div> */}
                          <div className="d-md-flex align-items-center">
                            <p className="fs-6 mb-md-0 mb-3 text-capitalize">Subscribe </p>
                            <div className="d-md-flex ms-md-3">
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill "
                                  {...register("count_learner_3")} name='count_learner_3' onChange={(e) => countLearnerOpt3(e.target.value)} aria-label="Default select example">
                                  {/* <option selected>0 Hours</option> */}
                                  <option value="2" selected>2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5 or more</option>
                                  <option value="8">8 or more</option>
                                  <option value="10">10 or more</option>
                                </select>
                                <span className="ms-2 text-capitalize">learners and get</span>
                              </div>
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">

                                <select className="form-select w-auto pe-5 rounded-pill fw-500 ms-2"
                                  {...register("learner_free")} name='learner_free' onChange={(e) => freeLearner(e.target.value)} aria-label="Default select example">
                                  <option value="1" selected>1 learner free</option>
                                  <option value="2">2 learners free </option>
                                  <option value="3">3 learners free</option>
                                  <option value="4">4 learners free</option>
                                  <option value="5">5 learners free</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </>) : <></>}

                        {/* <div className="form-group mb-4">
                          <label className="form-label">Select Discount Percentage</label>
                          <select className="form-select" {...register("amount")} name="amount" onChange={(e) => SelectedPercentage(e.target.value)}>
                            <option value="5" selected>5% OFF</option>
                            <option value="10">10% OFF</option>
                            <option value="15">15% OFF</option>
                            <option value="20">20% OFF</option>
                            <option value="25">25% OFF</option>
                            <option value="30">30% OFF</option>
                            <option value="50">50% OFF</option>
                          </select>
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.amount?.message}</span></p>
                        </div>
                        {getData &&
                          <div className="form-group mb-4">
                            <label className="form-label">After Discounte Price</label>
                            <input type="text" name="d_price" {...register("d_price")} value={getData} className="form-control" disabled />

                          </div>} */}
                        <div className="form-group mb-4 mt-2">

                          <p className='fs-6 fw-600 mb-4 text-capitalize'>You will receive a total amount of : for each learner {getAmountData} per lesson.
                          </p>
                         
                        </div>
                        {/* <div className="form-group mb-4 mt-2">
                          <label className="form-label">Enter Start Date</label>
                          <input type="date" {...register("start_date")} onChange={(e) => setStartDate(e.target.value)} min={currentDate} name="start_date" className="form-control" placeholder id />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_date?.message}</span></p>

                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">Enter End Date</label>
                          <input type="date" {...register("end_date")} name="end_date" min={startDate || currentDate} className="form-control" placeholder id />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.end_date?.message}</span></p>

                        </div> */}
                        <div className="button">
                          {/* <Link to="/my_coupon" className="btn-theme bg-yellow d-inline-block fw-600">Create Coupon</Link> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Create Coupon
                          </button>
                        </div>
                      </form>
                      {/*end*/}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
