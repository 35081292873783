import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { add_learner, forgot_password } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import * as yup from "yup";
import Modals from "react-modal";
import Model from "../Models/Model";
import Select from "react-select";
import countryList from "react-select-country-list";
import { yupResolver } from "@hookform/resolvers/yup";
import SendOtpModel from "../Models/SendOtpModel";
import BackToPrevious from "../../components/backToPrevious";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

var COMMON_STYLE_FOR_POPUP = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [avtarModel, setavtarModel] = useState(false);
  const [countryCode, setCountryCode] = useState();


  const handlePhoneChange = (phone, country) => {

    if (country && country.dialCode) {
      const formattedValue = phone.substring(country.dialCode.length);
      setCountryCode(country.dialCode);
      setValue("phone", formattedValue);
    }

  };

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone Number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits and contain only numbers"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let onSubmit = (data) => {
    // alert('gfy');
    let submitData = {
      phone: data.phone,
      country_code: "+" + countryCode,
    };
    console.log("submitData", submitData)
    // setIsOpen(true);
    // return
    forgot_password(submitData).then((res) => {
      console.log("add_learner_res :", res);
      // return
      // alert('hello')
      if (res.code == 1) {
      
        localStorage.setItem("PAid", res.data.id);
        localStorage.setItem("Otp", res.data.otp);
        setIsOpen(true);

        TOAST_SUCCESS(res.message);
      } else {
        TOAST_ERROR(res.message);
      }
    });

    reset();

    //  localStorage.setItem("step_1",JSON.stringify(data))
    // navigate("/dashboard");
  };

  return (
    <>
      {/* <Helmet>
        <script>
          {`
        	$("#mobile_code").intlTelInput({
                initialCountry: "in",
                separateDialCode: true,
                // utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js"
            });
        `}
        </script>
      </Helmet> */}

      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row">
              <div
                className="align-items-center col-lg-6 d-flex justify-content-center height_100vh left"
                style={{ backgroundImage: "url(./assets/images/auth/login_in.png)" }}
              >
                <h1 className="text-white poppins fw_500 position-relative">Be Inspired. Grow Your Skills. Knowledge. Talents.</h1>
              </div>
              <div className="col-lg-6 right">
                <div className="top">
                  <div className="d-md-flex justify-content-between">
                    <BackToPrevious />
                    <div>
                      <p className="fs-6 text-gray">
                        Don’t Have Account?{" "}
                        <Link to="/sign_in_step1" className="text-purple">
                          Join Now
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btm_div form-section">
                  <div className="select_anyone">
                    <div className="mb-4">
                      <h2 className="poppins fw-600">Forgot Password</h2>
                      <p className="text-gray fs-5">Enter your phone number to reset your password.</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-5" action id>
                      <div className="form-group mb-4">
                        {/* <label className="mb-2 d-block">phone Number</label>
                        <input type="text" id="mobile_code" {...register("phone")} className="form-control w-100" placeholder name="phone" />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.phone?.message}</span>
                        </p> */}
                            <PhoneInput
                            placeholder="  Enter phone number"
                            name="phone"
                            {...register("phone")}
                            id="phone"
                            onChange={(value, country) => handlePhoneChange(value, country)}
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.phone?.message}</span>
                          </p>{" "}
                      </div>
                      <div className="mt-5">
                        {/* <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn-theme bg-yellow fw-600 text-center d-block">Get OTP</a> */}
                        <button
                          type="submit"
                          onClick={() => setavtarModel(true)}
                          className="btn-theme bg-yellow text-center fw-600 d-block"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          {/* <Link to="/sign_in_step3">Next</Link> */}Get OTP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*start-modal*/}
      {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header pb-0 border-bottom-0">
                <h5 className="modal-title" id="staticBackdropLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="text-center mb-4">
                  <h4 className="modal-title" id="staticBackdropLabel">Verify OTP</h4>
                  <p className="fs-6 text-gray">We’ve sent a One Time Password (OTP) to the +91 7879955555. Please enter it to complete verification</p>
                </div>
                <form className="custom_form verf_otp mt-4" action id>
                  <div className="form-group d-flex justify-content-center mb-4">
                    <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(1)" maxLength={1} />
                    <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(2)" maxLength={1} />
                    <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(3)" maxLength={1} />
                    <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(4)" maxLength={1} />
                  </div>
                </form>
              </div>
              <div className="modal-footer border-top-0 justify-content-center pt-0">
                <a href="reset_password.php" className="btn-theme bg-yellow fw-600">Verify</a>
              </div>
              <div className="modal-footer justify-content-center">
                <p className="fs-6">Didn’t received?  <a href="#" className="text-purple text-uppercase">Resend</a></p>
              </div>
            </div>
          </div>
        </div> */}

      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <SendOtpModel message={`We have sent you an verification code to ${watch("phone")}`} type={`Signup2`} setIsOpen={setIsOpen} />
      </Modals>
    </>
  );
}
