import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import { get_faq_video } from '../../../api/apiHandler'

export default function OFaqDetail() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  const location = useLocation();
  const navigate = useNavigate()

  var [FaqVideoData, setFaqVideoData] = useState([]);
  console.log('FaqVideoData :', FaqVideoData);
  var faq_id = location?.state;
  console.log(faq_id);
  const FaqVideoDetails = () => {

    get_faq_video({ id: faq_id }).then((response) => {
      console.log("FaqVideo_details", response)

      if (response?.code == 1) {
        setFaqVideoData(response?.data)
      } else {
        setFaqVideoData([]);
        console.log('data not found');
      }
    });
  }

  useEffect(() => {

    FaqVideoDetails();

  }, []);
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <span className="mb-4 arrow_left mb-3 d-block" onClick={handleBack} >

                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <span onClick={handleBack} className="ms-2">Back</span>
              </span>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-bold">FaqVideo  - training Videos</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row mb-5 blogs_lists">

                    {FaqVideoData.map(video => (
                      <div key={video.id} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                        <div className="certi_box bg-transparent shadow-none">
                          <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                            <div className="blog_img">
                              <video controls className="w-100 border-radius-10">
                                <source src={video.media} type="video/mp4" />
                            
                              </video>



                            </div>
                          </div>
                          <Link to="/organization_video_details" state={video.id} className="d-block certi_ctn px-0">
                            <h3 className="fw-600 mb-2 text-dif-black text-capitalize poppins">{video.title}</h3>
                            <p className="text-dif-black text-capitalize">{video.description}</p>
                            <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="dwnl_img">
                                  <img src={video.profile_image} alt="center_div_img2" />
                                </div>
                                <div className="ms-3">
                                  <span className="font-14 fw-bold">{video.admin_name}</span>
                                </div>
                              </div>
                              <div>
                                <span>{video.formatted_date}</span> • <span>{video.formatted_time} ago</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}


                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
