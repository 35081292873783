import './App.css';
import React, { useState, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

// import { onMessage } from 'firebase/messaging';
// import { messaging, generateToken } from "./Firebase";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Suspense, lazy } from "react";
// import ScrollToTop from './ScrollToTop';
import Home from './pages/Home/Home';
import Login from './pages/Auth/Login';
import Signup1 from './pages/Auth/Signup1';
import Signup2 from './pages/Auth/Signup2';
import Signup3 from './pages/Auth/Signup3';
import AfterSchoolSignup from './pages/Auth/AfterSchoolSignup';
// import MyCertificates from './pages/Kids/MyCertificates/MyCertificates';

// import MyCourseInPerson from './pages/Kids/MyEnrollment/MyCourseInPerson';
// import ClassInfoAfterjoin from './pages/Kids/Search/Class/ClassInfoAfterjoin';
// import MyOfflineCourse from './pages/Kids/MyEnrollment/MyOfflineCourse';
// import PrivateAbout from './pages/Kids/AboutUs/PrivateAbout';
import RequestClassForm from './pages/RequestClass/RequestClassForm';
import Signup23 from './pages/Teacher/Auth/SignUp/Signup23';
import SignupStep from './pages/Teacher/Auth/SignUp/SignupStep';
import SignupStep1 from './pages/Teacher/Auth/SignUp/SignupStep1';
import TeacherDashboard from './pages/Teacher/Dashboard/TeacherDashboard';

import TeacherClass from './pages/Teacher/Add/TeacherClass';
import TMessage from './pages/Teacher/Message/TMessage';
import Notification from './pages/Teacher/Notification/Notification';
import Changepassword from './pages/Teacher/ChangePassword/Changepassword';
import EditProfile from './pages/Teacher/EditProfile/EditProfile';
import ContactUs from './pages/Teacher/ContactUs/ContactUs';
import Faqs from './pages/Teacher/Faq/Faqs';
import FaqDetails from './pages/Teacher/Faq/FaqDetails';
import Blog from './pages/Teacher/Blog/Blog';
import Refer from './pages/Teacher/Refer/Refer';
import BlogDetails from './pages/Teacher/Blog/BlogDetails';
import MyEarning from './pages/Teacher/MyEarning/MyEarning';
import SearchClass from './pages/Teacher/Search/SearchClass';
import SetupClass from './pages/Teacher/Add/SetupClass';
import SetupClub from './pages/Teacher/Add/SetupClub';
import SetupQuiz from './pages/Teacher/Add/Quiz/SetupQuiz';
import AddQue from './pages/Teacher/Add/Quiz/AddQue';
import QueList from './pages/Teacher/Add/Quiz/QueList';
import AddCompetitation from './pages/Teacher/Add/AddCompetitation/AddCompetitation';
import TClassDetail from './pages/Teacher/Search/ClassDetails/TClassDetail';
import TOnsiteClassDetail from './pages/Teacher/Search/ClassDetails/TOnsiteClassDetail';
import NewLeeds from './pages/Teacher/NewLeads/NewLeeds';
import PreferTeacher from './pages/Teacher/NewLeads/PreferTeacher';
import ReqClassDetail from './pages/Teacher/NewLeads/ReqClassDetail';
import MyCoupon from './pages/Teacher/Teach/Coupon/MyCoupon';
import MyPurchaseCoupon from './pages/Teacher/Teach/Coupon/MyPurchaseCoupon';
import AddCoupon from './pages/Teacher/Teach/Coupon/AddCoupon';
import TSetupClass from './pages/Teacher/Teach/SetupClass/TSetupClass';
import UpcomingMeeting from './pages/Teacher/Teach/SetupClass/UpcomingMeeting';
import TeachDetail from './pages/Teacher/Teach/TeachDetail/TeachDetail';
import TechDetailOffline from './pages/Teacher/Teach/TeachDetail/TechDetailOffline';
import AddLesson from './pages/Teacher/Teach/AddLesson/AddLesson';
import AddHomework from './pages/Teacher/Teach/AddHomework/AddHomework';
import AddAssessment from './pages/Teacher/Teach/AddAssessment/AddAssessment';
import TeacherQuiz from './pages/Teacher/Add/Quiz/TeacherQuiz';
import AddDisscussion from './pages/Teacher/Teach/AddDisscussion/AddDisscussion';
import ParentDashboard from './pages/Parents/Dashboard/ParentDashboard';
import PMessage from './pages/Parents/Message/PMessage';
import PNotification from './pages/Parents/Notification/PNotification';
import PSearchClass from './pages/Parents/SearchClass/PSearchClass';
import PClassDetail from './pages/Parents/SearchClass/Class/PClassDetail';
import POnsiteClass from './pages/Parents/SearchClass/OnSite/POnsiteClass';
import PRequestClass from './pages/Parents/ReqClass/PRequestClass';
import PReqForm from './pages/Parents/ReqClass/PReqForm';
import PReqClassDetail from './pages/Parents/ReqClass/PReqClassDetail';
import PTeacherDetail from './pages/Parents/TeacherDetail/PTeacherDetail';
import PMyEnrollment from './pages/Parents/MyEnrollment/PMyEnrollment';
import PMyCourseDetail from './pages/Parents/MyEnrollment/MyCourseDetail/PMyCourseDetail';
import PMyCourseInPerson from './pages/Parents/MyEnrollment/MyCourseDetail/PMyCourseInPerson';
import PMyOfflineCourse from './pages/Parents/MyEnrollment/MyCourseDetail/PMyOfflineCourse';
import ParentCertificate from './pages/Parents/MyCertificate/ParentCertificate';
import KidsCertificateList from './pages/Parents/MyCertificate/KidsCertificateList';
import ReferFriend from './pages/Parents/ReferFriend/ReferFriend';
import PBlogDetails from './pages/Parents/Blogs/PBlogDetails';
import PBlogs from './pages/Parents/Blogs/PBlogs';
import PFaq from './pages/Parents/Faq/PFaq';
import PFaqDetails from './pages/Parents/Faq/PFaqDetails';
import PContactUs from './pages/Parents/HelpContact/PContactUs';
import PEditProfile from './pages/Parents/EditProfile/PEditProfile';
import PChangePassword from './pages/Parents/ChangePaassword/PChangePassword';
import ODashboard from './pages/Organization/Dashboard/ODashboard';
import OMessage from './pages/Organization/Message/OMessage';
import ONotification from './pages/Organization/Notification/ONotification';
import OrganizationClass from './pages/Organization/Add/OrganizationClass';
import OAddClass from './pages/Organization/Add/AddClass/OAddClass';
import OAddCompetitation from './pages/Organization/Add/AddCompetitation/OAddCompetitation';
import OAddClub from './pages/Organization/Add/AddClub/OAddClub';
import OAddQuiz from './pages/Organization/Add/AddQuiz/OAddQuiz';
import OAddQue from './pages/Organization/Add/AddQuiz/OAddQue';
import OQueList from './pages/Organization/Add/AddQuiz/OQueList';
import OQuizInfo from './pages/Organization/Add/AddQuiz/OQuizInfo';
// import OSearchClass from './pages/Organization/SearchClass/OSearchClass';
// import OClassDetail from './pages/Organization/SearchClass/ClassDetail/OClassDetail';
// import OClassOnSiteDetail from './pages/Organization/SearchClass/ClassDetail/OClassOnSiteDetail';
import ORequestClass from './pages/Organization/RequestClass/ORequestClass';
import FillReqForm from './pages/Organization/RequestClass/FillReqForm';
import ManageTeacher from './pages/Organization/ManageTeacher/ManageTeacher';
import OTecherDetail from './pages/Organization/TeacherDetail/OTecherDetail';
import OTeachList from './pages/Organization/TeachList/OTeachList';
import OMyCoupon from './pages/Organization/Coupon/OMyCoupon';
import OMyPurchaseDetail from './pages/Organization/Coupon/OMyPurchaseDetail';
import OAddCoupon from './pages/Organization/Coupon/OAddCoupon';
import OTeachDetail from './pages/Organization/TeachList/TeachDetail/OTeachDetail';
import OTeachDetailInPerson from './pages/Organization/TeachList/TeachDetail/OTeachDetailInPerson';
import OTeachDetailOffline from './pages/Organization/TeachList/TeachDetail/OTeachDetailOffline';
import OSetupClass from './pages/Organization/SetupCLass/OSetupClass';
import OUpcomingMeeting from './pages/Organization/SetupCLass/OUpcomingMeeting';
import OMyEarning from './pages/Organization/MyEarning/OMyEarning';
import OPaymentSetting from './pages/Organization/PaymentSetting/OPaymentSetting';
import OPaymentDetails from './pages/Organization/PaymentSetting/OPaymentDetails';
import OReferFriend from './pages/Organization/ReferFriend/OReferFriend';
import Oblogs from './pages/Organization/Blog/Oblogs';
import OBlogDetails from './pages/Organization/Blog/OBlogDetails';
import OFaq from './pages/Organization/Faq/OFaq';
import OFaqDetail from './pages/Organization/Faq/OFaqDetail';
import OEditProfile from './pages/Organization/EditProfile/OEditProfile';
import OProfileDetail from './pages/Organization/EditProfile/OProfileDetail';
import OContactUs from './pages/Organization/ContactUs/OContactUs';
import OChangePassword from './pages/Organization/ChangePassword/OChangePassword';
// import ClassDetail3 from './pages/Kids/Search/Class/ClassDetail3';
import OReqClassDetail from './pages/Organization/RequestClass/OReqClassDetail';
import MyDetail from './pages/Teacher/Teach/MyDetail/MyDetail';
import ForgotPassword from './pages/Auth/ForgotPassword';

import PQuizInfo from './pages/Parents/Quiz/PQuizInfo';
import PquizList from './pages/Parents/Quiz/PquizList';
import PQuizReview from './pages/Parents/Quiz/PQuizReview';
import PStartQuiz from './pages/Parents/Quiz/PStartQuiz';

import ResetPassword from './pages/Auth/ResetPassword';
import PrivateComponent from './private/PrivateComponent';
import EditClass from './pages/Teacher/Add/EditClass';
import EditClub from './pages/Teacher/Add/EditClub';
import EditCompetitation from './pages/Teacher/Add/AddCompetitation/EditCompetitation';
import EditQuestion from './pages/Teacher/Add/Quiz/EditQuestion';
import AddAnotherQuestion from './pages/Teacher/Add/Quiz/AddAnotherQuestion';
import EditQuiz from './pages/Teacher/Add/Quiz/EditQuiz';
import Teach_new from './pages/Teacher/Teach_new';
import Loader from './utils/Loader';
import Teach_Details from './pages/Teacher/Teach_Details';
import EditLesson from './pages/Teacher/Teach/AddLesson/EditLesson';
import EditAssessment from './pages/Teacher/Teach/AddAssessment/EditAssessment';
import EditDisscussion from './pages/Teacher/Teach/AddDisscussion/EditDisscussion';
import Search_All from './pages/Teacher/Search_All';
import ALL_Quiz from './pages/Teacher/Add/Quiz/ALL_Quiz';
import Teacher_Class from './pages/Teacher/Add/Teacher_Class';
import New_Leeds from './pages/Teacher/New_Leeds';
import RespondedClassDetails from './pages/Teacher/NewLeads/RespondedClassDetails';
import RejectedClassDetails from './pages/Teacher/NewLeads/RejectedClassDetails';
import Allquestion from './pages/Teacher/Dashboard/Allquestion';
import TClubDetails from './pages/Teacher/Search/ClubDetails/TClubDetails';
import TCompetitionDetails from './pages/Teacher/Search/CompetitionDetails/TCompetitionDetails';
import TQuizDetails from './pages/Teacher/Search/QuizDetails/TQuizDetails';
import TeacherProfileDetails from './pages/Teacher/Search_All/TeacherProfileDetails';
import OSearch_All from './pages/Organization/OSearch_All';
import OClass_Details from './pages/Organization/OSearch_Detail/ClassDetails/OClass_Detail';
import OClub_Details from './pages/Organization/OSearch_Detail/ClubDetails/OClub_Details';
import OQuiz_Details from './pages/Organization/OSearch_Detail/QuizDetails/OQuiz_Details';
import OCompetition_Details from './pages/Organization/OSearch_Detail/CompetitionDetails/OCompetition_Details';
import OnsiteClass_Detail from './pages/Organization/OSearch_Detail/ClassDetails/OnsiteClass_Detail';
import OStudentQue from './pages/Organization/Dashboard/OStudentQue';
import ViewDetailsCommonPage from './pages/Teacher/Teach_Details/ViewDetailsCommonPage';
import ViewDetailPage from './pages/Teacher/Teach_Details/ViewDetailPage';
import QuizVIewDetail from './pages/Teacher/Teach_Details/QuizVIewDetail';
import QuizStartT from './pages/Teacher/Teach_Details/QuizStartT';
import QuizReviewT from './pages/Teacher/Teach_Details/QuizReviewT';
import OTeach_new from './pages/Organization/TeachList/OTeach_new';
import OrganizationHomeList from './pages/Organization/Add/OrganizationHomeList';
import OEditClass from './pages/Organization/Add/AddClass/OEditClass';
import ViewDetailsCommon from './pages/Teacher/Teach_Details/ViewDetailsCommon';
import OEditCompetitation from './pages/Organization/Add/AddCompetitation/OEditCompetitation';
import OAddAnotherQuestion from './pages/Organization/Add/AddQuiz/OAddAnotherQuestion';
import OEditQuestion from './pages/Organization/Add/AddQuiz/OEditQuestion';
import OAllQuizList from './pages/Organization/Add/AddQuiz/OAllQuizList';
import OEditQuiz from './pages/Organization/Add/AddQuiz/OEditQuiz';
import OteacherProfileDetail from './pages/Organization/OSearch_Detail/OteacherProfileDetail';
import OTeachDetails from './pages/Organization/OTeachDetails';
import OEditLesson from './pages/Organization/TeachAdd/OAddLesson/OEditLesson';
import OAddLesson from './pages/Organization/TeachAdd/OAddLesson/OAddLesson';
import OAddHomework from './pages/Organization/TeachAdd/OAddHomework/OAddHomework';
import OEditAssessment from './pages/Organization/TeachAdd/OAddAssessment/OEditAssessment';
import OAddDisscussion from './pages/Organization/TeachAdd/OAddDisscussion/OAddDisscussion';
import OEditDisscussion from './pages/Organization/TeachAdd/OAddDisscussion/OEditDisscussion';
import OAddAssessment from './pages/Organization/TeachAdd/OAddAssessment/OAddAssessment';
import OViewDetailPage from './pages/Organization/OTeachDetails/OViewDetailPage';
import OViewDetailsCommon from './pages/Organization/OTeachDetails/OViewDetailsCommon';
import QuizReviewO from './pages/Organization/OTeachDetails/QuizReviewO';
import QuizStartO from './pages/Organization/OTeachDetails/QuizStartO';
import QuizVIewDetailO from './pages/Organization/OTeachDetails/QuizVIewDetailO';
import RequestClassO from './pages/Organization/RequestClassO';
import ManageTeacherDetails from './pages/Organization/ManageTeacher/ManageTeacherDetails';
import VideoDetails from './pages/Teacher/Faq/VideoDetails';
import TeacherFavourite from './pages/Teacher/Tfavourite/TeacherFavourite';
import OrganizationFavourite from './pages/Organization/Ofavourite/OrganizationFavourite';
import OEditClub from './pages/Organization/Add/AddClub/OEditClub';
import OVideoDetails from './pages/Organization/Faq/OVideoDetails';
import AssessmentResponse from './pages/Teacher/Teach_Details/AssessmentResponse';
import TeacherVerification from './pages/Teacher/Auth/SignUp/TeacherVerification';
import TeacherBgCheck from './pages/Teacher/Auth/SignUp/TeacherBgCheck';
import Oapplication from './pages/Organization/OStep/Oapplication';
import Ostep from './pages/Organization/OStep/Ostep';
import OrganizationidVerification from './pages/Organization/OStep/OrganizationidVerification';
import Oagreement from './pages/Organization/OStep/Oagreement';
import OnsiteApplication from './pages/Organization/OStep/OnsiteApplication';
import SidebarList from './pages/Organization/ManageTeacher/SidebarList';
import SignUoOTeacher from './pages/Teacher/Auth/SignUp/SignUoOTeacher';
import Nopermission from './pages/Teacher/NoPermission/Nopermission';
import RedirectBlankPage from './pages/Common/RedirectBlankPage';
import RedirectBlankPageHome from './pages/Common/RedirectBlankPageHome';
import HomeworkResponse from './pages/Teacher/Teach_Details/HomeworkResponse';
import HomworkOResponse from './pages/Organization/OTeachDetails/HomworkOResponse';
import AssessmentOresponse from './pages/Organization/OTeachDetails/AssessmentOresponse';
import DisscussionResponse from './pages/Teacher/Teach_Details/DisscussionResponse';
import DisscussionOResponse from './pages/Organization/OTeachDetails/DisscussionOResponse';
import OmessageList from './pages/Organization/Message/OmessageList';
import AddStripeAccount from './pages/Teacher/AddStripe/AddStripeAccount';
import Link from './pages/Teacher/AddStripe/SetupLink';
import SetupLink from './pages/Teacher/AddStripe/SetupLink';
import Ewallet from './pages/Teacher/EWallet/Ewallet';
import EwalletBalance from './pages/Teacher/EWallet/EwalletBalance';
import OEwalletBalance from './pages/Organization/OEWallet/OEwalletBalance';
import OEwallet from './pages/Organization/OEWallet/OEwallet';
// import AgoraVideoCall from './pages/Teacher/Teach_Details/AgoraVideoCall';
import StartMeeting from './pages/Teacher/Teach_Details/TStartMeeting';
import OrgTeacherEarning from './pages/Teacher/MyEarning/OrgTeacherEarning';
import OrespondClassDetail from './pages/Organization/RequestClass/OrespondClassDetail';
import ORejectedDetail from './pages/Organization/RequestClass/ORejectedDetail';
import OAddStripe from './pages/Organization/OStripe/OAddStripe';
import OsetupLink from './pages/Organization/OStripe/OsetupLink';
import CompleteProfile from './pages/Teacher/Auth/SignUp/CompleteProfile';
import OCompleteProfile from './pages/Organization/OStep/OCompleteProfile';
import PaymentSuccess from './pages/Teacher/NewLeads/PaymentSuccess';
import AiList from './pages/Teacher/AIVirtual/AiList';
import WhoWeAre from "./pages/Home/WhoWeAre"
import LandingPageBlog from "./pages/Home/Blog/LandingPageBlog"
import LandingPageFaq from "./pages/Home/Faq/LandingPageFaq"
import LandingPageFaqVideo from "./pages/Home/Faq/LandingPageFaqVideo"
import LandingPageContactUs from "./pages/Home/LandingPageContactUs"
import Help from "./pages/Home/Help"
import Term from "./pages/Home/Term"
import NewsLetterDetail from "./pages/Home/NewsLetterDetail"
import LandingPageFaqVideoDetails from "./pages/Home/Faq/LandingPageFaqVideoDetails";
import PclubDetails from "./pages/Parents/SearchClass/Clubs/PClubDetails";
import PQuizzesDetails from "./pages/Parents/SearchClass/Quizzes/PQuizzesDetails";
import PCompetitionsDetails from "./pages/Parents/SearchClass/Competitions/PCompetitionsDetails";
import PFavorites from "./pages/Parents/SearchClass/MyFavorites/PFavorites";
import PreviousClassDetails from "./pages/Parents/New_Leeds/previousClassDetails";
import PPReqClassDetail from "./pages/Parents/ReqClass/PPReqClassDetail";
import EnrollmentCommonDetailPage from "./pages/Parents/MyEnrollment/MyCourseDetail/commonDetail";
import PMyCourseDetailsClub from "./pages/Parents/MyEnrollment/MyCourseDetail/PMyCourseDetailsClub";
import PMyCourseDetailsQuize from "./pages/Parents/MyEnrollment/MyCourseDetail/PMyCourseDetailsQuize";
import PMyCourseDetailsCompetitions from "./pages/Parents/MyEnrollment/MyCourseDetail/PMyCourseDetailsCompetitions";
import PVideoDetails from "./pages/Parents/Faq/PVideoDetails";
import AddLearner from "./pages/Parents/EditProfile/AddLearner";
import EditLearner from "./pages/Parents/EditProfile/EditLearner";
import DiscussionGroupChat from './pages/Teacher/Teach_Details/DiscussionGroupChat';
import DiscussionGroupChatUser from './pages/Parents/MyEnrollment/MyCourseDetail/DiscussionGroupChatUser';
import PNew_Leeds from './pages/Parents/New_Leeds';
import PStartMeeting from './pages/Parents/MyEnrollment/MyCourseDetail/PStartMeeting';
import PEwallet from './pages/Parents/Ewallet/PEwallet';
import TStartMeeting from './pages/Teacher/Teach_Details/TStartMeeting';


// import JqueryLoad from './JqueryLoad/JqueryLoad';

// import React, {useState, useEffect} from 'react';

// export const Loadable = (Component) => (props) => {
//   return (
//     <Suspense>
//       <Component {...props} />
//     </Suspense>
//   );
// };

// const Dashboard = Loadable(lazy(() => import("./pages/Parents/Dashboard/KidsDashboard")));



function App() {
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('../firebase-messaging-sw.js')
  //   .then(function(registration) {
  //     console.log('Registration successful, scope is:', registration.scope);
  //   }).catch(function(err) {
  //     console.log('Service worker registration failed, error:', err);
  //   });
  // }
  //   useEffect(() => {
  //     generateToken(); // Ensure this function is defined

  //     // Listen for incoming messages
  //     onMessage(messaging, (payload) => {
  //         console.log('Received payload:', payload);

  //         // Define the toast configuration
  //         const toastOptions = {
  //             icon: <img width="40px" height="40px" src="./assets/images/logo.png" alt="logo" />,
  //             style: {
  //                 border: '1px solid #07bc0c',
  //                 color: '#000',
  //             },
  //             autoClose: 4000, // Duration of the toast in milliseconds
  //             position: "top-center",
  //         };

  //         // Display the toast notification using react-toastify
  //         toast(payload.notification.body, toastOptions);
  //     });
  // }, []);

  // const navigate = useNavigate(); 
  const [isLoading, setIsLoading] = useState(false);

  const showLoder = (isLoading) => {
    setIsLoading(isLoading)
  }

  // const location = useLocation();
  // const currentPath = location.pathname;
  // console.log('currentPath :', currentPath);
  // if(currentPath != "//teach-detail"){
  //   localStorage.removeItem("Teach_Step")
  // }

  const getFlow = () => {
    if ((localStorage.getItem("TisLogin", false) && (localStorage.getItem("type") == "teacher" || localStorage.getItem("type") == "organization" || localStorage.getItem("type") == "org_teacher")) || (localStorage.getItem("MAisLogin", false) && (localStorage.getItem("userType") == "below_18" || localStorage.getItem("userType") == "above_18" || localStorage.getItem("userType") == "after_school" || localStorage.getItem("userType") == "learner"))
    ) {
      return (
        <>

          {/* {some_path ? <Navbar /> : ""} */}
          {/* <JqueryLoad/> */}
          <Loader isActive={isLoading} />
          {/* <ScrollToTop/> */}
          <Routes >
            <Route path="/no-permission" element={<Nopermission />} />
            <Route path="/organization-teacher/:id" element={<SignUoOTeacher />} />
            <Route path="/signup-teacher" element={<SignupStep />} />
            <Route path="/teacher-application" element={<SignupStep1 />} />
            <Route path="/teacher-idverification" element={<TeacherVerification />} />
            <Route path="/teacher-background" element={<TeacherBgCheck />} />
            <Route path="/organization-step" element={<Ostep />} />
            <Route path="/organization-application" element={<Oapplication />} />
            <Route path="/organization-id-verification" element={<OrganizationidVerification />} />
            <Route path="/organization-onsite-application" element={<OnsiteApplication />} />
            <Route path="/agreement" element={<Oagreement />} />
            <Route path="/organization-complete-profile" element={<OCompleteProfile />} />
            <Route path="/" element={<Home />} />
            <Route path="/who_we_are" element={<WhoWeAre />} />
            <Route path="/landing_page_blogs" element={<LandingPageBlog />} />
            <Route path="/landing_page_faq" element={<LandingPageFaq />} />
            <Route path="/landing_page_faq_video" element={<LandingPageFaqVideo />} />
            <Route path="/landing_page_faq_video_details" element={<LandingPageFaqVideoDetails />} />
            <Route path="/landing_page_contactus" element={<LandingPageContactUs />} />
            <Route path="/help" element={<Help />} />
            <Route path="/term" element={<Term />} />
            <Route path="/news_letter_details" element={<NewsLetterDetail />} />
            <Route path="/tstart-meeting" element={<TStartMeeting />} />

            <Route element={<PrivateComponent />}>

              {/*parent*/}
              {/* <Route path="/class_details_organization" element={<ClassDetail3 />} /> */}

              {/* <Route path="/my_certificates" element={<MyCertificates />} */}

              {/* <Route path="/my_courses_inperson" element={<MyCourseInPerson />} />
           <Route path="/my_courses_offline" element={<MyOfflineCourse />} /> */}
              {/* <Route path="/cls_info_after_join" element={<ClassInfoAfterjoin />} /> */}

              {/* <Route path="/privat_school_about_us" element={<PrivateAbout />} /> */}
              <Route path="/request_a_class_from" element={<RequestClassForm />} />

              {/* parent Routes */}

              <Route path="/request_class" element={<PNew_Leeds isLoader={showLoder} />} />
              <Route path="/dashboard" element={<ParentDashboard isLoader={showLoder} />} />
              <Route path="/messages" element={<PMessage isLoader={showLoder} />} />
              <Route path="/notification" element={<PNotification isLoader={showLoder} />} />
              <Route path="/search_classes" element={<Search_All />} />
              <Route path="/search_class" element={<PSearchClass isLoader={showLoder} />} />
              <Route path="/class_detail/:id" element={<PClassDetail isLoader={showLoder} />} />
              <Route path="/club_detail/:id" element={<PclubDetails isLoader={showLoder} />} />
              <Route path="/quize_detail/:id" element={<PQuizzesDetails isLoader={showLoder} />} />
              <Route path="/competitions_detail/:id" element={<PCompetitionsDetails isLoader={showLoder}/>} />
              <Route path="/favorites" element={<PFavorites isLoader={showLoder} />} />
              <Route path="/onsite_class_details" element={<POnsiteClass />} />
              {/* <Route path="/request_class" element={<PRequestClass />} /> */}
              <Route path="/request_class_form" element={<PReqForm />} />
              <Route path="/sponsored_teachers_details" element={<PReqClassDetail />} />
              <Route path="/request_class_details" element={<PreviousClassDetails />} />
              <Route path="/sponsored_teachers" element={<PPReqClassDetail />} />
              <Route path="/previos_request_class_details" element={<PreviousClassDetails />} />

              <Route path="/teachers_details" element={<PTeacherDetail />} />
              <Route path="/my_enrollments" element={<PMyEnrollment />} />
              <Route path="/my_courses_detail" element={<PMyCourseDetail />} />
              <Route path="/my_courses_detail_classes/:id" element={<EnrollmentCommonDetailPage />} />
              <Route path="/my_courses_details_clubs" element={<PMyCourseDetailsClub />} />
              <Route path="/my_courses_details_clubs/:id" element={<EnrollmentCommonDetailPage />} />
              <Route path="/my_courses_details_quizzes" element={<PMyCourseDetailsQuize />} />
              <Route path="/my_courses_details_quizzes/:id" element={<EnrollmentCommonDetailPage />} />
              <Route path="/my_courses_details_competitions" element={<PMyCourseDetailsCompetitions />} />
              <Route path="/my_courses_details_competitions/:id" element={<EnrollmentCommonDetailPage />} />
              <Route path="/disscussion_group_chat" element={<DiscussionGroupChatUser />} />


              <Route path="/my_course_inperson" element={<PMyCourseInPerson />} />
              <Route path="/my_offline_course" element={<PMyOfflineCourse />} />
              <Route path="/certificates_list" element={<ParentCertificate />} />
              <Route path="/kids_certificates_list" element={<KidsCertificateList />} />
              <Route path="/refer_friend" element={<ReferFriend />} />
              <Route path="/blogs" element={<PBlogs />} />
              <Route path="/blog_details" element={<PBlogDetails />} />
              <Route path="/faqs" element={<PFaq />} />
              <Route path="/faq_details" element={<PFaqDetails />} />
              <Route path="/faq_video_details" element={<PVideoDetails />} />
              <Route path="/Contact_us" element={<PContactUs />} />
              <Route path="/edit_profile" element={<PEditProfile />} />
              <Route path="/add_learner" element={<AddLearner />} />
              <Route path="/edit_learner" element={<EditLearner />} />

              <Route path="/change_password" element={<PChangePassword />} />

              <Route path="/quiz-info" element={<PQuizInfo />} />
              <Route path="/quizlist" element={<PquizList />} />
              <Route path="/quiz-review" element={<PQuizReview />} />
              <Route path="/start-quiz" element={<PStartQuiz />} />

              {/* organization routes */}

              <Route path="/organization_dashboard" element={<ODashboard />} />
              <Route path="/student-questions" element={<OStudentQue />} />
              <Route path="/organization_message" element={<OMessage />} />
              <Route path="/organization-message-list" element={<OmessageList />} />
              <Route path="/organization-notification" element={<ONotification />} />

              {/* <Route path="/organization-history" element={<OrganizationClass />} /> */}
              <Route path="/organization-history" element={<OrganizationHomeList />} />
              <Route path="/organization-add-class" element={<OAddClass />} />
              <Route path="/organization-edit-class" element={<OEditClass />} />
              <Route path="/organization-edit-class/:class_edit_id" element={<OEditClass />} />
              <Route path="/organization-add-competitation" element={<OAddCompetitation />} />
              <Route path="/organization-edit-competitation" element={<OEditCompetitation />} />
              <Route path="/organization-edit-competitation/:class_edit_id" element={<OEditCompetitation />} />
              <Route path="/organization-add-club" element={<OAddClub />} />
              <Route path="/organization-edit-club" element={<OEditClub />} />
              <Route path="/organization-edit-club/:class_edit_id" element={<OEditClub />} />
              <Route path="/organization-add-quiz" element={<OAddQuiz />} />
              <Route path="/organization-add-questions" element={<OAddQue />} />
              <Route path="/organization-questions-list" element={<OQueList />} />
              <Route path="/organization-add-another-question" element={<OAddAnotherQuestion />} />
              <Route path="/organization-edit-question/:id" element={<OEditQuestion />} />
              <Route path="/organization-all-quiz" element={<OAllQuizList />} />
              <Route path="/organization-edit-quiz" element={<OEditQuiz />} />
              <Route path="/organization-edit-quiz/:class_edit_id" element={<OEditQuiz />} />
              <Route path="/quiz_info" element={<OQuizInfo />} />
              {/* <Route path="/search_classes" element={<OSearchClass />} /> */}
              <Route path="/organization-search-classes" element={<OSearch_All />} />
              <Route path="/teacher-profile-detail" element={<OteacherProfileDetail />} />
              <Route path="/class-details" element={<OClass_Details />} />
              <Route path="/class-details/:id" element={<OClass_Details />} />
              <Route path="/club-details" element={<OClub_Details />} />
              <Route path="/club-details/:id" element={<OClub_Details />} />
              <Route path="/quiz-details" element={<OQuiz_Details />} />
              <Route path="/quiz-details/:id" element={<OQuiz_Details />} />
              <Route path="/competition-details" element={<OCompetition_Details />} />
              <Route path="/competition-details/:id" element={<OCompetition_Details />} />
              <Route path="/onsite-class-detail" element={<OnsiteClass_Detail />} />
              <Route path="/onsite-class-detail/:id" element={<OnsiteClass_Detail />} />
              {/* <Route path="/teacher_class_details" element={<OClassDetail />} />
              <Route path="/class_onsite_details" element={<OClassOnSiteDetail />} /> */}
              {/* <Route path="/req_class_detail" element={<ORequestClass />} /> */}
              <Route path="/request-class-detail" element={<RequestClassO isLoader={showLoder} />} />
              <Route path="/become-prefer-teacher-form" element={<FillReqForm />} />
              <Route path="/req_class_all_details" element={<OReqClassDetail />} />
              <Route path="/responded-class-detail" element={<OrespondClassDetail />} />
              <Route path="/rejected-class-detail" element={<ORejectedDetail />} />
              <Route path="/manage-teacher" element={<ManageTeacher isLoader={showLoder} />} />
              <Route path="/teacher-give-access" element={<SidebarList />} />
              <Route path="/teacher-detail" element={<ManageTeacherDetails />} />
              <Route path="/my_detail" element={<OTecherDetail />} />

              <Route path="/teach_list" element={<OTeachList />} />
              <Route path="/organization-coupon-list" element={<OMyCoupon />} />
              <Route path="/organization-coupon-details" element={<OMyPurchaseDetail />} />
              <Route path="/organization-add-coupon" element={<OAddCoupon />} />

              {/* <Route path="/teach_detail" element={<OTeachDetail />} /> */}
              <Route path="/organization-teach-list" element={<OTeach_new isLoader={showLoder} />} />
              <Route path="/organization-tech-detail" element={<OTeachDetails />} />
              <Route path="/organization-tech-detail/:id" element={<OTeachDetails />} />
              <Route path="/organizaion-add-lesson" element={<OAddLesson />} />
              <Route path="/organizaion-edit-lesson" element={<OEditLesson />} />
              <Route path="/organizaion-add-homework" element={<OAddHomework />} />
              <Route path="/organizaion-homework-response" element={<HomworkOResponse />} />
              <Route path="/organizaion-assessment-response" element={<AssessmentOresponse />} />
              <Route path="/organizaion-add-assessment" element={<OAddAssessment />} />
              <Route path="/organizaion-edit-assessment" element={<OEditAssessment />} />
              <Route path="/organizaion-add-discussion" element={<OAddDisscussion />} />
              <Route path="/organizaion-edit-disscussion" element={<OEditDisscussion />} />
              <Route path="/organization-discussion-response" element={<DisscussionOResponse />} />
              <Route path="/organization-view-details-page" element={<OViewDetailPage />} />
              <Route path="/organization-view-details-classroom" element={<OViewDetailsCommon />} />

              <Route path="/organization-view-details-quiz" element={<QuizVIewDetailO />} />
              <Route path="/organization-view-details-quiz/:id" element={<QuizVIewDetailO />} />
              <Route path="/organization-start-quiz" element={<QuizStartO />} />
              <Route path="/organization-quiz-review" element={<QuizReviewO />} />

              <Route path="/teach_detail_in_person" element={<OTeachDetailInPerson />} />
              <Route path="/teach_detail_offline" element={<OTeachDetailOffline />} />
              <Route path="/organization-favourite-list" element={<OrganizationFavourite />} />
              <Route path="/organization-setup-account" element={<OAddStripe />} />
              <Route path="/organization-setup-stripe-account/:id/:id" element={<OsetupLink isLoader={showLoder} />} />
              {/* 
          <Route path="/add_Lessons" element={<OAddLesson />} />
          <Route path="/add_Homework" element={<OAddHomework />} />
          <Route path="/add_assignment" element={<OAddAssignment />} />
          <Route path="/add_discussions" element={<OAddDisscussion />} /> */}

              <Route path="/organization-scheduling" element={<OSetupClass />} />
              <Route path="/upcoming_meeting_" element={<OUpcomingMeeting />} />

              <Route path="/organization-earning" element={<OMyEarning isLoader={showLoder} />} />
              <Route path="/organization-e-wallet" element={<OEwallet isLoader={showLoder} />} />
              <Route path="/organization-balance" element={<OEwalletBalance />} />

              <Route path="/organization_payment" element={<OPaymentSetting />} />
              <Route path="/organization_payment_details" element={<OPaymentDetails />} />

              <Route path="/refer_a_learner" element={<OReferFriend />} />

              <Route path="/organization_blog" element={<Oblogs />} />
              <Route path="/organization_blog_details" element={<OBlogDetails />} />

              <Route path="/organization_faq" element={<OFaq />} />
              <Route path="/organization_faq_details" element={<OFaqDetail />} />
              <Route path="/organization_video_details" element={<OVideoDetails />} />

              <Route path="/organization_contactus" element={<OContactUs />} />
              <Route path="/organization-editprofile" element={<OEditProfile />} />
              <Route path="/organization-profile-details" element={<OProfileDetail />} />
              <Route path="/organization-profile-details/:id" element={<OProfileDetail />} />
              <Route path="/organization_changePassword" element={<OChangePassword />} />

              {/* Teacher Routes */}

              <Route path="/teacher_dashboard" element={<TeacherDashboard />} />
              <Route path="/setup-account" element={<AddStripeAccount />} />
              <Route path="/teacher-setup-account/:id/:id" element={<SetupLink isLoader={showLoder} />} />
              <Route path="/teacher-e-wallet" element={<Ewallet isLoader={showLoder} />} />
              <Route path="/teacher-balance" element={<EwalletBalance isLoader={showLoder} />} />
              <Route path="/teacher-complete-profile" element={<CompleteProfile />} />

              {/* <Route path="/teachers_my_class" element={<TeacherClass />} /> */}
              <Route path="/teachers_my_class" element={<Teacher_Class />} />
              <Route path="/setup_your_class" element={<SetupClass />} />
              <Route path="/edit-your-class" element={<EditClass />} />
              <Route path="/edit-your-class/:class_edit_id" element={<EditClass />} />

              <Route path="/setup_my_club" element={<SetupClub />} />
              <Route path="/edit-your-club" element={<EditClub />} />
              <Route path="/edit-your-club/:class_edit_id" element={<EditClub />} />

              <Route path="/setup_my_club" element={<SetupClub />} />
              <Route path="/setup_my_quiz" element={<SetupQuiz />} />
              <Route path="/edit-quiz" element={<EditQuiz />} />
              <Route path="/edit-quiz/:class_edit_id" element={<EditQuiz />} />
              <Route path="/add_question" element={<AddQue />} />
              <Route path="/question_list" element={<QueList />} />
              <Route path="/edit-question/:id" element={<EditQuestion />} />
              <Route path="/add-another-question" element={<AddAnotherQuestion />} />

              <Route path="/teacher_quiz_info" element={<TeacherQuiz />} />
              <Route path="/teacher-all-quiz" element={<ALL_Quiz />} />

              <Route path="/setup_my_competition" element={<AddCompetitation />} />
              <Route path="/edit-competition" element={<EditCompetitation />} />
              <Route path="/edit-competition/:class_edit_id" element={<EditCompetitation />} />


              <Route path="/teacher_message" element={<TMessage />} />
              <Route path="/teacher-notification" element={<Notification />} />
              <Route path="/teacher_changePassword" element={<Changepassword />} />
              <Route path="/teacher_editProfile" element={<EditProfile />} />

              <Route path="/help_contactus" element={<ContactUs />} />
              <Route path="/teacher_faq" element={<Faqs />} />
              <Route path="/teacher_faqDetails" element={<FaqDetails />} />
              <Route path="/teacher_faqVideoDetails" element={<VideoDetails />} />

              <Route path="/teacher_blog" element={<Blog />} />
              <Route path="/teacher_blogDetails" element={<BlogDetails />} />
              <Route path="/teacher_refer" element={<Refer />} />
              <Route path="/teacher-earning" element={<MyEarning isLoader={showLoder} />} />
              <Route path="/org-teacher-earning" element={<OrgTeacherEarning />} />
              <Route path="/teacher_searchClass" element={<Search_All isLoader={showLoder} />} />
              <Route path="/class_detail" element={<TClassDetail />} />
              <Route path="/class_detail/:id" element={<TClassDetail />} />
              <Route path="/club-detail" element={<TClubDetails />} />
              <Route path="/club-detail/:id" element={<TClubDetails />} />

              <Route path="/quiz-detail" element={<TQuizDetails />} />
              <Route path="/quiz-detail/:id" element={<TQuizDetails />} />
              <Route path="/competition-detail" element={<TCompetitionDetails />} />
              <Route path="/competition-detail/:id" element={<TCompetitionDetails />} />
              <Route path="/class_detail_onsite" element={<TOnsiteClassDetail />} />
              <Route path="/class_detail_onsite/:id" element={<TOnsiteClassDetail />} />
              {/* <Route path="/new_leads" element={<NewLeeds />} /> */}
              <Route path="/new_leads" element={<New_Leeds isLoader={showLoder} />} />
              <Route path="/preferred_teacher" element={<PreferTeacher />} />
              <Route path="/request_class_detail" element={<ReqClassDetail />} />
              <Route path="/responded_class_detail" element={<RespondedClassDetails />} />
              <Route path="/rejected_class_detail" element={<RejectedClassDetails />} />
              <Route path="/my_coupon" element={<MyCoupon />} />
              <Route path="/my_purchase_detail" element={<MyPurchaseCoupon />} />
              <Route path="/add_coupon" element={<AddCoupon />} />
              <Route path="/scheduling_visibility" element={<TSetupClass />} />
              <Route path="/upcoming_meeting" element={<UpcomingMeeting />} />
              {/* <Route path="/teach-detail" element={<TeachDetail />} /> */}
              <Route path="/tech_detail_offline" element={<TechDetailOffline />} />
              {/* <Route path="/view-details-classroom" element={<ViewDetailsCommonPage />} /> */}
              <Route path="/view-details-classroom" element={<ViewDetailsCommon />} />
              <Route path="/view-details-page" element={<ViewDetailPage />} />
              <Route path="/view-details-quiz" element={<QuizVIewDetail />} />
              <Route path="/view-details-quiz/:id" element={<QuizVIewDetail />} />
              <Route path="/teacher-start-quiz" element={<QuizStartT />} />
              <Route path="/teacher-quiz-review" element={<QuizReviewT />} />
              <Route path="/add_lesson" element={<AddLesson />} />
              <Route path="/edit-lesson" element={<EditLesson />} />
              <Route path="/add-homework" element={<AddHomework />} />
              <Route path="/homework-response" element={<HomeworkResponse />} />
              <Route path="/add_assessment" element={<AddAssessment />} />
              <Route path="/edit-assessment" element={<EditAssessment />} />
              <Route path="/assesment-response" element={<AssessmentResponse />} />
              <Route path="/teacher-favourite-list" element={<TeacherFavourite isLoader={showLoder} />} />

              <Route path="/add_discussion" element={<AddDisscussion />} />
              <Route path="/discussion-response" element={<DisscussionResponse />} />
              <Route path="/discussion_group_chat" element={<DiscussionGroupChat />} />

              <Route path="/edit-disscussion" element={<EditDisscussion />} />
              <Route path="/my_class_details" element={<MyDetail />} />
              <Route path="/my_class_details/:id" element={<MyDetail />} />
              <Route path="/teacher-profile-details" element={<TeacherProfileDetails />} />
              <Route path="/questions-of-students" element={<Allquestion />} />
              {/* <Route path="/AgoraVideoCall" element={<AgoraVideoCall />} /> */}

              <Route path="/teach-list" element={<Teach_new isLoader={showLoder} />} />

              <Route path="/teach-detail" element={<Teach_Details isLoader={showLoder} />} />
              <Route path="/teach-detail/:id" element={<Teach_Details isLoader={showLoder} />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/ai-vertual-tutor" element={<AiList />} />
              <Route path="*" element={<RedirectBlankPageHome />} />

              <Route path="/pstart-meeting" element={<PStartMeeting />} />
              <Route path="/ewallet" element={<PEwallet isLoader={showLoder} />} />
            </Route>
          </Routes>
        </>
      )
    }
    else {
      return (
        <>
          {/* {some_path ? <Navbar /> : ""} */}
          <Loader isActive={isLoading} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login isLoader={showLoder} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/sign_in_step1" element={<Signup1 />} />
            <Route path="/sign_in_step2" element={<Signup2 isLoader={showLoder} />} />
            <Route path="/sign_in_step3" element={<Signup3 isLoader={showLoder} />} />
            <Route path="/sign_in_after_school_step3" element={<AfterSchoolSignup isLoader={showLoder} />} />
            <Route path="/sign_up" element={<Signup23 isLoader={showLoder} />} />
            <Route path="/organization-teacher/:id" element={<SignUoOTeacher />} />
            <Route path="/signup-teacher" element={<SignupStep />} />
            <Route path="/teacher-application" element={<SignupStep1 />} />
            <Route path="/teacher-idverification" element={<TeacherVerification />} />
            <Route path="/teacher-background" element={<TeacherBgCheck />} />
            <Route path="/teacher-complete-profile" element={<CompleteProfile />} />
            <Route path="/organization-step" element={<Ostep />} />
            <Route path="/organization-application" element={<Oapplication />} />
            <Route path="/organization-id-verification" element={<OrganizationidVerification />} />
            <Route path="/organization-onsite-application" element={<OnsiteApplication />} />
            <Route path="/agreement" element={<Oagreement />} />
            <Route path="/organization-complete-profile" element={<OCompleteProfile />} />.

            <Route path="/who_we_are" element={<WhoWeAre />} />
            <Route path="/landing_page_blogs" element={<LandingPageBlog />} />
            <Route path="/landing_page_faq" element={<LandingPageFaq />} />
            <Route path="/landing_page_faq_video" element={<LandingPageFaqVideo />} />
            <Route path="/landing_page_faq_video_details" element={<LandingPageFaqVideoDetails />} />
            <Route path="/landing_page_contactus" element={<LandingPageContactUs />} />
            <Route path="/help" element={<Help />} />
            <Route path="/term" element={<Term />} />
            <Route path="/news_letter_details" element={<NewsLetterDetail />} />

            {/* <Route path="/organization_step" element={<VerificationView />} />
            <Route path="/agreement_form" element={<AgreementForm />} />
            <Route path="/location-form" element={<LocationApproval />} /> */}
            <Route path="*" element={<RedirectBlankPage />} />
            <Route path="/tstart-meeting" element={<TStartMeeting />} />


          </Routes>
        </>
      )
    }
  }

  return (
    <>
      {getFlow()}
    </>
  );
}


export default App;
