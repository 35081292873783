import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { bank_provider_list, create_account_link } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { NoDataComponent } from '../../../utils/common.service'

export default function AddStripeAccount() {
    const navigate = useNavigate();
    const [bankData, setBankData] = useState([])

    const AddAccount = async () => {
        try {
            const response = await create_account_link();
            console.log('create_account_link :', response);
            if (response?.code == 1) {
                window.open(response?.data?.url, '_blank')
            } else {
                console.log("no data found")
            }
        } catch (err) {
            console.log('err :', err);
        }
    }
    const ShowData = async () => {
        try {
            const response = await bank_provider_list();
            console.log('bank_provider_list :', response);
            if (response?.code == 1) {
                setBankData(response?.data)
            } else {
                console.log("no data found")
            }
        } catch (err) {
            console.log('err :', err);
        }
    }

    useEffect(() => {
        ShowData();
    }, [])

    const columns = [
        {
            name: 'Id',
            selector: (row, index) => index + 1,
            cellExport: (row, index) => index + 1,
            sortable: true,
            width: '75px'
        },
        {
            name: 'User Name',
            selector: row => row.holder_name,
            sortable: true,
            cellExport: row => row.holder_name,
        },
 
        {
            name: 'AccountId',
            selector: row => row.bank_account_id,
            sortable: true,
            cellExport: row => row.bank_account_id
        },
        {
            name: 'Created Date',
            selector: row => dayjs(row.created_at).format('D MMM, YYYY'),
            sortable: true,
            cellExport: row => dayjs(row.created_at).format('D MMM, YYYY')
        },
        {
            name: 'Status',
            selector: row => (row.is_verified == "1" ? "Verfied" : "Unverified"),
            sortable: true,
            cellExport: row => row.is_verified
        },

    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
    };

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
<Helmet>
    <style>{`
             .data-table-extensions > .data-table-extensions-action > button {
                float: right;
                display: none;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: transparent;
                border: none;
                cursor: pointer;
                padding: 5px;
                border-radius: 0.3rem;
                outline: none;
                margin-right: 0.3rem;
            }
            .data-table-extensions {
              
                width: 100%;
                box-sizing: border-box;
                padding: 0.7rem 1.2rem;
                display: none;
            }
            .hQPXLq {
                display: flex;
                width: 100%;
                color: #570861;
                font-size: 12px;
                font-weight: 500;
            }
    `}</style>
</Helmet>
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div>
                                    <div className="row">
                                        <div className="col-xl-9">
                                            <div className="d-flex mb-4 justify-content-between">
                                                <h2 className>Teacher Account</h2>
                                            </div>
                                            <div className="lead_request pt-2">
                                                <div className="">
                                                    <div className="lead_req_img d-flex position-relative align-items-center">
                                                        <div className="ms-3">
                                                            <span className="fw-600"></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-md-flex align-items-end  justify-content-between ">
                                                        <Link onClick={(e) => AddAccount()} className="text-purple fs-5 text-decoration-underline" >Setup My Account</Link>
                                                        <div className="button">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    <div className="row mt-4">
                                            <DataTableExtensions columns={columns} data={bankData}>
                                                <DataTable columns={columns} data={bankData} responsive pagination customStyles={customStyles} noDataComponent={<NoDataComponent />}/>
                                            </DataTableExtensions>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <TFooter_Dashboard />
        </>
    )
}
