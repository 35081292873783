import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';

import { org_teacher_permission_listing, teach_class, teach_club, teach_competation, teach_quiz } from '../../../api/apiHandler'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import Teach_filter from './Teach_filter'
import Teach_Header from './Teach_Header'
import Teach_Map from './Teach_Map'

export default function Teach_new({ isLoader }) {

  const navigate = useNavigate();

  // const PermissionList = () => {
  //   org_teacher_permission_listing().then((response) => {
  //   console.log('org_teacher_permission_listing :', response);
  //     if (response?.code == 1) {
  //       var P_message = response.data[0]?.teacherData[0].teach
  //       console.log('P_message :', P_message);
  //       if(P_message == "0"){
  //          navigate("/no-permission");
  //       }
  //     } else {
  //       console.log('data not found')
  //     }
  //   });
  // }

  // useEffect(() => {
  //   PermissionList();
  // }, []);

  const handleStepChange = (newStep) => setStep(newStep);

  const [step, setStep] = useState(1);


  const [data, setData] = useState([]);
  const [clubData, setClubData] = useState([]);
  const [competitionData, setCompetitionData] = useState([]);
  const [quizData, setQuizData] = useState([]);

  const [classSize, setclassSize] = useState('')
  const [classType, setclassType] = useState('')
  const [classPrice, setclassPrice] = useState('')
  const [classAge, setclassAge] = useState('')
  const [classLength, setclassLength] = useState('')
  const [classLanguage, setclassLanguage] = useState('')
  const [classEnrollment, setclassEnrollment] = useState('')
  const [DateApply, setDateApply] = useState('')
  const [DateApplydata, setDateApplydata] = useState('')
  const [SortData, setSortData] = useState('Relevance')
  const [selectedDaysSend, setselectedDaysSend] = useState([]);

  const [StartTimeSend, setStartTimeSend] = useState('');
  const [EndTimeSend, setEndTimeSend] = useState('');
  const [SearchTab, setSearchTab] = useState('');
  const [CategoriesData, setCategoriesData] = useState("Academic")
  const [subCategoriesData, setSubCategoriesData] = useState([])
  const [CountryName, setCountryName] = useState('');

  var [stateList, setstateList] = useState('');

  const currentDate = dayjs();

  // Set the default value to the current year, month, and day
  const defaultValue = {
    year: currentDate.year(),
    month: currentDate.month() + 1,  // Month in Day.js starts from 0
    day: currentDate.date(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  var search_item = {};

  const teacherClassListing = () => {
    if (CountryName) {
      search_item.country = CountryName
    }
    if (stateList) {
      search_item.state = stateList
    }
    if (SearchTab) {
      search_item.search = SearchTab
    }
    if (subCategoriesData.length > 0) {
      search_item.main_category = CategoriesData
      search_item.sub_category = subCategoriesData
    }
    if (classSize) {
      search_item.class_type = classSize
    }
    if (classType) {
      search_item.class_size = classType
    }
    if (classPrice) {
      search_item.price = classPrice
    }
    if (classAge) {
      search_item.age = classAge
    }
    if (classLength) {
      search_item.class_length = classLength
    }
    if (classLanguage) {
      search_item.class_language = classLanguage
    }
    if (classEnrollment) {
      search_item.enrollments = classEnrollment
    }
    if (selectedDaysSend.length > 0) {
      search_item.daywise = selectedDaysSend
      search_item.start_time = StartTimeSend
      search_item.end_time = EndTimeSend
    }
    if (DateApply) {
      search_item.datewise = DateApply
      if (DateApply == 'Custom dates') {
        search_item.start_date = DateApplydata.from.year + "-" + DateApplydata.from.month + "-" + DateApplydata.from.day
        search_item.end_date = DateApplydata.to.year + "-" + DateApplydata.to.month + "-" + DateApplydata.to.day
      }
    }
    isLoader(true)

    teach_class(search_item).then((response) => {
      console.log("teach_class",response)
      isLoader(false)

      if (response?.code == 1) {
        setData(response.data)
      } else {
        setData([])

      }
    });
  }

  const teacherClubListing = () => {
    if (CountryName) {
      search_item.country = CountryName
    }
    if (stateList) {
      search_item.state = stateList
    }
    if (SearchTab) {
      search_item.search = SearchTab
    }
    if (subCategoriesData.length > 0) {
      search_item.main_category = CategoriesData
      search_item.sub_category = subCategoriesData
    }
    if (classSize) {
      search_item.class_type = classSize
    }
    if (classType) {
      search_item.class_size = classType
    }
    if (classPrice) {
      search_item.price = classPrice
    }
    if (classAge) {
      search_item.age = classAge
    }
    if (classLength) {
      search_item.class_length = classLength
    }
    if (classLanguage) {
      search_item.class_language = classLanguage
    }
    if (classEnrollment) {
      search_item.enrollments = classEnrollment
    }
    if (selectedDaysSend.length > 0) {
      search_item.daywise = selectedDaysSend
      search_item.start_time = StartTimeSend
      search_item.end_time = EndTimeSend
    }
    if (DateApply) {
      search_item.datewise = DateApply
      if (DateApply == 'Custom dates') {
        search_item.start_date = DateApplydata.from.year + "-" + DateApplydata.from.month + "-" + DateApplydata.from.day
        search_item.end_date = DateApplydata.to.year + "-" + DateApplydata.to.month + "-" + DateApplydata.to.day
      }
    }
    isLoader(true)

    teach_club(search_item).then((response) => {
    console.log('teach_club :', response);
      isLoader(false)

      if (response?.code == 1) {
        setClubData(response.data)
      } else {
        setClubData([])
      }
    });
  }

  const teacherCompetitationListing = () => {
    if (CountryName) {
      search_item.country = CountryName
    }
    if (stateList) {
      search_item.state = stateList
    }
    if (SearchTab) {
      search_item.search = SearchTab
    }
    if (subCategoriesData.length > 0) {
      search_item.main_category = CategoriesData
      search_item.sub_category = subCategoriesData
    }
    if (classSize) {
      search_item.class_type = classSize
    }
    if (classType) {
      search_item.class_size = classType
    }
    if (classPrice) {
      search_item.price = classPrice
    }
    if (classAge) {
      search_item.age = classAge
    }
    if (classLength) {
      search_item.class_length = classLength
    }
    if (classLanguage) {
      search_item.class_language = classLanguage
    }
    if (classEnrollment) {
      search_item.enrollments = classEnrollment
    }
    if (selectedDaysSend.length > 0) {
      search_item.daywise = selectedDaysSend
      search_item.start_time = StartTimeSend
      search_item.end_time = EndTimeSend
    }
    if (DateApply) {
      search_item.datewise = DateApply
      if (DateApply == 'Custom dates') {
        search_item.start_date = DateApplydata.from.year + "-" + DateApplydata.from.month + "-" + DateApplydata.from.day
        search_item.end_date = DateApplydata.to.year + "-" + DateApplydata.to.month + "-" + DateApplydata.to.day
      }
    }
    isLoader(true)

    teach_competation(search_item).then((response) => {
      isLoader(false)

      if (response?.code == 1) {
        setCompetitionData(response.data)
      } else {
        setCompetitionData([])
      }
    });
  }

  const teacherQuizListing = () => {
    if (CountryName) {
      search_item.country = CountryName
    }
    if (stateList) {
      search_item.state = stateList
    }
    if (SearchTab) {
      search_item.search = SearchTab
    }
    if (subCategoriesData.length > 0) {
      search_item.main_category = CategoriesData
      search_item.sub_category = subCategoriesData
    }
    if (classSize) {
      search_item.class_type = classSize
    }
    if (classType) {
      search_item.class_size = classType
    }
    if (classPrice) {
      search_item.price = classPrice
    }
    if (classAge) {
      search_item.age = classAge
    }
    if (classLength) {
      search_item.class_length = classLength
    }
    if (classLanguage) {
      search_item.class_language = classLanguage
    }
    if (classEnrollment) {
      search_item.enrollments = classEnrollment
    }
    if (selectedDaysSend.length > 0) {
      search_item.daywise = selectedDaysSend
      search_item.start_time = StartTimeSend
      search_item.end_time = EndTimeSend
    }
    if (DateApply) {
      search_item.datewise = DateApply
      if (DateApply == 'Custom dates') {
        search_item.start_date = DateApplydata.from.year + "-" + DateApplydata.from.month + "-" + DateApplydata.from.day
        search_item.end_date = DateApplydata.to.year + "-" + DateApplydata.to.month + "-" + DateApplydata.to.day
      }
    }
    isLoader(true)
    teach_quiz(search_item).then((response) => {
      isLoader(false)

      if (response?.code == 1) {
        setQuizData(response.data)
      } else {
        setQuizData([])
      }
    });
  }


  useEffect(() => {

    if (step == 1) {
      teacherClassListing();
    }
    else if (step == 2) {
      teacherClubListing();
    }
    else if (step == 3) {
      teacherQuizListing();
    } else {
      teacherCompetitationListing();
    }
  }, [step, SearchTab, classSize, classType, classPrice, classAge, classLength, classLanguage, classEnrollment, selectedDaysSend, DateApply, CategoriesData, subCategoriesData, CountryName, stateList]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Teach_Map setData={setData} data={data} step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} classEnrollment={classEnrollment} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList} />
        );
      case 2:
        return (
          <Teach_Map setData={setClubData} data={clubData} step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} classEnrollment={classEnrollment} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList} />
        );
      case 3:
        return (
          <Teach_Map setData={setQuizData} data={quizData} step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} classEnrollment={classEnrollment} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList} />
        );
      case 4:
        return (
          <Teach_Map setData={setCompetitionData} data={competitionData} step={step} SearchTab={SearchTab} classSize={classSize} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} classLanguage={classLanguage} classEnrollment={classEnrollment} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} CountryName={CountryName} stateList={stateList} />

        );

      default:
        return null;
    }
  };

  return (
    <>
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span>
              </a>
              {/*end*/}
              <Teach_Header step={step} onStepChange={handleStepChange} />
              <Teach_filter step={step} SearchTab={SearchTab} setSearchTab={setSearchTab} classSize={classSize} setclassSize={setclassSize} classType={classType} setclassType={setclassType} classPrice={classPrice} setclassPrice={setclassPrice} classAge={classAge} setclassAge={setclassAge} classLength={classLength} setclassLength={setclassLength} classLanguage={classLanguage} setclassLanguage={setclassLanguage} classEnrollment={classEnrollment} setclassEnrollment={setclassEnrollment} selectedDay={selectedDay} setSelectedDay={setSelectedDay} DateApply={DateApply} setDateApply={setDateApply} DateApplydata={DateApplydata} setDateApplydata={setDateApplydata} SortData={SortData} setSortData={setSortData} selectedDaysSend={selectedDaysSend} setselectedDaysSend={setselectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} setStartTimeSend={setStartTimeSend} setEndTimeSend={setEndTimeSend} CategoriesData={CategoriesData} setCategoriesData={setCategoriesData} subCategoriesData={subCategoriesData} setSubCategoriesData={setSubCategoriesData} CountryName={CountryName} setCountryName={setCountryName} stateList={stateList} setstateList={setstateList} />
              {renderStep()}
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
