import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { assetment_upload_listing, assetment_upload_marks, learner_name_list } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { htmlToText } from 'html-to-text';

var COMMON_STYLE_FOR_POPUP = {
    content: {
        background: 'none',
        border: '0px',
    },

}

const TruncateText = ({ text, length }) => {
    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    // Convert HTML to plain text
    const plainText = htmlToText(text || '', {
        wordwrap: false,
        ignoreImage: true,
        ignoreHref: true,
    });

    const truncatedText = truncateString(plainText, length);
    return (
        <p
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            className="fs-6"
            style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
        />
    );
};

export default function AssessmentResponse() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const location = useLocation();
    const assesment_id = location?.state?.assessment_id
    console.log('assesment_id :', assesment_id);
    console.log('assesment_id :', location?.state);

    const activity_id = location?.state?.id?.id
    const activity_type = location?.state?.id?.class

    const [data, setData] = useState([])
    const [learnerData, setLearnerData] = useState([]);


    const learner_listing = () => {

        learner_name_list({ activity_id: activity_id, activity_type: activity_type }).then((response) => {
            console.log("learner_name_list", response)

            if (response?.code == 1) {
                setLearnerData(response.data)
            } else {
                console.log('data not found')
            }
        });
    }
    useEffect(() => {
        learner_listing();
        AllAssesmment(0);
    }, []);

    const handleSelectedLearner = (data) => {
        console.log('data123 :', data);
        var main = JSON.parse(data);
        console.log('main :', main);
        if (main != 0) {
            AllAssesmment(main)
        } else {
            AllAssesmment(0)

        }
    }


    const AllAssesmment = async (main) => {
        console.log('main :', main);
        try {
            let Homework_data = {
                assesment_id: assesment_id,
            }
            if (main != 0) {
                if (main.type == 'user') {
                    Homework_data.userId = main.id
                } else {
                    Homework_data.learnerId = main.id
                }
            }
            const response = await assetment_upload_listing(Homework_data)
            console.log('response333 :', response);
            if (response?.code == 1) {
                setData(response.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        AllAssesmment();
    }, [])

    const [modalIsOpen, setIsOpen] = useState(false);
    const schema = yup.object().shape({
        teacher_comment: yup.string().required("This is required "),

    });
    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const schemaMobile = yup.object().shape({

    });

    const editTempFormOption = { resolver: yupResolver(schemaMobile) };
    const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, reset: reset1, formState: { errors: errors1 } } = useForm(editTempFormOption);

    const [learnerID, setLearnerID] = useState(null);
    console.log('learnerID :', learnerID);
    const [userId, setUserId] = useState(null);
    console.log('userId :', userId);

    let AddComment = async (learner_id, user_id) => {

        setLearnerID(learner_id)
        setUserId(user_id)
        setIsOpen(true)
    };

    let onSubmit = async (data) => {
        console.log('data :', data);
        let teacherComment = {
            assesment_id: assesment_id,
            comment: data.teacher_comment,

        }
        if (learnerID != null && learnerID != '') {
            teacherComment.learnerId = learnerID
        }
        if (userId != null && userId != '') {
            teacherComment.userId = userId
        }
        console.log('teacherComment :', teacherComment);

        assetment_upload_marks(teacherComment).then((response) => {

            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                setIsOpen(false)
                reset();
            } else {
                TOAST_ERROR(response.message);
            }
        });


    }

    const Reupload = (learner_id, user_id) => {

        // alert(id)

        Swal.fire({
            title: 'Are you sure you want the learner to reupload it?',
            //   text: "You won't be able to revert this!",
            icon: 'question',
            background: 'white',
            showCancelButton: true,
            confirmButtonColor: '#570861',
            cancelButtonColor: '#FFC924',
            confirmButtonText: 'Yes, Reupload it!',
            cancelButtonClass: 'swal-cancel-button',
        }).then((result) => {
            if (result.isConfirmed) {
                setLearnerID(learner_id)
                setUserId(user_id)
                let reUpload = {
                    assesment_id: assesment_id,
                    is_reupload: "1",
                }
                if (learner_id != null && learner_id != '') {
                    reUpload.learnerId = learner_id
                }
                if (user_id != null && user_id != '') {
                    reUpload.userId = user_id
                }
                console.log('reUpload :', reUpload);

                assetment_upload_marks(reUpload).then((response) => {
                    if (response?.code == 1) {
                        // TOAST_SUCCESS(response.message);
                        AllAssesmment();

                    } else {
                        TOAST_ERROR(response.message);
                    }
                })
            }
        })

    }

    const onSubmitMobile = (data, index) => {

        let reUpload = {
            assesment_id: assesment_id,
            score: data[`score_${index}`],
        }
        if (data[`learner_id_${index}`] != null && data[`learner_id_${index}`] != '') {
            reUpload.learnerId = data[`learner_id_${index}`]
        }
        if (data[`user_id_${index}`] != null && data[`user_id_${index}`] != '') {
            reUpload.userId = data[`user_id_${index}`]
        }

        assetment_upload_marks(reUpload).then((response) => {
            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                setValue1(`score_${index}`, '')
                // AllAssesmment();
                AllAssesmment(0);

            } else {
                TOAST_ERROR(response.message);
            }
        })
    }

    const DownloadHomework = async (homeworkUrl) => {
        try {
            // Fetch the file from the given URL
            const response = await fetch(homeworkUrl);

            // Check if the response is okay
            if (!response.ok) {
                throw new Error(`Failed to fetch the file: ${response.statusText}`);
            }

            // Convert the response to a blob
            const fileBlob = await response.blob();

            // Determine the file type (mime type) and file extension
            let fileType = fileBlob.type;  // Use blob's type if available
            let fileExtension = '';

            if (homeworkUrl.endsWith('.pdf')) {
                fileType = 'application/pdf';
                fileExtension = 'pdf';
            } else if (homeworkUrl.endsWith('.docx')) {
                fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                fileExtension = 'docx';
            } else if (homeworkUrl.endsWith('.xls')) {
                fileType = 'application/vnd.ms-excel';
                fileExtension = 'xls';
            } else if (homeworkUrl.endsWith('.xlsx')) {
                fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                fileExtension = 'xlsx';
            } else if (homeworkUrl.endsWith('.png')) {
                fileType = 'image/png';
                fileExtension = 'png';
            } else if (homeworkUrl.endsWith('.jpg') || homeworkUrl.endsWith('.jpeg')) {
                fileType = 'image/jpeg';
                fileExtension = 'jpg';
            } else {
                // Default to blob's type if file extension is not specified
                fileExtension = fileBlob.type.split('/')[1] || 'file';
            }

            // Create a random file name using the current timestamp and the file extension
            const randomName = `downloaded_file_${Date.now()}.${fileExtension}`;

            // Create a File object using the blob, random file name, and mime type
            const file = new File([fileBlob], randomName, { type: fileType });

            // Create a URL for the file
            const fileUrl = URL.createObjectURL(file);

            // Create an anchor element to trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = randomName;

            // Trigger the download
            downloadLink.click();

            // Release the object URL to free up resources
            URL.revokeObjectURL(fileUrl);

            console.log('Download started.');
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <Helmet>
                <style>
                    {`
                    .class-content {
                        padding: 18px 30px;
                    }
                    .btn-theme {
                        padding: 8px 50px;
                    }
                    `}
                </style>
            </Helmet>
            <main className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="#" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="class-content ">
                    {/*start*/}
                    <a className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                        <span>
                            <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                            </svg>
                            <span onClick={() => navigate(-1)} className="ms-2">Back</span>
                        </span></a>
                    {/*end*/}
                    {/*start*/}

                    {/*end*/}
                </div>

                <div className="class-content ">

                    {/* <Header onStepChange={handleStepChange} /> */}
                    <div className="row">
                        <div className="col-xl-12 mb-xl-0 mb-4">
                            {/*start-content*/}
                            <div className="tab-content" id="pills-tabContent">
                                <div className="col-xl-9 mb-xl-0 mb-4">
                                    <div className="d-flex mb-4 justify-content-between align-items-center">
                                        <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">

                                            <option value='0' selected >Learner’s Name</option>

                                            {learnerData?.map((student, index) => {
                                                const type = student?.learner_id ? 'learner' : 'user';
                                                const id = student?.learner_id || student?.user_id;
                                                const valueObj = JSON.stringify({ type, id });
                                                return (
                                                    <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="review_dack">
                                        {
                                            data?.map((Home, index) => {
                                                const maxScore = Home?.total_score || 100;
                                                console.log('maxScore :', maxScore);
                                                const handleSubmitForm = handleSubmit1((data) => {
                                                    // Handle form submission for this specific form
                                                    onSubmitMobile(data, index);
                                                });



                                                return (
                                                    <div className="card review_box mb-4" key={index}>
                                                        <div className="review_top">
                                                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                                <div className="list_btw_dots mb-3">
                                                                    <ul className="d-xl-flex align-items-center">
                                                                        <span className="pe-sm-3 position-relative fw-600">{Home.topic} <span className="green_dots position-absolute" /></span>
                                                                        <li>
                                                                            <span className="text-purple fw-600">Score : {Home?.score}/{Home?.total_score}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="text-purple fw-500">Total Duration: {Home.duration}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                                    <span>Learner's Name</span>:&nbsp;<span>{Home.full_name}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap justify-content-between align-items-satrt" >
                                                                <div className="list_btw_dots mb-3">
                                                                    <ul className="d-xl-flex align-items-center">
                                                                        {/* <p className="fs-6" style={{ wordWrap: 'break-word' }}>{Home.description}</p> */}
                                                                        <TruncateText text={Home?.description} length={25} className="fs-6" />

                                                                    </ul>
                                                                </div>
                                                                <form onSubmit={handleSubmitForm} className="custom_form">
                                                                    <div className="form-group mb-4">

                                                                        <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center" style={{ marginRight: "-225px" }} >
                                                                            {/* <Link to="/assesment-response" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Assesment</Link> */}
                                                                            <input type="hidden" value={Home.user_id} name={`user_id_${index}`} {...register1(`user_id_${index}`)}></input>
                                                                            <input type="hidden" value={Home.learner_id} name={`learner_id_${index}`} {...register1(`learner_id_${index}`)}></input>
                                                                            {/* <input
                                                                                type="text"
                                                                                className="form-control ms-lg-3 mr-2 mx-2"
                                                                                style={{ width: "25%" }}
                                                                                maxLength="2"
                                                                                pattern="[0-9]*"
                                                                                name={`score_${index}`}
                                                                                {...register1(`score_${index}`)} /> */}
                                                                            <input
                                                                                type="text"
                                                                                className={`form-control ms-lg-3 mr-2 mx-2 ${errors1[`score_${index}`] ? 'is-invalid' : ''}`}
                                                                                style={{ width: "25%" }}
                                                                                maxLength="2"
                                                                                pattern="[0-9]*"
                                                                                name={`score_${index}`}
                                                                                {...register1(`score_${index}`, {
                                                                                    required: "Score is required",
                                                                                    validate: value => parseInt(value, 10) <= maxScore || `Score must be less than or equal to ${maxScore}`
                                                                                })}
                                                                            />
                                                                            {errors1[`score_${index}`] && (
                                                                                <span className="invalid-feedback">
                                                                                    {errors1[`score_${index}`].message}
                                                                                </span>
                                                                            )}
                                                                            <button className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Add Score</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            {/* <div className="review_text row mb-4">
                                                    <div className="col-lg-8">
                                                        <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                                                    </div>
                                                </div> */}
                                                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                                <div className="button d-sm-flex align-items-center">

                                                                    {Home?.assetment_data?.map((dhome, index) => {
                                                                        return (
                                                                            <a onClick={() => DownloadHomework(dhome)} className="btn-theme text-center bg-yellow d-inline-block ms-sm-3 mx-2">Download {index + 1}</a>
                                                                        )
                                                                    })}
                                                                    <Link state={location?.state} onClick={(e) => AddComment(Home.learner_id, Home.user_id)} className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block mx-2"   >Comment</Link>
                                                                    <a onClick={() => (Reupload(Home.learner_id, Home.user_id))} className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block mx-2">Reupload

                                                                    </a>
                                                                </div>
                                                                {/* <div className="button d-sm-flex align-items-center">
                                                        <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                                    </div> */}
                                                                <div className="list_btw_dots mb-xl-0 mb-4">
                                                                    <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                                        {/* <li>
                                                                <span className="text-danger fw-500">1 Day Left</span>
                                                            </li> */}
                                                                        <li>
                                                                            <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {Home.upload_date}</span>
                                                                        </li>
                                                                        {/* <li>
                                                                <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                            </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-xl-12 mb-xl-0 mb-4">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="row">
                                <div className="col-xl-9 mb-xl-0 mb-4">
                                    <div className="d-flex mb-4 justify-content-between align-items-center">
                                        <select className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">
                                            <option selected>Learner’s Name</option>
                                            <option>Relevance</option>
                                            <option>New Classes</option>
                                            <option>Starting soon</option>
                                        </select>
                                    </div>
                                    <div className="review_dack">
                                        <div className="card review_box mb-4">
                                            <div className="review_top">
                                                <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                    <div className="list_btw_dots mb-3">
                                                        <ul className="d-xl-flex align-items-center">
                                                            <span className="pe-sm-3 position-relative fw-600">Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span>
                                                            <li>
                                                                <span className="text-purple fw-600">Score : 25/30</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                        <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                                                    </div>
                                                </div>
                                                <div className="review_text row mb-4">
                                                    <div className="col-lg-8">
                                                        <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                    <div className="button d-sm-flex align-items-center">
                                                        <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                                    </div>
                                                    <div className="list_btw_dots mb-xl-0 mb-4">
                                                        <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                            <li>
                                                                <span className="text-danger fw-500">1 Day Left</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card review_box mb-4">
                                            <div className="review_top">
                                                <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                    <div className="list_btw_dots mb-3">
                                                        <ul className="d-xl-flex align-items-center">
                                                            <span className="position-relative fw-600">Collecting Moodboard from Art.</span>
                                                            <li>
                                                                <span className="text-purple fw-600">Score : 25/30</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                        <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                                                    </div>
                                                </div>
                                                <div className="review_text row mb-4">
                                                    <div className="col-lg-8">
                                                        <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                    <div className="button d-sm-flex align-items-center">
                                                        <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                                        <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <a href="#!" className>
                                                                    <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                                                    </svg>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="setup_your_class.php" className>
                                                                    <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                                                    </svg>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="list_btw_dots mb-xl-0 mb-4">
                                                        <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                            <li>
                                                                <span className="text-danger fw-500">1 Day Left</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card review_box mb-4">
                                            <div className="review_top">
                                                <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                    <div className="list_btw_dots mb-3">
                                                        <ul className="d-xl-flex align-items-center">
                                                            <span className="pe-sm-3 position-relative fw-600">Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span>
                                                            <li>
                                                                <span className="text-purple fw-600">Score : 25/30</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                        <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                                                    </div>
                                                </div>
                                                <div className="review_text row mb-4">
                                                    <div className="col-lg-8">
                                                        <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                    <div className="button d-sm-flex align-items-center">
                                                        <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                                    </div>
                                                    <div className="list_btw_dots mb-xl-0 mb-4">
                                                        <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                            <li>
                                                                <span className="text-danger fw-500">1 Day Left</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                          
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
            </main>
            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

                {/* <div className="modal fade ask_the_teacher" id="ask_the_teacher" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Add A Comment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {/* <div className="teacher_detail">
<div><span className="fw-500">From: </span> <span className="text-gray"> {askData.user_name} (local time: 3:46 PM)</span></div>
<div><span className="fw-500">To: </span> <span className="text-gray"> {askData.teacher_name} (local time: 6:16 AM)</span></div>
<div><span className="fw-500">Subject: </span> <span className="text-gray"> Message about: Drawing 101: A Weekly Drawing Class for Aspiring Artists</span></div>
</div> */}
                            <div className>
                                <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Write Your Comment Here</label>
                                        <textarea className="form-control" name='teacher_comment' {...register("teacher_comment")} rows={4} />
                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacher_comment?.message}</span></p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>
                                        <button type="button" onClick={() => setIsOpen(false)} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                {/* </div> */}
            </Modals>

            <TFooter_Dashboard />

        </>
    )
}
