import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import { Controller, useForm } from "react-hook-form";
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { invite_teacher, manage_teacher_list, permission_tag_show, permission_view_details, teacher_permission } from '../../../api/apiHandler'
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service'

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

export default function SidebarList() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  

  const location = useLocation();
  const navigate = useNavigate();
  var user_id = location?.state;
  console.log('location :', user_id);

  var [data, setData] = useState([]);
  console.log('data11111 :', data);
  var [selectedPermissions, setselectedPermissions] = useState([]);

  const schema = yup.object().shape({

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const PermissionList = () => {
    // isLoader(true)

    permission_tag_show().then((response) => {
      console.log("permission_tag_show", response)
      // isLoader(false)

      if (response?.code == 1) {
        setData(response.data)


    permission_view_details({invite_id : user_id}).then((resposnse1) => {
      console.log("permission_view_details", resposnse1)
      // isLoader(false)

      if (resposnse1?.code == 1) {
        var set_data = selectedOption
        response.data.map((key,index)=>{
          console.log("data111",resposnse1.data[0])
            if(resposnse1.data[0][key.tags] == 1){
              set_data.push(key.tags)
            }
            setSelectedOption(set_data)
        })

        setListPermission(resposnse1.data)
      } else {
        console.log('data not found')
      }
    });
      } else {
        console.log('data not found')
      }
    });
  }

  useEffect(() => {

    PermissionList();

  }, []); 
  

  const [selectedOption,setSelectedOption] = useState([]);
  console.log('selectedOption :', selectedOption);


  const handlePermissionClick = (a, b, c) => {

    if (selectedOption.includes(a)) {
      let filteredArray = selectedOption.filter(item => item !== a);
      setSelectedOption(filteredArray)
  } else {
    setSelectedOption(prevSelectSubject => [...new Set([...prevSelectSubject, a])]);
  }

  }
  
  let onSubmit = async (data1) => {
    var submit_data = {
      invite_teachher_id : user_id
    }   
     for(var i = 0; i < data.length; i++){
      if(selectedOption.includes(data[i].tags)){
        submit_data[data[i].tags] = 1
      }else{
        submit_data[data[i].tags] = 0
      }
    }
    // console.log('submit_data :', submit_data);

    teacher_permission(submit_data).then((response) => {
      console.log("teacher_permission", response)
      // isLoader(false)
      
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message);
        navigate("/manage-teacher")
      } else {
        TOAST_ERROR(response.message);
      }
    });
  }

var [listPermission,setListPermission] = useState([])
console.log('listPermission :', listPermission);

  const ShowPermissionList = () => {
    // isLoader(true)

    // permission_view_details({invite_id : "1"}).then((response) => {
    //   console.log("permission_view_details", response)
    //   // isLoader(false)

    //   if (response?.code == 1) {
    //     var set_data = selectedOption
    //     data.map((key,index)=>{
    //       console.log("data111",response.data[0])
    //         if(response.data[0][key.tags] == 1){
    //           set_data.push(key.tags)
    //         }
    //         setSelectedOption(set_data)
    //     })
    //     setListPermission(response.data)
    //   } else {
    //     console.log('data not found')
    //   }
    // });
  }

  useEffect(() => {

    ShowPermissionList();

  }, []); 

  const handleChildPermissionClick = () => {

  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className="">
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span onClick={() => navigate(-1)} className="ms-2">Back</span>
                </span>
              </a>
              <div className="">
                <div className="mb-4">
                  <h2 className="mb-2 poppins fw-bold">Add Permission</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                  <div className="form-group mb-4 mt-4">
                    <label className="mb-4 fw-600 text-dark">Select Permission</label><br />
                    <div className="col-md-12 mb-4">
                      {/* <div className="row align-items-center">
                        {data.map((menuItem, index) => (
                          <div key={index} className="col-md-4 mt-4 ">
                            <div style={{ borderBottom: "2px solid #570861 " }}>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id={`permission-${index}`}
                                name={`add_permission`}
                                value={menuItem.menu_name}
                                onChange={(e) => handlePermissionClick(menuItem, index, e)}
                              />
                              <label htmlFor={`permission-${index}`} className="mx-2 fw-500" >
                                {menuItem.name}
                              </label>
                            </div>

                            </div>
                          </div>
                        ))}
                      </div> */}
                      <div className="row align-items-center" style={{ border: "2px solid #570861", borderRadius: "5px", padding: "10px", paddingBottom: "23px" }}>
                        {data.map((menuItem, index) => (
                          <div key={index} className="col-md-4 mt-4">
                            <div className="form-check">
                              <div className="d-fl+ex align-items-center">
                                <input className="form-check-input"
                                  type="checkbox"
                                  id={`permission-${index}`}
                                  name={`add_permission`}
                                  value={menuItem.tags} 
                                  checked={selectedOption.includes(menuItem.tags) ? true : false}
                                  onChange={(e) => handlePermissionClick(menuItem.tags, index, e)}
                                />
                                <label htmlFor={`permission-${index}`} className="mx-2 fw-500 text-dark"  >
                                  {menuItem.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="button my-4">
                        {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Assessment</Link> */}
                        <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                          Save
                        </button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      <OFooter_Dashboard />
    </>
  )
}
