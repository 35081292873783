import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { ask_the_teacher, ask_the_teacher_details, follow_unfollow } from "../../../api/apiHandler";
import { BASE_NAME } from "../../../Config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { uploadImageOnAWS } from "../../aws/Service";
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

const MeetTheTeacher = ({ teacherDetails, handleShowMoreReviews, visibleReviews, teacher_review = [], isLoader }) => {
  console.log('teacherDetails :', teacherDetails);

  const [isFollowed, setIsFollowed] = useState(teacherDetails?.is_follow);
  const [followCount, setFollowCount] = useState(teacherDetails?.Total_followers);
  var [ask_the_Teacher_Details, set_ask_the_Teacher_Details] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);

  var activeTab = localStorage.getItem("Search_Tab")
  const { id } = useParams();

  const handleFollowUnfollow = async () => {
    console.log(teacherDetails);
    try {
      const { code, message } = await follow_unfollow({ teacher_id: teacherDetails?.teacher_id });
      if (code == 1) {
        setFollowCount(isFollowed ? followCount - 1 : followCount + 1);
        setIsFollowed(!isFollowed);
      } else TOAST_ERROR(message);
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };


  let schema1 = yup.object().shape({
    question: yup.string().required("Question is required"), // Fixed typo here
    document: yup.string().required("document is required"),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    clearErrors: clearErrors1,
    reset: reset1,
    watch: watch1,
    control: control1,
    getValues: getValues1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
  });

  var classData = ask_the_Teacher_Details;

  const [profileImageState, setProfileImageState] = useState("");

  const handleImageChange = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    setValue1("document", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors1("document");
  };

  const ask_the_teacher_Details = (data) => {
    ask_the_teacher_details({ class_id: id, type: activeTab == 1 ? "class" : activeTab == 2 ? "club" : activeTab == 3 ? "quiz" : "competition" }).then((resposnse) => {
      console.log(resposnse, 'responseData');
      if (resposnse?.code == 1) {

        set_ask_the_Teacher_Details(resposnse?.data);

      } else {
      }
    });
  };

  let onSubmit = async (data) => {
    //   if (data.imageUpload != null) {
    //     var image = await uploadImageOnAWS(data?.attachments_data, "class_image");
    // }
    isLoader(true)
    var image = await uploadImageOnAWS(data?.document, "class_image");

    let submitData = {
      classes_id: id,
      classes_type: activeTab == 1 ? "class" : activeTab == 2 ? "club" : activeTab == 3 ? "quiz" : "competition",
      question: data.question,
      document: image,
    };

    ask_the_teacher(submitData).then((res) => {
      // return
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        // navigate('/teachers_my_class');
        setIsOpen(false)
        isLoader(false)
      } else {
        TOAST_ERROR(res.message);
        isLoader(false)

      }
    });
    reset1();
  };

  useEffect(() => {
    setIsFollowed(teacherDetails?.is_follow);
    setFollowCount(teacherDetails?.Total_followers);
    ask_the_teacher_Details()
  }, [teacherDetails]);
  console.log(teacherDetails);


  return (
    <>
      <section className="meet_techer">
        <h2>Meet the Teacher</h2>
        <div className="meet_details mb-5 d-lg-flex align-items-center">
          <Link
            to="/teachers_details"
            state={teacherDetails?.teacher_id}
            className="meet_img mb-lg-0 mb-4 pointer"
            teacherDetails-bs-toggle="tooltip"
            teacherDetails-bs-placement="top"
            teacherDetails-bs-custom-class="custom-tooltip"
            teacherDetails-bs-title="Teacher Details"
          >
            <img src={teacherDetails?.profile_image} alt="meet_techer" />
          </Link>
          <div className="meet_right">
            <div className="d-xl-flex mb-2 align-items-center">
              <div className="deg_name d-flex mb-xl-0 mb-3 align-items-center">
                <h2 className="poppins fw_500 text-purple">{teacherDetails?.full_name}</h2>
                {teacherDetails?.add_qualification && (

                  <span className=" ms-2 text-dif-black">({teacherDetails?.add_qualification  })</span>
                )}
              </div>
              <div className="d-flex align-items-center ms-xl-2">
                <button onClick={handleFollowUnfollow} className="btn-theme py-2 ms-2 d-block text-dif-black text-center" tabIndex={0}>
                  {isFollowed ? "Following" : "Follow"}
                </button>
                <span className="ms-3 text-dif-black fw-500">{followCount} Followers</span>
              </div>
            </div>
            <div className="live_canada align-items-center mb-3 d-flex">
              <span className="fs-5 mx-2">Lives in {teacherDetails?.country_name}</span>
              <span>{teacherDetails?.country_image}</span>
            </div>
            <p className="fs-5 text-dif-black mb-3">Experienced Multi-Subject Teacher: Creative Arts &amp; World Travels</p>
            <div className="mb-3 d-lg-flex align-items-center">
              <ul className="d-flex mb-lg-0 mb-2 align-items-center">
                <i data-star={teacherDetails?.Total_ratings}></i>
              </ul>
              <span className="fs-5 fw-600 ms-lg-3">({teacherDetails?.Total_ratings || 0}) Reviews</span>
              <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">({teacherDetails?.Total_review || 0})Total Reviews</span>
            </div>
            <span className="text-gray mb-3 d-block fw-600">238 Completed Class</span>
            <div className="d-xl-flex align-items-center">
              <a
                href="#!"
                className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block"
                onClick={() => setIsOpen(true)}
              >
                Ask A Question
              </a>
              <Link
                to="/teachers_details"
                state={teacherDetails?.teacher_id}
                className="btn-theme d-inline-block text-dif-black text-center ms-xl-3"
                tabIndex={0}
              >
                Watch My Video Introduction
              </Link>
            </div>
          </div>
        </div>
        <div className="meet_about mb-5">
          <h2 className="poppins fw-600 text-purple text-capitalize mb-2 fs-4">About Me</h2>
          <p className="text-capitalize">
            {teacherDetails?.about_me ? teacherDetails?.about_me : "No teacherDetails available"}
            {teacherDetails?.about_me && (
              <a href="#!" className="text-purple fw-600">
                Show More
              </a>
            )}
          </p>
          
        </div>
        <div className="mb-4 d-sm-flex align-items-center">
          <ul className="d-flex mb-sm-0 mb-2 align-items-center">
            <li className="me-2">
              <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                  fill="#FDC840"
                />
              </svg>
            </li>
          </ul>
          <span className="fs-5 fw-600 ms-lg-3">({teacherDetails?.Total_ratings}) Reviews</span>
          <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">({teacherDetails?.Total_review}) Total Reviews</span>
        </div>
        <div className="review_dack">
          {teacher_review?.slice(0, visibleReviews).map((classItem, index) => {
            return (
              <div className="card review_box mb-4" key={index}>
                <div className="review_top">
                  <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                    <div className="review_user d-flex align-items-center">
                      {classItem.profile_image &&
                        <img src={classItem.profile_image} />
                      }
                      <div className="ms-3">
                        <span className="d-block fs-5">{classItem.user_name}</span>

                        <ul className="d-flex d-lg-none align-items-center">
                          <i data-star={classItem.ratings}></i>

                        </ul>
                      </div>
                    </div>
                    <ul className="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
                      <i data-star={classItem.ratings}></i>

                    </ul>
                  </div>
                  <div className="review_text row mb-4">
                    <div className="col-lg-6">
                      <p className="fw-500">{classItem.review}</p>
                    </div>
                  </div>
                  <span className="text-gray fs-6">{classItem.created_at}</span>
                </div>
              </div>
            );
          })}

          <div className="button mt-5 text-center">
            {visibleReviews !== teacher_review?.length && (
              <button className="btn-theme d-inline-block text-center fw-600 text-dif-black" tabIndex={0} onClick={handleShowMoreReviews}>
                See More Reviews
              </button>
            )}
          </div>
          {/* <div className="button mt-5 text-center">
        <a href="#" className="btn-theme d-inline-block text-center fw-600 text-dif-black" tabIndex={0}>See More Reviews</a>
      </div> */}
        </div>

        {/* <div className="modal fade" id="ask_the_teacher" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}

        <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw-600" id="exampleModalLabel">
                  Ask The Teacher
                </h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setIsOpen(false)} />
              </div>
              <div className="modal-body">
                <div className="teacher_detail">
                  <div>
                    <span className="fw-500">From: </span> <span className="text-gray"> {classData && classData[0]?.user_name}({classData && classData[0]?.user_local_time})</span>
                  </div>
                  <div>
                    <span className="fw-500">To: </span> <span className="text-gray">{classData && classData[0]?.teacher_name}({classData && classData[0]?.teacher_local_time})</span>
                  </div>
                  <div>
                    <span className="fw-500">Subject: </span> <span className="text-gray">Message about: {classData && classData[0]?.summary}</span>
                  </div>
                  {/* <p>{classData && classData[0]?.classes_id}</p> */}
                </div>
                <div>
                  {/* <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" a> */}
                  <form onSubmit={handleSubmit1(onSubmit)} className="custom_form mt-4">
                    <div className="form-group mb-4">
                      <label className="mb-2">Write Your Question Here</label>
                      <textarea className="form-control" {...register1("question")} name="question" rows={4} defaultValue={""} />
                      <p className="mt-2 font-bold col-red">
                        <span style={{ color: "red" }}>{errors1.question?.message}</span>
                      </p>
                    </div>
                    <Controller
                      name="document"
                      control={control1}
                      defaultValue={null}
                      render={({ field }) => (
                        <>
                          <div className="form-group mb-4 file_upload">
                            <label className="mb-2">Document</label>
                            <div className="file_update position-relative">
                              <label htmlFor="document">
                                {/* <input type="file" onChange={(e) => handleImageChange(e.target.files[0])}  {...register1("document")} name="document" className="form-control" /> */}

                                <input
                                  type="file"
                                  name="files[]"
                                  id="document"
                                  accept="image/*"
                                  onChange={(e) => handleImageChange(e.target.files[0])}
                                />
                                <span className="file_icons position-absolute">
                                  <svg width={28} height={36} viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.2 0H0V36H28V9.86087L18.2 0ZM17.8889 10.1739V1.87826L26.1333 10.1739H17.8889Z" fill="#9B9B9B" />
                                  </svg>
                                </span>
                              </label>
                            </div>
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors1.document?.message}</span>
                            </p>
                          </div>
                        </>
                      )}
                    />

                    <div className="modal-footer">
                      {/* <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button> */}
                      <button className="btn-theme bg-yellow text-center fw-600 d-block">Send</button>
                      <button type="button" className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500" onClick={() => setIsOpen(false)}>
                        Don’t Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modals>
        {/* </div> */}
      </section>


    </>

  );
};

export default MeetTheTeacher;
