import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AgoraRTC from "agora-rtc-sdk-ng";
import { LocalUser, RemoteUser, useIsConnected, useJoin, useLocalMicrophoneTrack, useLocalCameraTrack, usePublish, useRemoteUsers, useLocalScreenTrack, RemoteVideoTrack, useRTCClient } from 'agora-rtc-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetailsApiCall } from '../../../../Store/slices/MasterSlice';
import { end_meeting, joinedMeetingUserList } from '../../../../api/apiHandler';
import io from "socket.io-client";
import { SOCKET_URL } from "../../../../Config";
import { BASE_NAME } from "../../../../Config";
import { useChat } from '../../../Common/useChatTeacher';
import { useChatParent } from '../../../Common/useChatParent';
import Modals from 'react-modal';
import { TOAST_INFO } from '../../../../utils/common.service';


const appId = "c4e8497aae464e2fa4e5206ade31ee65";

const PStartMeeting = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails: { data: userDetails } } = useSelector((state) => state.master);
    console.log('userDetails :', userDetails);


    const token = location?.state?.token;
    const channel = location?.state?.channel;
    const uid = parseInt(location?.state?.uid);
    const classRoomData = location?.state?.classRoomData;
    console.log('classRoomData :', classRoomData);
    const callData = location?.state?.callData;
    const groupData = location?.state?.groupData;
    const isConnected = useIsConnected();
    console.log('isConnected :', isConnected);
    // const [socket, setSocket] = useState(null);
    const USER_ID = JSON.parse(localStorage.getItem("PAid"));
    const USER_TYPE = localStorage.getItem("type") || localStorage.getItem("userType");

    // useJoin({ appid: appId, channel, token, uid }, calling);


    const [receiverDetailed, setReceiverDetailed] = useState(null);

    const [getReceiverUserList, setGetReceiverUserList] = useState([]);
    console.log('getReceiverUserList :', getReceiverUserList);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    const [waitingForApproval, setWaitingForApproval] = useState(true);
    const [shouldJoin, setShouldJoin] = useState(false);
    const [hostInfo, setHostInfo] = useState(null);
    const [status, setStatus] = useState(localStorage.getItem('status'));
    console.log('hostInfo :', hostInfo);
    // usePublish([localMicrophoneTrack, localCameraTrack]);
    const client = useRTCClient();
    const { localMicrophoneTrack } = useLocalMicrophoneTrack();
    const { localCameraTrack } = useLocalCameraTrack();
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const [screenOn, setScreenOn] = useState(false);
    const [screenTrack, setScreenTrack] = useState(null);
    const [localScreenTrack, setLocalScreenTrack] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);

    usePublish([localMicrophoneTrack, localCameraTrack, screenTrack].filter(Boolean));


    useJoin({ appid: appId, channel, token, uid }, shouldJoin);

    const socket = io(SOCKET_URL, { query: { user_id: USER_ID } });
    useEffect(() => {

        socket.emit('join_request', { user_id: userDetails?.id, channel, name: userDetails?.full_name ? userDetails?.full_name : userDetails?.learner_name, agora_uid: userDetails?.agora_uid, profile_image: userDetails?.profile_image, user_type: USER_TYPE });

        socket.on('request_status', (response) => {
            console.log('response :', response);
            if (response?.response?.status === "accept") {
                setWaitingForApproval(false);
                setShouldJoin(true);
                setStatus(response?.response?.status)
                localStorage.setItem('status', response?.response?.status)

            } else {
                navigate(-1);
                alert('Your request to join the call was rejected by the teacher.');
            }
        });

        socket.on('remove_user_from_meeting', (response) => {
            console.log('response :', response);
            if (response?.response?.agora_uid == uid) {
                localStorage.removeItem('status')
                navigate(-1)
                // alert(response?.response?.message)
            }

        })
        socket.on('update_host_status', (response) => {
            console.log('hostStatus :', response);
            setHostInfo(response?.response);
        });

        return () => {
            socket.disconnect();
        };
    }, [channel, uid, USER_ID, userDetails, navigate]);
    const remoteUsers = useRemoteUsers();
    console.log('remoteUsers :', remoteUsers);


    useEffect(() => {
        client.current = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

        if (shouldJoin) {
            const joinChannel = async () => {
                await client.current.join(appId, channel, token, uid);
                console.log('Joined channel');
            };

            joinChannel();
        }

        return () => {
            client.current.leave();
        };
    }, [shouldJoin, appId, channel, token, uid]);

    usePublish([localMicrophoneTrack, localCameraTrack, screenTrack].filter(Boolean));

    const handleScreenShareToggle = async () => {
        if (isPublishing) {
            console.log("A publish operation is already in progress. Please wait.");
            return;
        }

        setIsPublishing(true);

        try {
            if (!screenOn) {
                console.log("Starting screen share...");



                const tempScreenTrack = await AgoraRTC.createScreenVideoTrack(
                    {
                        encoderConfig: "1080p"
                    },
                    "auto"
                );

                console.log("Screen track created. Unpublishing camera track...");
                if (localCameraTrack) {
                    await client.unpublish(localCameraTrack);
                }

                console.log("Publishing screen track...");
                try {
                    // Create a new client for screen sharing
                    const screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

                    // Use a valid token and appId if required
                    await screenClient.join(appId, channel, token, uid);

                    // Publish the screen track on the new client
                    await screenClient.publish(tempScreenTrack);

                    setScreenTrack(tempScreenTrack);


                    setScreenOn(true);

                    // Handle when screen sharing ends
                    tempScreenTrack.on('track-ended', async () => {
                        console.log("Screen sharing ended by user.");
                        await handleStopScreenShare(screenClient);
                    });

                    console.log("Screen share started successfully.");
                } catch (publishError) {
                    console.error("Error publishing screen track:", publishError);
                    throw publishError;
                }
            } else {
                await handleStopScreenShare();
            }
        } catch (error) {
            console.error("Error in screen sharing:", error);
            setScreenOn(false);
            setScreenTrack(null);
        } finally {
            setIsPublishing(false);
        }
    };

    const handleStopScreenShare = async (screenClient = null) => {
        if (screenTrack) {
            console.log('screen1');
            try {
                console.log('screenClient ', screenClient);
                if (screenClient) {
                    // Check if the client has joined before trying to unpublish
                    if (screenClient.connectionState === "CONNECTED") {
                        await screenClient.unpublish(screenTrack);
                        await screenClient.leave();
                    } else {
                        console.warn("Screen client has not joined the channel yet.");
                    }
                } else if (client) {
                    await client.unpublish(screenTrack);
                }
    
                // Close and clean up the screen track
                screenTrack.close();
    
                // If local camera track exists, publish it again after stopping screen share
                if (localCameraTrack) {
                    await client.publish(localCameraTrack);
                }
    
                setScreenTrack(null);
                setScreenOn(false);
    
            } catch (error) {
                console.error("Error stopping screen share:", error);
            }
        }
    };
    

    useEffect(() => {
        const handleUserPublished = async (user, mediaType) => {
            await client.subscribe(user, mediaType);

        };

        const handleUserUnpublished = (user) => {

        };

        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);

        return () => {
            client.off("user-published", handleUserPublished);
            client.off("user-unpublished", handleUserUnpublished);
        };
    }, [client]);

    useEffect(() => {
        return () => {
            if (screenTrack) {
                screenTrack.close();
            }
        };
    }, [screenTrack]);

    const callSize = () => {
        switch (remoteUsers?.length) {
            case 1:
                return "col-xl-6 col-lg-6 col-sm-6 p-1"
            case 2:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 3:
                return "col-xl-6 col-lg-6 col-sm-6 p-1"
            case 4:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 5:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 6:
                return "col-xl-3 col-lg-3 col-sm-6 p-1"
            case 7:
                return "col-xl-3 col-lg-3 col-sm-6 p-1"
            case 8:
                return "col-xl-3 col-lg-2 col-sm-6 p-1"
            case 9:
                return "col-xl-3 col-lg-2 col-sm-6 p-1"
            case 10:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            case 11:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            case 12:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            default:
                return "col-md-12 p-1"
        }
    };

    const callVideoHeight = () => {
        const length = remoteUsers?.length;
        if (length === 1 || length === 2) {
            return "height84";
        } else if (length >= 3 && length <= 12) {
            return "height42";
        } else {
            return "height42";
        }
    };


    useEffect(() => {
        if (status == 'accept') {
            setWaitingForApproval(false);
            setShouldJoin(true);
        }
    }, [status]);


    const handleCallCut = async () => {
        // let { code: callStatusCode, data: callStatusData } = await end_meeting({ class_meeting_id: classRoomData ?.id, type: remoteUsers?.length === 1 ? 'all' : 'other' });
        // console.log('callStatusData :', callStatusData);
        // if (callStatusCode === '1') {

        //     if (remoteUsers?.length === 1) {
        //         const messageObj = {
        //             channel: channel,
        //             receiver_id: classRoomData?.teacher_id,
        //             sender_id: USER_ID,
        //         };
        //         console.log('messageObj :', messageObj);
        //     }
        // }
        socket.emit('call_cut', JSON.stringify({ agora_uid: userDetails?.agora_uid }));
        localStorage.removeItem('status')

        navigate(-1);
    }




    useEffect(() => {
        if (socket) {
            socket.on('call_ended', (response) => {

                navigate(-1)
                TOAST_INFO(response?.response?.message)

            });
        }
    }, []);



    useEffect(() => {
        if (remoteUsers?.length > 0) {
            handleJoinedUserList();
        }
    }, [remoteUsers]);

    const handleGetUserDetailsApiCall = () => {
        dispatch(getUserDetailsApiCall({ userId: localStorage.getItem("PAid") }));
    }

    useEffect(() => {
        if (!userDetails && localStorage.getItem("PAid")) {
            handleGetUserDetailsApiCall();
        }
    }, [userDetails]);

    const handleJoinedUserList = async () => {
        let joinedMeetingUserListSendData = {
            agora_uids: remoteUsers?.map(item => item?.uid),
        };

        const joinedMeetingUserListResponse = await joinedMeetingUserList(joinedMeetingUserListSendData);

        if (joinedMeetingUserListResponse?.code == "1") {
            let updatedReceiverUserList = joinedMeetingUserListResponse?.data;

            // Update the user list by setting isHost to true where type is teacher
            updatedReceiverUserList = updatedReceiverUserList.map(user => ({
                ...user,
                isHost: user.type == "teacher" ? true : user.isHost ?? false  // Set isHost to true for teacher
            }));

            // Check if the current user is already in the list
            const userExists = updatedReceiverUserList.some(item => item?.agora_uid == userDetails?.agora_uid);

            const userType = localStorage.getItem('userType');

            if (!userExists) {
                // Add the current user to the list
                updatedReceiverUserList.push({
                    full_name: userDetails?.full_name ? userDetails?.full_name : userDetails?.learner_name,
                    profile_image: userDetails?.profile_image,
                    agora_uid: userDetails?.agora_uid,
                    user_id: userDetails?.id,
                    isHost: userType === "teacher" ? true : false,  // Set isHost based on user type
                    type: userType,
                });
            }

            setGetReceiverUserList(updatedReceiverUserList);
        } else {
            console.error('Failed to fetch joined meeting user list');
        }
    };






    const {
        roomId,
        chatHistory,
        message,
        setMessage,
        image,
        imageVal,
        scrollToBottomRef,
        scrollToTopRef,
        inputRef,
        sendMessage,
        handleMessage,
        deleteImage,
        showMessages,
        downloadFile,
        checkRoomExist,
        handleImageClick
    } = useChatParent(classRoomData, getReceiverUserList, USER_TYPE);



    return (
        <>
            <Helmet>
                <style>
                    {`
                        /* styles.css */
                        .agora-btn {
                            background-color: #570861 !important;
                            border: none;
                        }
                        
                        .agora-btn svg {
                            fill: #ffffff;
                        }
                    `}
                </style>
            </Helmet>
            {/* {isConnected ?
                (<> */}
            <main className="main-content-block">
                <section className="videocall-sec-one">
                    <div className="container-fluid">
                        {waitingForApproval ? (
                            <div className="waiting-message">
                                <h4>Wait, the teacher will accept your request, then you can join the call.</h4>
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    <div className={callSize()}>
                                        <div className={`image-contaie-video active ${callVideoHeight()}`}>
                                            <LocalUser audioTrack={null}
                                                cameraOn={cameraOn}
                                                micOn={micOn}
                                                videoTrack={screenOn && screenTrack ? screenTrack : (cameraOn ? localCameraTrack : null)}
                                                className="z-3 rounded-4"
                                                cover={userDetails?.profile_image}>
                                                <div className="badge-chat">
                                                    {!micOn && (
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
                                                    )}
                                                    <p>{userDetails?.full_name ? userDetails?.full_name : userDetails?.learner_name}</p>
                                                </div>
                                            </LocalUser>
                                        </div>
                                    </div>
                                    {remoteUsers?.map((user) => {
                                        let userData = getReceiverUserList?.filter(item => item?.agora_uid == user?.uid)?.[0];
                                        return (
                                            <div className={callSize()} key={user.uid}>
                                                <div className={`image-contaie-video active ${callVideoHeight()}`}>
                                                    <RemoteUser cover={userData?.profile_image} user={user}>
                                                        <div className="badge-chat">
                                                            {user?._audio_muted_ && (
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
                                                            )}
                                                            <p>{userData?.full_name ? userData?.full_name : userData?.learner_name}</p>
                                                        </div>
                                                    </RemoteUser>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className='row'>
                                    <div className="col-12 p-0">
                                        <div className="videos-btns-container">
                                            {/* <button className="btn-videocall btn-refresh"></button> */}
                                            <button className="btn-participant btn-videocall" data-bs-toggle="offcanvas" data-bs-target="#learnerlist" aria-controls="offcanvasRight"></button>
                                            <button className="btn-message btn-videocall" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={checkRoomExist}></button>

                                            <button className={`btn-videocall ${!cameraOn ? 'btn-vidoo-videocall-mute' : 'btn-vidoo-videocall'}`} onClick={() => setCamera((a) => !a)}></button>
                                            {/* <button className="btn-profile-2user-videocall btn-videocall" onClick={() => dispatch(setOffcanvasStatus({ canvasType: CALL_MEMBER_CANVAS, isOpen: true, data: { callData, joinedAgoraUid: [...remoteUsers?.map(item => item?.uid), userDetails?.agora_uid] } }))}><span className="total-vallno">{remoteUsers?.length + 1}</span></button> */}
                                            <button className={`btn-videocall ${!micOn ? 'btn-microphone-mute' : 'btn-microphone-2'}`} onClick={() => setMic((a) => !a)}></button>
                                            <button className="cancellcall" onClick={handleCallCut}></button>

                                            <button className="btn-screen-share" onClick={() => handleScreenShareToggle()}>
                                                {screenOn ? 'Stop Sharing' : 'Share Screen'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                </section>
            </main>


            <div className="offcanvas offcanvas-end" tabIndex="-1" id="learnerlist" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Participants ({remoteUsers?.length + 1})</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="card card-body">
                        <ul className="user-container">
                            {[...remoteUsers, userDetails]?.map((user) => {
                                const isCurrentUser = user?.agora_uid == userDetails?.agora_uid;
                                const userData = isCurrentUser
                                    ? userDetails
                                    : getReceiverUserList?.find(item => item?.agora_uid == user?.uid);

                                const isHost = hostInfo?.agora_uid == user?.uid;

                                return (
                                    <li className="user_item" key={isCurrentUser ? userDetails?.agora_uid : user?.uid}>
                                        <div className="left">
                                            <div className="img_user1">
                                                <img src={userData?.profile_image} alt={userData?.full_name} />
                                            </div>
                                            <p className="name-user1">
                                                {userData?.full_name ? userData?.full_name : userData?.learner_name}
                                                {isCurrentUser && " (Me)"}
                                                {isHost && " (Host)"}
                                            </p>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>

                    </div>
                </div>

            </div>

            {/* message body */}
            <div className="offcanvas offcanvas-end d-block show" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Chat</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="conent-chat_group mt-3 mt-lg-0">

                        <div className="content-chatlog position-relative">
                            {showMessages}
                            <div ref={scrollToBottomRef}></div>
                        </div>
                        <div className="chat-bottom">
                            <div>
                                <button className="chat-upload-icon" onClick={() => handleImageClick()}>

                                </button>
                            </div>
                            <input
                                type="text"
                                className="chat-input"
                                placeholder="Type a message"
                                value={message}
                                onChange={handleMessage}
                                ref={inputRef}
                            />
                            <button className="send" onClick={sendMessage}>Send</button>

                        </div>
                        <div className="">
                            {image.blob?.type?.includes("image") ? (
                                <div className="image-container">
                                    <img src={image?.base64} width={150} />
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("video") ? (
                                <div>
                                    <video src={image?.base64} width={150} />
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/pdf") ? (
                                <div>
                                    Pdf File
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || image.blob?.type?.includes("application/vnd.ms-excel") ? (
                                <div>
                                    Excel File
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || image.blob?.type?.includes("application/msword") ? (
                                <div>
                                    Word Document
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : null}


                            <Modals isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Media Modal"
                                style={{
                                    overlay: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                        zIndex: 1000,
                                    },
                                    content: {
                                        //  top: '50%',
                                        //  left: '50%',
                                        //  right: 'auto',
                                        //  bottom: 'auto',
                                        //  marginRight: '-50%',
                                        //  transform: 'translate(-50%, -50%)',
                                        padding: '40px',
                                        //  borderRadius: '8px',
                                        //  maxHeight: '50vh',
                                        overflowY: 'auto',
                                        width: '80%',
                                        marginLeft: '150px'
                                    },
                                }}
                            >
                                <button onClick={closeModal} style={{ position: 'absolute', top: '2px', right: '2px', background: 'none', border: 'none', fontSize: '16px', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 512 512" id="close"><path d="M256 33C132.3 33 32 133.3 32 257s100.3 224 224 224 224-100.3 224-224S379.7 33 256 33zm108.3 299.5c1.5 1.5 2.3 3.5 2.3 5.6 0 2.1-.8 4.2-2.3 5.6l-21.6 21.7c-1.6 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3L256 289.8l-75.4 75.7c-1.5 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6 0-2.1.8-4.2 2.3-5.6l75.7-76-75.9-75c-3.1-3.1-3.1-8.2 0-11.3l21.6-21.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l75.7 74.7 75.7-74.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l21.6 21.7c3.1 3.1 3.1 8.2 0 11.3l-75.9 75 75.6 75.9z" /></svg>
                                </button>
                                {modalContent}
                            </Modals>
                        </div>

                    </div>
                </div>
                <input
                    type="file"
                    onChange={handleMessage}
                    ref={imageVal}
                    style={{ display: 'none' }}
                />
            </div>





        </>
    );
};
export default PStartMeeting;