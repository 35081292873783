import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { teacher_application } from '../../../../api/apiHandler';
import { Helmet } from 'react-helmet';

export default function SignupStep1() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();


  const schema = yup.object().shape({
    first_name: yup.string().required("Full name is required"),
    public_name: yup.string().required("Public name is required"),
    legal_name: yup.string().required("Legal name is required"),
    email: yup.string().email().required("Email is required").matches(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, "Email is invalid"),
    phone: yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9+]{10,13}$/, "Phone number must be 10 digits and contain only numbers"),
    teach_experience: yup.string().required("This is required"),
    degree: yup.string().required("This is required"),
    experience_year: yup.number()
      .required("This is required")
      .min(2, "You need a minimum of 2 years experience to apply.")
      .typeError("Experience must be a number")
      .positive("Experience must be a positive number")
      .integer("Experience must be a whole number")
      .test("non-zero", "Experience cannot be zero", value => value !== 0),
    attach1: yup
      .mixed()
      .required("This is required"),
    attach2: yup.string().required("This is required"),
    teaching_topics: yup.string().required("This is required"),
    teacherVideo: yup.string().required("This is required"),
    num_classes: yup.string().required("This is required"),

    date1: yup.string().required("Date is required"),
    country_of_residence: yup.string().required("Country of Residence is required"),
    checkbox: yup.boolean()
      .oneOf([true], 'You must agree this agreement')
      .required('You must agree this agreement'),


  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [contryData, setContryData] = useState('1');
  const [above30, setAbove30] = useState('');
  const [StartDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);

  const handleOnChange = (value, country) => {
    console.log('country35453 :', country.dialCode);
    console.log('value :', value);
    const formattedValue = value.substring(country.dialCode.length);

    setPhone(formattedValue);
    setCountry(country.dialCode);
    setValue('phone', value)

  };
  const SelectedCountry = async (value) => {
    setContryData(value)
  }
  const [profileImageState1, setProfileImageState1] = useState('')
  const [profileImageState2, setProfileImageState2] = useState('')
  const [profileImageState3, setProfileImageState3] = useState('')
  const [profileImageState4, setProfileImageState4] = useState('')
  const [docExtension1, setDocExtension1] = useState('')
  const [docExtension2, setDocExtension2] = useState('')
  const [docExtension3, setDocExtension3] = useState('')
  const [docExtension4, setDocExtension4] = useState('')
  // console.log("profileImageState1", profileImageState1)

  const handleImage1Change = async (image) => {
    console.log('image :', image.type);
    setDocExtension1(image.type)
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("attach1", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState1(bufferImage);
    clearErrors("attach1");
  }

  const handleImage2Change = async (image) => {
    console.log('image2 :', image);
    setDocExtension2(image.type)
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    console.log('image2222222 :', image);
    setValue("attach2", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState2(bufferImage);
    clearErrors("attach2");
  }

  var attach2Value = watch("attach2");
  console.log("Watched attach2:", attach2Value);

  const handleImage3Change = async (image) => {
    console.log('image3 :', image);
    setDocExtension3(image.type)
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("Teaching_Experience", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState3(bufferImage);
    clearErrors("Teaching_Experience");
  }

  const handleImage4Change = async (image) => {
    console.log('image :', image.type);
    setDocExtension4(image.type)
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("teacherVideo", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState4(bufferImage);
    clearErrors("teacherVideo");
  }


  var attach4Value = watch("teacherVideo");
  console.log("Watched attach4:", attach4Value);

  let onSubmit = async (data) => {

    console.log('data :', data);


    let submit_data = {
      teacher_id: localStorage.getItem("Tid"),
      first_name: data.first_name,
      public_name: data.public_name,
      legal_name: data.legal_name,
      email: data.email,
      phone_no: data.phone,
      // country: data.country_name,
      country: contryData,
      above_earning_30: above30,
      gst_hst_number: data.gst_hst,
      elecator_speech: data.teach_experience,
      academy_degree: data.degree,
      teaching_exp: data.experience_year,
      // teaching_resume : 
      social_profile_link: data.Online_Profiles,
      // certificate_upload : 
      // additional_material : 
      topic_about: data.teaching_topics,
      // sample_video : 
      additional_info: data.additional_info
    }

    let attachment1 = data?.attach1
    let attachment3 = data?.Teaching_Experience


    if (data?.attach1) {
      attachment1 = await uploadImageOnAWS(attachment1, "teacher_application");
      submit_data.teaching_resume = attachment1;
    }

    if (attach2Value) {
      attach2Value = await uploadImageOnAWS(attach2Value, "teacher_application");
      submit_data.certificate_upload = attach2Value;
    }

    if (data?.Teaching_Experience) {
      attachment3 = await uploadImageOnAWS(attachment3, "teacher_application");
      submit_data.additional_material = attachment3;
    }

    if (attach4Value) {
      attach4Value = await uploadImageOnAWS(attach4Value, "teacher_application");
      submit_data.sample_video = attach4Value;
    }

    console.log('submit_data :', submit_data);

    teacher_application(submit_data).then((response) => {
      console.log('response :', response);

      if (response.code == 1) {
        TOAST_SUCCESS(response.message);
        navigate("/signup-teacher")
        reset();

      } else {
        TOAST_ERROR(response.message);

      }
    })


  }

  return (
    <>
      <Helmet>
        <style>
          {`
        .custom_form .form-group label {
          text-transform: capitalize;
          color: #000000;
        }
        `}
        </style>
      </Helmet>
      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row my-4 justify-content-center">
              <div className="col-lg-8 right card">
                <div className="top">
                  <div className="arrow_back mb-3">
                    <a onClick={() => navigate(-1)} className="d-flex align-items-center">
                      <span>
                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                        </svg>
                      </span>
                      <span onClick={() => navigate(-1)} className="ps-3">Back</span>
                    </a>
                  </div>
                  <h2 className="mb-2">Teacher Application</h2>
                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                    <p className="text-purple fw-600">Your Information : </p>

                    <div className="row">
                      {/* <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Your First Name:<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" {...register("first_name")} name="first_name" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.first_name?.message}</span></p>
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label>Legal Name:<span className="text-danger">*</span></label>
                          <p className="mb-2">Please enter your full legal name. This will only be visible to our team, and cannot be edited later.</p>
                          <input type="text" className="form-control" {...register("legal_name")} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.legal_name?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className>Public Name:<span className="text-danger">*</span></label>
                          <p className="mb-2">This is what families will see on your class listings and teacher profile. It can be edited later</p>
                          <input type="text" className="form-control" {...register("public_name")} name="public_name" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.public_name?.message}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Email:<span className="text-danger">*</span></label>
                          <input type="email" className="form-control" {...register("email")} name='email' />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.email?.message}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2 d-block">Your Phone Number<span className="text-danger">*</span></label>
                      <input type="text" id="mobile_code" className="form-control w-100" name="phone" {...register("phone")} />
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>

                    </div>
                    {/* <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          <PhoneInput placeholder="Enter phone number" name="phone" {...register("phone")}
                            id="phone" onChange={(value, country) => handleOnChange(value, country)} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>

                        </div> */}
                    <div className="form-group mb-4">
                      <label className="mb-2">Country<span className="text-danger">*</span></label>
                      <select className="form-select" {...register("country_name")} onChange={(e) => SelectedCountry(e.target.value)} aria-label="Default select example">
                        <option value={"1"}>Canada</option>
                        <option value={"2"}>USA</option>
                        <option value={"3"}>UK</option>
                        <option value={"4"}>Australia</option>
                        {/* <option value={"India"}>India</option> */}
                        <option value={"5"}>New Zealand</option>
                        <option value={"6"}>Singapore</option>
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Please give us an elevator speech of your accomplishments. It should include experience you have in teaching or working with youth, whether as a professional, a volunteer, or in your personal life<span className="text-danger">*</span></label>
                      <textarea className="form-control" rows={6} {...register("teach_experience")} />
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teach_experience?.message}</span></p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Please list any academic degrees, teaching credentials (current or expired), professional training, or other relevant professional certifications you hold. Include anything that will help us learn more about your background. Please include Year, Subject, and Institution.<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" {...register("degree")} name="degree" />
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.degree?.message}</span></p>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">How many years of teaching experience do you have? You need a minimum of 2 years experience to apply.<span className="text-danger">*</span></label>
                      <input type="Number" className="form-control"  {...register("experience_year")} name="experience_year" />
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.experience_year?.message}</span></p>
                    </div>
                    {contryData == '1' ?
                      <div className="form-group mb-4">
                        <label className="mb-2">
                          Are you earning above $30,000 per year ?
                          <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex gap-2">
                          <input className="form-check-input" type="radio" id="above"  {...register("above")} onClick={(e) => setAbove30('yes')} name="above" />
                          <label htmlFor="above" className="mb-2">
                            Yes
                          </label>
                          <input className="form-check-input" type="radio" id="under"  {...register("under")} onClick={(e) => setAbove30('no')} name="under" />
                          <label htmlFor="under" className="mb-2">
                            No
                          </label>
                        </div>

                      </div>
                      : <></>}
                    {above30 == 'yes' ?
                      <div className="form-group mb-4">
                        <label className="mb-2">Please add your GST/HST number.</label>
                        <input type="text" className="form-control"  {...register("gst_hst")} name="gst_hst" />
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.experience_year?.message}</span></p> */}
                      </div>
                      : <></>}
                    <div className="form-group mb-4">
                      <label className="mb-2">Please Upload Your Teaching Resume <span className="text-danger">*</span></label>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                              <>
                                <div className="file_update position-relative">
                                  <div className="d-flex align-items-center justify-content-center">


                                    {(docExtension1 === 'image/jpeg' || docExtension1 === 'image/png') && (
                                      <img onClick={() => {
                                        document.getElementById("attach1")?.click()
                                      }}
                                        className="image"
                                        src={profileImageState1 ? profileImageState1 : ""}
                                        alt=""
                                        style={{
                                          width: profileImageState1 ? "100%" : "",
                                          height: profileImageState1 ? "166px" : "",
                                        }}
                                      />
                                    )}


                                    {docExtension1 === 'application/pdf' && (

                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                          document.getElementById("attach1")?.click()
                                        }}>
                                          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                        </svg>

                                      </>
                                    )}

                                    {docExtension1 === 'application/msword' && (
                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                          document.getElementById("attach1")?.click()
                                        }}>
                                          <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                        </svg>
                                      </>
                                    )}

                                  </div>
                                  <input type="file" id='attach1' name="attach1" onInput={(e) => handleImage1Change(e.target.files[0])} className="form-control" style={{ display: profileImageState1 ? "none" : "" }} />
                                  {profileImageState1 ? "" :
                                    <span className="file_icons position-absolute">
                                      <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                      </svg>
                                    </span>
                                  }
                                </div>
                              </>
                            )} />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach1?.message}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Online profiles: do you have a professional website, Facebook page, or LinkedIn profile that helps to describe your experience and expertise? Share it with us here!</label>
                      <input type="text" className="form-control" {...register("Online_Profiles")} name="Online_Profiles" />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Please Upload any certificates of your education and professional qualifications. <span className="text-danger">*</span></label>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <Controller name="attach2" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                              <>
                                <div className="file_update position-relative">
                                  <div className="d-flex align-items-center justify-content-center">

                                    {(docExtension2 === 'image/jpeg' || docExtension2 === 'image/png') && (
                                      <img onClick={() => {
                                        document.getElementById("attach2")?.click()
                                      }}
                                        className="image"
                                        src={profileImageState2 ? profileImageState2 : ""}
                                        alt=""
                                        style={{
                                          width: profileImageState2 ? "100%" : "",
                                          height: profileImageState2 ? "166px" : "",
                                        }}
                                      />
                                    )}


                                    {docExtension2 === 'application/pdf' && (

                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                          document.getElementById("attach2")?.click()
                                        }}>
                                          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                        </svg>

                                      </>
                                    )}

                                    {docExtension2 === 'application/msword' && (
                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                          document.getElementById("attach2")?.click()
                                        }}>
                                          <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                        </svg>
                                      </>
                                    )}

                                  </div>
                                  <input type="file" id='attach2' name="attach2" onInput={(e) => handleImage2Change(e.target.files[0])} className="form-control" style={{ display: profileImageState2 ? "none" : "" }} />
                                  {profileImageState2 ? "" :
                                    <span className="file_icons position-absolute">
                                      <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                      </svg>
                                    </span>
                                  }
                                </div>
                              </>
                            )} />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach2?.message}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Optional : Any additional material you would like to send us relating to your teaching experience</label>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-lg-0 mb-4  file_upload">
                            <Controller name="Teaching_Experience" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                              <>
                                <div className="file_update position-relative">
                                  <div className="d-flex align-items-center justify-content-center">

                                    {(docExtension3 === 'image/jpeg' || docExtension3 === 'image/png') && (
                                      <img onClick={() => {
                                        document.getElementById("Teaching_Experience")?.click()
                                      }}
                                        className="image"
                                        src={profileImageState3 ? profileImageState3 : ""}
                                        alt=""
                                        style={{
                                          width: profileImageState3 ? "100%" : "",
                                          height: profileImageState3 ? "166px" : "",
                                        }}
                                      />
                                    )}

                                    {docExtension3 === 'application/pdf' && (
                                      // <embed
                                      //   src={profileImageState1 ? profileImageState1 : ""}
                                      //   width="500"
                                      //   height="375"
                                      //   type="application/pdf"
                                      // />
                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                          document.getElementById("Teaching_Experience")?.click()
                                        }}>
                                          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                        </svg>

                                      </>
                                    )}

                                    {docExtension3 === 'application/msword' && (
                                      <>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                          document.getElementById("Teaching_Experience")?.click()
                                        }}>
                                          <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                        </svg>
                                      </>
                                    )}

                                  </div>
                                  <input type="file" id='Teaching_Experience' name="Teaching_Experience" onInput={(e) => handleImage3Change(e.target.files[0])} className="form-control" style={{ display: profileImageState3 ? "none" : "" }} />
                                  {profileImageState3 ? "" :
                                    <span className="file_icons position-absolute">
                                      <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                      </svg>
                                    </span>
                                  }
                                </div>
                              </>
                            )} />
                            {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.Teaching_Experience?.message}</span></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="mb-4">
                        <p className="text-purple fw-600"> Class Statistics :</p>
                        <label className="mb-2">How many classes would you like to teach on our platform? <span className="text-danger">*</span></label>
                        <input type="number" className="form-control" rows={6} {...register("num_classes")} name="num_classes" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.num_classes?.message}</span></p>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="mb-4">
                        <label className="mb-2">Teachers create their own classes. What topics are you passionate about teaching? Which ages of learners do you want to work with? What experience or expertise do you have in these subject areas? It’s fine to share high-level topics here (no need to have the perfect class title). <span className="text-danger">*</span></label>
                        <textarea className="form-control" rows={6} {...register("teaching_topics")} name="teaching_topics" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teaching_topics?.message}</span></p>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="mb-4">
                        <p className="text-purple fw-600">Tell us about yourself ?</p>
                        <label className="mb-2">Record a 2-3 minute video where you teach a sample of the class you described above. This video will stay private and will not be visible to families. It’s a way for our team to get a quick read on who you are and the kind of teaching environment you’ll provide to our learners. You should: Show us (and show off!) the space where you will teach. Show us your teaching personality. Share your expertise! Teach us something that you are excited to share with learners.<span className="text-danger">*</span></label>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group mb-lg-0 mb-4  file_upload">
                              <Controller name="teacherVideo" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                <>
                                  <div className="file_update position-relative">
                                    <div className="d-flex align-items-center justify-content-center">
                                      {/* <img className="image" onClick={() => {
                                      document.getElementById("attach1")?.click()
                                    }} src={profileImageState1 ? profileImageState1 : ""} alt="" style={{
                                      width: profileImageState1 ? "100%" : "",
                                      height: profileImageState1 ? "166px" : "",
                                    }} /> */}
                                      {docExtension4 === 'video/mp4' && (
                                        // <img 
                                        //   className="image"
                                        //   src={profileImageState4 ? profileImageState4 : ""}
                                        //   alt=""
                                        //   style={{
                                        //     width: profileImageState4 ? "100%" : "",
                                        //     height: profileImageState4 ? "166px" : "",
                                        //   }}
                                        // />
                                        <video onClick={() => {
                                          document.getElementById("teacherVideo")?.click()
                                        }} src={profileImageState4} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls style={{
                                          width: profileImageState4 ? "100%" : "",
                                          height: profileImageState4 ? "166px" : "",
                                        }}></video>
                                      )}
                                    </div>
                                    <input type="file" id='teacherVideo' name="teacherVideo" accept="video/*" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageState4 ? "none" : "" }} />
                                    {profileImageState4 ? "" :
                                      <span className="file_icons position-absolute">
                                        <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                        </svg>
                                      </span>
                                    }
                                  </div>
                                </>
                              )} />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacherVideo?.message}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Any additional information you would like us to know</label>
                      <textarea className="form-control" {...register("additional_info")} name="additional_info" />
                    </div>
                    <div className="form-group mb-3 d-flex gap-4 align-items-center ">
                      <input type="checkbox" id="ideclare"  {...register('checkbox')} name='checkbox' />
                      <label htmlFor="ideclare" className="d-block">
                        I hereby read, understand and agree to the terms
                      </label>
                    </div>
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox?.message}</span></p>

                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label className="mb-2">Your Full Name:<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" {...register("first_name")} name="first_name" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.first_name?.message}</span></p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label className="mb-2 mx-2">
                          Date:
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input type="date" className="form-control w-25 d-inline-block" {...register("date1")} name="date1" min={StartDate} value={StartDate} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date1?.message}</span></p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label className="mb-2">Your Country of Residence :<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" {...register("country_of_residence")} name="country_of_residence" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country_of_residence?.message}</span></p>
                      </div>
                    </div>
                    <div className="button mt-5">
                      {/* <Link to="/teacher_dashboard" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</Link> */}
                      <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
