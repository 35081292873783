import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { teacher_add_homework, teacher_add_lession } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { Helmet } from 'react-helmet';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ content, onChange }) => {
  return (
    <ReactQuill
      value={content}
      onChange={onChange}
      modules={Editor.modules}
      formats={Editor.formats}
      theme="snow"
      placeholder="Write something..."
      style={{ borderRadius: '10px' }}
    />
  );
};

Editor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};

Editor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
];

export default function OAddHomework() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const location = useLocation();
  var id = location?.state;

  const schema = yup.object().shape({
    homework_topic: yup.string().required("Homework topic topic is required"),

    description: yup.string().required("Homework description is required"),
    due_date: yup.string().required("Due date is required"),
    due_time: yup.string().required("Due time  is required"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [profileImageState, setProfileImageState] = useState('')
  const [AttachmentData, setAttachmentData] = useState(['', '', '', '', '', ''])
  const [description, setDescription] = useState('');

  const handleImageChange = async (image, fileIndex, type) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }

    var imageName = await uploadImageOnAWS(image, "homework_attachements");
    var temp_arr = AttachmentData;
    temp_arr[fileIndex] = { attachments: imageName, attachment_type: type }

    // AttachmentData[fileIndex] = image
    setAttachmentData(temp_arr)
    setValue("attachments_data", setAttachmentData);

    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("attachments_data");
  }

  const handleDescriptionChange = (value) => {
    const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
    setDescription(isEmptyDescription ? '' : value);
    setValue('description', isEmptyDescription ? '' : value);
    clearErrors('description');
  };

  let onSubmit = async (data) => {
    // var image = await uploadImageOnAWS(data?.attachments_data, "class_image");

    let homework_data = {
      activity_id: id.id,
      activity_type: id.class,
      topic: data.homework_topic,
      description: data.description,
      // attachments: image,
      due_date: data.due_date,
      due_time: data.due_time
    }
    homework_data.attachment = {
      activity_type: "homework",
      attachments: AttachmentData.filter(item => item !== '')

    }

    teacher_add_homework(homework_data).then((response) => {

      if (response?.code == 1) {
        TOAST_SUCCESS(response.message);
        navigate('/organization-tech-detail', {
          state: { id: id.id, class: id.class }
        })
      } else {
        TOAST_ERROR(response.message);
      }
    });

  }



  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
        </style>

      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-8">
                      {/*start*/}
                      <div className="mb-4">
                        <h2 className="mb-2 poppins fw-bold">Add Homework Information</h2>
                      </div>
                      {/*end*/}
                      {/*start*/}
                      <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                        <div className="form-group mb-4">
                          <label className="form-label">Add Homework Topic</label>
                          <div className="position-relative class_title">

                            <input type="text" {...register("homework_topic")} maxLength={50} name="homework_topic" className="form-control" placeholder id />
                            <span className="position-absolute word_count">{50 - (watch("homework_topic")?.split('')?.length ?? 0)} Words</span>

                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.homework_topic?.message}</span></p>
                          </div>
                        </div>
                        {/* <div className="form-group mb-4">
                          <label className="form-label">Add Homework Description</label>
                          <div className="position-relative class_summary">

                            <textarea className="form-control" {...register("description")} name="description" rows={7} placeholder />
                            <span className="position-absolute word_count">
                              {(watch("description") ? watch("description")?.length : 0)} Words
                            </span>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                          </div>
                        </div> */}
                         <div className="form-group mb-4">
                          <label className="form-label">Add Homework Description</label>
                          <div className="position-relative class_summary">
                            <Editor
                              {...register("description")}
                              content={description}
                              onChange={handleDescriptionChange}
                            />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                          </div>
                        </div>
                        <div className="form-group mb-4 attach_box">
                          <label className="form-label">Attachments</label>
                          <div className="position-relative">
                            <textarea type="text" className="form-control" name rows={7} defaultValue={""} disabled />
                            <div className="att_icon_list position-absolute">
                              <ul className="d-flex align-items-center justify-content-end">
                                <li>
                                  <label htmlFor="attach1">
                                    <a>
                                      <img src="./assets/images/attach1.png" alt="attach1" />
                                    </a>
                                  </label>
                                  <input type="file" id='attach1' onInput={(e) => {
                                    const file = e.target.files[0];
                                    const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                    handleImageChange(file, 0, fileType);
                                  }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                </li>
                                <li>
                                  <label htmlFor='attach2'>
                                    <a >
                                      <img src="./assets/images/attach2.png" alt="attach2" />
                                    </a>
                                  </label>
                                  <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                </li>
                                <li>
                                  <label htmlFor='attach3'>
                                    <a>
                                      <img src="./assets/images/attach3.png" alt="attach3" />
                                    </a>
                                    <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                  </label>
                                </li>
                                <li>
                                  <label htmlFor='attach4'>
                                    <a>
                                      <img src="./assets/images/attach4.png" alt="attach4" />
                                    </a>
                                    <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                  </label>
                                </li>
                                <li>

                                  <label htmlFor='attach5'>

                                    <a >
                                      <img src="./assets/images/attach5.png" alt="attach5" />
                                    </a>
                                    <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                  </label>
                                </li>
                                <li>
                                  <label htmlFor='attach6'>

                                    <a >
                                      <img src="./assets/images/attach6.png" alt="attach6" />
                                    </a>
                                    <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group mb-4">
                              <label className="form-label">Homework Due Date</label>
                              <input type="date" {...register("due_date")} name="due_date" min={currentDate} className="form-control" placeholder id />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_date?.message}</span></p>

                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-4">
                              <label className="form-label">Homework Due Time</label>
                              <input type="time" {...register("due_time")} name="due_time" className="form-control" placeholder id />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_time?.message}</span></p>

                            </div>
                          </div>
                        </div>
                        <div className="enviorment_div mb-5 align-items-center d-md-flex">
                          <div className="env_img">
                            <img src="./assets/images/enviorment.png" alt="enviorment" />
                          </div>
                          <div className="env_text">
                            <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                          </div>
                        </div>
                        <div className="button">
                          {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Homework </Link> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Create Homework
                          </button>
                        </div>
                      </form>
                      {/*end*/}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
