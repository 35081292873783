import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import { useFormik } from "formik";
import * as Yup from "yup";
import { uploadImageOnAWS } from '../../aws/Service'
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone';
import Select from 'react-select';

import { contact_us, contact_us_details } from '../../../api/apiHandler'

export default function OContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
const navigate = useNavigate();
  const getData = () => {
    console.log('test');
    contact_us_details().then((response) => {
      console.log("details", response.data[0].user_name)

      if (response?.code == 1) {

        contactUsForm.setValues({
          name: response.data[0].user_name,
          email: response.data[0].user_email,
          country_code:response.data[0].user_country_code,
          phone: response.data[0].user_phone,
          country: response.data[0].user_country,
          time_zone: response.data[0].time_zone,
          code: response.data[0].code_generate,
          current_status: 'New to StudentScholars',
          reason: 'Technical Website Issue',
          who_you_are: 'I am a teacher',
          message: ''


        });
        // setData(response?.data)
      } else {
        // setData([]);
        console.log('data not found');
      }
    });
  }


  const [data, setData] = useState()
  const [selectedTimezone, setSelectedTimezone] = useState('');

  const contactUsSchema = Yup.object({
    name: Yup.string().required("Please enter name"),
    email: Yup.string().required("Please enter email"),
    attachment: Yup.array(),
    phone: Yup.string().required("Please enter phone number"),
    country: Yup.string().required("Please enter country name"),
    time_zone: Yup.string().required("Please enter time zone"),
    code: Yup.string(),
    current_status: Yup.string().required("Please enter current status"),
    reason: Yup.string().required("Please enter reason"),
    who_you_are: Yup.string().required("Please enter designation"),
    message: Yup.string().required("Please enter messsage"),
    url: Yup.string()

  });

  const contactUsForm = useFormik({
    initialValues: {
      name: '',
      email: '',
      attachment: [],
      phone: '',
      country: 'india',
      time_zone: 'Asia/Kolkata',
      code: '',
      current_status: 'New to StudentScholars',
      reason: 'Technical Website Issue',
      who_you_are: 'I am a teacher',
      message: '',
      url: ''
    },
    validationSchema: contactUsSchema,

    onSubmit: async (data) => {
      console.log(data);

      var image = []
      if (data.attachment) {
        for (let i = 0; i < data.attachment.length; i++) {
          var attachment = await uploadImageOnAWS(data?.attachment[i], "contactus_attachment");
          image.push({ attachment: attachment })
        }

        data.attachment = image
      }



      contact_us(data).then((response) => {
        if (response?.code == 1) {
          TOAST_SUCCESS(response.message);
          contactUsForm.resetForm();
          getData()

        } else {
          TOAST_ERROR(response.message);
        }
      });

    },

  });

  const [imagePreviews, setImagePreviews] = useState([null, null, null]);

  const handleImage = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      const updatedPreviews = [...imagePreviews];
      updatedPreviews[index] = previewUrl;
      setImagePreviews(updatedPreviews);
    }
  };

  console.log(contactUsForm);

  const displayError = (fieldName) =>
    contactUsForm.touched[fieldName] && contactUsForm.errors[fieldName];



  useEffect(() => {
    getData()
  }, [])
  
  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: `${tz} (UTC ${moment.tz(tz).format('Z')})`,
  }));

  useEffect(() => {
    // Detect and set the user's timezone as the default value.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(userTimezone);
  }, []);

  let handleChange = (selectedOption) => {
    setSelectedTimezone(selectedOption.value);
    contactUsForm.setFieldValue('time_zone', selectedOption.value);

    // console.log(setSelectedTimezone)
  };


  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
          .css-olqui2-singleValue {
            grid-area: 1/1/2/3;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: hsl(0deg 0% 6.3%);
            margin-left: 2px;
            margin-right: 2px;
            box-sizing: border-box;
        }
        .css-1s7z704-control {
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: default;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          min-height: 38px;
          outline: 0!important;
          position: relative;
          -webkit-transition: all 100ms;
          transition: all 100ms;
          background-color: hsl(0, 0%, 95%);
          border-color: hsl(0, 0%, 90%);
          border-radius: 12px;
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 5px 10px;
          height: 46px;
          color: #212529;
          background-color: #e9ecef;
        }
          `}
        </style>
      </Helmet>
      <main className="dashboard-app">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-it  ems-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content">
          {/*start*/}
          <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
              </svg>
              <span  className="ms-2">Back</span>
            </span></a>
          {/*end */}
          <section className="requirement_form">
            <h2 className="mb-4 fw-600 poppins text-capitalize text-dif-black text-center">Contact Us</h2>
            <div className="title mb-4 text-center">
              <h2 className="fw-600 text-dif-black mb-3 poppins">Hello There, <span className="text-purple">Welcome to StudentScholars Support</span></h2>
              <p className="fw-500 text-gray">Please fill in the form below and our Support Team will email you</p>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="card border-purple p-4">
                  <form className="custom_form mt-4" onSubmit={contactUsForm.handleSubmit}>
                  <div className="form-group mb-4">
                      <label className="mb-2">Code</label>

                      <input type="text" className="form-control" name="code" onChange={contactUsForm.handleChange} value={contactUsForm.values.code} disabled/>
                      {displayError("code") && (
                        <div className="text-danger">{contactUsForm.errors.code}</div>
                      )}
                      <input type='hidden' name='code' />
                    </div>

                    <div className="form-group mb-4">
                      <label className="mb-2">Name</label>

                      <input type="text" className="form-control" name="name" onChange={contactUsForm.handleChange} value={contactUsForm.values.name} disabled/>
                      {displayError("name") && (
                        <div className="text-danger">{contactUsForm.errors.name}</div>
                      )}
                      <input type='hidden' name='code'  />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Email</label>
                      <input type="email" className="form-control" name="email" onChange={contactUsForm.handleChange} value={contactUsForm.values.email} disabled/>
                      {displayError("email") && (
                        <div className="text-danger">{contactUsForm.errors.email}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2 d-block">Phone Number</label>
                      <input type="text" id="mobile_code" className="form-control w-100" placeholder="Phone Number" name="phone" onChange={contactUsForm.handleChange} value={contactUsForm.values.country_code + ' ' +contactUsForm.values.phone} disabled/>
                      {displayError("phone") && (
                        <div className="text-danger">{contactUsForm.errors.phone}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Country</label>
                      <select className="form-select" aria-label="Default select example" name="country" onChange={contactUsForm.handleChange} value={contactUsForm.values.country} style={{"backgroundColor": "#e9ecef"}} disabled>
                        <option selected={data && data[0].user_country == 'Australia' ? 'selected' : ''} value="Australia" >Australia</option>
                        <option selected={data && data[0].user_country == 'Canada' ? 'selected' : ''} value="Canada">Canada</option>
                        <option selected={data && data[0].user_country == 'New Zealand' ? 'selected' : ''} value="New Zealand">New Zealand</option>
                        <option selected={data && data[0].user_country == 'Singapore' ? 'selected' : ''} value="Singapore">Singapore</option>
                        <option selected={data && data[0].user_country == 'UK' ? 'selected' : ''} value="UK">UK</option>
                        <option selected={data && data[0].user_country == 'USA' ? 'selected' : ''} value="USA">USA</option>
                      </select>
                      {displayError("country") && (
                        <div className="text-danger">{contactUsForm.errors.country}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Time Zone</label>
                      <Select
                          options={timezones}
                          value={timezones.find((tz) => tz.value === selectedTimezone)}
                          onChange={handleChange}
                          isSearchable={true}
                          placeholder="Search for a timezone..."
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: '1px solid rgba(102, 102, 102, 0.35)',
                              borderRadius: '12px',
                              padding: '5px 10px', // Adjust padding to make the component smaller
                              height: '46px', // Set the height of the component
                              color: "#212529",
                            }),

                          }}
                          isDisabled />
                      {displayError("time_zone") && (
                        <div className="text-danger">{contactUsForm.errors.time_zone}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Current Status</label>
                      <select className="form-select" aria-label="Default select example" name='current_status' onChange={contactUsForm.handleChange} value={contactUsForm.values.current_status}>
                        <option value="New to StudentScholars" selected>New to StudentScholars</option>
                        <option value="Currently Registered on StudentScholars">Currently Registered on StudentScholars</option>
                      </select>
                      {displayError("current_status") && (
                        <div className="text-danger">{contactUsForm.errors.current_status}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Who you are</label>
                      <select className="form-select" aria-label="Default select example" name='who_you_are' onChange={contactUsForm.handleChange} value={contactUsForm.values.who_you_are}>
                        <option value="I am a teacher" selected>I am a teacher</option>
                        <option value="I am a learner">I am a learner</option>
                        <option value="I am a parent">I am a parent</option>
                        <option value="I am interested to create a partnership">I am interested to create a partnership</option>
                      </select>
                      {displayError("who_you_are") && (
                        <div className="text-danger">{contactUsForm.errors.who_you_are}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Reason for contacting us</label>
                      <select className="form-select" aria-label="Default select example" name='reason' onChange={contactUsForm.handleChange} value={contactUsForm.values.reason}>
                        <option value="Technical Website Issue" selected>Technical Website Issue</option>
                        <option value="Cannot Access a Class">Cannot Access a Class</option>
                        <option value="Withdraw From a Class">Withdraw From a Class</option>
                        <option value="Student Payments and Refunds">Student Payments and Refunds</option>
                        <option value="Transfers and  Cancellation">Transfers and  Cancellation</option>
                        <option value="Help Using StudentScholars">Help Using StudentScholars</option>
                        <option value="Teacher Application or Class Listing">Teacher Application or Class Listing</option>
                        <option value="Teacher Payout, Receipts">Teacher Payout, Receipts</option>
                        <option value="Referral  or Credit">Referral  or Credit</option>
                        <option value="Account Issue">Account Issue</option>
                      </select>
                      {displayError("reason") && (
                        <div className="text-danger">{contactUsForm.errors.reason}</div>
                      )}
                    </div>

                    <div className="form-group mb-4">
                      <label className="mb-2">Paste Your Class URL (Optional)</label>
                      <input type="text" className="form-control" name='url' onChange={contactUsForm.handleChange} value={contactUsForm.values.url} />
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Type a Message</label>
                      <textarea className="form-control" rows={6} name='message' onChange={contactUsForm.handleChange} value={contactUsForm.values.message} />
                      {displayError("message") && (
                      <div className="text-danger">{contactUsForm.errors.message}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="mb-2">Upload Attachments</label>
                      {/* <div className="row">
                        {[0, 1, 2].map((index) =>

                          <div className="col-lg-4">
                            <div className="form-group mb-lg-0 mb-4  file_upload">
                              <div className="file_update position-relative">
                                <input type="file" className="form-control" name='attachment' onChange={(e) => handleImage(e)} />
                                <span className="file_icons position-absolute">
                                  <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      </div> */}
                         <div className="row">
                        {[0, 1, 2].map((index) => (
                          <div className="col-lg-4" key={index}>
                            <div className="form-group mb-lg-0 mb-4 file_upload">
                              <div className="file_update position-relative">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    className="image"
                                    onClick={() => document.getElementById(`attachment-${index}`).click()}
                                    src={imagePreviews[index] ? imagePreviews[index] : ""}
                                    alt=""
                                    style={{
                                      width: imagePreviews[index] ? "100%" : "",
                                      height: imagePreviews[index] ? "166px" : "",
                                    }}
                                  />
                                </div>
                                <input
                                  type="file"
                                  id={`attachment-${index}`}
                                  className="form-control"
                                  name='attachment'
                                  onChange={(e) => handleImage(e, index)}
                                  style={{ display: imagePreviews[index] ? "none" : "" }}
                                />
                                 {!imagePreviews[index] && (
                                <span className="file_icons position-absolute">
                                  <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z"
                                      fill="#131313"
                                      fillOpacity="0.4"
                                    />
                                  </svg>
                                </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="button mt-5">
                      <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div class="col-lg-6">
        			<div class="">
        				<img src="./assets/images/help_contact.png" alt="help_contact">
        			</div>
        		</div> */}
            </div>
          </section>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
