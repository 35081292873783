import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { get_faq_video } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

export default function PFaqDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  var [FaqVideoData, setFaqVideoData] = useState([]);
  console.log("FaqVideoData :", FaqVideoData);
  var faq_id = location?.state;
  console.log(faq_id);
  const FaqVideoDetails = () => {
    get_faq_video({ id: faq_id }).then((resposnse) => {
      console.log("FaqVideo_details", resposnse);

      if (resposnse?.code == 1) {
        setFaqVideoData(resposnse?.data);
      } else {
        setFaqVideoData([]);
        console.log("data not found");
      }
    });
  };

  useEffect(() => {
    FaqVideoDetails();
  }, []);
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
             <BackToPrevious/>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-bold">FaqVideo - training Videos</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row mb-5 blogs_lists">
                    {FaqVideoData.map((video) => (
                      <div key={video.id} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                        <div className="certi_box bg-transparent shadow-none">
                          <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                            <div className="blog_img">
                              <video controls className="w-100 border-radius-10">
                                <source src={video.media} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                          <Link to="/faq_video_details" state={video.id} className="d-block certi_ctn px-0">
                            <h3 className="fw-600 mb-2 text-dif-black text-capitalize poppins">{video.title}</h3>
                            <p className="text-dif-black text-capitalize">{video.description}</p>
                            <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="dwnl_img">
                                  <img src={video.profile_image} alt="center_div_img2" />
                                </div>
                                <div className="ms-3">
                                  <span className="font-14 fw-bold">{video.admin_name}</span>
                                </div>
                              </div>
                              <div>
                                <span>{video.formatted_date}</span> • <span>{video.formatted_time} ago</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
