import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import { faq_list } from '../../../api/apiHandler'

export default function OFaq() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  var [FaqData, setFaqData] = useState([]);
  console.log('FaqData :', FaqData);
  const panel_name = localStorage.getItem("type")

  const FaqDetails = () => {

    faq_list().then((response) => {
      console.log("Faq_details", response)

      if (response?.code == 1) {
        const filteredData = response.data.filter((item) =>
        item.panels && Array.isArray(item.panels) && item.panels.some(panel => panel.panel_name === panel_name)  );
        setFaqData(filteredData)      
      } else {
        setFaqData([]);
        console.log('data not found');
      }
    });
  }

  useEffect(() => {

    FaqDetails();

  }, []);
  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              {/* <a href="#" className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a> */}
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600 text-capitalize">FAQ  - training Videos</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row">
                    <div className="col-lg-8">
                      {/*start-Search*/}
                      <div className="search_box mb-4 position-relative">
                        <input type="text" name placeholder="Search" />
                        <span className="ms-3 position-absolute">
                          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#666666" fillOpacity="0.4" />
                          </svg>
                        </span>
                        <img src="./assets/images/rec_icon.png" alt="rec_icon" className="position-absolute rec_search_icon" />
                      </div>
                      {/*end-Search*/}
                      <ul className="traning_list ps-lg-3">
                        {FaqData && FaqData.length > 0 ? (
                          FaqData.map(item => (
                            <li key={item.id} className="mb-4 ">
                              <Link to="/organization_faq_details" state={item.id} className="d-flex align-items-center justify-content-between">
                                <div>
                                  <h3 className="fs-5 poppins fw_500">{item.title}</h3>
                                </div>
                                <div >

                                  <svg width={20} height={15} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.6164 9.06753L14.9491 0.400093C14.7017 0.15268 14.372 0.0168762 14.0204 0.0168762C13.6684 0.0168762 13.3388 0.152875 13.0914 0.400093L12.3045 1.18721C12.0573 1.43423 11.9211 1.76418 11.9211 2.11598C11.9211 2.46759 12.0573 2.80866 12.3045 3.05568L17.3608 8.12315H1.29657C0.572288 8.12315 0 8.69017 0 9.41465V10.5274C0 11.2519 0.572288 11.8761 1.29657 11.8761H17.4182L12.3047 16.9719C12.0575 17.2193 11.9213 17.5402 11.9213 17.892C11.9213 18.2435 12.0575 18.5691 12.3047 18.8163L13.0916 19.6009C13.339 19.8483 13.6686 19.9832 14.0206 19.9832C14.3722 19.9832 14.7019 19.8466 14.9493 19.5992L23.6166 10.9319C23.8646 10.6837 24.001 10.3524 24 10.0002C24.0008 9.64685 23.8646 9.31534 23.6164 9.06753Z" fill="black" />
                                  </svg>

                                </div>
                              </Link>
                            </li>
                          ))
                        ) : (
                          <li>No Data Found</li>
                        )}

                      </ul>
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
