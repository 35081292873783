import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { disscussion_data, disscussion_edit_data } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';

export default function OEditDisscussion() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);

    const location = useLocation();
    const navigate = useNavigate();

    var edit_id = location?.state;

    const schema = yup.object().shape({
        topic: yup.string().required("Discussion topic is required"),
        description: yup.string().required("Discussion description is required"),
        status: yup.string().required("Discussion status is required"),
        open_date: yup.string().required("Open date is required"),
        open_time: yup.string().required("Open time  is required"),
        close_date: yup.string().required("Close date is required"),
        close_time: yup.string().required("Close time  is required"),
        score: yup.string().required("Score is required"),
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [lessonData, setLessonData] = useState([])
    var [classTitleLength, setclassTitleLength] = useState(0);
    const [getOpenDate, setGetOpenDate] = useState("");

    const SelectOpenDate = (d) => {
        setGetOpenDate(d)
    }

    const disscussion_lessonlist = () => {

        disscussion_data({ discussion_id: edit_id }).then((response) => {

            if (response?.code == 1) {
                setclassTitleLength(response?.data?.[0]?.topic.length)
                setGetOpenDate(response?.data?.[0]?.open_date)
                reset({
                    // topic: response?.data?.[0]?.topic,
                    description: response?.data?.[0]?.description,
                    status: response?.data?.[0]?.status,
                    open_date: response?.data?.[0]?.open_date,
                    open_time: response?.data?.[0]?.open_time,
                    close_date: response?.data?.[0]?.close_date,
                    close_time: response?.data?.[0]?.close_time,
                    attachments_data: response?.data?.[0]?.attachments,

                    hours: response?.data?.[0]?.duration?.split(' ')?.[0],
                    minutes: response?.data?.[0]?.duration?.split(' ')?.[2],
                    score: response?.data?.[0]?.score,
                });
                setLessonData(response?.data?.[0])

            } else {
                console.log('data not found')
            }
        });
    }

    let classTitleData = (e) => {

        setclassTitleLength(0)
    }

    useEffect(() => {
        disscussion_lessonlist();
    }, [])

    const [profileImageState, setProfileImageState] = useState('')

    const [AttachmentData, setAttachmentData] = useState(['', '', '', '', '', ''])

    const handleImageChange = async (image, fileIndex, type) => {
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }

        var imageName = await uploadImageOnAWS(image, "assesment_attachements");
        var temp_arr = AttachmentData;
        temp_arr[fileIndex] = { attachments: imageName, attachment_type: type }
        // AttachmentData[fileIndex] = image
        setAttachmentData(temp_arr)
        setValue("attachments_data", setAttachmentData);

        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("attachments_data");
    }


    let onSubmit = async (data) => {
        let image = data?.attachments_data
        if (image instanceof Blob) image = await uploadImageOnAWS(image, "class_image");


        let discussion_data = {
            discussion_id: edit_id,

            topic: data.topic,
            description: data.description,
            // attachments: image,
            status: data.status,
            open_date: data.open_date,
            open_time: data.open_time,
            close_date: data.close_date,
            close_time: data.close_time,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
            score: data.score,

        }
        discussion_data.attachment = {
            activity_type: "discussion",
            attachments: AttachmentData.filter(item => item !== '')
        }

        disscussion_edit_data(discussion_data).then((response) => {

            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                navigate('/organization-tech-detail', {
                    state: { id: lessonData.activity_id, class: lessonData.activity_type }
                })
            } else {
                TOAST_ERROR(response.message);
            }
        });
        reset();


    }

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className=" ">
                                    {/*start*/}
                                    <div className="row">
                                        <div className="col-xl-8">
                                            {/*start*/}
                                            <div className="mb-4">
                                                <h2 className="mb-2 poppins fw-bold">Edit Discussion Information</h2>
                                            </div>
                                            {/*end*/}
                                            {/*start*/}
                                            <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Add Discussion Topic</label>
                                                    <div className="position-relative class_title">

                                                        <input type="text" {...register("topic")} maxLength={50} name="topic" className="form-control" placeholder id defaultValue={lessonData.topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                        <span className="position-absolute word_count">
                                                            {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                                        </span>
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.topic?.message}</span></p>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Add Discussion Description</label>
                                                    <textarea className="form-control" {...register("description")} name="description" rows={7} placeholder id />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>

                                                </div>
                                                <div className="form-group mb-4 attach_box">
                                                    <label className="form-label">Add Attachments</label>
                                                    <div className="position-relative">
                                                        <textarea type="text" className="form-control" name rows={7} defaultValue={""} />
                                                        <div className="att_icon_list position-absolute">
                                                            <ul className="d-flex align-items-center justify-content-end">
                                                                <li>
                                                                    <label htmlFor="attach1">
                                                                        <a>
                                                                            <img src="./assets/images/attach1.png" alt="attach1" />
                                                                        </a>
                                                                    </label>
                                                                    <input type="file" id='attach1' onInput={(e) => {
                                                                        const file = e.target.files[0];
                                                                        const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                        handleImageChange(file, 0, fileType);
                                                                    }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </li>
                                                                <li>
                                                                    <label htmlFor='attach2'>
                                                                        <a >
                                                                            <img src="./assets/images/attach2.png" alt="attach2" />
                                                                        </a>
                                                                    </label>
                                                                    <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </li>
                                                                <li>
                                                                    <label htmlFor='attach3'>
                                                                        <a>
                                                                            <img src="./assets/images/attach3.png" alt="attach3" />
                                                                        </a>
                                                                        <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label htmlFor='attach4'>
                                                                        <a>
                                                                            <img src="./assets/images/attach4.png" alt="attach4" />
                                                                        </a>
                                                                        <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                    </label>
                                                                </li>
                                                                <li>

                                                                    <label htmlFor='attach5'>

                                                                        <a >
                                                                            <img src="./assets/images/attach5.png" alt="attach5" />
                                                                        </a>
                                                                        <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label htmlFor='attach6'>

                                                                        <a >
                                                                            <img src="./assets/images/attach6.png" alt="attach6" />
                                                                        </a>
                                                                        <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Discussion Status</label>
                                                    <input type="text" {...register("status")} name="status" className="form-control" placeholder id />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.status?.message}</span></p>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Discussion Open Date</label>
                                                            <input type="date" {...register("open_date")} name="open_date" min={currentDate} onChange={(e) => SelectOpenDate(e.target.value)} className="form-control" placeholder id />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.open_date?.message}</span></p>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Discussion Open Time</label>
                                                            <input type="time" {...register("open_time")} name="open_time" className="form-control" placeholder id />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.open_time?.message}</span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Discussion Close Date</label>
                                                            <input type="date" {...register("close_date")} name="close_date" min={getOpenDate} className="form-control" placeholder id />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.close_date?.message}</span></p>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Discussion Close Time</label>
                                                            <input type="time" {...register("close_time")} name="close_time" className="form-control" placeholder id />
                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.close_time?.message}</span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className="mb-3">Discussion duration</h3>
                                                <div className="d-md-flex mb-4 align-items-center">
                                                    <p className="fs-6  mb-md-0 mb-3">Expected Time spent On Discussion Hours</p>
                                                    <div className="d-md-flex ms-3">
                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                                <option value={0}>0 </option>
                                                                <option value={1}>1 </option>
                                                                <option value={2}>2 </option>
                                                                <option value={3}>3 </option>
                                                                <option value={4}>4 </option>
                                                            </select>
                                                            <span className="ms-3">Minutes</span>
                                                        </div>
                                                        <div className="ms-md-3">
                                                            <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                                <option value={0}>0 </option>
                                                                <option value={5}>5 </option>
                                                                <option value={10}>10 </option>
                                                                <option value={15}>15 </option>
                                                                <option value={20}>20 </option>
                                                                <option value={25}>25 </option>
                                                                <option value={30}>30 </option>
                                                                <option value={35}>35 </option>
                                                                <option value={40}>40 </option>
                                                                <option value={45}>45 </option>
                                                                <option value={45}>50 </option>
                                                                <option value={45}>55 </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className="mb-3">Discussion Score</h3>
                                                <div className="form-group mb-4">
                                                    <label className="form-label">total score for this Discussion</label>
                                                    <input type="text" {...register("score")} name="score" className="ms-3" placeholder id />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.score?.message}</span></p>

                                                </div>
                                                <div className="enviorment_div mb-5 align-items-center d-md-flex">
                                                    <div className="env_img">
                                                        <img src="./assets/images/enviorment.png" alt="enviorment" />
                                                    </div>
                                                    <div className="env_text">
                                                        <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                                                    </div>
                                                </div>
                                                <div className="button">
                                                    {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Discussion</Link> */}
                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Discussion
                                                    </button>
                                                </div>
                                            </form>
                                            {/*end*/}
                                        </div>
                                    </div>
                                    {/*end*/}
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
