import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { homework_upload_listing, homework_upload_marks, learner_name_list } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import TFooter_Dashboard from '../Include/TFooter_Dashboard';
import THeader_Dashboard from '../Include/THeader_Dashboard';
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { htmlToText } from 'html-to-text';

var COMMON_STYLE_FOR_POPUP = {
    content: {
        background: 'none',
        border: '0px',
    },
}

const TruncateText = ({ text, length }) => {
    const truncateString = (str, num) => {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    };
  
    // Convert HTML to plain text
    const plainText = htmlToText(text || '', {
      wordwrap: false,
      ignoreImage: true,
      ignoreHref: true,
    });
  
    const truncatedText = truncateString(plainText, length);
    return (
      <p
        dangerouslySetInnerHTML={{ __html: truncatedText }}
        className="fs-6"
        style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
      />
    );
  };

  
export default function HomeworkResponse() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location :', location?.state);
    const homework_id = location?.state?.homework_id
    // console.log('homework_id :', homework_id);
    const activity_id = location?.state?.id?.id
    const activity_type = location?.state?.id?.class

    const [data, setData] = useState([])
    const [learnerData, setLearnerData] = useState([]);


    const learner_listing = () => {

        learner_name_list({ activity_id: activity_id, activity_type: activity_type }).then((response) => {
            console.log("learner_name_list", response)

            if (response?.code == 1) {
                setLearnerData(response.data)
            } else {
                console.log('data not found')
            }
        });
    }
    useEffect(() => {
        learner_listing();
        AllHomework(0);
    }, []);


    const handleSelectedLearner = (data) => {
        console.log('data123 :', data);
        var main = JSON.parse(data);
        console.log('main :', main);
        if (main != 0) {
            AllHomework(main)


        } else {
            AllHomework(0)

        }
    }

    const AllHomework = async (main) => {
        try {
            let Homework_data = {
                homework_id: homework_id,
            }
            if (main != 0) {
                if (main.type == 'user') {
                    Homework_data.userId = main.id
                } else {
                    Homework_data.learnerId = main.id
                }
            }
            console.log('Homework_data :', Homework_data);

            const response = await homework_upload_listing(Homework_data)
            console.log('response123 :', response);
            if (response?.code == 1) {
                setData(response.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        AllHomework();
    }, [])

    const [modalIsOpen, setIsOpen] = useState(false);
    const schema = yup.object().shape({
        teacher_comment: yup.string().required("This is required "),

    });
    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [learnerID, setLearnerID] = useState(null);
    console.log('learnerID :', learnerID);
    const [userId, setUserId] = useState(null);
    console.log('userId :', userId);

    let AddComment = async (learner_id, user_id) => {

        setLearnerID(learner_id)
        setUserId(user_id)
        setIsOpen(true)
    };

    let onSubmit = async (data) => {
        console.log('data :', data);
        let teacherComment = {
            homework_id: homework_id,
            comment: data.teacher_comment,

        }
        if (learnerID != null && learnerID != '') {
            teacherComment.learnerId = learnerID
        }
        if (userId != null && userId != '') {
            teacherComment.userId = userId
        }
        console.log('teacherComment :', teacherComment);

        homework_upload_marks(teacherComment).then((response) => {

            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                setIsOpen(false)
                reset();
            } else {
                TOAST_ERROR(response.message);
            }
        });


    }

    const Reupload = (learner_id, user_id) => {

        // alert(id)

        Swal.fire({
            title: 'Are you sure you want the learner to reupload it?',
            //   text: "You won't be able to revert this!",
            icon: 'question',
            background: 'white',
            showCancelButton: true,
            confirmButtonColor: '#570861',
            cancelButtonColor: '#FFC924',
            confirmButtonText: 'Yes, Reupload it!',
            cancelButtonClass: 'swal-cancel-button',
        }).then((result) => {
            if (result.isConfirmed) {
                let reUpload = {
                    homework_id: homework_id,
                    is_reupload: "1",
                }
                if (learner_id != null && learner_id != '') {
                    reUpload.learnerId = learner_id
                }
                if (user_id != null && user_id != '') {
                    reUpload.userId = user_id
                }
                homework_upload_marks(reUpload).then((response) => {
                    if (response?.code == 1) {
                        // TOAST_SUCCESS(response.message);
                        AllHomework();

                    } else {
                        TOAST_ERROR(response.message);
                    }
                })
            }
        })

    }

    const DownloadHomework = async (homeworkUrl) => {
        try {
            // Fetch the file from the given URL
            const response = await fetch(homeworkUrl);

            // Check if the response is okay
            if (!response.ok) {
                throw new Error(`Failed to fetch the file: ${response.statusText}`);
            }

            // Convert the response to a blob
            const fileBlob = await response.blob();

            // Determine the file type (mime type) and file extension
            let fileType = fileBlob.type;  // Use blob's type if available
            let fileExtension = '';

            if (homeworkUrl.endsWith('.pdf')) {
                fileType = 'application/pdf';
                fileExtension = 'pdf';
            } else if (homeworkUrl.endsWith('.docx')) {
                fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                fileExtension = 'docx';
            } else if (homeworkUrl.endsWith('.xls')) {
                fileType = 'application/vnd.ms-excel';
                fileExtension = 'xls';
            } else if (homeworkUrl.endsWith('.xlsx')) {
                fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                fileExtension = 'xlsx';
            } else if (homeworkUrl.endsWith('.png')) {
                fileType = 'image/png';
                fileExtension = 'png';
            } else if (homeworkUrl.endsWith('.jpg') || homeworkUrl.endsWith('.jpeg')) {
                fileType = 'image/jpeg';
                fileExtension = 'jpg';
            } else {
                // Default to blob's type if file extension is not specified
                fileExtension = fileBlob.type.split('/')[1] || 'file';
            }

            // Create a random file name using the current timestamp and the file extension
            const randomName = `downloaded_file_${Date.now()}.${fileExtension}`;

            // Create a File object using the blob, random file name, and mime type
            const file = new File([fileBlob], randomName, { type: fileType });

            // Create a URL for the file
            const fileUrl = URL.createObjectURL(file);

            // Create an anchor element to trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = randomName;

            // Trigger the download
            downloadLink.click();

            // Release the object URL to free up resources
            URL.revokeObjectURL(fileUrl);

            console.log('Download started.');
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <Helmet>
                <style>
                    {`
                    .class-content {
                        padding: 18px 30px;
                    }
                    .btn-theme {
                        padding: 8px 50px;
                    }
                    `}
                </style>
            </Helmet>

            <main className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="#" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="class-content ">
                    {/*start*/}
                    <a className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                        <span>
                            <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                            </svg>
                            <span onClick={() => navigate(-1)} className="ms-2">Back</span>
                        </span></a>
                    {/*end*/}
                    {/*start*/}

                    {/*end*/}
                </div>

                <div className="class-content ">

                    <div className="row">
                        <div className="col-xl-9 mb-xl-0 mb-4">
                            <div className="d-flex mb-4 justify-content-between align-items-center">
                                <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">

                                    <option value='0' selected >Learner’s Name</option>

                                    {learnerData?.map((student, index) => {
                                        const type = student?.learner_id ? 'learner' : 'user';
                                        const id = student?.learner_id || student?.user_id;
                                        const valueObj = JSON.stringify({ type, id });
                                        return (
                                            <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="review_dack">
                                {
                                    data?.map((Home, index) => {
                                        return (
                                            <div className="card review_box mb-4">
                                                <div className="review_top">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                        <div className="list_btw_dots mb-3">
                                                            <ul className="d-xl-flex align-items-center">
                                                                <span className="pe-sm-4 fw-600 position-relative">{Home.topic}<span className="green_dots position-absolute" /></span>
                                                                <li>
                                                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                            <span>Learner’s Name</span> :&nbsp;<span>{Home.full_name}</span>
                                                        </div>

                                                    </div>
                                                    <div className="review_text row mb-4">
                                                        <div className="col-lg-8">
                                                            {/* <p className="fs-6">{Home.description}</p> */}
                                                            <TruncateText text={Home?.description} length={25} className="fs-6" />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                        <div className="button d-sm-flex align-items-center">
                                                            {/* <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a> */}
                                                            {Home?.homeworkListingdata?.map((dhome, index) => {
                                                                return (
                                                                    <a onClick={() => DownloadHomework(dhome)} className="btn-theme text-center bg-yellow d-inline-block ms-sm-3 mx-2">Download {index + 1}</a>
                                                                )
                                                            })}
                                                            <Link state={location?.state} onClick={(e) => AddComment(Home.learner_id, Home.user_id)} className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block mx-2"   >Comment</Link>
                                                            <a onClick={() => (Reupload())} className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block mx-2">Reupload

                                                            </a>
                                                        </div>
                                                        <div className="list_btw_dots mb-xl-0 mb-4">
                                                            <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                                {/* <li>
                                                        <span className="text-danger fw-500">1 Day Left</span>
                                                    </li> */}
                                                                <li>
                                                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {Home.upload_date}</span>
                                                                </li>
                                                                {/* <li>
                                                        <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                    </li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>
                            {/*start-pagination*/}

                        </div>

                    </div>

                </div>
            </main>
            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

                {/* <div className="modal fade ask_the_teacher" id="ask_the_teacher" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Add A Comment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {/* <div className="teacher_detail">
        <div><span className="fw-500">From: </span> <span className="text-gray"> {askData.user_name} (local time: 3:46 PM)</span></div>
        <div><span className="fw-500">To: </span> <span className="text-gray"> {askData.teacher_name} (local time: 6:16 AM)</span></div>
        <div><span className="fw-500">Subject: </span> <span className="text-gray"> Message about: Drawing 101: A Weekly Drawing Class for Aspiring Artists</span></div>
      </div> */}
                            <div className>
                                <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Write Your Comment Here</label>
                                        <textarea className="form-control" name='teacher_comment' {...register("teacher_comment")} rows={4} />
                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacher_comment?.message}</span></p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>
                                        <button type="button" onClick={() => setIsOpen(false)} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                {/* </div> */}
            </Modals>
            <TFooter_Dashboard />
        </>
    )
}
