import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { teacher_background_check } from '../../../../api/apiHandler';

export default function TeacherBgCheck() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();

    const [teacherUnder18, setTeacherUnder18] = useState("");
    console.log('teacherUnder18 :', teacherUnder18);
    const [certifiedSchool, setCertifiedSchool] = useState("");
    const [privateSchool, setPrivateSchool] = useState("");
    const [legalCheck, setLegalCheck] = useState("");
    var [OrgCountryData, SetOrgCountryData] = useState("");
    console.log('OrgCountryData :', OrgCountryData);

    const baseSchema = yup.object().shape({
        under_18: yup.string().required("Please select whether you are going to teach children under 18 years old."),
        checkbox: yup.boolean().oneOf([true], 'You must agree that your information is accurate.').required('You must agree that your information is accurate.'),
        date1: yup.string().required("Date is required"),
        country_of_residence: yup.string().required("Country of Residence is required"),
        full_name: yup.string().required("Full name is required"),
    });

    let schema;
    let schema1;
    let schema2;
    let schema3;
    let schema4;

    if (teacherUnder18 === 'yes') {
        schema = baseSchema.shape({
            certified_school: yup.string().required('This is required'),
            board_private: yup.string().required('This is required'),
            legal_check: yup.string().required('This is required'),
        });

        if (certifiedSchool === 'yes') {
            schema1 = schema.shape({
                registration_number: yup.string().required('This is required'),
                attach1: yup.mixed().required("This is required"),
            });
        }
        if (privateSchool === 'yes') {
            schema2 = baseSchema.shape({
                school_name: yup.string().required('School name is required'),
                school_address: yup.string().required('School address is required'),
                school_phone_number: yup.string().required('School phone number is required'),
                school_email: yup.string().required('Email is required'),
                bg_expiry_date: yup.string().required('Background expiry date is required'),
                company_info_image: yup.mixed().required("This is required"),
            });
        }
        if (legalCheck === 'yes') {
            schema3 = baseSchema.shape({
                legal_check_doc: yup.mixed().required("Legal check document is required"),
            });
        } else {
            schema3 = baseSchema.shape({});
        }
    } else {
        schema = baseSchema.shape({
            violence: yup.string().required('This is required'),
            barred_from: yup.string().required('This is required'),
            Fraud: yup.string().required('This is required'),
            safety: yup.string().required('This is required'),
            illegal_drugs: yup.string().required('This is required'),
        });

        if (legalCheck == 'no') {
            schema4 = baseSchema.shape({
                country_you_live_in: yup.string().required('Country you live in is required'),
            });
        } else {
            schema4 = baseSchema.shape({});
        }
    }

    // Combine the schemas using yup's mixed type
    const finalSchema = schema1 ? schema1.concat(schema2 || schema3 || schema4 || schema) : schema2 ? schema2.concat(schema3 || schema4 || schema) : schema3 ? schema3.concat(schema4 || schema) : schema4 ? schema4.concat(schema) : schema;

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(finalSchema)
    });


    const [StartDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);

    const Under_18 = (data) => {
        // console.log('data :', data);
        setTeacherUnder18(data)
    }

    const certified_school = (data) => {
        setCertifiedSchool(data)
    }

    const private_school = (data) => {
        setPrivateSchool(data)
    }

    const legal_Check = (data) => {
        setLegalCheck(data)
    }

    const orgCountryData = (data) => {
        SetOrgCountryData(data)
    }

    const [profileImageState1, setProfileImageState1] = useState('')
    const [profileImageState2, setProfileImageState2] = useState('')
    const [profileImageState3, setProfileImageState3] = useState('')
    const [profileImageStateCountry, setProfileImageStateCountry] = useState('')
    const [docExtension1, setDocExtension1] = useState('')
    const [docExtension2, setDocExtension2] = useState('')
    const [docExtension3, setDocExtension3] = useState('')
    const [docExtension4, setDocExtension4] = useState('')

    const handleImage1Change = async (image) => {
        console.log('image :', image.type);
        setDocExtension1(image.type)
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        // console.log('image :', image);
        setValue("attach1", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState1(bufferImage);
        clearErrors("attach1");
    }

    const handleImage2Change = async (image) => {
        console.log('image :', image.type);
        setDocExtension2(image.type)
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        // console.log('image :', image);
        setValue("company_info_image", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState2(bufferImage);
        clearErrors("company_info_image");
    }

    const handleImage3Change = async (image) => {
        console.log('image :', image.type);
        setDocExtension3(image.type)
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        // console.log('image :', image);
        setValue("legal_check_doc", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState3(bufferImage);
        clearErrors("legal_check_doc");
    }

    const handleImage4Change = async (image) => {
        console.log('image :', image.type);
        setDocExtension4(image.type)
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        // console.log('image :', image);
        setValue("country_bg_check", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageStateCountry(bufferImage);
        clearErrors("country_bg_check");
    }

    let onSubmit = async (data) => {
        console.log('data :', data);
        // teacher_id,teach_children,certified_school_board,certifiacte_teach,private_school,registration_number,school_name,school_address,school_contact,school_email,back_comp_info,expiry_date,country_requirements,legal,valid_legal_check,local_police_station,country_livein,reside_aus,reside_can,reside_ind,reside_nz,reside_sing,reside_uk,reside_usa,org_position,org_name,org_address,staffing_position,convicted,offences,outstanding,convicted_fraud,barred
        // (data.city != undefined ? data.city : ''), 
        // country_requirements  delete
        // reside change

        if (teacherUnder18 == "yes") {
            var submit_data = {


                teacher_id: localStorage.getItem("Tid"),
                teach_children: teacherUnder18,
                certified_school_board: teacherUnder18 == 'yes' ? certifiedSchool : "",
                registration_number: (data.registration_number != undefined ? data.registration_number : ''),
                private_school: teacherUnder18 == 'yes' ? privateSchool : "",
                school_name: (data.school_name != undefined ? data.school_name : ''),
                school_address: (data.school_address != undefined ? data.school_address : ''),
                school_contact: (data.school_phone_number != undefined ? data.school_phone_number : ''),
                school_email: (data.school_email != undefined ? data.school_email : ''),
                expiry_date: (data.bg_expiry_date != undefined ? data.bg_expiry_date : ''),
                legal: legalCheck ? legalCheck : "",
                local_police_station: (data.country_you_live_in != undefined ? data.country_you_live_in : ''),
                country_livein: (data.country != undefined ? data.country : ''),
                org_position: (data.Description_Of_The_Position != undefined ? data.Description_Of_The_Position : ''),
                org_name: (data.name_of_org != undefined ? data.name_of_org : ''),
                org_address: (data.address_of_org != undefined ? data.address_of_org : ''),
                org_position: (data.staffing_position != undefined ? data.staffing_position : ''),
                additional_info: (data.additional_info != undefined ? data.additional_info : ''),

            }

            let attachment1 = data?.attach1
            let attachment2 = data?.company_info_image
            let attachment3 = data?.legal_check_doc
            let attachment4 = data?.country_bg_check


            if (data?.attach1) {
                attachment1 = await uploadImageOnAWS(attachment1, "teacher_bg");
                submit_data.certifiacte_teach = attachment1;
            }
            if (data?.company_info_image) {
                attachment2 = await uploadImageOnAWS(attachment2, "teacher_bg");
                submit_data.back_comp_info = attachment2;
            }
            if (data?.legal_check_doc) {
                attachment3 = await uploadImageOnAWS(attachment3, "teacher_bg");
                submit_data.valid_legal_check = attachment3;
            }
            if (data?.country_bg_check) {
                attachment4 = await uploadImageOnAWS(attachment4, "teacher_bg");
                submit_data.reside = attachment4;
            }
        } else {
            var submit_data = {
                teacher_id: localStorage.getItem("Tid"),
                teach_children: teacherUnder18,
                convicted: (data.violence != undefined ? data.violence : ''),
                offences: (data.illegal_drugs != undefined ? data.illegal_drugs : ''),
                outstanding: (data.safety != undefined ? data.safety : ''),
                convicted_fraud: (data.Fraud != undefined ? data.Fraud : ''),
                barred: (data.barred_from != undefined ? data.barred_from : ''),
                additional_info: (data.additional_info != undefined ? data.additional_info : ''),

            }
        }
        console.log('submit_data :', submit_data);
        teacher_background_check(submit_data).then((response) => {
            if (response?.code == 1) {
                TOAST_SUCCESS(response.message)
                navigate("/signup-teacher")
                // reset();
            } else {
                TOAST_ERROR(response.message)
            }
        })

    }

    return (
        <>
            <Helmet><style>
                {`
                 .custom_form .form-group label {
                     text-transform: capitalize;
                     color: #666666;
                     margin: 0 !important;
                 }
                 .custom_form .form-group label {
                    text-transform: capitalize;
                    color: #000000;
                  }
            
                 `}
            </style></Helmet>
            <main>
                <div className="login_sec">
                    <div className="container-fluid">
                        <div className="row my-4 justify-content-center">
                            <div className="col-lg-8 right card">
                                <div className="top">
                                    <div className="arrow_back mb-3">
                                        <a onClick={()=>navigate(-1)} className="d-flex align-items-center">
                                            <span>
                                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                                                </svg>
                                            </span>
                                            <span onClick={() => navigate(-1)} className="ps-3">Back</span>
                                        </a>
                                    </div>
                                    <h2 className="mb-4">Teacher Background Check</h2>
                                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">
                                                    Will you teach children, under 18 years old?
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="d-flex gap-2">
                                                        <input className="form-check-input" type="radio" id="yes_under_18"  {...register("under_18")} name="under_18" onClick={(e) => Under_18("yes")} />
                                                        <label htmlFor="yes_under_18" className="mb-2">
                                                            Yes
                                                        </label>
                                                        <input className="form-check-input" type="radio" id="no_under_18"  {...register("under_18")} name="under_18" onClick={(e) => Under_18("no")} />
                                                        <label htmlFor="no_under_18" className="mb-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    {errors.under_18 && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.under_18.message}</span></p>}

                                                </div>
                                            </div>


                                            {teacherUnder18 == "yes" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className>Are you currently a certified school board teacher?
                                                                <span className="text-danger">*</span></label>
                                                            <div className="d-flex gap-2">
                                                                <input className="form-check-input" type="radio" id="yes_certified_school" name="certified_school" {...register("certified_school")} onClick={(e) => certified_school("yes")} />
                                                                <label htmlFor="yes_certified_school" className="mb-2">
                                                                    Yes
                                                                </label>
                                                                <input className="form-check-input" type="radio" id="no_certified_school" name="certified_school" {...register("certified_school")} onClick={(e) => certified_school("no")} />
                                                                <label htmlFor="no_certified_school" className="mb-2">
                                                                    No
                                                                </label>
                                                            </div>
                                                            {errors.certified_school && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.certified_school.message}</span></p>}
                                                            {/* <p className="mb-2">If yes follow below </p> */}
                                                            {certifiedSchool == "yes" ?
                                                                <>
                                                                    <p className="mb-2">
                                                                        upload the teaching certificate
                                                                    </p>
                                                                    <div className="row">
                                                                        <div className="col-lg-4">
                                                                            <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                                <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                    <>
                                                                                        <div className="file_update position-relative">
                                                                                            <div className="d-flex align-items-center justify-content-center">

                                                                                                {(docExtension1 === 'image/jpeg' || docExtension1 === 'image/png') && (
                                                                                                    <img onClick={() => {
                                                                                                        document.getElementById("attach1")?.click()
                                                                                                    }}
                                                                                                        className="image"
                                                                                                        src={profileImageState1 ? profileImageState1 : ""}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: profileImageState1 ? "100%" : "",
                                                                                                            height: profileImageState1 ? "166px" : "",
                                                                                                        }}
                                                                                                    />
                                                                                                )}

                                                                                                {docExtension1 === 'application/pdf' && (

                                                                                                    <>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                            document.getElementById("attach1")?.click()
                                                                                                        }}>
                                                                                                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                        </svg>

                                                                                                    </>
                                                                                                )}

                                                                                                {docExtension1 === 'application/msword' && (
                                                                                                    <>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                            document.getElementById("attach1")?.click()
                                                                                                        }}>
                                                                                                            <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                        </svg>
                                                                                                    </>
                                                                                                )}

                                                                                            </div>
                                                                                            <input type="file" id='attach1' name="attach1" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage1Change(e.target.files[0])} className="form-control" style={{ display: profileImageState1 ? "none" : "" }} />
                                                                                            {profileImageState1 ? "" :
                                                                                                <span className="file_icons position-absolute">
                                                                                                    <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                    </svg>
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )} />
                                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach1?.message}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : <></>}
                                                        </div>
                                                    </div>
                                                    {certifiedSchool == "yes" ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-4">
                                                                    {/* <p className="mb-2">If No follow below </p> */}
                                                                    <p className="mt-2 mb-2">
                                                                        Enter your Teacher registration number
                                                                    </p>
                                                                    <input type="text" {...register("registration_number")} className="form-control" name="registration_number" />
                                                                </div>
                                                                {errors.registration_number && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.registration_number.message}</span></p>}
                                                            </div>
                                                        </>
                                                        : <></>}
                                                </> : <></>}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {teacherUnder18 == "yes" ?

                                                    <div className="form-group mb-4">
                                                        <label>
                                                            Are you currently teaching with a school board or at a private
                                                            school?
                                                            <span className="text-danger">*</span></label>
                                                        {/* <p className="mb-2">If yes follow below </p> */}
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" type="radio" id="yes_board_private" name="board_private" {...register("board_private")} onClick={(e) => private_school("yes")} />
                                                            <label htmlFor="yes_board_private" className="mb-2">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" type="radio" id="no_board_private" name="board_private" {...register("board_private")} onClick={(e) => private_school("no")} />
                                                            <label htmlFor="no_board_private" className="mb-2">
                                                                No
                                                            </label>
                                                        </div>
                                                        {errors.board_private && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.board_private.message}</span></p>}
                                                    </div>
                                                    : <></>}
                                            </div>
                                            {privateSchool == "yes" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">school name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" {...register("school_name")} name="school_name" />
                                                            {errors.school_name && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.school_name.message}</span></p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">full address of the school<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" {...register("school_address")} name="school_address" />
                                                            {errors.school_address && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.school_address.message}</span></p>}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">School contact phone number<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" {...register("school_phone_number")} name="school_phone_number" />
                                                            {errors.school_phone_number && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.school_phone_number.message}</span></p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">school contact email address<span className="text-danger">*</span></label>
                                                            <input type="email" className="form-control" {...register("school_email")} name="school_email" />
                                                            {errors.school_email && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.school_email.message}</span></p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">
                                                                Please upload a valid background check to teach children under 18 years
                                                                as per your country requirements. We accept a valid (not expired)
                                                                background check, it does not have to have our company information.
                                                                <span className="text-danger">*</span></label>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                        <Controller name="company_info_image" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                            <>
                                                                                <div className="file_update position-relative">
                                                                                    <div className="d-flex align-items-center justify-content-center">

                                                                                        {(docExtension2 === 'image/jpeg' || docExtension2 === 'image/png') && (
                                                                                            <img onClick={() => {
                                                                                                document.getElementById("company_info_image")?.click()
                                                                                            }}
                                                                                                className="image"
                                                                                                src={profileImageState2 ? profileImageState2 : ""}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: profileImageState2 ? "100%" : "",
                                                                                                    height: profileImageState2 ? "166px" : "",
                                                                                                }}
                                                                                            />
                                                                                        )}

                                                                                        {docExtension2 === 'application/pdf' && (

                                                                                            <>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                    document.getElementById("company_info_image")?.click()
                                                                                                }}>
                                                                                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                </svg>

                                                                                            </>
                                                                                        )}

                                                                                        {docExtension2 === 'application/msword' && (
                                                                                            <>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                    document.getElementById("company_info_image")?.click()
                                                                                                }}>
                                                                                                    <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                </svg>
                                                                                            </>
                                                                                        )}

                                                                                    </div>
                                                                                    <input type="file" id='company_info_image' name="company_info_image" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage2Change(e.target.files[0])} className="form-control" style={{ display: profileImageState2 ? "none" : "" }} />
                                                                                    {profileImageState2 ? "" :
                                                                                        <span className="file_icons position-absolute">
                                                                                            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )} />
                                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.company_info_image?.message}</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2">
                                                                What is the expiry date of the background check - Month and Year
                                                                <span className="text-danger">*</span></label>
                                                            <input type="date" className="form-control" {...register("bg_expiry_date")} name="bg_expiry_date" />
                                                            {errors.bg_expiry_date && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.bg_expiry_date.message}</span></p>}
                                                        </div>
                                                    </div>
                                                </> : <></>}
                                            {certifiedSchool === 'no' && privateSchool === "no" ? <>
                                                {teacherUnder18 == "yes" ? <>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            {/* <p className="mb-2">If No follow below </p> */}
                                                            <label className="mb-2">
                                                                To legally teach children, you are required to have a safety check completed
                                                                as per your country requirement.
                                                                <span className="text-danger">*</span></label>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-4">
                                                            {/* <p className="mb-2">If Yes follow below </p> */}
                                                            <label className="mb-2 d-block">
                                                                Do you have a valid legal check ?
                                                                <span className="text-danger">*</span></label>
                                                            <div className='d-flex gap-2' >
                                                                <input className="form-check-input" type="radio" id="yes_legal_check" name="legal_check" {...register("legal_check")} onClick={(e) => legal_Check("yes")} />
                                                                <label htmlFor="yes_legal_check">
                                                                    Yes
                                                                </label>
                                                                <input className="form-check-input" type="radio" id="no_legal_check" name="legal_check" {...register("legal_check")} onClick={(e) => legal_Check("no")} />
                                                                <label htmlFor="no_legal_check">
                                                                    No
                                                                </label>

                                                            </div>
                                                            {errors.legal_check && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.legal_check.message}</span></p>}
                                                            {legalCheck == "yes" ?
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                            <Controller name="legal_check_doc" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                <>
                                                                                    <div className="file_update position-relative">
                                                                                        <div className="d-flex align-items-center justify-content-center">

                                                                                            {(docExtension3 === 'image/jpeg' || docExtension3 === 'image/png') && (
                                                                                                <img onClick={() => {
                                                                                                    document.getElementById("legal_check_doc")?.click()
                                                                                                }}
                                                                                                    className="image"
                                                                                                    src={profileImageState3 ? profileImageState3 : ""}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: profileImageState3 ? "100%" : "",
                                                                                                        height: profileImageState3 ? "166px" : "",
                                                                                                    }}
                                                                                                />
                                                                                            )}

                                                                                            {docExtension3 === 'application/pdf' && (

                                                                                                <>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                        document.getElementById("legal_check_doc")?.click()
                                                                                                    }}>
                                                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                    </svg>

                                                                                                </>
                                                                                            )}

                                                                                            {docExtension3 === 'application/msword' && (
                                                                                                <>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                        document.getElementById("legal_check_doc")?.click()
                                                                                                    }}>
                                                                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                    </svg>
                                                                                                </>
                                                                                            )}

                                                                                        </div>
                                                                                        <input type="file" id='legal_check_doc' name="legal_check_doc" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage3Change(e.target.files[0])} className="form-control" style={{ display: profileImageState3 ? "none" : "" }} />
                                                                                        {profileImageState3 ? "" :
                                                                                            <span className="file_icons position-absolute">
                                                                                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                </svg>
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )} />
                                                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.legal_check_doc?.message}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <></>}
                                                        </div>
                                                    </div>
                                                    {legalCheck == "no" ?
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-4">
                                                                <p className="mb-2">If no follow below </p>
                                                                <label className="mb-2 d-block">
                                                                    If No - Please contact your local police station and upload the check
                                                                    required for teaching children under 18 years of age.
                                                                    <span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" {...register("country_you_live_in")} name="country_you_live_in" />
                                                                {errors.country_you_live_in && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country_you_live_in.message}</span></p>}
                                                            </div>

                                                        </div>
                                                        : <></>}

                                                </> : <></>}
                                                {teacherUnder18 == "yes" ? <>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            The following requirements are necessary to teach children below 18 years of
                                                            age. Please upload this document as per the country you live in:
                                                            <span className="text-danger">*</span></label>
                                                        {/* <input type="text" className="form-control" placeholder=" " name=" " defaultValue /> */}
                                                        <select className="form-select" {...register("country")} name="country"
                                                            onChange={(e) => orgCountryData(e.target.value)} aria-label="Default select example">
                                                            <option value="" >Select Country</option>

                                                            <option selected={OrgCountryData == 'Australia' ? 'selected' : ''} value="Australia" >Australia</option>
                                                            <option selected={OrgCountryData == 'Canada' ? 'selected' : ''} value="Canada">Canada</option>
                                                        
                                                            <option selected={OrgCountryData == 'New Zealand' ? 'selected' : ''} value="New Zealand">New Zealand</option>
                                                            <option selected={OrgCountryData == 'Singapore' ? 'selected' : ''} value="Singapore">Singapore</option>
                                                            <option selected={OrgCountryData == 'UK' ? 'selected' : ''} value="UK">UK</option>
                                                            <option selected={OrgCountryData == 'USA' ? 'selected' : ''} value="USA">USA</option>
                                                        </select>
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country?.message}</span></p>
                                                    </div>
                                                    {OrgCountryData == "Australia" ?
                                                        <div className="form-group mb-4">
                                                            <label className="mb-2 d-block">
                                                                If you reside in Australia - Work with children check
                                                                <span className="text-danger">*</span></label>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                        <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                            <>
                                                                                <div className="file_update position-relative">
                                                                                    <div className="d-flex align-items-center justify-content-center">

                                                                                        {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                            <img onClick={() => {
                                                                                                document.getElementById("country_bg_check")?.click()
                                                                                            }}
                                                                                                className="image"
                                                                                                src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: profileImageStateCountry ? "100%" : "",
                                                                                                    height: profileImageStateCountry ? "166px" : "",
                                                                                                }}
                                                                                            />
                                                                                        )}

                                                                                        {docExtension4 === 'application/pdf' && (

                                                                                            <>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                }}>
                                                                                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                </svg>

                                                                                            </>
                                                                                        )}

                                                                                        {docExtension4 === 'application/msword' && (
                                                                                            <>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                }}>
                                                                                                    <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                </svg>
                                                                                            </>
                                                                                        )}

                                                                                    </div>
                                                                                    <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                    {profileImageStateCountry ? "" :
                                                                                        <span className="file_icons position-absolute">
                                                                                            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : OrgCountryData == "Canada" ?
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2 d-block">
                                                                    If you reside in Canada - Vulnerable sector check
                                                                    <span className="text-danger">*</span></label>
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                            <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                <>
                                                                                    <div className="file_update position-relative">
                                                                                        <div className="d-flex align-items-center justify-content-center">

                                                                                            {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                                <img onClick={() => {
                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                }}
                                                                                                    className="image"
                                                                                                    src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: profileImageStateCountry ? "100%" : "",
                                                                                                        height: profileImageStateCountry ? "166px" : "",
                                                                                                    }}
                                                                                                />
                                                                                            )}

                                                                                            {docExtension4 === 'application/pdf' && (

                                                                                                <>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                        document.getElementById("country_bg_check")?.click()
                                                                                                    }}>
                                                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                    </svg>

                                                                                                </>
                                                                                            )}

                                                                                            {docExtension4 === 'application/msword' && (
                                                                                                <>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                        document.getElementById("country_bg_check")?.click()
                                                                                                    }}>
                                                                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                    </svg>
                                                                                                </>
                                                                                            )}

                                                                                        </div>
                                                                                        <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                        {profileImageStateCountry ? "" :
                                                                                            <span className="file_icons position-absolute">
                                                                                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                </svg>
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                                : OrgCountryData == "New Zealand" ?
                                                                    <div className="form-group mb-4">
                                                                        <label className="mb-2 d-block">
                                                                            If you reside in New Zeland - National Criminal background check
                                                                            <span className="text-danger">*</span></label>
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                                    <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                        <>
                                                                                            <div className="file_update position-relative">
                                                                                                <div className="d-flex align-items-center justify-content-center">

                                                                                                    {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                                        <img onClick={() => {
                                                                                                            document.getElementById("country_bg_check")?.click()
                                                                                                        }}
                                                                                                            className="image"
                                                                                                            src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                            alt=""
                                                                                                            style={{
                                                                                                                width: profileImageStateCountry ? "100%" : "",
                                                                                                                height: profileImageStateCountry ? "166px" : "",
                                                                                                            }}
                                                                                                        />
                                                                                                    )}

                                                                                                    {docExtension4 === 'application/pdf' && (

                                                                                                        <>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                                document.getElementById("country_bg_check")?.click()
                                                                                                            }}>
                                                                                                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                            </svg>

                                                                                                        </>
                                                                                                    )}

                                                                                                    {docExtension4 === 'application/msword' && (
                                                                                                        <>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                                document.getElementById("country_bg_check")?.click()
                                                                                                            }}>
                                                                                                                <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                            </svg>
                                                                                                        </>
                                                                                                    )}

                                                                                                </div>
                                                                                                <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                                {profileImageStateCountry ? "" :
                                                                                                    <span className="file_icons position-absolute">
                                                                                                        <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                        </svg>
                                                                                                    </span>
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : OrgCountryData == "Singapore" ?
                                                                        <div className="form-group mb-4">
                                                                            <label className="mb-2 d-block">
                                                                                If you reside in Singapore - Complete Criminal Background Check
                                                                                <span className="text-danger">*</span></label>
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                                        <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                            <>
                                                                                                <div className="file_update position-relative">
                                                                                                    <div className="d-flex align-items-center justify-content-center">

                                                                                                        {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                                            <img onClick={() => {
                                                                                                                document.getElementById("country_bg_check")?.click()
                                                                                                            }}
                                                                                                                className="image"
                                                                                                                src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                                alt=""
                                                                                                                style={{
                                                                                                                    width: profileImageStateCountry ? "100%" : "",
                                                                                                                    height: profileImageStateCountry ? "166px" : "",
                                                                                                                }}
                                                                                                            />
                                                                                                        )}

                                                                                                        {docExtension4 === 'application/pdf' && (

                                                                                                            <>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                                }}>
                                                                                                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                                </svg>

                                                                                                            </>
                                                                                                        )}

                                                                                                        {docExtension4 === 'application/msword' && (
                                                                                                            <>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                                }}>
                                                                                                                    <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                                </svg>
                                                                                                            </>
                                                                                                        )}

                                                                                                    </div>
                                                                                                    <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                                    {profileImageStateCountry ? "" :
                                                                                                        <span className="file_icons position-absolute">
                                                                                                            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        )} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : OrgCountryData == "UK" ?
                                                                            <div className="form-group mb-4">
                                                                                <label className="mb-2 d-block">
                                                                                    If you reside in UK - Enhanced Database Check
                                                                                    <span className="text-danger">*</span></label>
                                                                                <div className="row">
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                                            <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                                <>
                                                                                                    <div className="file_update position-relative">
                                                                                                        <div className="d-flex align-items-center justify-content-center">

                                                                                                            {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                                                <img onClick={() => {
                                                                                                                    document.getElementById("country_bg_check")?.click()
                                                                                                                }}
                                                                                                                    className="image"
                                                                                                                    src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                                    alt=""
                                                                                                                    style={{
                                                                                                                        width: profileImageStateCountry ? "100%" : "",
                                                                                                                        height: profileImageStateCountry ? "166px" : "",
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}

                                                                                                            {docExtension4 === 'application/pdf' && (

                                                                                                                <>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                                        document.getElementById("country_bg_check")?.click()
                                                                                                                    }}>
                                                                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                                    </svg>

                                                                                                                </>
                                                                                                            )}

                                                                                                            {docExtension4 === 'application/msword' && (
                                                                                                                <>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                                        document.getElementById("country_bg_check")?.click()
                                                                                                                    }}>
                                                                                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                                    </svg>
                                                                                                                </>
                                                                                                            )}

                                                                                                        </div>
                                                                                                        <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                                        {profileImageStateCountry ? "" :
                                                                                                            <span className="file_icons position-absolute">
                                                                                                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                                </svg>
                                                                                                            </span>
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            )} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : OrgCountryData == "USA" ?
                                                                                <div className="form-group mb-4">
                                                                                    <label className="mb-2 d-block">
                                                                                        If you reside in the USA - two checks are required. County-wide criminal
                                                                                        background check and sex offender registry search
                                                                                        <span className="text-danger">*</span></label>
                                                                                    <div className="row">
                                                                                        <div className="col-lg-4">
                                                                                            <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                                                <Controller name="country_bg_check" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                                                    <>
                                                                                                        <div className="file_update position-relative">
                                                                                                            <div className="d-flex align-items-center justify-content-center">

                                                                                                                {(docExtension4 === 'image/jpeg' || docExtension4 === 'image/png') && (
                                                                                                                    <img onClick={() => {
                                                                                                                        document.getElementById("country_bg_check")?.click()
                                                                                                                    }}
                                                                                                                        className="image"
                                                                                                                        src={profileImageStateCountry ? profileImageStateCountry : ""}
                                                                                                                        alt=""
                                                                                                                        style={{
                                                                                                                            width: profileImageStateCountry ? "100%" : "",
                                                                                                                            height: profileImageStateCountry ? "166px" : "",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}

                                                                                                                {docExtension4 === 'application/pdf' && (

                                                                                                                    <>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                                                            document.getElementById("country_bg_check")?.click()
                                                                                                                        }}>
                                                                                                                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                                                        </svg>

                                                                                                                    </>
                                                                                                                )}

                                                                                                                {docExtension4 === 'application/msword' && (
                                                                                                                    <>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                                                            document.getElementById("country_bg_check")?.click()
                                                                                                                        }}>
                                                                                                                            <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                                                        </svg>
                                                                                                                    </>
                                                                                                                )}

                                                                                                            </div>
                                                                                                            <input type="file" id='country_bg_check' name="country_bg_check" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageStateCountry ? "none" : "" }} />
                                                                                                            {profileImageStateCountry ? "" :
                                                                                                                <span className="file_icons position-absolute">
                                                                                                                    <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                )} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : <></>
                                                    }
                                                    <div className="form-group mb-4">
                                                        <p className="mb-2">
                                                            Information to include
                                                        </p>
                                                        <div className="mb-3">
                                                            <label className="mb-2 d-block">
                                                                A description of the position
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <input type="text" className="form-control"  {...register("Description_Of_The_Position")} name="Description_Of_The_Position" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="mb-2 d-block">
                                                                The name of the organization
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <input type="text" className="form-control"  {...register("name_of_org")} name="name_of_org" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="mb-2 d-block">
                                                                Address of the organization
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <input type="text" className="form-control"  {...register("address_of_org")} name="address_of_org" />
                                                        </div>
                                                        <div className="mb-1">
                                                            <label className="mb-2 d-block">
                                                                Staffing the position
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <input type="text" className="form-control"  {...register("staffing_position")} name="staffing_position" />
                                                        </div>
                                                    </div>
                                                </> : <></>}

                                            </> : <></>}

                                            {teacherUnder18 == "no" ?
                                                <>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            Safety Check:
                                                            <span className="text-danger">*</span></label>
                                                        <label className="mb-2 d-block">
                                                            Have you been convicted for violence, sexual abuse, child abuse, or neglect?
                                                            <span className="text-danger">*</span></label>
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" {...register("violence")} type="radio" id="yes_violence" name="violence" value="yes" />
                                                            <label htmlFor="yes_violence">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" {...register("violence")} type="radio" id="no_violence" name="violence" value="no" />
                                                            <label htmlFor="no_violence">
                                                                no
                                                            </label>
                                                        </div>
                                                        {errors.violence && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.violence.message}</span></p>}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            Do you have offences relating to possession or supply of illegal drugs?
                                                            <span className="text-danger">*</span></label>
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" type="radio" id="yes_illegal_drugs" name="illegal_drugs"  {...register("illegal_drugs")} value="yes" />
                                                            <label htmlFor="yes_illegal_drugs">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" type="radio" id="no_illegal_drugs" name="illegal_drugs"  {...register("illegal_drugs")} value="no" />
                                                            <label htmlFor="no_illegal_drugs">
                                                                no
                                                            </label>
                                                        </div>
                                                        {errors.illegal_drugs && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.illegal_drugs.message}</span></p>}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            Do you have any outstanding crimes that may affect the safety of our
                                                            learners?
                                                            <span className="text-danger">*</span></label>
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" type="radio" id="yes_safety" name="safety"  {...register("safety")} value="yes" />
                                                            <label htmlFor="yes_safety">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" type="radio" id="no_safety" name="safety"  {...register("safety")} value="no" />
                                                            <label htmlFor="no_safety">
                                                                no
                                                            </label>
                                                        </div>
                                                        {errors.safety && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.safety.message}</span></p>}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            Have you been convicted of Fraud and deception, Internet crimes, Statutory
                                                            crimes or Criminal intent?
                                                            <span className="text-danger">*</span></label>
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" type="radio" id="yes_Fraud" name="Fraud" {...register("Fraud")} value="yes" />
                                                            <label htmlFor="yes_Fraud">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" type="radio" id="no_Fraud" name="Fraud" {...register("Fraud")} value="no" />
                                                            <label htmlFor="no_Fraud">
                                                                no
                                                            </label>
                                                        </div>
                                                        {errors.Fraud && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.Fraud.message}</span></p>}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="mb-2 d-block">
                                                            Have you been barred from teaching children and adults in any country?
                                                            <span className="text-danger">*</span></label>
                                                        <div className="d-flex gap-2">
                                                            <input className="form-check-input" type="radio" id="yes_barred_from" name="barred_from" {...register("barred_from")} value="yes" />
                                                            <label htmlFor="yes_barred_from">
                                                                Yes
                                                            </label>
                                                            <input className="form-check-input" type="radio" id="no_barred_from" name="barred_from" {...register("barred_from")} value="no" />
                                                            <label htmlFor="no_barred_from">
                                                                no
                                                            </label>
                                                        </div>
                                                        {errors.barred_from && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.barred_from.message}</span></p>}
                                                    </div>

                                                </> : <></>
                                            }
                                            <div className="form-group mb-4">
                                                <label className="mb-2 d-block">
                                                    Any additional information you would like us to know
                                                </label>
                                                <input type="text" className="form-control" {...register('additional_info')} name="additional_info" />
                                            </div>
                                            <div className="form-group d-flex gap-4 align-items-center ">
                                                <input type="checkbox" id="checkbox" {...register('checkbox')} name='checkbox' />
                                                <label htmlFor="checkbox" className="d-block">
                                                    I declare that the above information provided by me is accurate and
                                                    truthful.
                                                </label>
                                            </div>
                                            <br></br> <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox?.message}</span></p>


                                            <br></br>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">Your Full Name:<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" {...register("full_name")} name="full_name" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.full_name?.message}</span></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2 mx-2">
                                                    Today’s Date :
                                                    </label>
                                                    <input type="date" className="form-control w-25 d-inline-block mr-2" {...register("date1")} min={StartDate} value={StartDate} name="date1" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date1?.message}</span></p>

                                                </div>               
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">Your Country of Residence :<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" {...register("country_of_residence")} name="country_of_residence" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country_of_residence?.message}</span></p>
                                                </div>
                                            </div>

                                            <div className="button mt-5">
                                                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
