import React, { useEffect, useState } from "react";
import { learner_avatar_images } from "../../api/apiHandler";

export default function LearnerAvatar({ setavtarModel, handleSelectAvtar, setavtarDone, setselectAvtar }) {
  const [imageName, setimageName] = useState("");

  var [Avtar, setAvtar] = useState([]);

  useEffect(() => {
    learner_avatar_images_details();
  }, []);

  const learner_avatar_images_details = () => {
    learner_avatar_images().then((resposnse) => {
      console.log(resposnse, "learner_avatar_images_details");

      if (resposnse?.code == 1) {
        setAvtar(resposnse?.data);
        console.log(resposnse?.data, "learner_avatar_images_details111");
      } else {
        console.log("data not found");
      }
    });
  };

  const abc = () => {
    handleSelectAvtar(imageName);
  };

  return (
    <>
      {/* <div className="modal fade" id="avtar_kids" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
      <div className="modal-dialog modal-lg" id="avtar_kids">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">Select Your Avatar</h1>
            <button
              type="button"
              onClick={() => {
                setavtarModel(false);
                setselectAvtar([]);
              }}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <ul className="list-unstyled your_avatar">
              {Avtar.map((avatar, index) => (
                <li key={index}>
                  <a onClick={() => setimageName(avatar.avatar_image)} className={`kid_img ${imageName === avatar.avatar_image ? "active" : ""}`}>
                    <img src={avatar.avatar_image} alt={avatar.image} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="col-lg-4 col-12">
              <button
                type="button"
                onClick={() => {
                  setavtarDone(true);
                  setavtarModel(false);
                  abc();
                }}
                className="btn-theme w-100 bg-yellow fw-600"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
